import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Image, Dropdown } from 'react-bootstrap';
import Moment from 'react-moment';
// import Fuse from 'fuse.js';
import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import MobileTopBar from '../../components/MobileTopBar';
import Footer from '../../components/Footer';
import SuccessModal from '../AddPoints/components/SuccessModal';
import FilterModal from './components/FilterModal';
import { useStore } from '../../store';
import { CartDiv } from './elements/styles';
// import { SearchBar } from '../Meetings/elements/styles';
import bgDesign from '../../assets/images/back-design.svg';
import UserHolder from '../../assets/images/profile-holder.svg';
import pointslisticon from '../../assets/images/pointshistory.svg';
import smWave from '../../assets/images/smwave.svg';
import wave from '../../assets/images/wave1.svg';
import points from '../../assets/images/pointstar.svg';
// import search from '../../assets/icons/search.svg';
import filter from '../../assets/icons/Filter2.svg';
import down from '../../assets/icons/meetingdown.svg';
import { HashLink as Link } from 'react-router-hash-link';
import Agenda from './components/Agenda';
import lockimg from '../../assets/icons/lock.svg';

const PointsHistory = observer(() => {
  const location = useHistory();
  const [selectedPoints, setSelectedPoints] = useState('Type');
  const [data, setData] = useState();
  const [success, setSuccess] = useState();
  const [displayDate, setDisplayDate] = useState();

  const { pointsStore, accountStore } = useStore();
  const { pointsHistory, listPointsHistory } = pointsStore;
  const { user, listRefreshPoints, refreshPoints } = accountStore;
  // const { allowRedemptiom, listAllowRedemption } = meetingStore;
  useEffect(() => {
    if (user?.consent === false) {
      location?.push(`/terms`);
    }
  }, [location, user]);
  // useEffect(() => {
  //   listAllowRedemption();
  // }, [listAllowRedemption]);

  

  useEffect(() => {
    listPointsHistory();
  }, [listPointsHistory]);
  useEffect(() => {
    listRefreshPoints();
    setTimeout(
  () => listRefreshPoints(), 
  2000
);
  }, [listRefreshPoints]);

  useEffect(() => {
    setData(pointsHistory);
  }, [pointsHistory]);

  // const searchData = (pattern) => {
  //   if (!pattern) {
  //     setData(pointsHistory);
  //     return;
  //   }
  //   const fuse = new Fuse(data, {
  //     keys: [
  //       'product.name',
  //       'product.engagement',
  //       'meeting.initiator.full_name',
  //       'meeting.engagement',
  //       'meeting.meetingparticipants_set.user.full_name',
  //     ],
  //   });

  //   const result = fuse.search(pattern);
  //   const matches = [];
  //   if (!result.length) {
  //     setData([]);
  //   } else {
  //     result.forEach(({ item }) => {
  //       matches.push(item);
  //     });
  //     setData(matches);
  //   }
  // };

  //   const UserCard = ({ participant, item }) => {
  //     return (
  //       <Link to={`/my-meetings#${item?.meeting?.uid} ` }>
  //       <div className='d-flex'>
  //         <Image
  //           src={
  //             item?.meeting?.meetingparticipants_set?.length <= 1 &&
  //             participant?.photo
  //               ? participant?.photo
  //               : UserHolder
  //           }
  //           alt='profile-pic'
  //           className='profile-image'
  //           roundedCircle
  //         />

  //         <div className='dm-info'>
  // {item?.meeting?.meetingparticipants_set?.length <= 1 ? (
  //   <div>
  //           <div className='points-head designation'>
  //             {item.product?.name}  with
  //           </div>

  //             <>
  //               <h6>{participant?.full_name}</h6>
  //               <div className='designation' >
  //                 {participant?.title},
  //               </div>
  //               <div className='designation' >
  //               {participant?.company_name}
  //                 </div>

  //             </>
  //             </div>
  //           ) : (
  //             <div>
  //             <div className='points-head designation'>
  //             {item.product?.name}
  //           </div>
  //             <div className='points-head designation'>
  //               Agenda :
  //             </div>
  //             <div className='points-head designation'>
  //                                         {item?.meeting?.request_message}
  //                                         </div>
  //             </div>

  //           )}
  //            {item?.note && (
  //                                         <div className='designation' >
  //                                           Note : {''}

  //                                            {item?.note}
  //                                         </div>)}
  //           <div className='designation'>
  //                   Meeting ID: {item?.meeting?.id}
  //                  </div>

  //                                         <div className='designation'>
  //                                       Transaction ID: {item.id}
  //                                         </div>
  //           <div className='designation time'>
  //             <Moment format=' DD MMMM YYYY h:mm A'>{item?.created_at}</Moment>
  //           </div>
  //         </div>
  //       </div></Link>
  //     );
  //   };

  return (
    <>
      <Helmet>
        <title>My Points | 121connects</title>
      </Helmet>
      <Navigation />
      <SuccessModal show={success} setShow={setSuccess} />
      <CartDiv>
        <MobileTopBar />
        <div className='glass'>
          <div className='glass-design d-none  d-lg-block'>
            <Image
              src={wave}
              alt='bg-design'
              className='wave-design d-none d-lg-block'
            />
          </div>
          <div className='holder'>
            {/* <button
              className='back-button '
              onClick={() => location.push('/')}
            ></button> */}
            <div className='d-lg-flex justify-content-between header-div'>
              <div>
                <h2 className='page-title '>Points</h2>

                {/* <SearchBar className='d-none d-lg-flex'>
                  <Form.Control
                    type='search'
                    id='search-tablet'
                    data-testid='search-input-tablet'
                    placeholder='Search points'
                    aria-label='Search'
                    aria-describedby='basic-addon1'
                    onChange={(e) => searchData(e.target.value)}
                  />
                  <button className='search-button' type='submit'>
                    <Image src={search} />
                  </button>
                </SearchBar> */}
              </div>
              <div>
                <div className='meeting-form'>
                  <div className='points-div d-flex align-items-start'>
                    <div className='d-flex top-points'>
                      <Image src={points} className='points-image' />
                      <div className='points-info'>
                        {user.user_type === 0
                          ? 'Available points'
                          : 'Account balance'}
                        <h5>{refreshPoints?.points}</h5>
                      </div>
                      {user.user_type === 0 &&
                      <div className='points-info'>
                         Redeemable points
                          
                        <h5>{refreshPoints?.redeemable_points}</h5>
                      </div>}
                    </div>
                    <div>
                      <button
                        className='btn-default'
                        onClick={() =>
                          user.user_type === 0
                            ? location.push('/rewards')
                            : setSuccess(true)
                        }
                      >
                        {user.user_type === 0 ? 'Redeem' : 'Add points'}
                      </button>
                    </div>
                  </div>
                  {/* <SearchBar className='d-flex d-lg-none'>
                    <Form.Control
                      type='search'
                      id='search-tablet-small'
                      data-testid='search-input-tablet'
                      placeholder='Search points'
                      aria-label='Search'
                      aria-describedby='basic-addon1'
                      onChange={(e) => searchData(e.target.value)}
                    />
                    <button className='search-button' type='submit'>
                      <Image src={search} />
                    </button>
                  </SearchBar> */}

                  <div className='d-flex drop align-items-baseline'>
                    <div className='filter-drop'>
                      <Image src={filter} /> Filter by
                    </div>

                    <Dropdown className='date-filter'>
                      <Dropdown.Toggle
                        variant='basic'
                        id='more'
                        className='filter-button'
                      >
                        {displayDate ? displayDate : 'Date'}
                        <Image src={down} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align='right'>
                        <FilterModal setDate={setDisplayDate} />
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='basic'
                        id='more1'
                        className='filter-button'
                      >
                        {selectedPoints ? selectedPoints : 'Type'}
                        <Image src={down} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu align='right'>
                        <Dropdown.Item
                          className={`engagement w-100 ${
                            selectedPoints === 'Type' ? 'active' : 'Earned'
                          }`}
                          onClick={() => {
                            setSelectedPoints('Type');
                          }}
                        >
                          All
                        </Dropdown.Item>
                        {user?.user_type === 1 ? (
                          <>
                            <Dropdown.Item
                              className={`engagement w-100 ${
                                selectedPoints === 'Spent' ? 'active' : 'Spent'
                              }`}
                              onClick={() => {
                                setSelectedPoints('Spent');
                              }}
                            >
                              Spent
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={`engagement w-100 ${
                                selectedPoints === 'Purchased' ? 'active' : ''
                              }`}
                              onClick={() => {
                                setSelectedPoints('Purchased');
                              }}
                            >
                              Purchased
                            </Dropdown.Item>
                          </>
                        ) : (
                          <>
                            <Dropdown.Item
                              className={`engagement w-100 ${
                                selectedPoints === 'Earned'
                                  ? 'active'
                                  : 'Earned'
                              }`}
                              onClick={() => {
                                setSelectedPoints('Earned');
                              }}
                            >
                              Earned
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={`engagement w-100 ${
                                selectedPoints === 'Redeemed' ? 'active' : ''
                              }`}
                              onClick={() => {
                                setSelectedPoints('Redeemed');
                              }}
                            >
                              Redeemed
                            </Dropdown.Item>
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <Image src={smWave} className='d-lg-none sm-wave' />
                </div>
              </div>
            </div>
            {(selectedPoints === 'Earned' ||
              selectedPoints === 'Purchased') && (
              <div className='w-100 cards'>
                <div className='contents'>
                  {data
                    ?.filter((item) => item.debit_or_credit === 'CREDIT')
                    .map((item) => (
                      <>
                        {item.due_to === 'RECHARGE' && (
                          <div
                            className='d-flex justify-content-between item-card'
                            key={item.id}
                          >
                            {item?.meeting ? (
                              <>
                                <div className='d-flex'>
                                  <Image
                                    src={
                                      item?.meeting?.meetingparticipants_set
                                        ?.length <= 1 &&
                                      item?.meeting?.initiator?.id === user.id
                                        ? [
                                            item?.meeting
                                              ?.meetingparticipants_set[0]?.user
                                              ?.photo
                                              ? item?.meeting
                                                  ?.meetingparticipants_set[0]
                                                  ?.user?.photo
                                              : UserHolder,
                                          ]
                                        : [
                                            item.meeting?.initiator?.photo
                                              ? item.meeting?.initiator?.photo
                                              : UserHolder,
                                          ]
                                    }
                                    alt='profile-pic'
                                    className='profile-image'
                                    roundedCircle
                                  />
                                  <div className='dm-info d-lg-flex align-items-start'>
                                    <div>
                                      {item?.meeting?.meetingparticipants_set
                                        ?.length <= 1 ? (
                                        <div>
                                          <div className='points-head designation'>
                                            {item.product?.name} with
                                          </div>

                                          <>
                                            <h6>
                                              {item?.meeting?.initiator?.id ===
                                              user.id
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.full_name
                                                : item?.meeting?.initiator
                                                    ?.full_name}
                                            </h6>
                                            <div className='designation'>
                                              {item?.meeting?.initiator?.id ===
                                              user.id
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.title
                                                : item?.meeting?.initiator
                                                    ?.title}
                                              ,
                                            </div>
                                            <div className='designation'>
                                              {item?.meeting?.initiator?.id ===
                                              user.id
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.company_name
                                                : item?.meeting?.initiator
                                                    ?.company_name}
                                            </div>
                                          </>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className='points-head designation'>
                                            {item.product?.name}
                                          </div>
                                          <Agenda item={item} />
                                        </div>
                                      )}
                                      <Agenda item={item} />

                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Meeting ID: {item?.meeting?.id}
                                      </div>

                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Transaction ID: {item.id}
                                      </div>
                                      <div className='designation time'>
                                        <Moment format=' DD MMMM YYYY h:mm A'>
                                          {item?.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    <div className='redeem-message'>
                                      Refunded
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column align-items-end'>
                                  <div
                                    className={`points ${
                                      item.debit_or_credit === 'CREDIT'
                                        ? 'added'
                                        : ''
                                    }`}
                                  >
                                    {item.debit_or_credit === 'DEBIT'
                                      ? '-'
                                      : '+'}
                                    {item?.points}
                                  </div>
                                  points
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='d-flex align-items-start'>
                                  <Image src={pointslisticon} />

                                  <div className='dm-info'>
                                    <h6>Points purchase</h6>
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column align-items-end'>
                                  <div
                                    className={`points ${
                                      item.debit_or_credit === 'CREDIT'
                                        ? 'added'
                                        : ''
                                    }`}
                                  >
                                    {item.debit_or_credit === 'DEBIT'
                                      ? '-'
                                      : '+'}
                                    {item?.points}
                                  </div>
                                  points
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        {item.due_to === 'SELF' && (
                          <div
                            className='d-flex justify-content-between item-card'
                            key={item.id}
                          >
                            {item?.meeting ? (
                              <>
                                <div className='d-flex'>
                                  <Image
                                    src={
                                      item?.meeting?.meetingparticipants_set
                                        ?.length <= 1 &&
                                      item?.meeting?.initiator?.id === user.id
                                        ? [
                                            item?.meeting
                                              ?.meetingparticipants_set[0]?.user
                                              ?.photo
                                              ? item?.meeting
                                                  ?.meetingparticipants_set[0]
                                                  ?.user?.photo
                                              : UserHolder,
                                          ]
                                        : [
                                            item.meeting?.initiator?.photo
                                              ? item.meeting?.initiator?.photo
                                              : UserHolder,
                                          ]
                                    }
                                    alt='profile-pic'
                                    className='profile-image'
                                    roundedCircle
                                  />
                                  <div className='dm-info d-lg-flex align-items-start'>
                                    <div>
                                      {item?.meeting?.meetingparticipants_set
                                        ?.length <= 1 ? (
                                        <Link
                                          to={`/my-meetings#${item?.meeting?.uid} `}
                                        >
                                          <div>
                                            <div className='points-head designation'>
                                              {item.product?.name} with
                                            </div>

                                            <>
                                              <h6>
                                                {item?.meeting?.initiator
                                                  ?.id === user.id
                                                  ? item?.meeting
                                                      ?.meetingparticipants_set[0]
                                                      ?.user?.full_name
                                                  : item?.meeting?.initiator
                                                      ?.full_name}
                                              </h6>
                                              <div className='designation'>
                                                {
                                                  item?.meeting?.initiator
                                                    ?.title
                                                }
                                                ,
                                              </div>
                                              <div className='designation'>
                                                {item?.meeting?.initiator
                                                  ?.id === user.id
                                                  ? item?.meeting
                                                      ?.meetingparticipants_set[0]
                                                      ?.user?.company_name
                                                  : item?.meeting?.initiator
                                                      ?.company_name}
                                              </div>
                                            </>
                                          </div>
                                        </Link>
                                      ) : (
                                        <div>
                                          <div className='points-head designation'>
                                            {item.product?.name}
                                          </div>
                                          <Agenda item={item} />
                                        </div>
                                      )}
                                      <Agenda item={item} />
                                      <div
                                        className='designation '
                                        id='transaction'
                                      >
                                        Meeting ID: {item?.meeting?.id}
                                      </div>

                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Transaction ID: {item.id}
                                      </div>
                                      <div className='designation time'>
                                        <Moment format=' DD MMMM YYYY h:mm A'>
                                          {item?.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column align-items-end'>
                                  <div
                                    className={`points ${
                                      item.debit_or_credit === 'CREDIT'
                                        ? 'added'
                                        : ''
                                    }`}
                                  >
                                    {item.debit_or_credit === 'DEBIT'
                                      ? '-'
                                      : '+'}
                                    {item?.points}
                                  </div>
                                  points
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='d-flex align-items-start'>
                                  <Image src={pointslisticon} />

                                  <div className='dm-info d-lg-flex align-items-start'>
                                    <div>
                                      <div className='points-head designation'>
                                        Points Redemption
                                      </div>
                                      <h6>{item?.product?.name}</h6>
                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Transaction ID: {item.id}
                                      </div>
                                      <div className='designation time'>
                                        <Moment format=' DD MMMM YYYY h:mm A'>
                                          {item?.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    <div className='redeem-message'>
                                      Refunded
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column align-items-end'>
                                  <div
                                    className={`points ${
                                      item.debit_or_credit === 'CREDIT'
                                        ? 'added'
                                        : ''
                                    }`}
                                  >
                                    {item.debit_or_credit === 'DEBIT'
                                      ? '-'
                                      : '+'}
                                    {item?.points}
                                  </div>
                                  points
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {item.due_to === 'REDEEM' && (
                          <div key={item.id} className='item-card'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-start'>
                                <Image src={pointslisticon} />

                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    <div className='points-head designation'>
                                      Points Redemption
                                    </div>
                                    <h6>{item?.product?.name}</h6>
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  <div className='redeem-message'>
                                    {item.redeem_success === true
                                      ? 'Completed'
                                      : item.redeem_success === false
                                      ? 'Failed'
                                      : 'Processing'}
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </div>
                          </div>
                        )}

                        {item.due_to === 'REVERSE' && (
                          <div key={item.id} className='item-card'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-start'>
                                <Image src={pointslisticon} />

                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    <h6>{item?.product?.name}</h6>
                                    <Agenda item={item} />
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>

                                  <div className='redeem-message'>Reversed</div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </div>
                          </div>
                        )}

                        {item.due_to === 'BONUS' && (
                          <div key={item.id} className='item-card'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-start'>
                                <Image src={pointslisticon} />

                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    <h6>{item?.product?.name}</h6>
                                    <Agenda item={item} />
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}

                  <div className='dispute-div d-lg-flex'>
                    Have a query about transactions points?{' '}
                    <button
                      className='d-flex align-items-center dispute-button'
                      onClick={() => location.push(`/dispute`)}
                    >
                      Raise a request
                    </button>
                  </div>
                </div>

                <Image
                  src={bgDesign}
                  alt='bg-design'
                  className='back-design d-none d-lg-block'
                />
              </div>
            )}

            {(selectedPoints === 'Redeemed' || selectedPoints === 'Spent') && (
              <div className='w-100 cards'>
                <div className='contents'>
                  {data
                    ?.filter((item) => item.debit_or_credit === 'DEBIT')
                    .map((item) => (
                      <>
                        {item.due_to === 'RECHARGE' && (
                          <div
                            className='d-flex justify-content-between item-card'
                            key={item.id}
                          >
                            {item?.meeting ? (
                              <>
                                <div className='d-flex'>
                                  <Image
                                    src={
                                      item?.meeting?.meetingparticipants_set
                                        ?.length <= 1 &&
                                      item?.meeting?.initiator?.id === user.id
                                        ? [
                                            item?.meeting
                                              ?.meetingparticipants_set[0]?.user
                                              ?.photo
                                              ? item?.meeting
                                                  ?.meetingparticipants_set[0]
                                                  ?.user?.photo
                                              : UserHolder,
                                          ]
                                        : [
                                            item.meeting?.initiator?.photo
                                              ? item.meeting?.initiator?.photo
                                              : UserHolder,
                                          ]
                                    }
                                    alt='profile-pic'
                                    className='profile-image'
                                    roundedCircle
                                  />
                                  <div className='dm-info d-lg-flex align-items-start'>
                                    <div>
                                      {item?.meeting?.meetingparticipants_set
                                        ?.length <= 1 ? (
                                        <div>
                                          <div className='points-head designation'>
                                            {item.product?.name} with
                                          </div>

                                          <>
                                            <h6>
                                              {item?.meeting?.initiator?.id ===
                                              user.id
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.full_name
                                                : item?.meeting?.initiator
                                                    ?.full_name}
                                            </h6>
                                            <div className='designation'>
                                              {item?.meeting?.initiator?.id ===
                                              user.id
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.title
                                                : item?.meeting?.initiator
                                                    ?.title}
                                              ,
                                            </div>
                                            <div className='designation'>
                                              {item?.meeting?.initiator?.id ===
                                              user.id
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.company_name
                                                : item?.meeting?.initiator
                                                    ?.company_name}
                                            </div>
                                          </>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className='points-head designation'>
                                            {item.product?.name}
                                          </div>
                                          <Agenda item={item} />
                                        </div>
                                      )}
                                      <Agenda item={item} />
                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Meeting ID: {item?.meeting?.id}
                                      </div>

                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Transaction ID: {item.id}
                                      </div>
                                      <div className='designation time'>
                                        <Moment format=' DD MMMM YYYY h:mm A'>
                                          {item?.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    <div className='redeem-message'>
                                      Refunded
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column align-items-end'>
                                  <div
                                    className={`points ${
                                      item.debit_or_credit === 'CREDIT'
                                        ? 'added'
                                        : ''
                                    }`}
                                  >
                                    {item.debit_or_credit === 'DEBIT'
                                      ? '-'
                                      : '+'}
                                    {item?.points}
                                  </div>
                                  points
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='d-flex align-items-start'>
                                  <Image src={pointslisticon} />

                                  <div className='dm-info'>
                                    <h6>Points purchase</h6>
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column align-items-end'>
                                  <div
                                    className={`points ${
                                      item.debit_or_credit === 'CREDIT'
                                        ? 'added'
                                        : ''
                                    }`}
                                  >
                                    {item.debit_or_credit === 'DEBIT'
                                      ? '-'
                                      : '+'}
                                    {item?.points}
                                  </div>
                                  points
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        {item.due_to === 'SELF' && (
                          <div
                            className='d-flex justify-content-between item-card'
                            key={item.id}
                          >
                            {item?.meeting ? (
                              <>
                                <div className='d-flex'>
                                  <Image
                                    src={
                                      item?.meeting?.meetingparticipants_set
                                        ?.length <= 1 &&
                                      item?.meeting?.initiator?.id === user.id
                                        ? [
                                            item?.meeting
                                              ?.meetingparticipants_set[0]?.user
                                              ?.photo
                                              ? item?.meeting
                                                  ?.meetingparticipants_set[0]
                                                  ?.user?.photo
                                              : UserHolder,
                                          ]
                                        : [
                                            item.meeting?.initiator?.photo
                                              ? item.meeting?.initiator?.photo
                                              : UserHolder,
                                          ]
                                    }
                                    alt='profile-pic'
                                    className='profile-image'
                                    roundedCircle
                                  />
                                  <div className='dm-info d-lg-flex align-items-start'>
                                    <div>
                                      {item?.meeting?.meetingparticipants_set
                                        ?.length <= 1 ? (
                                        <Link
                                          to={`/my-meetings#${item?.meeting?.uid} `}
                                        >
                                          <div>
                                            <div className='points-head designation'>
                                              {item.product?.name} with
                                            </div>

                                            <>
                                              <h6>
                                                {item?.meeting?.initiator
                                                  ?.id === user.id
                                                  ? item?.meeting
                                                      ?.meetingparticipants_set[0]
                                                      ?.user?.full_name
                                                  : item?.meeting?.initiator
                                                      ?.full_name}
                                              </h6>
                                              <div className='designation'>
                                                {
                                                  item?.meeting?.initiator
                                                    ?.title
                                                }
                                                ,
                                              </div>
                                              <div className='designation'>
                                                {item?.meeting?.initiator
                                                  ?.id === user.id
                                                  ? item?.meeting
                                                      ?.meetingparticipants_set[0]
                                                      ?.user?.company_name
                                                  : item?.meeting?.initiator
                                                      ?.company_name}
                                              </div>
                                            </>
                                          </div>
                                        </Link>
                                      ) : (
                                        <div>
                                          <div className='points-head designation'>
                                            {item.product?.name}
                                          </div>
                                          <Agenda item={item} />
                                        </div>
                                      )}
                                      <Agenda item={item} />
                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Meeting ID: {item?.meeting?.id}
                                      </div>

                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Transaction ID: {item.id}
                                      </div>
                                      <div className='designation time'>
                                        <Moment format=' DD MMMM YYYY h:mm A'>
                                          {item?.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column align-items-end'>
                                  <div
                                    className={`points ${
                                      item.debit_or_credit === 'CREDIT'
                                        ? 'added'
                                        : ''
                                    }`}
                                  >
                                    {item.debit_or_credit === 'DEBIT'
                                      ? '-'
                                      : '+'}
                                    {item?.points}
                                  </div>
                                  points
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='d-flex align-items-start'>
                                  <Image src={pointslisticon} />

                                  <div className='dm-info d-lg-flex align-items-start'>
                                    <div>
                                      <div className='points-head designation'>
                                        Points Redemption
                                      </div>
                                      <h6>{item?.product?.name}</h6>
                                      <Agenda item={item} />
                                      <div
                                        className='designation'
                                        id='transaction'
                                      >
                                        Transaction ID: {item.id}
                                      </div>
                                      <div className='designation time'>
                                        <Moment format=' DD MMMM YYYY h:mm A'>
                                          {item?.created_at}
                                        </Moment>
                                      </div>
                                    </div>
                                    <div className='redeem-message'>
                                      Refunded
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column align-items-end'>
                                  <div
                                    className={`points ${
                                      item.debit_or_credit === 'CREDIT'
                                        ? 'added'
                                        : ''
                                    }`}
                                  >
                                    {item.debit_or_credit === 'DEBIT'
                                      ? '-'
                                      : '+'}
                                    {item?.points}
                                  </div>
                                  points
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {item.due_to === 'REDEEM' && (
                          <div key={item.id} className='item-card'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-start'>
                                <Image src={pointslisticon} />

                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    <div className='points-head designation'>
                                      Points Redemption
                                    </div>
                                    <h6>{item?.product?.name}</h6>
                                    <Agenda item={item} />
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  <div className='redeem-message'>
                                    {item.redeem_success === true
                                      ? 'Completed'
                                      : item.redeem_success === false
                                      ? 'Failed'
                                      : 'Processing'}
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </div>
                          </div>
                        )}

                        {item.due_to === 'REVERSE' && (
                          <div key={item.id} className='item-card'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-start'>
                                <Image src={pointslisticon} />

                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    <h6>{item?.product?.name}</h6>
                                    <Agenda item={item} />
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>

                                  <div className='redeem-message'>Reversed</div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </div>
                          </div>
                        )}

                        {item.due_to === 'BONUS' && (
                          <div key={item.id} className='item-card'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-start'>
                                <Image src={pointslisticon} />

                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    {/* <div className='points-head designation'>
                                              Bonus
                                            </div> */}
                                    <h6>{item?.product?.name}</h6>
                                    <Agenda item={item} />
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  {/* <div className='redeem-message'>
                                           Awarded
                                          </div> */}
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}

                  <div className='dispute-div d-lg-flex'>
                    Have a query about transactions points?{' '}
                    <button
                      className='d-flex align-items-center dispute-button'
                      onClick={() => location.push(`/dispute`)}
                    >
                      Raise a request
                    </button>
                  </div>
                </div>

                <Image
                  src={bgDesign}
                  alt='bg-design'
                  className='back-design d-none d-lg-block'
                />
              </div>
            )}

            {selectedPoints === 'Type' && (
              <div className='w-100 cards'>
                <div className='contents'>
                  {data?.map((item) => (
                    <>
                      {item.due_to === 'RECHARGE' && (
                        <div
                          className='d-flex justify-content-between item-card'
                          key={item.id}
                        >
                          {item?.meeting ? (
                            <>
                              <div className='d-flex'>
                                <Image
                                  src={
                                    item?.meeting?.meetingparticipants_set
                                      ?.length <= 1 &&
                                    item?.meeting?.initiator?.id === user.id
                                      ? [
                                          item?.meeting
                                            ?.meetingparticipants_set[0]?.user
                                            ?.photo
                                            ? item?.meeting
                                                ?.meetingparticipants_set[0]
                                                ?.user?.photo
                                            : UserHolder,
                                        ]
                                      : [
                                          item.meeting?.initiator?.photo
                                            ? item.meeting?.initiator?.photo
                                            : UserHolder,
                                        ]
                                  }
                                  alt='profile-pic'
                                  className='profile-image'
                                  roundedCircle
                                />
                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    {item?.meeting?.meetingparticipants_set
                                      ?.length <= 1 ? (
                                      <div>
                                        <div className='points-head designation'>
                                          {item.product?.name} with
                                        </div>

                                        <>
                                          <h6>
                                            {item?.meeting?.initiator?.id ===
                                            user.id
                                              ? item?.meeting
                                                  ?.meetingparticipants_set[0]
                                                  ?.user?.full_name
                                              : item?.meeting?.initiator
                                                  ?.full_name}
                                          </h6>
                                          <div className='designation'>
                                            {item?.meeting?.initiator?.id ===
                                            user.id
                                              ? item?.meeting
                                                  ?.meetingparticipants_set[0]
                                                  ?.user?.title
                                              : item?.meeting?.initiator?.title}
                                            ,
                                          </div>
                                          <div className='designation'>
                                            {item?.meeting?.initiator?.id ===
                                            user.id
                                              ? item?.meeting
                                                  ?.meetingparticipants_set[0]
                                                  ?.user?.company_name
                                              : item?.meeting?.initiator
                                                  ?.company_name}
                                          </div>
                                        </>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className='points-head designation'>
                                          {item.product?.name}
                                        </div>
                                        <Agenda item={item} />
                                      </div>
                                    )}
                                    <Agenda item={item} />
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Meeting ID: {item?.meeting?.id}
                                    </div>

                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  <div className='redeem-message'>Refunded</div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='d-flex align-items-start'>
                                <Image src={pointslisticon} />

                                <div className='dm-info'>
                                  <h6>Points purchase</h6>
                                  <div className='designation' id='transaction'>
                                    Transaction ID: {item.id}
                                  </div>
                                  <div className='designation time'>
                                    <Moment format=' DD MMMM YYYY h:mm A'>
                                      {item?.created_at}
                                    </Moment>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      {item.due_to === 'SELF' && (
                        <div
                          className='d-flex justify-content-between item-card'
                          key={item.id}
                        >
                          {item?.meeting ? (
                            <>
                              <div className='d-flex'>
                                <Image
                                  src={
                                    user.vendor_admin
                                      ? [
                                          item?.meeting
                                            ?.meetingparticipants_set[0]?.user
                                            ?.photo
                                            ? item?.meeting
                                                ?.meetingparticipants_set[0]
                                                ?.user?.photo
                                            : UserHolder,
                                        ]
                                      : item?.meeting?.meetingparticipants_set
                                          ?.length <= 1 &&
                                        item?.meeting?.initiator?.id === user.id
                                      ? [
                                          item?.meeting
                                            ?.meetingparticipants_set[0]?.user
                                            ?.photo
                                            ? item?.meeting
                                                ?.meetingparticipants_set[0]
                                                ?.user?.photo
                                            : UserHolder,
                                        ]
                                      : [
                                          item.meeting?.initiator?.photo
                                            ? item.meeting?.initiator?.photo
                                            : UserHolder,
                                        ]
                                  }
                                  alt='profile-pic'
                                  className='profile-image'
                                  roundedCircle
                                />
                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    {item?.meeting?.meetingparticipants_set
                                      ?.length <= 1 ? (
                                      <Link
                                        to={`/my-meetings#${item?.meeting?.uid} `}
                                      >
                                        <div>
                                          <div className='points-head designation'>
                                            {item.product?.name} with
                                          </div>

                                          <>
                                            <h6>
                                              {user.vendor_admin === true
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.full_name
                                                : item?.meeting?.initiator
                                                    ?.id === user.id
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.full_name
                                                : item?.meeting?.initiator
                                                    ?.full_name}
                                            </h6>
                                            <div className='designation'>
                                              {item?.meeting?.initiator?.title},
                                            </div>
                                            <div className='designation'>
                                              {item?.meeting?.initiator?.id ===
                                              user.id
                                                ? item?.meeting
                                                    ?.meetingparticipants_set[0]
                                                    ?.user?.company_name
                                                : item?.meeting?.initiator
                                                    ?.company_name}
                                            </div>
                                          </>
                                        </div>
                                      </Link>
                                    ) : (
                                      <div>
                                        <div className='points-head designation'>
                                          {item.product?.name}

                                          
                                        </div>
                                      </div>
                                    )}                   
                                    <Agenda item={item} />
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Initiator:{' '}
                                      {item?.meeting?.initiator?.full_name}
                                    </div>
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Meeting ID: {item?.meeting?.id}
                                    </div>

                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='d-flex align-items-start'>
                                <Image src={pointslisticon} />

                                <div className='dm-info d-lg-flex align-items-start'>
                                  <div>
                                    <div className='points-head designation'>
                                      Points Redemption
                                    </div>
                                    <h6>{item?.product?.name}</h6>
                                    <Agenda item={item} />
                                    <div
                                      className='designation'
                                      id='transaction'
                                    >
                                      Transaction ID: {item.id}
                                    </div>
                                    <div className='designation time'>
                                      <Moment format=' DD MMMM YYYY h:mm A'>
                                        {item?.created_at}
                                      </Moment>
                                    </div>
                                  </div>
                                  <div className='redeem-message'>Refunded</div>
                                </div>
                              </div>
                              <div className='d-flex flex-column align-items-end'>
                                <div
                                  className={`points ${
                                    item.debit_or_credit === 'CREDIT'
                                      ? 'added'
                                      : ''
                                  }`}
                                >
                                  {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                  {item?.points}
                                </div>
                                points
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {item.due_to === 'REDEEM' && (
                        <div key={item.id} className='item-card'>
                          <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-start'>
                              <Image src={pointslisticon} />

                              <div className='dm-info d-lg-flex align-items-start'>
                                <div>
                                  <div className='points-head designation'>
                                    Points Redemption
                                  </div>
                                  <h6>{item?.product?.name}</h6>
                                  <Agenda item={item} />
                                  <div className='designation' id='transaction'>
                                    Transaction ID: {item.id}
                                  </div>
                                  <div className='designation time'>
                                    <Moment format=' DD MMMM YYYY h:mm A'>
                                      {item?.created_at}
                                    </Moment>
                                  </div>
                                </div>
                                <div className='redeem-message'>
                                  {item.redeem_success === true
                                    ? 'Completed'
                                    : item.redeem_success === false
                                    ? 'Failed'
                                    : 'Processing'}
                                </div>
                              </div>
                            </div>
                            <div className='d-flex flex-column align-items-end'>
                              <div
                                className={`points ${
                                  item.debit_or_credit === 'CREDIT'
                                    ? 'added'
                                    : ''
                                }`}
                              >
                                {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                {item?.points}
                              </div>
                              points
                            </div>
                          </div>
                        </div>
                      )}

                      {item.due_to === 'REVERSE' && (
                        <div key={item.id} className='item-card'>
                          <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-start'>
                              <Image src={pointslisticon} />

                              <div className='dm-info d-lg-flex align-items-start'>
                                <div>
                                  <h6>{item?.product?.name}</h6>
                                  <Agenda item={item} />
                                  <div className='designation' id='transaction'>
                                    Transaction ID: {item.id}
                                  </div>
                                  <div className='designation time'>
                                    <Moment format=' DD MMMM YYYY h:mm A'>
                                      {item?.created_at}
                                    </Moment>
                                  </div>
                                </div>

                                <div className='redeem-message'>Reversed</div>
                              </div>
                            </div>
                            <div className='d-flex flex-column align-items-end'>
                              <div
                                className={`points ${
                                  item.debit_or_credit === 'CREDIT'
                                    ? 'added'
                                    : ''
                                }`}
                              >
                                {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                {item?.points}
                              </div>
                              points
                            </div>
                          </div>
                        </div>
                      )}

                      {item.due_to === 'BONUS' && (
                        <div key={item.id} className='item-card'>
                          <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-start'>
                              <Image src={pointslisticon} />

                              <div className='dm-info d-lg-flex align-items-start'>
                                <div>
                                  {/* <div className='points-head designation'>
                                      Bonus
                                    </div> */}
                                  <h6>
                                    {item?.product?.name}{' '}
                                    
                                    {
                                      item.product_lock && (
                                        <span className='stat'>
                                          {' '}
                                          <Image src={lockimg} />
                                          <div className='stat-tooltip'>
                                            <p>
                                             {item?.product?.product_lock_details?.unlock_instruction}
                                            </p>
                                          </div>
                                        </span>
                                      )}
                                  </h6>
                                  <Agenda item={item} />
                                  <div className='designation' id='transaction'>
                                    Transaction ID: {item.id}
                                  </div>
                                  <div className='designation time'>
                                    <Moment format=' DD MMMM YYYY h:mm A'>
                                      {item?.created_at}
                                    </Moment>
                                  </div>
                                </div>
                                {/* <div className='redeem-message'>
                                   Awarded
                                  </div> */}
                              </div>
                            </div>
                            <div className='d-flex flex-column align-items-end'>
                              <div
                                className={`points ${
                                  item.debit_or_credit === 'CREDIT'
                                    ? 'added'
                                    : ''
                                }`}
                              >
                                {item.debit_or_credit === 'DEBIT' ? '-' : '+'}
                                {item?.points}
                              </div>
                              points
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}

                  <div className='dispute-div d-lg-flex'>
                    Have a query about transactions points?{' '}
                    <button
                      className='d-flex align-items-center dispute-button'
                      onClick={() => location.push(`/dispute`)}
                    >
                      Raise a request
                    </button>
                  </div>
                </div>

                <Image
                  src={bgDesign}
                  alt='bg-design'
                  className='back-design d-none d-lg-block'
                />
              </div>
            )}
          </div>
        </div>
      </CartDiv>
      <Footer />
      <BottomBar />
    </>
  );
});

export default PointsHistory;
