import React, { useEffect, useState } from 'react';
import { Image, Dropdown } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useLocation, useHistory } from 'react-router-dom';
// import { useForm } from "react-hook-form";
// import Fuse from 'fuse.js';
import BottomBar from '../../components/BottomBar';
import Footer from '../../components/Footer';
import MobileTopBar from '../../components/MobileTopBar';
// import UserMeetingCard from "./components/UserMeetingCard";
import CompanyMeetingCard from './components/CompanyMeetingCard';
import AcceptModal from '../MeetingDetails/components/AcceptModal';
// import ProjectMeetingCard from "./components/ProjectMeetingCard";
import Navigation from '../../components/TopBar';
import { useStore } from '../../store';
import { MeetingsDiv } from './elements/styles';
// import { StyledModal } from "../MeetingDetails/elements/styles";
// import logo from "../../assets/images/Logo-Black@2x.svg";
// import search from '../../assets/icons/search.svg';
import smWave from '../../assets/images/smwave.svg';
import wave from '../../assets/images/wave1.svg';
import down from '../../assets/icons/meetingdown.svg';
// import close from "../../assets/icons/close.svg";
import RejectModal from '../MeetingDetails/components/RejectModal';
import { Helmet } from 'react-helmet';
import filter from '../../assets/icons/Filter2.svg';
import moment from 'moment';
import EmailModal from '../MeetingDetails/components/EmailModal';

// import empty from "../../assets/images/empty.svg";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Meetings = observer(() => {
  const location = useHistory();
  const [data, setData] = useState();
  const [count, setCount] = useState(true);
  const [loaded, setloaded] = useState(false);
  const [mystate, setmystate] = useState('Status');
  const [showReject, setShowReject] = useState();
  const [showAccept, setShowAccept] = useState();
  const [emailAccept, setEmailAccept] = useState();

  const [selectedMeeting, setSelectedMeeting] = useState();

  // const { register, handleSubmit } = useForm();
  const [selectedStatus, setSelectedStatus] = useState();
  const { meetingStore, searchStore, accountStore } = useStore();
  const { listEngagements } = searchStore;
  const {
    listMeetings,
    meetings,
    onChangeMeetingListStatus,
    meetingProducts,
    listMeetingProducts,
    onFetchMeetingDetails,
  } = meetingStore;
  const [selectedInitiator, setSelectedInitiator] = useState();
  const { user } = accountStore;
  let query = useQuery();

  useEffect(() => {
    if (user?.consent === false) {
      location?.push(`/terms`);
    }
  }, [location, user]);

  useEffect(() => {
    if (count) {
      setCount(false);

      if (query.get('meeting')) {
        onFetchMeetingDetails(query.get('meeting')).then((res) => {
          if (res.data.status === 'REQUESTED') {
            if (query.get('action') === 'accept') {
              onChangeMeetingListStatus(query.get('meeting'), null, 'accept');
              location.replace({ search: '' });
              setShowAccept(true);
            } else if (query.get('action') === 'reject') {
              setShowReject(true);
            }
          } else {
            setEmailAccept(true);
          }
        });
      }
    }
  }, [
    query,
    onChangeMeetingListStatus,
    setCount,
    setShowReject,
    count,
    location,
    onFetchMeetingDetails,
  ]);

  useEffect(() => {
    listMeetingProducts();
  }, [listMeetingProducts]);

  useEffect(() => {
    listMeetings();
  }, [listMeetings]);
  useEffect(() => {
    listEngagements();
  }, [listEngagements]);

  // const onSubmit = (data) => {
  //   if (data.reject_reason) {
  //     data.status = "REJECTED";
  //     onChangeMeetingListStatus(query.get("meeting"), data, "reject");
  //     setShowReject(false);
  //     setError("");
  //   } else {
  //     setError("This field is required.");
  //   }
  // };

  useEffect(() => {
    setData(meetings);
  }, [meetings]);

  // const searchData = (pattern) => {
  //   if (!pattern) {
  //     setData(meetings);
  //     return;
  //   }
  //   const fuse = new Fuse(data, {
  //     keys: ['company_name', 'participants.user.full_name'],
  //   });

  //   console.log(data)
  //   const result = fuse.search(pattern);
  //   const matches = [];
  //   if (!result.length) {
  //     setData([]);
  //   } else {
  //     result.forEach(({ item }) => {
  //       matches.push(item);
  //     });
  //     setData(matches);
  //   }
  // };

  useEffect(() => {
    const pathArray = window.location.hash;
    if (pathArray) {
      const el1 = document.getElementById(pathArray.replace('#', ''));
      if (el1 !== null && el1 !== '' && !loaded) {
        setloaded(true);
        el1.scrollIntoView(true);
        const y = el1.getBoundingClientRect().top + window.pageYOffset - 130;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [loaded, data]);

  return (
    <>
      <Helmet>
        <title>Meetings | 121connects</title>
      </Helmet>
      <Navigation />
      <MeetingsDiv>
        <AcceptModal showAccept={showAccept} setShowAccept={setShowAccept} />
        <EmailModal showAccept={emailAccept} setShowAccept={setEmailAccept} />
        <RejectModal
          show={showReject}
          setShow={setShowReject}
          uuid={query.get('meeting')}
        />
        <MobileTopBar />
        <div className='glass'>
          <div className='glass-design d-none  d-lg-block'>
            <Image
              src={wave}
              alt='bg-design'
              className='wave-design d-none d-lg-block'
            />
          </div>

          <div className='holder'>
            <div className='header-div'>
              <Image src={smWave} className='d-lg-none sm-wave' />

              <h2>Meetings</h2>
              {/* <SearchBar className='d-none d-lg-flex'>
                <Form.Control
                  type='search'
                  id='search-tablet'
                  data-testid='search-input-tablet'
                  placeholder='Search meetings'
                  aria-label='Search'
                  aria-describedby='basic-addon1'
                  onChange={(e) => searchData(e.target.value)}
                />
                <button className='search-button' type='submit'>
                  <Image src={search} className='serch' />
                </button>
              </SearchBar> */}
            </div>
            <div className='d-none d-lg-flex align-items-center filter-div '>
              <img src={filter} alt='' className='filter-img' />
              Filter by
              <Dropdown>
                <Dropdown.Toggle
                  variant='basic'
                  id='more'
                  className='a_selectSidebar profile-pic'
                >
                  {selectedMeeting ? selectedMeeting.name : 'Meeting type'}
                  <Image src={down} />
                </Dropdown.Toggle>

                <Dropdown.Menu align='right'>
                  {meetingProducts.map((item) => (
                    <Dropdown.Item
                      key={item.id}
                      className={`engagement w-100 ${
                        selectedMeeting?.id === item.id ? 'active' : ''
                      }`}
                      onClick={() => {
                        setSelectedMeeting(item);
                        listMeetings(item.id);
                      }}
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item
                    className={`engagement w-100 ${
                      !selectedMeeting ? 'active' : ''
                    }`}
                    onClick={() => {
                      setSelectedMeeting(null);
                      listMeetings();
                    }}
                  >
                    All meetings
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant='basic'
                  id='more'
                  className='a_selectSidebar profile-pic'
                >
                  {selectedInitiator ? selectedInitiator : 'Initiated by'}
                  <Image src={down} />
                </Dropdown.Toggle>

                <Dropdown.Menu align='right'>
                  <Dropdown.Item
                    key=''
                    className={`engagement w-100 ${
                      selectedMeeting === 'Me' ? 'active' : ''
                    }`}
                    onClick={() => {
                      listMeetings(null, 'me');
                      setSelectedInitiator('Me');
                    }}
                  >
                    Me
                  </Dropdown.Item>
                  <Dropdown.Item
                    key=''
                    className={`engagement w-100 ${
                      selectedMeeting === 'Organizer' ? 'active' : ''
                    }`}
                    onClick={() => {
                      listMeetings(null, 'organizer');
                      setSelectedInitiator('Organizer');
                    }}
                  >
                    Organizer
                  </Dropdown.Item>

                  <Dropdown.Item
                    className={`engagement w-100 ${
                      !selectedMeeting ? 'Partner' : ''
                    }`}
                    onClick={() => {
                      listMeetings(null, 'vendor');
                      setSelectedInitiator('Partner');
                    }}
                  >
                    Partner
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={`engagement w-100 ${
                      selectedMeeting ? 'Peer' : ''
                    }`}
                    onClick={() => {
                      listMeetings(null, 'peer');
                      setSelectedInitiator('Peer');
                    }}
                  >
                    Peer
                  </Dropdown.Item>

                  <Dropdown.Item
                    className={`engagement w-100 ${
                      !selectedMeeting ? 'active' : ''
                    }`}
                    onClick={() => {
                      setSelectedInitiator(null);
                      listMeetings();
                    }}
                  >
                    All
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant='basic'
                  id='more'
                  className='a_selectSidebar profile-pic'
                >
                  {selectedStatus ? selectedStatus : 'Status'}
                  <Image src={down} />
                </Dropdown.Toggle>

                <Dropdown.Menu align='right'>
                  <Dropdown.Item
                    key='Accepted'
                    className={`engagement w-100 ${
                      selectedStatus === 'Accepted' ? 'active' : ''
                    }`}
                    onClick={() => {
                      setmystate('Accepted');
                      setSelectedStatus('Accepted');
                    }}
                  >
                    Accepted
                  </Dropdown.Item>
                  <Dropdown.Item
                    key='Cancelled'
                    className={`engagement w-100 ${
                      selectedStatus === 'Cancelled' ? 'active' : ''
                    }`}
                    onClick={() => {
                      setmystate('Cancelled');
                      setSelectedStatus('Cancelled');
                    }}
                  >
                    Cancelled
                  </Dropdown.Item>

                  <Dropdown.Item
                    key='Completed'
                    className={`engagement w-100 ${
                      selectedStatus === 'Completed' ? 'active' : ''
                    }`}
                    onClick={() => {
                      setmystate('Completed');
                      setSelectedStatus('Completed');
                    }}
                  >
                    Completed
                  </Dropdown.Item>
                  <Dropdown.Item
                    key='Regretted'
                    className={`engagement w-100 ${
                      selectedStatus === 'Regretted' ? 'active' : ''
                    }`}
                    onClick={() => {
                      setmystate('Regretted');
                      setSelectedStatus('Regretted');
                    }}
                  >
                    Regretted
                  </Dropdown.Item>
                  <Dropdown.Item
                    key='Requested'
                    className={`engagement w-100 ${
                      selectedStatus === 'Requested' ? 'active' : ''
                    }`}
                    onClick={() => {
                      setmystate('Requested');
                      setSelectedStatus('Requested');
                    }}
                  >
                    Requested
                  </Dropdown.Item>

                  <Dropdown.Item
                    key='Scheduled'
                    className={`engagement w-100 ${
                      selectedStatus === 'Scheduled' ? 'active' : ''
                    }`}
                    onClick={() => {
                      setmystate('Scheduled');
                      setSelectedStatus('Scheduled');
                    }}
                  >
                    Scheduled
                  </Dropdown.Item>

                  <Dropdown.Item
                    key='Status'
                    className={`engagement w-100 ${
                      !selectedStatus ? 'active' : ''
                    }`}
                    onClick={() => {
                      setmystate('Status');
                      setSelectedStatus('Status');
                    }}
                  >
                    All status
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className=' nav-contents'>
              <div className='d-flex flex-row nav-header'></div>
              <div className='w-100 cards'>
                {/* <SearchBar className='d-flex d-lg-none header-div'>
                  <Form.Control
                    type='search'
                    id='search-tablet-small'
                    data-testid='search-input-tablet'
                    className='small-form'
                    placeholder='Search meetings'
                    aria-label='Search'
                    aria-describedby='basic-addon1'
                    onChange={(e) => searchData(e.target.value)}
                  />
                  <button className='search-button' type='submit'>
                    <Image src={search} />
                  </button>
                </SearchBar> */}
                <div className='d-flex d-lg-none align-items-center filter-div'>
                  <img className='filter-img' src={filter} alt='' />
                  Filter by
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='basic'
                      id='more'
                      className='a_selectSidebar profile-pic'
                    >
                      {selectedMeeting ? selectedMeeting.name : 'Meeting type'}
                      <Image src={down} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu align='right'>
                      {meetingProducts.map((item) => (
                        <Dropdown.Item
                          key={item.id}
                          className={`engagement w-100 ${
                            selectedMeeting?.id === item.id ? 'active' : ''
                          }`}
                          onClick={() => {
                            setSelectedMeeting(item);
                            listMeetings(item.id);
                          }}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                      <Dropdown.Item
                        className={`engagement w-100 ${
                          !selectedMeeting ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedMeeting(null);
                          listMeetings();
                        }}
                      >
                        All meetings
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='basic'
                      id='more'
                      className='a_selectSidebar profile-pic'
                    >
                      {selectedInitiator ? selectedInitiator : 'Initiated by'}
                      <Image src={down} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu align='right'>
                      <Dropdown.Item
                        key=''
                        className={`engagement w-100 ${
                          selectedMeeting === 'Me' ? 'active' : ''
                        }`}
                        onClick={() => {
                          listMeetings(null, 'me');
                          setSelectedInitiator('Me');
                        }}
                      >
                        Me
                      </Dropdown.Item>

                      <Dropdown.Item
                        key=''
                        className={`engagement w-100 ${
                          selectedMeeting === 'Organizer' ? 'active' : ''
                        }`}
                        onClick={() => {
                          listMeetings(null, 'organizer');
                          setSelectedInitiator('Organizer');
                        }}
                      >
                        Organizer
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`engagement w-100 ${
                          selectedMeeting ? 'Partner' : ''
                        }`}
                        onClick={() => {
                          listMeetings(null, 'vendor');
                          setSelectedInitiator('Partner');
                        }}
                      >
                        Partner
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={`engagement w-100 ${
                          selectedMeeting ? 'Peer' : ''
                        }`}
                        onClick={() => {
                          listMeetings(null, 'peer');
                          setSelectedInitiator('Peer');
                        }}
                      >
                        Peer
                      </Dropdown.Item>

                      <Dropdown.Item
                        className={`engagement w-100 ${
                          !selectedMeeting ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedInitiator(null);
                          listMeetings();
                        }}
                      >
                        All
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant='basic'
                      id='more'
                      className='a_selectSidebar profile-pic'
                    >
                      {selectedStatus ? selectedStatus : 'Status'}
                      <Image src={down} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu align='right'>
                      <Dropdown.Item
                        key='Accepted'
                        className={`engagement w-100 ${
                          selectedStatus === 'Accepted' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setmystate('Accepted');
                          setSelectedStatus('Accepted');
                        }}
                      >
                        Accepted
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Cancelled'
                        className={`engagement w-100 ${
                          selectedStatus === 'Cancelled' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setmystate('Cancelled');
                          setSelectedStatus('Cancelled');
                        }}
                      >
                        Cancelled
                      </Dropdown.Item>

                      <Dropdown.Item
                        key='Completed'
                        className={`engagement w-100 ${
                          selectedStatus === 'Completed' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setmystate('Completed');
                          setSelectedStatus('Completed');
                        }}
                      >
                        Completed
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Regretted'
                        className={`engagement w-100 ${
                          selectedStatus === 'Regretted' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setmystate('Regretted');
                          setSelectedStatus('Regretted');
                        }}
                      >
                        Regretted
                      </Dropdown.Item>
                      <Dropdown.Item
                        key='Requested'
                        className={`engagement w-100 ${
                          selectedStatus === 'Requested' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setmystate('Requested');
                          setSelectedStatus('Requested');
                        }}
                      >
                        Requested
                      </Dropdown.Item>

                      <Dropdown.Item
                        key='Scheduled'
                        className={`engagement w-100 ${
                          selectedStatus === 'Scheduled' ? 'active' : ''
                        }`}
                        onClick={() => {
                          setmystate('Scheduled');
                          setSelectedStatus('Scheduled');
                        }}
                      >
                        Scheduled
                      </Dropdown.Item>

                      <Dropdown.Item
                        key='Status'
                        className={`engagement w-100 ${
                          !selectedStatus ? 'active' : ''
                        }`}
                        onClick={() => {
                          setmystate('Status');
                          setSelectedStatus('Status');
                        }}
                      >
                        All status
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className='contents'>
                  {mystate === 'Status' ? (
                    <>
                      {data
                        ?.filter(() => true)
                        .sort(function (a, b) {
                          if (
                            a.meeting_type !== '121 MEETING' &&
                            a.initiator_details.id !== user.id
                          ) {
                            var a_date = a?.participants.find(
                              (item) => item?.user?.id === user.id
                            )?.updated_at;
                          } else {
                            a_date = a?.updated_at;
                          }
                          if (
                            b.meeting_type !== '121 MEETING' &&
                            b.initiator_details.id !== user.id
                          ) {
                            var b_date = b?.participants.find(
                              (item) => item?.user?.id === user?.id
                            )?.updated_at;
                          } else {
                            b_date = b?.updated_at;
                          }
                          if (moment(a_date) < moment(b_date)) {
                            return 1;
                          }
                          if (moment(a_date) > moment(b_date)) {
                            return -1;
                          }
                          return 0;
                        })
                        .map((item) => (
                          <CompanyMeetingCard meeting={item} key={item.id} />
                        ))}
                    </>
                  ) : mystate === 'Requested' ? (
                    data
                      ?.filter((item) => {
                        if (
                          item.meeting_type !== '121 MEETING' &&
                          item.initiator_details.id !== user.id &&
                          item.status === 'SCHEDULED'
                        ) {
                          return (
                            item?.participants?.find(
                              (item) => item.user.id === user.id
                            )?.attendance === 'Not Responded'
                          );
                        } else {
                          return item.status === 'REQUESTED';
                        }
                      })

                      .sort(function (a, b) {
                        if (
                          a.meeting_type !== '121 MEETING' &&
                          a.initiator_details.id !== user.id
                        ) {
                          var a_date = a.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          a_date = a.updated_at;
                        }
                        if (
                          b.meeting_type !== '121 MEETING' &&
                          b.initiator_details.id !== user.id
                        ) {
                          var b_date = b.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          b_date = b.updated_at;
                        }
                        if (moment(a_date) < moment(b_date)) {
                          return 1;
                        }
                        if (moment(a_date) > moment(b_date)) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((item) => (
                        <CompanyMeetingCard meeting={item} key={item.id} />
                      ))
                  ) : mystate === 'Accepted' ? (
                    data
                      ?.filter((item) => item.status === 'ACCEPTED')
                      .sort(function (a, b) {
                        if (
                          a.meeting_type !== '121 MEETING' &&
                          a.initiator_details.id !== user.id
                        ) {
                          var a_date = a.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          a_date = a.updated_at;
                        }
                        if (
                          b.meeting_type !== '121 MEETING' &&
                          b.initiator_details.id !== user.id
                        ) {
                          var b_date = b.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          b_date = b.updated_at;
                        }
                        if (moment(a_date) < moment(b_date)) {
                          return 1;
                        }
                        if (moment(a_date) > moment(b_date)) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((item) => (
                        <CompanyMeetingCard meeting={item} key={item.id} />
                      ))
                  ) : mystate === 'Scheduled' ? (
                    data
                      ?.filter((item) => {
                        if (
                          item.meeting_type !== '121 MEETING' &&
                          item.initiator_details.id !== user.id &&
                          item.status === 'SCHEDULED'
                        ) {
                          return (
                            item?.participants?.find(
                              (item) => item.user.id === user.id
                            )?.attendance === 'Attending'
                          );
                        } else {
                          return item.status === 'SCHEDULED';
                        }
                      })
                      .sort(function (a, b) {
                        if (
                          a.meeting_type !== '121 MEETING' &&
                          a.initiator_details.id !== user.id
                        ) {
                          var a_date = a.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          a_date = a.updated_at;
                        }
                        if (
                          b.meeting_type !== '121 MEETING' &&
                          b.initiator_details.id !== user.id
                        ) {
                          var b_date = b.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          b_date = b.updated_at;
                        }
                        if (moment(a_date) < moment(b_date)) {
                          return 1;
                        }
                        if (moment(a_date) > moment(b_date)) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((item) => (
                        <CompanyMeetingCard meeting={item} key={item.id} />
                      ))
                  ) : mystate === 'Completed' ? (
                    data
                      ?.filter((item) => item.status === 'COMPLETED')
                      .sort(function (a, b) {
                        if (
                          a.meeting_type !== '121 MEETING' &&
                          a.initiator_details.id !== user.id
                        ) {
                          var a_date = a.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          a_date = a.updated_at;
                        }
                        if (
                          b.meeting_type !== '121 MEETING' &&
                          b.initiator_details.id !== user.id
                        ) {
                          var b_date = b.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          b_date = b.updated_at;
                        }
                        if (moment(a_date) < moment(b_date)) {
                          return 1;
                        }
                        if (moment(a_date) > moment(b_date)) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((item) => (
                        <CompanyMeetingCard meeting={item} key={item.id} />
                      ))
                  ) : mystate === 'Cancelled' ? (
                    data
                      ?.filter((item) => item.status === 'CANCELLED')
                      .sort(function (a, b) {
                        if (
                          a.meeting_type !== '121 MEETING' &&
                          a.initiator_details.id !== user.id
                        ) {
                          var a_date = a.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          a_date = a.updated_at;
                        }
                        if (
                          b.meeting_type !== '121 MEETING' &&
                          b.initiator_details.id !== user.id
                        ) {
                          var b_date = b.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          b_date = b.updated_at;
                        }
                        if (moment(a_date) < moment(b_date)) {
                          return 1;
                        }
                        if (moment(a_date) > moment(b_date)) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((item) => (
                        <CompanyMeetingCard meeting={item} key={item.id} />
                      ))
                  ) : (
                    data
                      ?.filter((item) => {
                        if (
                          item.meeting_type !== '121 MEETING' &&
                          item.initiator_details.id !== user.id &&
                          item.status === 'SCHEDULED'
                        ) {
                          return (
                            item?.participants?.find(
                              (item) => item.user.id === user.id
                            )?.attendance === 'Not Attending'
                          );
                        } else {
                          return item.status === 'REJECTED';
                        }
                      })

                      .sort(function (a, b) {
                        if (
                          a.meeting_type !== '121 MEETING' &&
                          a.initiator_details.id !== user.id
                        ) {
                          var a_date = a.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          a_date = a.updated_at;
                        }
                        if (
                          b.meeting_type !== '121 MEETING' &&
                          b.initiator_details.id !== user.id
                        ) {
                          var b_date = b.participants.find(
                            (item) => item.user.id === user.id
                          ).updated_at;
                        } else {
                          b_date = b.updated_at;
                        }
                        if (moment(a_date) < moment(b_date)) {
                          return 1;
                        }
                        if (moment(a_date) > moment(b_date)) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((item) => (
                        <CompanyMeetingCard meeting={item} key={item.id} />
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </MeetingsDiv>
      <Footer />
      <BottomBar />
    </>
  );
});

export default Meetings;
