import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Image } from 'react-bootstrap';
import { ConnectDiv } from '../../../components/ConnectModal/styles';
import check from '../../../assets/icons/check.svg';
import { useHistory } from 'react-router-dom';

const SuccessModal = observer(({ show }) => {
  const location = useHistory();
  return (
    <ConnectDiv show={show}>
      <div>
        <div className='success-circle'>
          <Image src={check} id='checkCircle' />
        </div>
        <h2 className='my-2'>We have received your request.</h2>
        <h4>121 connects team will contact you shortly.</h4>
        <div className='d-flex justify-content-center'>
          <Button
            variant='light'
            onClick={() => {
              location.push('/my-points');
            }}
            className='btn-default a_sendButton'
          >
            Back to points
          </Button>
        </div>
      </div>
    </ConnectDiv>
  );
});

export default SuccessModal;
