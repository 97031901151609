class LocalStorageService {
  static setToken = (tokenObj) => {
    localStorage.setItem("access_token", tokenObj.access);
    localStorage.setItem("refresh_token", tokenObj.refresh);
  };

  static getAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  static getRefreshToken = () => {
    return localStorage.getItem("refresh_token");
  };

  static clearToken = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  };

  static getUser = () => {
    const loggedUser = localStorage.getItem("user");
    return loggedUser ? JSON.parse(loggedUser) : null;
  };
}
export default LocalStorageService;
