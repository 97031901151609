import { makeAutoObservable } from 'mobx';
import {
  getSearchResults,
  getCities,
  getStates,
  getInterests,
  getEngagements,
  getHRanges,
  getTRanges,
  getRoles,
  getProfiles,
  getIndustries,
  getPreviousSearch,
  getSearchinRoles,
} from '../../api/search';

class SearchStore {
  searchResultsLoading = false;

  searchResults = [];

  rangeChecked = [];

  selectedRegionList = [];

  roleL1List = [];

  roleL2List = [];

  industryL2List = [];

  industryL1List = [];

  selectedHRangeList = [];

  selectedManuList = [];

  selectedInterList = [];

  selectedInterObject = [];

  selectedCitiesList = [];

  key = '';

  typeFilters = [];

  cities = [];

  states = [];

  interests = [];

  engagements = [];

  // tags = [];

  hranges = [];

  tranges = [];

  roles = [];

  searchinRoles = [];

  profiles = [];

  profileL1List = [];

  profileL2List = [];

  industries = [];

  suggestedResults = [];

  selectedInterestList = [];

  previousSearches = [];

  searchResultCount = '';

  paginateLoading = false;

  sortBy = 'user_asc';

  constructor() {
    makeAutoObservable(this);
  }

  clearAllFilters = () => {
    this.rangeChecked = [];

    this.selectedRegionList = [];

    this.roleL1List = [];

    this.roleL2List = [];

    this.industryL2List = [];

    this.industryL1List = [];

    this.selectedHRangeList = [];

    this.selectedManuList = [];

    this.selectedInterList = [];

    this.selectedInterObject = [];

    this.selectedCitiesList = [];

    this.profileL1List = [];

    this.profileL2List = [];

    this.selectedInterestList = [];
  };

  setSearchLoading = (value) => {
    this.searchResultsLoading = value;
  };

  setCities = (data, flag) => {
    if (flag) {
      this.cities = [...data, { name: 'OTHERS' }];
    } else {
      this.cities = data;
    }
  };

  setSortBy = (option) => {
    this.sortBy = option;
    this.listSearchResults();
  };

  listCities = async (flag) => {
    const res = await getCities(flag);
    this.setCities(res.data, flag);
  };

  setStates = (data) => {
    this.states = data;
  };

  setPreviousSearches = (data) => {
    this.previousSearches = data;
  };

  listPreviousSearches = async () => {
    const res = await getPreviousSearch();
    if (res.status === 200) {
      this.setPreviousSearches(res.data);
    }
  };

  listStates = async () => {
    const res = await getStates();
    this.setStates(res.data);
  };

  setInterests = (data) => {
    this.interests = data;
  };

  listInterests = async () => {
    const res = await getInterests();
    this.setInterests(res.data);
  };

  // setTags = (data) => {
  //   this.tags = data;
  // };

  // listTags = async () => {
  //   const res = await getTags();
  //   this.setTags(res.data);
  // };

  setHRange = (data) => {
    this.hranges = data;
  };

  listHRanges = async () => {
    const res = await getHRanges();
    this.setHRange(res.data);
  };

  setTRange = (data) => {
    this.tranges = data;
  };

  listTRanges = async () => {
    const res = await getTRanges();
    this.setTRange(res.data);
  };

  setEngagements = (data) => {
    this.engagements = data;
  };

  listEngagements = async () => {
    const res = await getEngagements();
    this.setEngagements(res.data);
  };

  setRoles = (data) => {
    this.roles = data;
  };

  setSearchinRoles = (data) => {
    this.searchinRoles = data;
  };

  listRoles = async () => {
    const res = await getRoles(true);
    this.setRoles(res.data);
  };

  listSearchinRoles = async () => {
    const res = await getSearchinRoles(true);
    this.setSearchinRoles(res.data);
  };

  setProfiles = (data) => {
    this.profiles = [...data, { name: 'OTHERS', subprofiles: [] }];
  };

  listProfiles = async () => {
    const res = await getProfiles();
    this.setProfiles(res.data);
  };

  setIndustries = (data) => {
    this.industries = [...data, { industryl1: 'OTHERS', industryl2: [] }];
  };

  listIndustries = async () => {
    const res = await getIndustries();
    this.setIndustries(res.data);
  };

  setKey = (key) => {
    this.key = key;
    // this.clearAllFilters();
  };

  onProfileListChanged = (value, checked, parent) => {
    if (parent) {
      if (checked) {
        this.profileL1List.push(value);
      } else {
        const index = this.profileL1List.findIndex((item) => item === value);
        this.profileL1List.splice(index, 1);
      }
    } else {
      const index = this.profileL2List.findIndex((item) => item === value);

      if (checked) {
        if (index < 0) {
          this.profileL2List.push(value);
        }
      } else {
        this.profileL2List.splice(index, 1);
      }
    }
  };

  onCityListChanged = (target) => {
    if (target.checked) {
      this.selectedCitiesList.push(target.value);
    } else {
      const index = this.selectedCitiesList.findIndex(
        (item) => item === target.value
      );
      this.selectedCitiesList.splice(index, 1);
    }
  };

  onInterestListChanged = (target) => {
    if (target.checked) {
      this.selectedInterestList.push(target.value);
    } else {
      const index = this.selectedInterestList.findIndex(
        (item) => item === target.value
      );
      this.selectedInterestList.splice(index, 1);
    }
  };

  onInterListChanged = (target, label) => {
    if (target.checked) {
      this.selectedInterList = [target.value];
      this.selectedInterObject = [{ value: target.value, label }];
    } else {
      const index = this.selectedInterList.findIndex(
        (item) => item === target.value
      );
      this.selectedInterList.splice(index, 1);
      this.selectedInterObject.splice(index, 1);
    }
  };

  onManuListChanged = (target) => {
    if (target.checked) {
      this.selectedManuList = [target.value];
    } else {
      const index = this.selectedManuList.findIndex(
        (item) => item === target.value
      );
      this.selectedManuList.splice(index, 1);
    }
  };

  onTrangeListChanged = (target) => {
    if (target.checked) {
      this.rangeChecked.push(target.value);
    } else {
      const index = this.rangeChecked.findIndex(
        (item) => item === target.value
      );
      this.rangeChecked.splice(index, 1);
    }
  };

  onHrangeListChanged = (target) => {
    if (target.checked) {
      this.selectedHRangeList.push(target.value);
    } else {
      const index = this.selectedHRangeList.findIndex(
        (item) => item === target.value
      );
      this.selectedHRangeList.splice(index, 1);
    }
  };

  onRegionListChange = (target) => {
    if (target.checked) {
      this.selectedRegionList.push(target.value);
    } else {
      const index = this.selectedRegionList.findIndex(
        (item) => item === target.value
      );
      this.selectedRegionList.splice(index, 1);
    }
  };

  onRoleListChanged = (value, checked) => {
    if (checked) {
      this.roleL2List.push(value);
    } else {
      const index = this.roleL2List.findIndex((item) => item === value);
      this.roleL2List.splice(index, 1);
    }
  };

  onRoleListChanged = (value, checked, parent) => {
    if (parent) {
      if (checked) {
        this.roleL1List.push(value);
      } else {
        const index = this.roleL1List.findIndex((item) => item === value);
        this.roleL1List.splice(index, 1);
      }
    } else {
      const index = this.roleL2List.findIndex((item) => item === value);
      if (checked) {
        if (index < 0) {
          this.roleL2List.push(value);
        }
      } else {
        this.roleL2List.splice(index, 1);
      }
    }
  };

  onIndustryListChanged = (value, checked, parent) => {
    if (parent) {
      if (checked) {
        this.industryL1List.push(value);
      } else {
        const index = this.industryL1List.findIndex((item) => item === value);
        this.industryL1List.splice(index, 1);
      }
    } else {
      const index = this.industryL2List.findIndex((item) => item === value);
      if (checked) {
        if (index < 0) {
          this.industryL2List.push(value);
        }
      } else {
        this.industryL2List.splice(index, 1);
      }
    }
  };

  setSearchResults = (results) => {
    this.searchResults = results;
  };
  setSearchresultcount = (results) => {
    this.searchResultCount = results;
  };
  paginatePages = '';
  setPaginatePages = (results) => {
    this.paginatePages = results;
  };

  listSearchResults = async () => {
    this.setSearchLoading(true);
    this.setSearchResults('');

    let rolel1,
      rolel2,
      industryl2,
      industryl1,
      region,
      turnoverrange,
      headcountrange,
      manufacturing_or_service,
      brickmortar_or_internet,
      search_in,
      city,
      profile,
      subprofile,
      interest,
      sort_by;

    sort_by = this.sortBy;
    if (this.roleL1List.length) {
      rolel1 = this.roleL1List.join(',');
    }
    if (this.roleL2List.length) {
      rolel2 = this.roleL2List.join(',');
    }
    if (this.industryL1List.length) {
      industryl1 = this.industryL1List.join(',');
    }
    if (this.industryL2List.length) {
      industryl2 = this.industryL2List.join(',');
    }
    if (this.selectedRegionList.length) {
      region = this.selectedRegionList.join(',');
    }
    if (this.rangeChecked.length) {
      turnoverrange = this.rangeChecked.join(',');
    }
    if (this.selectedHRangeList.length) {
      headcountrange = this.selectedHRangeList.join(',');
    }
    if (this.selectedManuList.length) {
      manufacturing_or_service = this.selectedManuList.join(',');
    }
    if (this.selectedInterList.length) {
      brickmortar_or_internet = this.selectedInterList.join(',');
    }
    if (this.typeFilters.length && this.typeFilters.length !== 7) {
      search_in = this.typeFilters.join(',');
    }
    if (this.selectedCitiesList.length) {
      city = this.selectedCitiesList.join(',');
    }
    if (this.profileL1List.length) {
      profile = this.profileL1List.join(',');
    }
    if (this.profileL2List.length) {
      subprofile = this.profileL2List.join(',');
    }
    if (this.selectedInterestList.length) {
      interest = this.selectedInterestList.join(';');
    }
    if (this.key.length) {
      let offset = this?.searchResults?.length;

      const res = await getSearchResults(offset, this.key, {
        rolel1,
        rolel2,
        industryl1,
        industryl2,
        region,
        turnoverrange,
        headcountrange,
        manufacturing_or_service,
        brickmortar_or_internet,
        search_in,
        city,
        profile,
        subprofile,
        interest,
        sort_by,
      });
      if (res.status === 200) {
        this.setSearchResults(res.data.results);
        this.setSearchresultcount(res?.data?.count);
        this.setPaginatePages(res?.data?.next);
        // history.push(`/search?q=${this.key}`);
      }
    }
    this.setSearchLoading(false);
  };
  setPaginateresultloading = (results) => {
    this.paginateLoading = results;
  };
  paginateSearchResults = async () => {
    let rolel1,
      rolel2,
      industryl2,
      industryl1,
      region,
      turnoverrange,
      headcountrange,
      manufacturing_or_service,
      brickmortar_or_internet,
      search_in,
      city,
      profile,
      subprofile,
      interest,
      sort_by;

    sort_by = this.sortBy;
    if (this.roleL1List.length) {
      rolel1 = this.roleL1List.join(',');
    }
    if (this.roleL2List.length) {
      rolel2 = this.roleL2List.join(',');
    }
    if (this.industryL1List.length) {
      industryl1 = this.industryL1List.join(',');
    }
    if (this.industryL2List.length) {
      industryl2 = this.industryL2List.join(',');
    }
    if (this.selectedRegionList.length) {
      region = this.selectedRegionList.join(',');
    }
    if (this.rangeChecked.length) {
      turnoverrange = this.rangeChecked.join(',');
    }
    if (this.selectedHRangeList.length) {
      headcountrange = this.selectedHRangeList.join(',');
    }
    if (this.selectedManuList.length) {
      manufacturing_or_service = this.selectedManuList.join(',');
    }
    if (this.selectedInterList.length) {
      brickmortar_or_internet = this.selectedInterList.join(',');
    }
    if (this.typeFilters.length && this.typeFilters.length !== 7) {
      search_in = this.typeFilters.join(',');
    }
    if (this.selectedCitiesList.length) {
      city = this.selectedCitiesList.join(',');
    }
    if (this.profileL1List.length) {
      profile = this.profileL1List.join(',');
    }
    if (this.profileL2List.length) {
      subprofile = this.profileL2List.join(',');
    }
    if (this.selectedInterestList.length) {
      interest = this.selectedInterestList.join(';');
    }

    if (this.key.length && this.paginatePages !== null) {
      this.setPaginateresultloading(true);

      let offset = this?.searchResults?.length;
      const res = await getSearchResults(offset, this.key, {
        rolel1,
        rolel2,
        industryl1,
        industryl2,
        region,
        turnoverrange,
        headcountrange,
        manufacturing_or_service,
        brickmortar_or_internet,
        search_in,
        city,
        profile,
        subprofile,
        interest,
        sort_by,
      });
      if (res.status === 200) {
        this.setPaginateresultloading(false);

        this.searchResults = this.searchResults.concat(res?.data?.results);
        this.setSearchresultcount(res?.data?.count);
      }
    }
    this.setSearchLoading(false);
    this.setPaginateresultloading(false);
  };

  setSearchIn = (value) => {
    this.typeFilters = [value];
  };

  clearTypeFilters = () => {
    this.typeFilters = [];
  };

  setTypeFilters = (target) => {
    if (target.checked) {
      this.typeFilters.push(target.value);
    } else {
      const index = this.typeFilters.findIndex((item) => item === target.value);
      this.typeFilters.splice(index, 1);
    }
    this.listSearchResults();
  };

  setSuggestResults = (data) => {
    this.suggestedResults = data?.results?.slice(0, 5);
  };

  listSuggestResults = async (key) => {
    let data = {};
    data.predict = true;
    data.sort_by = 'user_asc';
    let offset = 0;
    const res = await getSearchResults(offset, key, data);
    if (res.status === 200) {
      this.setSuggestResults(res.data);
    }
  };
}

export default SearchStore;
