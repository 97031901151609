import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const MeetingDiv = styled.div`
  margin: 0 auto 0 auto;
  position: relative;
  padding: 11px;
  padding-bottom: 80px;

  .glass {
    background: rgba(235, 255, 254, 0.2);
    margin: 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding-bottom: 10px;
  }

  .logo {
    margin: 40px 0px 30px 24px;
  }

  .meeting-data {
    max-width: 894px;

    @media only screen and (min-width: 992px) {
      margin: 27px 0px 12px 80px;
      border-radius: 5px;
      padding: 40px 50px 40px 50px;
    }
    h2 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    background: #fff;
    padding: 20px;

    border-radius: 5px;

    .blur {
      color: transparent;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      .profile-image {
        filter: blur(4px);
      }
    }

    .profile-image {
      width: 70px;
      height: 70px;
    }

    .employee-image {
      width: 26px;
      height: 26px;
    }

    .company-head {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .company-info {
      padding-top: 20px;
      margin-bottom: 14px;
      border-top: 0.5px solid #b9b9b9;

      .company-details {
        h6,
        p {
          margin-bottom: 0;
        }
        h6 {
          font-size: 14px;
          font-weight: 700;
        }
        p {
          font-size: 13px;
        }
      }
    }

    .prof-info {
      margin-left: 10px;
      h3,
      p {
        margin-bottom: 0;
      }

      p {
        color: #666666;
        font-size: 13px;
      }
    }

    .employee-info {
      border-bottom: 0.5px solid #b9b9b9;
      padding-bottom: 30px;
      .employee-text {
        padding-left: 10px;
      }
      h6 {
        font-weight: 700;
        margin-bottom: 0;
      }
      p {
        font-size: 10px;
      }
    }

    .btn {
      margin: 5px;
    }
    .btn-secondary,
    .btn-secondary:hover,
    .btn-secondary:focus {
      background: transparent;
      color: #00aeae;
      border: 0;
    }
    .btn-default {
      padding: 8px 57px;
      width: fit-content;
    }

    .description {
      padding-top: 20px;
      white-space: break-spaces;

      h4 {
        margin-bottom: 5px;
      }
      p {
        color: #666666;
        white-space: break-spaces;
      }
    }
  }

  .interest {
    color: #666666;
    text-transform: capitalize;
    margin-bottom: 13px;
  }

  .requested {
    background: #e3e3f0;
  }

  .rejected,
  .cancelled {
    background: #ffeeee;
  }

  .scheduled {
    background: #fbf5e6;
  }

  .schedule-details {
    .meeting-time {
      font-size: 10px;
      padding-left: 10px;
    }
    @media only screen and (max-width: 992px) {
      border-bottom: 0.5px solid #b9b9b9;
    }
  }

  .time-details {
    padding-top: 20px;
    .calendar {
      margin-right: 12px;
    }
    p {
      font-size: 10px;
      margin-bottom: 0;
    }
    time {
      font-weight: 700;
      color: #333333;
    }
  }

  .connect-info {
    padding-top: 27px;
  }

  .company-image {
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 2px solid #f4f4f4;
    margin-right: 10px;
    p {
      margin: auto;
      font-size: 15px;
    }
  }

  .button-group {
    margin-top: 17px;
    background: #fbf5e6;
    padding: 23px 47px 20px 27px;
  }

  .participant-buttons {
    font-weight: 700;
    margin-top: 17px;

    .form-check {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const StyledModal = styled(Modal)`
  .modal-content {
    padding: 10px 10px 25px 26px;

    h3 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    form {
      padding: 15px 40px 10px 0px;
    }
    .react-datetime-picker__wrapper {
      border: 0.5px solid #cdeaf0;
      background: #ebf4f9;
    }
    .form-control {
      background: #ebf4f9;
      font-size: 12px;
      font-weight: 600;
      color: #403c3c;
      box-shadow: none;
      border: 0.5px solid #cdeaf0;
      padding: 13px 16px 13px 20px;
    }

    .form-label {
      font-weight: 600;
      color: #333333;
    }

    .form-check {
      margin-top: 20px;
    }
    .form-check-label {
      margin-left: 10px;
      color: #333333;
      font-size: 12px;
      font-weight: 500;
    }

    .danger {
      border: 0.5px solid red;
    }

    .text-danger {
      font-size: 12px;
    }

    @media only screen and (min-width: 992px) {
      padding-top: 30px;
      .form-control {
        font-size: 14px;
      }
    }

    .btn-default {
      padding: 9px 36px;
      margin-top: 26px;
      max-width: 220px;
    }
  }
  .a_closeButton {
    background: transparent;
    border: 0;
    align-self: flex-end;
  }

  .reject-terms {
    color: #666666;
    max-width: 400px;
  }
`;
