import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { FooterDiv } from './footer';

import logo from '../../assets/icons/footerlogo.svg';
import linkedin from '../../assets/icons/Icon-Linkedin1.svg';

import { useStore } from '../../store';

const Footer = () => {
  const { accountStore } = useStore();
  const { footer, listFooter } = accountStore;

  useEffect(() => {
    listFooter();
  }, [listFooter]);

  return (
    <FooterDiv className='d-none d-lg-block'>
      <div className='footer d-flex align-items-center justify-content-between'>
        <Image src={logo} className='footer-logo' />
        <div className='d-flex foot-contents'>
          {footer.map((item) => (
            <div className='button-group'>
              <a href={item.link} target='_blank' rel='noreferrer'>
                <button className='link' id='terms'>
                  {item.text}
                </button>
              </a>
            </div>
          ))}

          <p className='copyright'>
            {' '}
            {process.env.REACT_APP_FOOTER_COPYRIGHT}
          </p>
          <div className='icons'>
            <a
              href={process.env.REACT_APP_API_LINKEDIN_LINK}
              target='_blank'
              rel='noreferrer'
            >
              <img src={linkedin} className='linked-logo' alt='icon' />{' '}
            </a>
          </div>
        </div>
      </div>
    </FooterDiv>
  );
};

export default Footer;
