import styled from "styled-components";

export const LoginDiv = styled.div`
  margin: 0 auto 0 auto;
  padding: 76px 18px 45px 17px;
  max-width: 600px;
  border-radius: 0.5rem;
  position: relative;

  .tr {
    position: absolute;
    top: -1px;
    right: 17px;
    z-index: 1;
  }

  .welcome {
    color: #797979;
    font-size: 14px;
  }

  .danger {
    border: 1px solid #ff647f !important;
  }
 .scroll-msg{
  
  font-size: 13px;
 }
  .glass {
   
    padding: 0;
    margin: 0;
    border-radius: 5px;
    margin-top: -252px;
    margin-left: -19px;

    @media only screen and (max-width:600px){
      margin: auto;
      top: -189px;
      position: relative;
  }
    }
  }
  .wave-design{
    margin: auto;
    top: 27px;
    position: relative;
    top: -94px;
    width: 335px;
    height: 237px;
    right:-30px;

    @media only screen and (min-width:900px){
      margin-left: -181px;
    }
  }

  .logo {
    margin: 40px 0px 30px 40px;
  }

  .newlogo{
    position: absolute;
    left: 13.54%;
    right: 55.21%;
    top: 16.68%;
    bottom: 73.93%;
    @media only screen and (max-width:600px){
      top: 11.68%;
      left: 13.54%;
  }
    
  }



.login-title{
  font-size: 1.5rem;
  margin-top: 6rem;
}
  .form-hold {
    background: white;
    padding: 4em 2em 3em 5em;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    // @media only screen and (min-width: 920px) {
    //   padding-left: 5.5em;
    //   background: white;
    // padding: 4em 2em 1em 2em;
    // border-bottom-left-radius: 5px;
    // border-bottom-right-radius: 5px;
      
    // }
    @media only screen and (max-width: 600px){
      padding: 4em 3em 3em 3em;
      margin-bottom: -186px;
    }
  }

  
    #termsCond{
    background: white;
    padding: 4em 2em 3em 5em;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @media only screen and (min-width: 920px) {
      padding-left: 5.5em;
      background: white;
    padding: 4em 2em 1em 2em;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
      
    }
    @media only screen and (max-width: 600px){
      padding: 4em 3em 3em 3em;
      margin-bottom: -186px;
    }
  }

  .form-hold h2 {
    color: #32e0e0;
    background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media only screen and (max-width:600px){
      font-size: 24px
    }
  }

  .form-hold form {
    width: 100%;
  }

  .a_loginResponseText {
    margin-top: -30px;
    color: #ff647f;
    font-weight: 600;
  
  }

  .form-label {
    color: #797979;
    margin: 1em 0 0 0;
    margin-top: 3px
    font-size: 14px
  @media only screen and (max-width:600px){
      font-size: 12px
    }}  

  .password-control {
    margin: 0 0 2em 0;
  }
  .form-control {
    margin: 0.5em 0;
    background: #ebf4f9;
    padding: 0.5em 1em;
    border: 1px solid #cdeaf0;
    border-radius: 5px;
    height: 45px;
    font-size: 1em;
    color: #403c3c;
    @media only screen and (min-width: 500px) {
      width: 350px;
    }
  }

  .loginForm {
    .toggleButton {
      border: none;
      background: transparent;
      color: #7a7a7a;
      border-right: none;
      font-size: 20px !important;
      margin: 5px 0px 10px -35px;
    }
    #togglePassword {
      width: 18px;
      height: 18px;
    }
  }

  .form-control:focus {
    box-shadow: none;
    border: 1px solid #48bcd177;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    background-color: #cdeaf0;
    border: 0.5px solid #cdeaf0 !important;
  }

  .form-check-label {
    color: #797979;
    margin: 0 auto 0 9px;
  }

  .a_loginButton {
    margin-top: 33px;
    height: 45px;
    margin-top: 22px;
    padding: 1px;
   
  }
 #reject_terms{
  border:none;
  background:none;
 }

  .a_loginForgotPassword {
    color: #1ec6c6;
    font-weight: 600;
    padding: 0;
    margin-top: 1px;
    background: 0;
    border: 0;
    font-size:14px;
    @media only screen and (max-width:600px){
      font-size: 12px;
    }
  }

  .a_requestMembership {
    margin-top: 19px;
    width: 245px;
    font-size:13px;
    @media only screen and (max-width:600px){
      font-size: 13px
    }

    a {
      margin-left: 5px;
    }
  }

  @media only screen and (min-width: 1025px) {
    min-width: 600px;
  }

  .terms-backbtn{
    display: flex;
    justify-content: flex-end;
  }

  .back-closebtn{
    
    cursor:pointer;
    @media only screen and (min-width: 992px){
      margin-right: 22px;
    
    }
  }
   
  .terms-root{
    padding: 1px 1px 241px 1px;
    @media only screen and (min-width:992px){
      padding: 1px 1px 10px 1px;
      margin-top: -63px;
    }
  }

  .terms-logo{
    position: absolute;
    left: 16.54%;
    right: 55.21%;
    top: 12.68%;
    bottom: 73.93%;
    @media only screen and (min-width:992px){
      position: absolute;
    left: 16.54%;
    right: 55.21%;
    top: 7.68%;
    bottom: 73.93%;
    }
}
  }

  .terms {
    button {
      color: #00aeae;
      font-weight: 700;
      font-size: 14px;
    }

    .btn-default {
      width: fit-content;
      padding: 5px 20px;
      color: #505050;
      margin-right: 18px;
    }
  }

  .terms-content {
    max-height: 400px;
    overflow: auto;
    @media only screen and (min-width: 1025px) {
      margin-right: 20px;
    }

    .scroll-content {
      max-width: 456px;
    }

    ::-webkit-scrollbar {
      width: 9px;
    }

    ::-webkit-scrollbar-track {
      border-left: 1px solid grey;
    }

    ::-webkit-scrollbar-thumb {
      background: grey;
    }
  }
  .condition {
    font-size: 12px;
    max-width: 400px;
  }
  .terms1{
    border:none;
   
    border: none;
    background: transparent;
    color: #fff;
  
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
  font-size: 11px;
}
}
.privacy{
  border:none;
    position: absolute;
    border: none;
    background: transparent;
    color: #fff;
    margin-left: 149px;
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
  font-size: 11px;
  margin-left: 130px;
}
}
.cookie{
  border:none;
    position: absolute;
    border: none;
    background: transparent;
    color: #fff;
    margin-left: 257px;
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
  font-size: 11px;
  margin-left: 231px;
}
}
  }
  .face-logo{
    position: absolute;
    left: 79.72%;
    right: 33.93%;
    top: 90.6%;
    bottom: 20.88%;
    @media only screen and (max-width:600px){
      position: absolute;
      left: 7.72%;
      right: 33.93%;
      top: 91.6%;
      bottom: 20.88%;
    }
  }
  .linked-logo{
    position: absolute;
left: 85.6%;
right: 32.05%;
top: 90.6%;
bottom: 20.88%;
@media only screen and (max-width:600px){
  position: absolute;
  left: 15.6%;
  right: 32.05%;
  top: 91.6%;
  bottom: 20.88%;
  }}
  .twitter-logo{
    position: absolute;
left: 91.1%;
right: 30.18%;
top: 90.6%;
bottom: 20.88%;
@media only screen and (max-width:600px){
  position: absolute;
    left: 23.1%;
    right: 30.18%;
    top: 91.6%;
    bottom: 20.88%;
}

  }
  .icon-div{
   
    @media only screen and (max-width:600px){
      margin-top: 32px;
  }}
  
  .login-title{
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 28px;

  }

  .toggle-icon{
    @supports (-webkit-touch-callout: none) {
      margin-left: -18px;
    }
  }
  


`;
