import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { Col, Form, Button, Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useStore } from '../../store';
import { LoginDiv } from './elements/styles';
import background from '../../assets/images/Backgroundnew.svg.svg';

import show from '../../assets/icons/Show.svg';
import showtoggle from '../../assets/icons/toggleshow.svg';
import LogoBlack from '../../assets/images/LogoBlack.png';
import linkedin from '../../assets/icons/Icon-Linkedin.svg';
import wave from '../../assets/images/waveDesign.svg';

const Login = observer(() => {
  const location = useHistory();
  const { authStore, accountStore } = useStore();
  const { loginResponseText, onLogin, setLoginResponseText, setNextUrl } =
    authStore;
  const { footer, listFooter } = accountStore;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(true);

  /** calls the onLogin function from the store to perform the login and redirect to home page on success and sets error on failure
   * @params {object} data - contains email address and password
   */
  const onSubmit = (data) => {
    onLogin(data);

    setLoginResponseText(null);
  };

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    listFooter();
  }, [listFooter]);

  const query = new URLSearchParams(useLocation().search);

  setNextUrl(query.get('next'));

  return (
    <LoginDiv className='login-page'>
      <Helmet>
        <title>Login | 121connects</title>
        <meta
          id='meta-description'
          name='description'
          content="121connects helps you find and network with a curated list of India's leading CIOs, CISOs, CSOs, and IT Leaders in this members exclusive community"
        />
        <style>
          {`body { 
            
            background: 0; 
            background-image:  url(${background}); 
            background-position: left center ;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 105vh; }`}
        </style>
      </Helmet>
      <img src={wave} alt='' className='wave-design' />
      <div className='glass'>
        <img src={LogoBlack} alt='' className='newlogo' />

        <div className='form-hold'>
          <h2 className='login-title'>Sign in</h2>

          <Form className='loginForm' onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} sm={12} controlId='emailAddress'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  size='sm'
                  type='text'
                  name='email'
                  className={classNames({
                    danger: errors.email || loginResponseText,
                  })}
                  {...register('email', { required: true })}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm={12} controlId='password'>
                <Form.Label>Password</Form.Label>
                <div className='d-flex password-control'>
                  <Form.Control
                    size='sm'
                    type={showPassword ? 'password' : 'text'}
                    name='password'
                    {...register('password', {
                      required: 'Password is required.',
                    })}
                    className={classNames({
                      danger: errors.password || loginResponseText,
                    })}
                  />
                  <button
                    onClick={() => toggleVisibility()}
                    className='toggleButton'
                    type='button'
                  >
                    <Image
                      src={showPassword ? show : showtoggle}
                      id='togglePassword'
                      className='toggle-icon'
                    />
                  </button>
                </div>

                <div
                  id='login-Status-Text'
                  className='small mb-0 a_loginResponseText'
                >
                  {errors.password && errors.password.message
                    ? errors.password.message
                    : loginResponseText}
                </div>
                <button
                  onClick={() => location.push('/reset-password')}
                  className='a_loginForgotPassword'
                  id='forgot-password'
                  type='button'
                >
                  Forgot Password?
                </button>
              </Form.Group>
            </Form.Row>
            <Button
              variant='light'
              className='a_loginButton btn-default'
              type='submit'
              id='login_confirm'
            >
              Sign in
            </Button>

            <div className='a_requestMembership text-center'>
              New here?
              <a href='https://www.121connects.com'>Sign up</a>
            </div>
          </Form>
        </div>
      </div>
      <div className='d-flex'>
        {footer.map((item) => (
          <div className='button-group d-flex'>
            <a href={item.link} target='_blank' rel='noreferrer'>
              <button className='terms1' id='terms'>
                {item.text}
              </button>
            </a>
          </div>
        ))}
      </div>

      <div className='icon-div'>
        <a
          href={process.env.REACT_APP_API_LINKEDIN_LINK}
          target='_blank'
          rel='noreferrer'
        >
          <img src={linkedin} className='linked-logo' alt='icon' />{' '}
        </a>
      </div>
    </LoginDiv>
  );
});

export default Login;
