import React from 'react';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../../store';
import connect from '../../../assets/icons/carticon.svg';
import MobSearch from './MobSearch';

const ScrollTopBar = observer(() => {
  const location = useHistory();
  const { meetingStore } = useStore();
  const { cart } = meetingStore;
  return (
    <>
      <MobSearch />

      <div className='cart-div'>
        <button className='cart' onClick={() => location.push('/cart')}>
          {cart?.members_details?.length > 0 && (
            <div className='cart-count'>{cart.members_details.length}</div>
          )}
          <Image src={connect} />
        </button>
      </div>
    </>
  );
});

export default ScrollTopBar;
