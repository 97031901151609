import React from "react";
import { observer } from "mobx-react-lite";
// import { Form, Image } from "react-bootstrap";
// import Fuse from "fuse.js";
// import { SearchBar } from "../elements/styles";
import IndustryCard from "./IndustryCard";
// import industries from "../../../assets/data/industry.json";
import { useStore } from "../../../store";
// import search from "../../../assets/icons/search.svg";

const IndustryFilter = observer(({ desktop }) => {
  const { searchStore } = useStore();
  const { industries } = searchStore;
  // const [data, setData] = useState();

  // const searchData = (pattern) => {
  //   if (!pattern) {
  //     setData(industries);
  //     return;
  //   }
  //   const fuse = new Fuse(data, {
  //     keys: ["industryl1", "industryl2.industryl2"],
  //   });

  //   const result = fuse.search(pattern);
  //   const matches = [];
  //   if (!result.length) {
  //     setData([]);
  //   } else {
  //     result.forEach(({ item }) => {
  //       matches.push(item);
  //     });
  //     setData(matches);
  //   }
  // };

  return (
    <div>
      {/* <SearchBar onChange={(e) => searchData(e.target.value)}>
        <Form.Control
          type="search"
          id="search-tablet-industry"
          data-testid="search-input-tablet"
          className="search-filter"
          placeholder="Type to search"
          aria-label="Search"
          aria-describedby="basic-addon1"
        />

        <button className="search-button" type="submit">
          <Image src={search} />
        </button>
      </SearchBar> */}
      {industries.map((item) => {
        return (
          <IndustryCard item={item} key={item.industryl1} desktop={desktop} />
        );
      })}
    </div>
  );
});

export default IndustryFilter;
