import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

/** calls the login api and sets the user and token in the local storage if success
 * @params {object} formData - contains email address and password
 * @return status as true if success or false on failure
 */
const login = (formData) => {
  return instance.post("token/", formData).then(
    (res) => res,
    (err) => err.response
  );
};

const sendResetOtp = (email, otp) => {
  return instance.post("/users/password/reset/", { email, otp }).then(
    (res) => res,
    (err) => err.response
  );
};

const resetPassword = (email, otp, password) =>
  instance
    .post("/users/password/reset/confirm/", { email, otp, password })
    .then(
      (res) => res,
      (err) => err.response
    );

export { login, sendResetOtp, resetPassword };
