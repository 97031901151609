import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const SwitchModal = styled(Modal)`
  .modal-content {
    padding: 30px 36px 50px 30px;

    .close {
      background: #fff;
      border: 0;
    }

    h2 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-top: 8px;
    }

    .button-group {
      padding-top: 26px;
      button {
        font-size: 14px;
      }
      .secondary {
        border: 0;
        background: #fff;
        color: #00aeae;
        font-weight: 700;
      }
      .btn-default {
        width: 130px;
        padding: 8px 30px;
      }
    }
  }
`;
