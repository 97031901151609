import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import { SuccessDiv } from "../elements/styles";

import close from "../../../assets/icons/close.svg";
import { useStore } from "../../../store";


const SuccessModal = observer(({ show, meeting }) => {
  const location = useHistory();
  const {accountStore} = useStore();
  const {user} = accountStore
  return (
    <SuccessDiv show={show}>
      <div>
        <div className="d-flex justify-content-end">
         
          <Image src={close} onClick={() => location.push("/")} />
        </div>

        <h2 className="my-2"> Meeting request received</h2>
        {user.user_type === 0 ? 
        <h4>
        121connects will follow-up on your behalf. You can check the status under 'Meetings'.
        </h4>: <h4>Thank you for using 121connects. We will follow-up on your behalf to schedule this meeting. You can check the status under 'Meetings'. </h4>}
        <div className="button-group">
          <Button
            variant="light"
            onClick={() => location.push("/my-meetings")}
            className="btn-default a_sendButton"
          >
           Meetings
          </Button>
         
        </div>
      </div>
    </SuccessDiv>
  );
});

export default SuccessModal;
