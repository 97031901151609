import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory,useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Image, Dropdown } from 'react-bootstrap';
import { useStore } from '../../../store';
import useDebounce from '../../../components/TopBar/use-debounce';
import { SearchBar1, SearchDiv1 } from './Mobstyle';
import search from '../../../assets/icons/search.svg';
import suggest from '../../../assets/icons/recent.svg';

const MobSearch = observer(() => {
  const [history, setHistory] = useState(false);

  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState();

  const location = useHistory();
  const { searchStore } = useStore();

  const {
    listSearchResults,
    setKey,
    listSuggestResults,
    suggestedResults,
    setSuggestResults,
    previousSearches,
    
    interests,
    industries,
    searchinRoles,
    clearTypeFilters,
  } = searchStore;

  const [searchTerm, setSearchTerm] = useState('');

  const query = new URLSearchParams(useLocation().search);
  const key = query.get('q');
  

  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = () => {
    clearTypeFilters();
    location.push(`/search?q=${key}`);
    listSearchResults();
    setSuggestResults([]);
  };

  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const suggestResults = (key) => {
    setSuggestResults([]);
    setSearchValue(key);
    setSearchDropdown(true);

    if (key.length < 1) {
      setHistory(true);
    } else {
      setHistory(false);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) listSuggestResults(debouncedSearchTerm);
  }, [debouncedSearchTerm, listSuggestResults]);

  const refSearch = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refSearch &&
        refSearch.current &&
        !refSearch.current.contains(event.target)
      ) {
        setSearchDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
  }, [refSearch, setSearchDropdown]);

  const suggestSearch = (key,searchtype) => {
    setKey(key);
    setValue('key', key);
    if(searchtype === ''){
      location.push(`/search?q=${key}`);
    }else{
      location.push(`/search?q=${encodeURIComponent(key)}&in=${searchtype}`);
  };
  };

  const getHighlightedText = (text) => {
    const parts = text.split(new RegExp(`(${searchValue})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part?.toLowerCase() === searchValue?.toLowerCase()
                ? { fontWeight: 600, color: '#1f1f1f' }
                : {}
            }
          >
            {part}
          </span>
        ))}{' '}
      </span>
    );
  };

  return (
    <>
      <SearchDiv1>
        <div className='d-lg-none' id='searchbar'>
          <SearchBar1
            onSubmit={handleSubmit(onSubmit)}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setKey(e.target.value);
              
              suggestResults(e.target.value);
            }}
            onBlur={() =>
              setTimeout(() => {
                setSuggestResults([]);
              }, 500)
            }
            onFocus={(e) => suggestResults(e.target.value)}
            ref={refSearch}
          >
            <Form.Control
              type='search'
              id='search-tablet'
              data-testid='search-input-tablet'
              className='form-control '
              placeholder='Members'
              defaultValue={key}
              aria-label='Search'
              aria-describedby='basic-addon1'
              autoComplete='off'
              name='key'
              {...register('key', { required: true })}
            />

            <button className='search-button' type='submit'>
              <Image src={search} />
            </button>
            {searchDropdown && history ? (
              <div className='suggested'>
                <div className='search-head'>Recent searches</div>
                {previousSearches?.map((item) => (
                  <div
                    key={item.keyword}
                    className='search-chip d-flex'
                    onClick={() => suggestSearch(item.keyword,'')}
                  >
                    <Image src={suggest} />
                    <div className='key'>{item.keyword}</div>
                  </div>
                ))}
              </div>
            ) : (
              suggestedResults?.length > 0 && (
                <div className='suggested'>
                  {suggestedResults?.map((item) => (
                    <div
                      key={item.id}
                      className='suggest-card'
                      onClick={() =>
                        location.push(
                          `/${item?.full_name?.toLowerCase().replace(' ', '-')}_${
                            item?.id
                          }_m`
                        )
                      }
                    >
                      <div className='suggest-name'>
                        {getHighlightedText(item.full_name)}
                      </div>
                      <div
                        className='suggest-info'
                        onClick={(e) => {
                          location.push(
                            `/${item.company_name
                              ?.toLowerCase()
                              ?.replace(/\s/g, '-')}_${item.company_id}_c`
                          );
                          e.stopPropagation();
                        }}
                      >
                        {item.title}, {getHighlightedText(item.company_name)}
                      </div>
                    </div>
                  ))}{' '}
                  <p
                    className='seeAll'
                    onClick={() => suggestSearch(searchTerm,'')}
                  >
                    See all
                  </p>
                </div>
              )
            )}
          </SearchBar1>
          <div className='search-initiatorr d-flex' id='search-initiator'>
            Discover By{' '}
            <Dropdown>
              <Dropdown.Toggle
                variant='basic'
                id='more'
                className='a_selectSidebar d-flex align-items-center'
              >
                Interest
                <i className='droparrow' />
              </Dropdown.Toggle>

              <Dropdown.Menu align='bottom'>
                <i className='arrow d-none d-lg-block' />

                <div className='d-lg-flex'>
                  <div>
                    {interests
                      ?.slice(0, Math.ceil(interests?.length / 2))
                      .map((item) => (
                        <Dropdown.Item
                          as='button'
                          key={item.name}
                          onClick={() => {
                            // setSearchIn('interest');
                            suggestSearch(item.name,'interest');
                          }}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                  </div>
                  <div>
                    {interests
                      ?.slice(Math.ceil(interests?.length / 2))
                      .map((item) => (
                        <Dropdown.Item
                          as='button'
                          key={item.name}
                          onClick={() => {
                            // setSearchIn('interest');
                            suggestSearch(item.name,'interest');
                          }}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle
                variant='basic'
                id='more'
                className='a_selectSidebar d-flex align-items-center'
              >
                Responsibility
                <i className='droparrow' />
              </Dropdown.Toggle>

              <Dropdown.Menu align='bottom'>
                <i className='arrow' />

                <div className='d-lg-flex'>
                  <div>
                    {searchinRoles
                      ?.slice(0, Math.ceil(searchinRoles?.length / 2))
                      .map((item) => (
                        <Dropdown.Item
                          as='button'
                          key={item.rolel2}
                          onClick={() => {
                            // setSearchIn('role');
                            suggestSearch(item.rolel2,'role');
                          }}
                        >
                          {item.rolel2}
                        </Dropdown.Item>
                      ))}
                  </div>
                  <div>
                    {searchinRoles
                      ?.slice(Math.ceil(searchinRoles?.length / 2))
                      .map((item) => (
                        <Dropdown.Item
                          as='button'
                          key={item.rolel2}
                          onClick={() => {
                            // setSearchIn('role');
                            suggestSearch(item.rolel2,'role');
                          }}
                        >
                          {item.rolel2}
                        </Dropdown.Item>
                      ))}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle
                variant='basic'
                id='more'
                className='a_selectSidebar d-flex align-items-center'
              >
                Industry
                <i className='droparrow' />
              </Dropdown.Toggle>

              <Dropdown.Menu align='bottom'>
                <i className='arrow d-none d-lg-block' />

                <div>
                  {industries
                    ?.filter((item) => item.industryl1 !== 'OTHERS')
                    .map((item) => (
                      <Dropdown.Item
                        as='button'
                        key={item.industryl1}
                        onClick={() => {
                          // setSearchIn('industry');
                          suggestSearch(item.industryl1,'industry');
                        }}
                      >
                        {item.industryl1}
                      </Dropdown.Item>
                    ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </SearchDiv1>
    </>
  );
});

export default MobSearch;
