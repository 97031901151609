import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import check from '../../../assets/icons/blue-check.svg';

export const ProfileDiv = styled.div`
  border-radius: 0.5rem;
  position: relative;
  padding: 11px;
  padding-bottom: 80px;

  .page-title {
    text-transform: capitalize;
    margin-bottom: 5px;
    width: 410px;
    left: 257px;
    top: 210px;
    font-weight: normal;
    font-size: 28px;
    line-height: 100%;
    color: #ffffff;
  }

  .primary {
    h6 {
      margin-bottom: 3px;
    }
  }

  .glass {
    @media only screen and (max-width: 600px) {
      margin-top: 73px;
      border: 1px solid rgba(255, 255, 255, 0.7);
      background: linear-gradient(
        161.3deg,
        rgba(234, 255, 254, 0.2) -8.5%,
        rgba(201, 229, 241, 0.2) 151.17%
      );
      padding-top: 20px;
    }

    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    .profileForm {
      background: transparent;
    }
  }

  .glass-design {
    position: relative;
    margin-bottom: -200px;
    margin-top: 30px;
    max-width: 1280px;
    height: 188px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    border-radius: 10px;
  }

  #header-img {
    position: absolute;
    right: 1px;
  }

  .profile-data {
    @media only screen and (min-width: 992px) {
      display: flex;

      .address-div {
        margin-top: 300px;

        .profile-bio {
          background: transparent;
          padding-bottom: 0px;
          margin-left: 0px;

          .side-div-top {
            width: 80%;
            padding-bottom: 50px;
            border-bottom: 1px solid #7e7e7e33;
          }

          h4 {
            width: 80%;
            font-size: 14px;
            @media only screen and (min-width: 992px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .profile-pic {
    position: relative;
    .sm-wave {
      position: absolute;
      left: -30px;
      top: -70px;
      z-index: -1;
      @media only screen and (max-width: 361px) {
        width: 300px;
      }
      @media only screen and (min-width: 992px) {
        left: -90px;
        top: -90px;
        left: 832px;
        top: -41px;
      }
    }
  }

  #public-profile {
    margin-left: 211px;
    @media only screen and (min-width: 992px) {
      margin-left: 305px;
    }
  }

  .profile-info {
    padding: 50px 20px 0 40px;
    border-radius: 15px;
    margin-bottom: 27px;
    position: relative;
    color: #fff;

    .wave-design {
      position: absolute;
      right: -280px;
      top: -140px;
      z-index: -1;
    }

    h2 {
      font-size: 1.2 rem;
      font-weight: 700;
    }

    .role-title-edit {
      h6 {
        margin-bottom: 0px;
      }
    }

    @media only screen and (min-width: 992px) {
      .contain {
        align-items: baseline;
        justify-content: space-between;
      }
      .second-div {
        padding-left: 20px;
        margin-left: 20px;
        border-left: 0.5px solid #bcb8b8;
      }
    }

    @media only screen and (max-width: 992px) {
      .contact-info {
        padding-top: 11px;
      }

      .secondary-info {
        padding-bottom: 20px;
        .contain {
          align-items: center;
          margin-bottom: 3px;

          h5 {
            margin-bottom: 0;
          }
          .line {
            flex-grow: 1;
            border-bottom: 0.5px solid #ffffff33;
            margin: 5px;
          }
        }
      }
    }

    @media only screen and (min-width: 992px) {
      display: flex;
      margin: 0px 0px 0px 20px;
      border-radius: 5px;
      padding: 53px 0px 20px 50px;

      .edit-button {
        color: #00ffc2;
      }

      .contact-info {
        margin-top: 21px;
        display: flex;
        justify-content: space-between;
        .phone {
          margin-bottom: 0;
        }
      }

      .profile-image {
        margin: 0;
      }

      .info-tab {
        width: 100%;
        padding: 0px 0px 0px 23px;
      }
    }
  }

  h2 {
    font-weight: Bold;
    margin-bottom: 23px;
    color: #32e0e0;
    background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
  }

  .top-divs {
    .points-div {
      display: flex;
      padding: 15px 30px 7px 38px;
    }
    @media only screen and (min-width: 992px) {
      margin-left: 20px;
      justify-content: space-between;

      h2 {
        font-size: 18px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
      }
    }

    .profile-bio {
      margin-left: 0px;
      padding: 21px 30px 25px 39px;

      .points-div {
        display: block;
        padding: 15px 50px 20px 20px;
        width: 22%;
      }
      .edit-button {
        font-size: 12px;
      }
    }
  }

  #primary {
    @media only screen and (min-width: 992px) {
      margin-bottom: 17px;
    }
  }

  .edit-button {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    padding: 0;
    border: 0;
    background: transparent;
    color: #00aeae;
    font-size: 14px;
    white-space: nowrap;
    @media only screen and (max-width: 992px) {
      color: #00ffc2;
      font-size: 10px;
    }
  }

  #header-edit {
    @media only screen and (min-width: 900px) {
      margin-right: 180px;
    }
  }

  .edit-pic {
    margin-left: -16px;
    border-radius: 50%;
    cursor: pointer;
    width: 25px;
    height: 25px;
    padding: 0;
    border: 0;
    background: transparent;
    @media only screen and (min-width: 992px) {
      position: absolute;
      top: 50px;
    }
  }

  .profile-bio {
    background: #fff;
    border-radius: 5px;
    padding: 55px 20px 38px 38px;
    margin-bottom: 11px;
    position: relative;

    .points {
      margin-left: 10px;
      color: #666666;
      font-size: 12px;
      font-weight: 600;
      h5 {
        font-weight: 900;
        font-size: 18px;
      }
    }
    @media only screen and (min-width: 992px) {
      margin-left: 20px;
      padding: 50px 50px 65px 50px;
    }

    .bg-design {
      position: absolute;
      left: -27px;
      bottom: -45px;
      z-index: -1;
    }

    .back-design {
      position: absolute;
      bottom: -200px;
      left: -150px;
      z-index: -1;
    }

    .edit-icon {
      width: 18px;
      height: 18px;
      padding: 0;
      cursor: pointer;
    }

    .form-control {
      padding: 10px;
      background: #ebf4f9;
    }

    .description {
      overflow: hidden;
      padding: 15px;
    }

    .btn-default {
      margin-top: 10px;
      max-width: 100px;
      padding: 5px 7px;
    }

    .edit-button {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      padding: 0;
      border: 0;
      background: transparent;
      color: #00aeae;
      margin-top: -19px;
    }

    .engage-div {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      // -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      // justify-content: space-between;
    }

    .form-check {
      @media only screen and (max-width: 600px) {
        width: 225px;
        margin-left: 10px;
        margin-right: -24px;
        margin-bottom: 15px;
      }

      @media only screen and (min-width: 900px) {
        width: 225px;
        margin-left: 10px;
        float: left;
        margin-right: -24px;
        margin-bottom: 15px;
      }
    }

    .form-check-label {
      font-size: 14px;
      color: #797979;
      margin-left: 8px;
      @media only screen and (min-width: 992px) {
        color: #111;
        font-size: 16px;
      }
    }

    .form-check-input[type='checkbox'] {
      margin-top: 7px;
      width: 15px;
      height: 15px;
      background: 0;
      border: 0.5px solid #cdeaf0;
      opacity: 1;
    }

    .form-check-input:checked[type='checkbox'] {
      background: 0;
      border: 0.5px solid #00aeae;
      background-image: url(${check});
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1;
    }

    .Area-interest {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .Responsibility {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .form-check-input:checked[type='checkbox'] ~ .form-check-label {
    opacity: 1;
  }
  .bio-details {
    line-height: 24px;
    font-size: 16px;
    letter-spacing: 0px;
    white-space: break-spaces;

    .tags {
      white-space: pre;
      flex-wrap: wrap;

      .interest {
        margin: 3px;
      }
    }
  }

  .more-less {
    width: 13px;
    cursor: pointer;
  }
  .p-moreless {
    color: #00aeae;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
  }

  .less {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  h4 {
    color: #333333;
    line-height: 24px;
    text-transform: capitalize;
  }

  .profile-image {
    width: 70px;
    height: 70px;
    border-color: white;
    border-width: 3px;
    border-style: solid;
    margin-top: -30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media only screen and (min-width: 992px) {
      width: 97px;
      height: 97px;
    }
  }
  .top-divs {
    .secondary {
      @media only screen and (min-width: 992px) {
        margin-left: 10px;
      }
    }
  }
`;

export const ProfileFormDiv = styled(Modal)`
  .rbt-token-removeable {
    width: fit-content;
    background: white;
    padding-left: 5px;
    margin-bottom: 3px;
  }
  .rbt-close {
    border: 0;
    background: 0;

    .sr-only {
      display: none;
    }
  }

  .form-control-file {
    display: none;
  }
  .edit-pic {
    padding: 20px;
    border-radius: 50%;
    background: #00ffc2;
    border: 3px solid #ffffff99;
  }
  .upload-pic {
    margin-left: 14px;
    .active {
      filter: brightness(0) saturate(100%) invert(30%) sepia(0%) saturate(542%)
        hue-rotate(191deg) brightness(92%) contrast(83%);
    }
  }
  .tip {
    color: #9a9a9a;
    font-size: 12px;
  }
  .modal-content {
    padding: 41px 28px 52px 31px;

    h4 {
      color: #797979;
    }

    h2 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .close-button {
      background: #fff;
      border: 0;
    }
    .form-control {
      background: #ebf4f9;
      padding: 0.5em 1em;
      border: 1px solid #cdeaf0;
      color: #403c3c;
      margin-bottom: 22px;
    }

    .form-label {
      font-size: 12px;
      color: #797979;
      font-weight: 600;
    }

    .asteric {
      color: red;
      font-size: 17px;
    }

    .warning {
      font-size: 14px;
    }

    .search-form {
      margin: 0;
    }

    .search-filter {
      font-size: 10px;
      width: -webkit-fill-available;
      margin-bottom: 20px;
      height: 19px;
      border: 0;
      margin-top: 23px;
      box-shadow: 0px 0px 0px 0px;
      border-bottom: 0.5px solid #b9b9b9;
      background: transparent;
      border-radius: 0;
      padding: 0;
      @media screen and (min-width: 992px) {
        font-size: 14px;
        padding-bottom: 8px;
      }
    }

    .card-header {
      background: #ebf4f9;
      border: 0;
    }
    .card {
      border: 0;
      margin-top: 3px;

      .card-body {
        padding-left: 2rem;
      }
    }

    .form-check {
      margin-left: 10px;

      .form-check-input {
        width: 16px;
        height: 16px;
        background: white;
        border-radius: 3px;
      }

      .form-check-input:checked[type='checkbox'] {
        border: 0.5px solid #00aeae;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-position: center;
      }

      label {
        padding-top: 2px;
        padding-left: 4px;
        font-size: 11px;
      }
    }

    .drop {
      margin: 0;
      padding: 0;
      border: 0;
      background: transparent;
    }

    .btn-default {
      margin-top: 20px;
      max-width: 245px;
      padding: 5px 7px;
    }
  }

  .request-change {
    h5 {
      border-bottom: 1px solid #b9b9b9;
      padding-bottom: 1px;
    }
    .current {
      font-size: 12px;
      padding-bottom: 14px;
    }
  }
  .glass-design {
    position: absolute;
    left: 7.56%;
    right: 7.56%;
    top: 0.83%;
    height: 261px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    /* bottom: 46.76%; */
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    /* backdrop-filter: blur(3px); */
    border-radius: 10px;
  }
  .modal-content .form-check {
    width: 100%;
  }
  col-lg-8 {
    margin-left: 24px;
  }
  .footbar {
    margin-top: 2100px;
  }
`;
