import { makeAutoObservable } from 'mobx';
import { Allowredeem } from '../../api/Allowredeem';
import {
  getMeetings,
  sendMeetingRequest,
  getMeetingDetails,
  changeMeetingStatus,
  getCart,
  addtoCart,
  getProducts,
} from '../../api/meeting';
import AccountStore from '../account/accountStore';

class MeetingStore {
  accountStore: AccountStore;

  meetings = [];

  statusList = [];

  meetingDetails = {};

  cart = {};

  products = [];

  meetingProducts = [];

  selectedProduct = {};

  results = [];

  recentUser = null;

  constructor(accountStore: AccountStore) {
    this.accountStore = accountStore;
    makeAutoObservable(this);
  }

  setSelectedProduct = (data) => {
    this.selectedProduct = data;
    this.onAddToCart(null, false, true);
  };

  setCart = (data) => {
    this.cart = data;
    if (this.cart?.product_details) {
      this.selectedProduct = this.cart?.product_details;
    }
  };

  fetchCart = async () => {
    const res = await getCart();
    if (res.status === 200) {
      this.setCart(res.data[0]);
    }
  };

  setProducts = (data) => {
    this.products = data;

    if (!this.cart?.product_details) {
      this.selectedProduct = this.products[0];
    }
  };

  listProducts = async () => {
    const res = await getProducts();
    if (res.status === 200) {
      this.setProducts(res.data);
    }
  };

  setMeetingProducts = (data) => {
    this.meetingProducts = data;
  };

  listMeetingProducts = async () => {
    const res = await getProducts(true);
    if (res.status === 200) {
      this.setMeetingProducts(res.data);
    }
  };

  setAllowRedemptiom = (data) => {
    this.allowRedemptiom = data;
  };

  listAllowRedemption = async () => {
    const res = await Allowredeem();

    this.setAllowRedemptiom(res.data);
  };

  setRecentUser = (data) => {
    this.recentUser = data;
  };

  onAddToCart = async (member, add, product) => {
    let data = {};
    let members = [];

    if (this.cart?.members?.length) {
      members = this.cart.members_details;
    }
    if (add) {
      if (member) {
        members.push(member);
        this.setRecentUser(member);
        setTimeout(() => {
          this.setRecentUser(null);
        }, 2000);
      }
    } else {
      if (members?.length > 0 && !product) {
        const index = members.findIndex((item) => item.id === member.id);
        members.splice(index, 1);
      }
    }
    data.product = this.selectedProduct.id;
    data.user = this.accountStore.user.id;
    if (!product) {
      data.members = members?.map((item) => item.id);
    } else {
      data.members = [];
    }

    const res = await addtoCart(data);
    if (res.status === 200) {
      this.setCart(res.data);
    }
  };

  onChangeStatus = (status) => {
    const index = this.statusList.findIndex((item) => item === status);
    if (index > -1) {
      this.statusList.splice(index, 1);
    } else {
      this.statusList.push(status);
    }
    this.listMeetings();
  };

  setMeetings = (meetings) => {
    this.meetings = meetings;
  };

  onSendMeetingRequest = async (data, cb) => {
    const res = await sendMeetingRequest(data);
    if (res.status === 201) {
      cb();
    }
  };

  listMeetings = async (meetingType, initiator) => {
    // const status = this.statusList.join(",");
    const res = await getMeetings(meetingType, initiator);
    if (res.status === 200) {
      this.setMeetings(res.data);
    }
  };

  setMeetingDetails = (meeting) => {
    this.meetingDetails = meeting;
  };

  onFetchMeetingDetails = async (uuid) => {
    const res = await getMeetingDetails(uuid);
    return res;
  };

  onChangeMeetingStatus = async (uuid, data, action) => {
    const res = await changeMeetingStatus(uuid, data, action);
    if (res.status === 200) {
      if (action !== 'cancel' && action !== 'reschedule') {
        this.setMeetingDetails(res.data);
      }
    }
  };

  setResults = (data) => {
    this.results = data;
  };

  onChangeMeetingListStatus = async (uuid, data, action) => {
    const res = await changeMeetingStatus(uuid, data, action);
    if (res.status === 200) {
      if (action === 'accept' || action === 'reject') {
        const updatedMeetings = [...this.meetings];
        const index = updatedMeetings.findIndex((item) => item.uid === uuid);
        updatedMeetings[index] = res.data;
        this.setResults(updatedMeetings);
        // this.setMeetings(updatedMeetings);
      }
    } else {
      this.setResults(this.meetings);
    }
  };
}

export default MeetingStore;
