import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Image, Form } from 'react-bootstrap';

import { StyledModal } from '../elements/styles';
import { useStore } from '../../../store';
import close from '../../../assets/icons/close.svg';

const RejectModal = observer(({ show, setShow, uuid }) => {
  const [errorMessage, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [reason, setRejectReason] = useState();




  const { meetingStore } = useStore();

  const { onChangeMeetingListStatus, setMeetings, results } = meetingStore;

  const onSubmit = () => {
    let data = {};
    data.reject_reason = reason;
    if (data.reject_reason) {
      setError('');
      data.status = 'REJECTED';
      onChangeMeetingListStatus(uuid, data, 'reject');
      
      setSuccess(true);
    } else {
      setError('This field is required.');
    }
  };

  return (
    <StyledModal show={show}>
      <button
        className='a_closeButton'
        onClick={() => {
          setShow(false);
          setSuccess(false);
        }}
      >
        <Image src={close} alt='close' />
      </button>
      {!success ? (
        <>
          <h3>Regret meeting request</h3>
          <Form>

            
            
          <Form.Check
              type="radio"
              name="reject_reason"
              id="ongoing"
              label="Sorry, not interested"
              value="Sorry, not interested"
              onChange={() => {
                setRejectReason("Sorry, not interested");
                
              }}
            />
            <Form.Check
              type="radio"
              name="reject_reason"
              id="planning"
              label=" Calendar doesn't permit "
              value=" Calendar doesn't permit "
              onChange={() => {
                setRejectReason(" Calendar doesn't permit ");
               
              }}
            />
          </Form>

          <Form>

          

            <Form.Label>Add reason/alternate dates </Form.Label>

            <Form.Control
              size='sm'
              reject_reason
              type='text'
              as='textarea'
              rows={4}
              name='reject_reason'
              onChange={(e) => setRejectReason(e.target.value)}
            />

            <div className='text-danger'>{errorMessage}</div>
            <Button onClick={() => onSubmit()} className='btn-default'>
              Regret 
            </Button>
          </Form>
        </>
      ) : (
        <>
          <h3>Meeting regretted.</h3>
          <Button
            className='btn-default'
            onClick={() => {
              setShow(false);
              setSuccess(false);
              setMeetings(results);
            }}
          >
            Close
          </Button>
        </>
      )}
    </StyledModal>
  );
});

export default RejectModal;
