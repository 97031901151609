import styled from 'styled-components';

export const CarouselCard = styled.div`
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 3px solid #eaedf3;
    background: white;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: 1px;
    
  }

  h4 {
    font-size: 16px;
    
    white-space: break-spaces;

  }

  @media only screen and (max-width: 992px) {
    img {
      width: 20px;
      height: 20px;
    }

    h2 {
      font-size: 14px;
      font-weight: 500;
     
    }
    h4 {
      font-size: 13px;
      
      
    }
  }

  .user-info {
    display: none;
    color: #fff;
    margin-top: 7px;
    width: 300px;
    
  }
  
 
  }
  .text-center1{
    font-family: Lato;
font-style: normal;

font-size: 18px;
line-height: 130%;
/* or 23px */

text-align: center;

color: #FFFFFF;
  }

  .test-center{
    font-family: Lato;
font-style: normal;


line-height: 130%;


text-align: center;
    color: black;
  }
`;
