import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Form, Image, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../store';
import { VerificationDiv } from './elements/styles';

import back from '../../assets/icons/back.svg';
import check from '../../assets/icons/check.svg';
import linkedin from '../../assets/icons/Icon-Linkedin.svg';
import info from '../../assets/icons/info-circle.svg';
import background from '../../assets/images/Backgroundnew.svg.svg';
import LogoBlack from '../../assets/images/LogoBlack.png';
import wave from '../../assets/images/waveDesign.svg';

const MobileVerification = observer(() => {
  const { authStore, accountStore } = useStore();
  const location = useHistory();
  const [otp, setOtp] = useState(null);
  const [show, setShow] = useState(false);
  const [mobile, setMobile] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpSent, setOtpSent] = useState(false);


  const [seconds, setSeconds] = useState();

  useEffect(() => {
    if (seconds > 0 && otpSent) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds();
    }
  }, [seconds, otpSent]);

  const { sendOTP, verifyOTP, setVerificationError, otpVerificationStatus } =
    authStore;
  const { user, footer, listFooter } = accountStore;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    listFooter();
  }, [listFooter]);

  const sendVerificationCode = (data) => {
    if (data.mobile) {
      setMobile((process.env.REACT_APP_COUNTRY === 'India' ? '+91' : countryCode) + data.mobile);
      sendOTP((process.env.REACT_APP_COUNTRY === 'India' ? '+91' : countryCode) + data.mobile, () => {
        setOtpSent(true);
      });
    } else {
      setMobile(user?.mobile);
      sendOTP(user?.mobile, () => {
        setOtpSent(true);
      });
    }
    setTimeout(() => setSeconds(60), 1000);
  };

  const resendOTP = () => {
    sendOTP(mobile, () => {
      setOtpSent(true);
    });
    setShow(true);
    setSeconds(60);
  };

  const handleChange = (otp) => {
    setOtp(otp);

    if (otp?.length === 4) {
      verifyOTP(otp, mobile, () => {
        setOtpVerified(true);
      });
    }
  };

  const [countryCode, setCountryCode] = useState(user?.mobile.substring(0, 3));

  const mobileS = user?.mobile; // assuming user?.mobile contains the mobile number
  const countryode = mobileS.substr(0, 3); // extract the first two characters
  console.log(countryode);

  return (
    <VerificationDiv className='verify-mobile-page'>
      <Helmet>
        <style>
          {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: left center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh; }`}
        </style>
      </Helmet>

      <img src={wave} alt='' className='wave-design' />
      <div className='glass'>
        <img src={LogoBlack} alt='' className='newlogo1' />

        <div className='content-card'>
          {!otpSent ? (
            <>
              <h4 className=' welcome mb-1'>
                Welcome {user?.first_name} {user?.last_name}
              </h4>
              <h2>Verify mobile number</h2>
              <Form
                className='loginForm'
                onSubmit={handleSubmit(sendVerificationCode)}
              >
                <Form.Label className='mob d-flex flex-column '>
                  {process.env.REACT_APP_COUNTRY === 'India' ? 'Enter your 10 digit mobile number' : 'Enter your mobile number and select appropriate country code'}
                </Form.Label>
                <div className='d-flex'>
                  {process.env.REACT_APP_COUNTRY === 'India' ?
                    <span className='code'> +91 </span> :
                    <>
                      {countryode ?
                        <select defaultValue={countryode} className='country-options' onClick={(e => setCountryCode(e.target.value))}>
                          <option>
                            {countryode}
                          </option>
                          <option>
                            {countryode === '+61' ? '+64' : '+61'}
                          </option>
                        </select> :
                        <select defaultValue={countryode} className='country-options' onClick={(e => setCountryCode(e.target.value))}>
                          <option>
                            +61
                          </option>
                          <option>
                            +64
                          </option>
                        </select>}
                    </>}
                  <Form.Control
                    size='sm'
                    type='number'
                    name='mobile'
                    defaultValue={process.env.REACT_APP_COUNTRY === 'India' ? user?.mobile.replace('+91', '') : user?.mobile.replace(countryode, '')}
                    {...register('mobile')}
                    className={classNames({
                      moberror: errors.mobile || setVerificationError,
                    })}
                  />
                </div>
                <div className='error-info'>
                  {setVerificationError}
                  {errors.mobile && errors.mobile.message}
                </div>

                <Button
                  className='w-100 btn-default my-5 '
                  type='submit'
                  id='login_confirm'
                >
                  Send verification code
                </Button>
              </Form>
            </>
          ) : (
            <>
              <Image
                src={back}
                onClick={() => setOtpSent(false)}
                className='a_backButton'
              />
              <h2>Enter verification code</h2>

              <p className='code-data'>
                We have sent a verification code to your registered mobile
              </p>
              <div className='code-data code-entry'>
                <Button
                  className='code-resend'
                  disabled={seconds > 0}
                  onClick={() => resendOTP()}
                >
                  Resend code
                </Button>{' '}
                {seconds && `after ${seconds} secs`}
                {show && (
                  <Alert
                    variant='success'
                    onClose={() => setShow(false)}
                    dismissible
                  >
                    OTP resend
                  </Alert>
                )}
              </div>
              <OtpInput
                value={otp}
                isInputNum
                onChange={(e) => {
                  handleChange(e);
                }}
                numInputs={4}
                separator={<span>-</span>}
                inputStyle={`otp-input ${otpVerificationStatus === 'Incorrect verification code' &&
                  'moberror'
                  }`}
                focusStyle='otp-focus'
                containerStyle='otp-content'
                isDisabled={otpVerified}
              />
              <div
                id='verify-status-text'
                className='small  status-verification-text text-center'
              >
                {otpVerificationStatus === 'Verified' && (
                  <div className='d-flex success'>
                    <div className='verify-circle'>
                      <Image src={check} id='checkCircle' />
                    </div>

                    <p>{otpVerificationStatus}</p>
                  </div>
                )}
                {otpVerificationStatus === 'Incorrect verification code' && (
                  <div className='d-flex danger'>
                    <Image src={info} />
                    <p className='d-flex'>{otpVerificationStatus}</p>
                  </div>
                )}

                {otpVerificationStatus === 'Verifying' && (
                  <div className='d-flex'>
                    <Spinner animation='border spinner' />
                    <p>{otpVerificationStatus}</p>
                  </div>
                )}
              </div>
              {user.consent === true && user.mobile_verified === false ? (
                <Button
                  className='w-100 a_continue btn-default'
                  id='continue'
                  disabled={!otpVerified || otp?.length !== 4}
                  onClick={() => location.push('/')}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  className='w-100 a_continue btn-default'
                  id='continue'
                  disabled={!otpVerified || otp?.length !== 4}
                  onClick={() => location.push('/terms')}
                >
                  Continue
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className='d-flex'>
        {footer.map((item) => (
          <div className='button-group d-flex'>
            <a href={item.link} target='_blank' rel='noreferrer'>
              <button className='terms1' id='terms'>
                {item.text}
              </button>
            </a>
          </div>
        ))}
      </div>

      <div className='icon-div'>
        <a
          href={process.env.REACT_APP_API_LINKEDIN_LINK}
          target='_blank'
          rel='noreferrer'
        >
          <img src={linkedin} className='linked-logo' alt='icon' />{' '}
        </a>
      </div>
    </VerificationDiv>
  );
});

export default MobileVerification;
