import styled from "styled-components";

export const SidebarDiv = styled.div`
  height: 100%;
  width: 70%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 18px 18px 30px 44px;
  background-color: #fff;
  z-index: 200;
  border-top-left-radius: 5px;

  .sidebutton {
    padding: 0;
    background: 0;
    border: 0;
    color: #00aeae;
    margin-top: 10px;
  }
  #close {
    font-size: 9px;
    margin-left: auto;
  }

  .account-info {
    height: -webkit-fill-available;
    h3 {
      margin-top: 10px;
      margin-bottom: 6px;
    }
    .profile-pic {
      width: 66px;
      height: 66px;
      border: 3px solid #eaedf3;
    }
    #edit-profile {
      width: 123px;
      height: 37px;
      padding: 0;
      margin-top: 27px;
    }

    .designation {
      color: #666666;
      font-size: 12px;
      margin: 0;
    }
  }

  .btn-actions {
    margin-top: 40px;
    font-size: 16px;
    color: #00aeae;
    button {
      font-weight: 700;
      margin-bottom: 30px;
    }

    img {
      border: 0;
      margin-right: 14px;
      margin-bottom: 3px;
    }

    .points {
      color: #666666;
      font-weight: 600;
      font-size: 10px;
      h5 {
        font-size: 10px;
        margin-bottom: 0;
        margin-left: 4px;
        color: #333333;
      }
      img {
        margin-right: 7px;
        margin-bottom: 0;
      }
    }
  }

  .side-footer {
    font-size: 12px;
  }
  .consent {
    color: #666666;
    margin-top: 9px;
    button {
      text-decoration: underline;
    }
  }

  .version {
    margin-top: 10px;
    color: #666666;
  }
`;
