import styled from "styled-components";

export const BottomDiv = styled.nav`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background: #fff;
  z-index: 100;

  font-size: 9px;
  .nav-link {
    color: #8c97ac;
    font-weight: 500;
    img {
      height: 20px;
      filter: brightness(0) saturate(100%) invert(83%) sepia(82%)
        saturate(4808%) hue-rotate(176deg) brightness(70%) contrast(87%);
    }
  }
  .nav-link.active {
    color: #00aeae;
    img {
      filter: brightness(0) saturate(100%) invert(70%) sepia(86%)
        saturate(4740%) hue-rotate(144deg) brightness(92%) contrast(101%);
    }
  }
  .more-button {
    padding: 0;
    font-size: 9px;
    background: #fff;
    border: #fff;
  }

  .tab-label {
    margin-top: 7px;
  }
`;
