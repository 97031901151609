import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ConnectDiv } from '../PeerWarningModal/styles';
import close from '../../assets/icons/close.svg';

const ChatAppModal = observer(({ show, setShow }) => {
  const location = useHistory();
  return (
    <ConnectDiv show={show} onClick={(e) => e.stopPropagation()}>
      <div className='d-flex align-items-start justify-content-between'>
        <div>
          <h3>
          Chat and Forum are features on
conversations.121connects.com. </h3>
<h3>
You will need to log in with separate
credentials to use the site. You must enable
‘Peer Chat &amp; Forums’ to gain access. New
accounts will take up to 24 hour to activate.
          </h3>
        </div>
        <Image
          src={close}
          onClick={(e) => {
            setShow(false);
            e.stopPropagation();
          }}
          alt='close'
          className='close'
        />
      </div>
     <div >
      <a href="https://www.conversations.121connects.com/" target='_blank'
                        rel='noreferrer'>
      <Button
        variant='light'
        onClick={()=>setShow(false)}
        className='btn-default a_sendButton'
      >
        Proceed
      </Button></a>
      <Button
        variant='light'
        onClick={(e) => {
          location.push('/profile');
          e.stopPropagation();
        }}
        className='btn-default a_sendButton'
        id='change-btn'
      >
        Change in profile
      </Button>
      </div>
    </ConnectDiv>
  );
});

export default ChatAppModal;