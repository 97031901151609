import styled from 'styled-components';

export const ProfileDiv = styled.div`
  border-radius: 0.5rem;
  position: relative;
  padding: 11px;
  padding-bottom: 80px;

  // .logo {
  //   margin: 40px 0px 30px 40px;
  // }

  .glass {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-bottom: 10px;
    max-width: 1280px;
    margin: 96px auto 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: rgba(235, 255, 254, 0.2);

    @media only screen and (min-width: 992px) {
      border: 0;
      background: 0;
      margin-top: 46px;
    }
  }

  .glass-design {
    margin-top: -10px;
    position: relative;
    margin-bottom: -180px;
    max-width: 1280px;
    height: 181px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    border-radius: 10px;
  }

  .back-button {
    background: transparent;
    color: #00ffc2;
    border: 0;
    font-weight: 500;
    position: absolute;
    top: -30px;

    img {
      margin-right: 9.5px;
    }
    @media only screen and (min-width: 992px) {
      top: -35px;
    }
  }

  .page-title {
    color: #fff;
    margin-bottom: 0;
    font-size: 28px;
  }

  .meeting-div {
    background: #fff;
    border-radius: 5px;
    padding: 28px 32px 27px 17px;
    .meeting-type {
      color: #666666;
      font-size: 10px;
      button {
        font-size: 10px;
        color: #00aeae;
      }
    }
  }

  .meeting-type {
    display: flex;
    font-size: 14px;
    color: #fff;

    @media only screen and (min-width: 992px) {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      margin-top: 9px;
      /* identical to box height */

      color: #666666;
    }
    .dropdown-toggle {
      :after {
        display: none;
      }
    }
    button,
    .dropdown-toggle {
      padding: 0;
      background: transparent;
      border: 0;
      color: #00ffc2;
      font-size: 14px;
      @media only screen and (min-width: 992px) {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: flex-end;
        color: #00aeae;
        margin-left: 25px;
      }
    }
    button:focus {
      box-shadow: none;
    }
    .dropdown {
      padding-left: 3px;
    }

    .active {
      background: #bbffef;
    }

    .dropdown-toggle:focus {
      box-shadow: none;
    }
    .dropdown-menu {
      padding: 0;
      border: 0.5px solid #cdeaf0;
      box-shadow: 3px 4px 20px 0px #0000001a;

      .dropdown-item {
        padding: 17px 0px 11px 15px;
        color: #333333;
        @media only screen and (min-width: 992px) {
          padding: 17px 30px 11px 15px;
          margin: 0;
        }
      }
    }

    @media only screen and (max-width: 992px) {
      display: block;
    }
  }
  #meeting-div {
    height: 1px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  .down-toggle {
    @media screen only and (min-width: 992px) {
      color: #00aeae;
    }
  }

  .wave-design {
    position: absolute;
    right: 1px;
  }
  .profile-info {
    position: relative;

    .profile-pic {
      position: relative;
      .sm-wave {
        position: absolute;
        left: -30px;
        top: -70px;
        z-index: -1;
        @media only screen and (max-width: 361px) {
          width: 300px;
        }
      }
    }
    padding: 50px 0 0 40px;
    border-radius: 15px;

    h4 {
      color: #fff;
      font-size: 16px;
    }

    .profile-image {
      width: 70px;
      height: 70px;
      border-color: white;
      border-width: 3px;
      border-style: solid;
      margin-top: -30px;
    }
    .userpic {
      border-radius: 50%;
      font-weight: 600;
      font-size: 28px;
    }

    .info-tab {
      .company-name {
        cursor: pointer;
        color: #00ffc2;
      }
    }

    @media only screen and (min-width: 992px) {
      padding-top: 15px;
      display: flex;
      align-items: center;
      margin: 0px 0px 12px 20px;
      border-radius: 5px;
      padding: 30px 0px 7px 50px;

      .profile-image {
        margin: 0;
        width: 97px;
        height: 97px;
      }
      .userpic {
        border-radius: 50%;
        font-weight: 600;
        font-size: 32px;
      }

      .info-tab {
        margin-left: 23px;
      }
    }
  }
  .address-div {
    @media only screen and (min-width: 992px) {
      width: 34%;
    }
  }
  .interest {
    margin-left: 50px;
    margin-right: -20px;
    margin-bottom: 10px;
  }
  .member-company {
    @media only screen and (min-width: 992px) {
      margin-left: 889px;
      margin-top: -404px;
      margin-bottom: 337px;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 11px;
      background: #fff;
      border-radius: 5px;
      padding: 44px 70px 50px 38px;
      position: relative;
    }
  }
  .member-website {
    color: #32e0e0;
  }
  .edit-pic {
    margin-left: -16px;
    border-radius: 50%;
    cursor: pointer;
    width: 25px;
    height: 25px;
  }

  .profile-bio {
    @media only screen and (min-width: 992px) {
      margin-left: 21px;
    }
    margin-top: 11px;
    background: #fff;
    border-radius: 5px;
    padding: 44px 70px 50px 38px;
    position: relative;

    .bg-design {
      position: absolute;
      left: -27px;
      bottom: -45px;
      z-index: -1;
    }

    .back-design {
      position: absolute;
      bottom: -230px;
      left: -150px;
      z-index: -1;
    }

    h2 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: fit-content;
      font-size: 18px;
      font-weight: Bold;
    }

    button {
      margin-left: 85%;
      border: none;
    }

    .bio-details {
      line-height: 24px;
      font-size: 16px;
      letter-spacing: 0px;
      text-align: left;
      white-space: break-spaces;

      .interest {
        margin: 3px;
      }
    }

    .bio-description {
      cursor: pointer;
      font-size: 10px;
      width: 30px;
      height: 30px;
      background: rgba(0, 174, 174, 0.1);
      padding: 10px 14px 12px 14px;
      border-radius: 50%;
    }

    .less {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .more-less {
      width: 13px;
      cursor: pointer;
    }
    .p-moreless {
      color: #00aeae;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      width: fit-content;
    }

    h4 {
      color: #333333;
      line-height: 24px;
      text-transform: capitalize;
    }
  }

  .a_buttonConnect {
    display: flex;
  }

  .a_buttonConnect {
    width: 120px;
    height: 40px;
    padding: 12px 29px 13px 33px;
    font-size: 16px;
    margin-left: 12px;

    @media only screen and (min-width: 992px) {
      margin-top: -28px;
      padding: 8px 29px 13px 27px;
    }
    @supports (-webkit-touch-callout: none) {
      display: flex;
    }

    img {
      width: 15px;
      margin-right: 9px;
      margin-top: 4px;
      @supports (-webkit-touch-callout: none) {
        margin-top: 5px;
      }
    }
    @media only screen and (max-width: 992px) {
      width: 110px;
      height: 40px;
      padding: 11px 17px;
      font-size: 14px;
      img {
        width: 13.5px;
        margin-right: 5px;
      }
    }
  }

  .disabled {
    background: #e1fbf5;
  }

  .added {
    background: #00ffc2d0;
    @media only screen and (max-width: 992px) {
      background: #00ffc266;
    }
  }
  #chat-button {
    margin-left: 415px;
    margin-top: -40px;
  }
  .inter-div {
    @media only screen and (min-width: 992px) {
      margin-left: 906px;
      margin-top: -363px;
      position: relative;
      white-space: pre;
      margin-bottom: 355px;
      height: fit-content;
    }
    @media only screen and (max-width: 600px) {
      margin-top: 11px;
      background: #fff;
      border-radius: 5px;
      padding: 44px 70px 50px 38px;
      position: relative;
    }
  }
  .inter-div1 {
    @media only screen and (min-width: 992px) {
      margin-left: 906px;
      margin-top: -246px;
      position: relative;
      white-space: pre;
      margin-bottom: 355px;
      height: fit-content;
    }
    @media only screen and (max-width: 600px) {
      margin-top: 11px;
      background: #fff;
      border-radius: 5px;
      padding: 44px 70px 50px 38px;
      position: relative;
    }
  }
  .side-text {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    color: #32e0e0;
  }
  #res-section {
    @media only screen and (min-width: 992px) {
      padding-right: 20px;
      margin-top: 192px;
      background: transparent;
      border: none;
      margin-left: 1px;
      margin-bottom: -62px;
    }
  }
  #inter-section {
    @media only screen and (min-width: 992px) {
      padding-right: 20px;
      margin-left: 8px;
      background: transparent;
    }
  }
  .role-line {
    line-height: 17px;
  }
  #meeting-div {
    padding: 17px 70px 50px 38px;
  }

  .company-url {
    font-size: 11px;
  }
`;
