import React from "react";
import { observer } from "mobx-react-lite";
import { Button, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { VerificationText } from "../elements/styles";
import check from "../../../assets/icons/check.svg";

const SuccessPage = observer(() => {
  const location = useHistory();

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="success-circle d-flex">
          <Image src={check} id="checkCircle" />
        </div>
        <h2 className="login-title">Password Reset.</h2>
      </div>

      <VerificationText className="my-2">
      Your password has been successfully changed. 
      </VerificationText>

      <Button
        variant="light"
        className="w-100 a_loginButton btn-default my-1"
        type="submit"
        id="login_confirm"
        onClick={() => location.push("/")}
      >
        Sign in
      </Button>
    </>
  );
});

export default SuccessPage;
