import { makeAutoObservable, runInAction } from 'mobx';
import {
  login,
  sendResetOtp,
  resetPassword as resetPasswordApi,
} from '../../api/index';
import {
  setPassword as setPasswordApi,
  sendOTP as sendOTPApi,
  verifyOTP as verifyOTPApi,
  editProfile,
  editProfilePic,
  optOut,
} from '../../api/account';
import LocalStorageService from '../../utils/LocalStorageService';
import history from '../../history';
import AccountStore from '../account/accountStore';

class AuthStore {
  accountStore: AccountStore;

  loginResponseText = null;

  resetOtpResponseText = null;

  resetOtp = null;

  otpVerificationStatus = '';

  reotpVerificationStatus = '';

  resetOtpVerified = false;

  setPasswordError = '';

  setVerificationError = '';

  passwordResetMessage = '';

  nextUrl = '';

  constructor(accountStore: AccountStore) {
    this.accountStore = accountStore;
    makeAutoObservable(this);
  }

  setPasswordResetMessage = (text) => {
    this.passwordResetMessage = text;
  };

  setLoginResponseText = (text) => {
    this.loginResponseText = text;
  };

  setResetOtpVerified = (value) => {
    this.resetOtpVerified = value;
  };

  setresetOtpResponseText = (value) => {
    this.resetOtpResponseText = value;
  };

  setOtpVerificationStatus = (value) => {
    this.otpVerificationStatus = value;
  };

  setReOtpVerificationStatus = (value) => {
    this.reotpVerificationStatus = value;
  };

  setResetOtp = (otp) => {
    this.resetOtp = otp;
  };

  setNextUrl = (value) => {
    this.nextUrl = value;
  };

  sendOTP = async (mobile, cb) => {
    const res = await sendOTPApi(mobile);
    if (res.status === 200) {
      cb();
      runInAction(() => {
        this.setVerificationError = null;
      });
    } else {
      runInAction(() => {
        if (res.data.mobile.length) {
          this.setVerificationError = res.data.mobile[0];
        }
      });
    }
  };

  sendresetOtp = async (email, cb) => {
    const res = await sendResetOtp(email, '');
    if (res.status === 200) {
      cb();
      this.setresetOtpResponseText(null);
    } else {
      if (res.data.message) {
        this.setresetOtpResponseText(res.data.message);
      } else if (res.data.email) {
        this.setresetOtpResponseText(res.data.email);
      }
    }
  };

  verifyResetOtp = async (email, otp) => {
    this.setReOtpVerificationStatus('Verifying');
    const res = await sendResetOtp(email, otp);
    if (res.status === 200) {
      this.setResetOtp(otp);
      this.setResetOtpVerified(true);
      this.setReOtpVerificationStatus('Verified');
      this.setPasswordResetMessage(null);
    } else {
      this.setResetOtpVerified(false);
      this.setReOtpVerificationStatus('Incorrect verification code');
    }
  };

  resetPassword = async (email, password) => {
    const res = await resetPasswordApi(email, this.resetOtp, password);
    if (res.status === 200) {
      history.push('/reset-password/success');
    } else {
      if (res.data.message) {
        this.setPasswordResetMessage(res.data.message);
      } else if (res.data.new_password2) {
        this.setPasswordResetMessage(res.data.new_password2);
      } else if (res.data.otp) {
        this.setPasswordResetMessage('Otp expired.');
      }
    }
  };

  verifyOTP = async (otp, mobile, cb) => {
    this.setOtpVerificationStatus('Verifying');
    const res = await verifyOTPApi(otp, mobile);
    if (res.status === 200) {
      cb();
      this.setOtpVerificationStatus('Verified');
    } else {
      this.setOtpVerificationStatus('Incorrect verification code');
    }
  };

  onOptOut = async (reason) => {
    const res = await optOut(reason);
    if (res.status === 200) {
      this.onLogOut();
      history.push('/login');
    }
  };

  onLogOut = () => {
    LocalStorageService.clearToken();
  };

  setPassword = async (password1, password2) => {
    const res = await setPasswordApi(password1, password2);

    if (res.status === 200) {
      history.push('/verify-mobile');
    } else {
      runInAction(() => {
        if (res.data.new_password2.length) {
          this.setPasswordError = res.data.new_password2[0];
        } else if (res.data.message) {
          this.setPasswordError = res.data.message;
        }
      });
    }
  };

  onLogin = async (formData: any) => {
    this.setLoginResponseText(null);
    let data = {};

    data.email = formData.email;

    data.password = formData.password;
    const res = await login(data);
    if (res.status === 200) {
      const { refresh, access, user } = res.data;
      this.accountStore.setUser(user);
      await LocalStorageService.setToken({ refresh, access });
      if (user.first_login) {
        history.push('/set-password');
      } else if (!user.first_login && !user.mobile_verified) {
        history.push('/verify-phone-number');
      } else if (!user.consent) {
        history.push('/terms');
      } else {
        if (this.nextUrl === '') {
          history.push('/');
        } else {
          history.push(this.nextUrl);
        }
      }
    } else {
      this.setLoginResponseText('Incorrect email or password.');
    }
  };

  onEditProfilePic = async (photo) => {
    var data = new FormData();
    if (photo) {
      data.append('photo', photo);
    }
    const res = await editProfilePic(data, this.accountStore.user.id);
    if (res.status === 200) {
      runInAction(() => {
        const editUser = this.accountStore.user;
        editUser.photo = res.data.photo;
        this.accountStore.setUser(editUser);
      });
    }
  };

  onEditProfile = async (data) => {
    const interest = data.interests;
    const editedEngagements = data.engagements;
    if (data.interests) {
      data.interests = data.interests.map((item) => item.id);
    }
    if (data.engagements) {
      data.engagements = data.engagements.map((item) => item.id);
    }
    const res = await editProfile(data, this.accountStore.user.id);
    if (res.status === 200) {
      runInAction(() => {
        const editUser = this.accountStore.user;
        editUser.description = res.data.description;
        editUser.alternate_mobile = res.data.alternate_mobile;
        editUser.first_name = res.data.first_name;
        editUser.last_name = res.data.last_name;
        editUser.salutation = res.data.salutation;
        editUser.secondary_email = res.data.secondary_email;
        editUser.title = res.data.title;
        editUser.experience = res.data.experience;
        if (data.interests) {
          editUser.interests = interest;
        }
        if (data.engagements) {
          editUser.engagements = editedEngagements;
        }
        if (data.role) {
          editUser.role = this.accountStore.roleObjects.filter((item) =>
            data.role?.includes(item.id)
          );
        }
        this.accountStore.setUser(editUser);
      });
    }
  };
}

export default AuthStore;
