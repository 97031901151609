import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { CarouselDiv } from './styles';
import CarouselTile from '../CarouselTile';

const Carousel = ({ carousel }) => {
  const [active, setActive] = useState(0);
  const [direction, setDirection] = useState('');

  useEffect(() => {
    setTimeout(() => {
      var newActive = active;
      setActive((newActive + 1) % carousel.length);
      setDirection('right');
    }, 3000);
  }, [active, carousel.length]);

  const generateItems = () => {
    var newitems = [];
    var level;
    for (var i = active - 3; i < active + 4; i++) {
      var index = i;
      if (i < 0) {
        index = carousel.length + i;
      } else if (i >= carousel.length) {
        index = i % carousel.length;
      }
      level = active - i;
      newitems.push(
        <CSSTransition timeout={300}>
          <CarouselTile
            key={index}
            id={carousel[index]}
            level={level}
            member={carousel[index]}
          />
        </CSSTransition>
      );
    }
    return newitems;
  };

  return (
    <CarouselDiv id='carousel' className='noselect'>
      <TransitionGroup className={direction}>{generateItems()}</TransitionGroup>
    </CarouselDiv>
  );
};

export default Carousel;
