import React, { useEffect } from 'react';
import '../components/Style.css';
import { observer } from 'mobx-react-lite';
import { Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useStore } from '../../../store';
import { SuccesspageDiv } from './Successstyles';
import logo from '../../../assets/images/LogoBlack.png';

import check from '../../../assets/icons/check.svg';
import background from '../../../assets/images/Backgroundnew.svg.svg';
import linkedin from '../../../assets/icons/Icon-Linkedin.svg';
import wave from '../../../assets/images/waveDesign.svg';

const SuccessPage = observer(() => {
  const location = useHistory();
  const { authStore, accountStore } = useStore();

  const { footer, listFooter } = accountStore;

  const { setOtpVerificationStatus } = authStore;
  // useEffect(() => {
  //   onLogOut();
  // }, [onLogOut]);
  useEffect(() => {
    setOtpVerificationStatus();
  }, [setOtpVerificationStatus]);

  useEffect(() => {
    listFooter();
  }, [listFooter]);

  return (
    <SuccesspageDiv className=' success-page'>
      <Helmet>
        <style>
          {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh; }`}
        </style>
      </Helmet>
      <img src={wave} alt='' className='wave-design' />
      <div className='glass'>
        <Image src={logo} alt='logo' className='log' />

        <div className='content-card'>
          <h2>All set!</h2>

          <div className='d-flex success-info'>
            <div className='success-circle'>
              <Image src={check} id='checkCircle' />
            </div>
            <h6 className='code-data'>
              Your account has been setup. We recommend you review your profile
              and preferences in the Edit Profile menu option when you sign in
              for the first time
            </h6>
          </div>

          <Button
            variant='light'
            className='w-100 btn-default'
            type='submit'
            id='success-continue'
            onClick={() => {
              location.push('/');
              window.location.reload();
            }}
          >
            Enter
          </Button>
        </div>
      </div>
      <div className='d-flex'>
        {footer.map((item) => (
          <div className='button-group d-flex'>
            <a href={item.link} target='_blank' rel='noreferrer'>
              <button className='terms1' id='terms'>
                {item.text}
              </button>
            </a>
          </div>
        ))}
      </div>

      <div className='icon-div'>
        <a
          href={process.env.REACT_APP_API_LINKEDIN_LINK}
          target='_blank'
          rel='noreferrer'
        >
          <img src={linkedin} className='linked-logo' alt='icon' />{' '}
        </a>
      </div>
    </SuccesspageDiv>
  );
});

export default SuccessPage;
