import React, { useState} from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../store';
import { Form, Button, Image } from 'react-bootstrap';
import { ProfileFormDiv } from '../elements/styles';
import close from '../../../assets/icons/close.svg';

const PreferenceForm = observer(({ user, show, setEdit, engagements }) => {
  const [multiSelections, setMultiSelections] = useState(user.engagements);

  const { authStore } = useStore();

  const { onEditProfile } = authStore;

const onChange =(e,item)=>{
    let userEngagements = [...multiSelections];
    const index = userEngagements.findIndex((eng) => eng.id === Number(item.id));
    if (index < 0 && e.target.checked) {
      userEngagements.push(item);
    }
    if (!e.target.checked && index >= 0) {
     userEngagements.splice(index, 1);
    }
    setMultiSelections(userEngagements);
}

  const onSubmit = () => {
    let data = {};
    data.engagements = multiSelections;
    onEditProfile(data);
    setEdit(false);
    
  };

  // const onChange = (e, item) => {
  //   let data = [...multiSelections];
  //   const index = data.findIndex((eng) => eng.id === Number(item.id));
  //   if (index < 0 && e.target.checked) {
  //     console.log("itemmm",item)
  //     data.push(item);
  //   }
  //   if (!e.target.checked && index >= 0) {
  //     data.splice(index, 1);
  //   }
  //   setMultiSelections(data);
  // };

  return (
    <ProfileFormDiv show={show}>
      <div className='d-flex justify-content-between align-items-start mb-4'>
        <div>
          <h4>Edit your profile</h4>
          <h2>Engagements</h2>
        </div>

        <button
          onClick={() => {
            setMultiSelections(user.engagements);
            setEdit(false);
          }}
          className='close-button'
        >
          <Image src={close} alt='close' className='close' />
        </button>
      </div>

      <Form className='typeahead-form'>
        <Form.Group className='d-flex flex-wrap'>
          {engagements?.map((item) => (
            <Form.Check
              key={item.id}
              name='engagements'
              label={item.name}
              value={item}
              defaultChecked={multiSelections?.some(
                (element) => element.id === item.id
              )}
              onChange={(e) => onChange(e, item)}
            />
          ))}
        </Form.Group>
      </Form>
      <Button className='btn-default' type='button' onClick={() => onSubmit()}>
        Save
      </Button>
    </ProfileFormDiv>
  );
});

export default PreferenceForm;
