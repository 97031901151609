import styled from 'styled-components';

export const Top = styled.div`
  padding: 20px 20px 12px 21px;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  height: fit-content;
  @supports (-webkit-touch-callout: none) {
    height: 76px;
  }
  position: fixed;
  z-index: 2;
  background: #ffffff;
  .cart {
    border-radius: 50%;
    border: 0;
    background: #fff;
    width: 40px;
    height: 40px;
    @supports (-webkit-touch-callout: none) {
     width: 50px
    }
    
    img {
      width: 32px;
    }

    position: relative;

    .cart-count {
      right: -7px;
      top: -1px;
      position: absolute;
      color: #ff647f;
      width: 20px;
      font-size: 10px;
      height: 14px;
      line-height: 12px;
      font-weight: 700;
      background: #fff;
      border: 1px solid #00aeae;
      border-radius: 3px;
    }
  }
  .logo-button {
    border: none;
    background: white;
  }

  .cart-div {
    position: relative;
    .alert {
      position: fixed;
      min-width: max-content;
      padding: 15px 15px 10px 11px;
      // bottom: -65px;
      top: 95px;
      z-index: 100;
      right: 10px;
      // right: -30px;
      background: #fff7da;
      border-radius: 5px;
      color: #666666;
      font-size: 10px;

      span {
        font-weight: 700;
        padding: 0 2px;
      }

      .alert-arrow {
        position: absolute;
        top: -5px;
        right: 45px;
        border: solid #fff7da;
        background: #fff7da;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      .success-circle {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #27d3db;
        margin-right: 2px;
        #checkCircle {
          height: 3.5px;
          width: 5.5px;
          margin-left: 3px;
          margin-bottom: 5px;
        }
      }
    }
  }
`;
