import React from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { Form, Button, Image } from "react-bootstrap";
import { useStore } from "../../../store";
import { ProfileFormDiv } from "../elements/styles";
import close from "../../../assets/icons/close.svg";

const ContactForm = observer(({ user, show, setEdit }) => {
  const { authStore } = useStore();

  const { onEditProfile } = authStore;

  const { handleSubmit, register,reset } = useForm();

  const onSubmit = (data) => {
    onEditProfile(data);
    setEdit(false);
  };

  return (
    <ProfileFormDiv show={show}>
      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h4>Edit your profile</h4>
          <h2>Edit contact info</h2>
        </div>
        <button onClick={() => {setEdit(false);reset()}} className="close-button">
          <Image src={close} alt="close" className="close" />
        </button>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group>
          <Form.Label>Alternate Mobile</Form.Label>
          <Form.Control
            name="alternate_mobile"
            defaultValue={user?.alternate_mobile}
            {...register("alternate_mobile")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Secondary Email</Form.Label>
          <Form.Control
            name="secondary_email"
            defaultValue={user?.secondary_email}
            {...register("secondary_email")}
          />
        </Form.Group>

        <Button className="btn-default" type="submit">
          Save
        </Button>
      </Form>
    </ProfileFormDiv>
  );
});

export default ContactForm;
