import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import { Image, Button } from 'react-bootstrap';
import { useStore } from '../../../store';
// import { useForm } from "react-hook-form";
import AcceptModal from '../../MeetingDetails/components/AcceptModal';
import { UserCardBox } from '../elements/styles';
// import CancelModal from '../../MeetingDetails/components/CancelModal';
import RejectModal from '../../MeetingDetails/components/RejectModal';
// import { StyledModal } from "../../MeetingDetails/elements/styles";
// import group from "../../../assets/images/group.svg";
// import close from "../../../assets/icons/close.svg";
import copylink from '../../../assets/icons/copylink.svg';
import UserHolder from '../../../assets/images/profile-holder.svg';
import info from '../../../assets/icons/reason.svg';
import close from '../../../assets/icons/cross.svg';
import check from '../../../assets/icons/check.svg';
import points from '../../../assets/icons/pointsN.svg';
import tick from '../../../assets/icons/tick.svg';
import OrgModal from '../../MeetingDetails/components/OrgModal';
import classNames from 'classnames';
import up from '../../../assets/icons/dropup-arrow.svg';
import down from '../../../assets/icons/dropdown-arrow.svg';
import OrgCancelModal from '../../MeetingDetails/components/OrgCancelModal';

const MeetingCard = observer(({ meeting }) => {
  const [showAccept, setShowAccept] = useState();
  const [showReject, setShowReject] = useState();
  const [more, setMore] = useState(false);

  const location = useHistory();
  const { accountStore, meetingStore } = useStore();
  const { user } = accountStore;
  const { onChangeMeetingListStatus } = meetingStore;
  function toggle() {
    setMore(!more);
  }

  return (
    <UserCardBox className='w-100 py-3' id={meeting.uid}>
      <div className='meeting-info'>
        <div className='box-content'>
          <div>
            <span className='meeting-type'>{meeting.product_name}</span>
            {/* {meeting.status === 'REJECTED' && (
            <span className='meeting-status'>REGRETTED</span>)} */}

            {meeting.meeting_type !== '121 MEETING' &&
            meeting.initiator_details.id !== user.id &&
            meeting.status === 'SCHEDULED' ? (
              meeting?.participants?.find((item) => item.user.id === user.id)
                ?.attendance === 'Not Attending' ? (
                <span className='meeting-status'>REGRETTED</span>
              ) : meeting?.participants?.find(
                  (item) => item.user.id === user.id
                )?.attendance === 'Attending' ? (
                <span className='meeting-status'>{meeting.status}</span>
              ) : (
                <span className='meeting-status'>REQUESTED</span>
              )
            ) : meeting.status === 'REJECTED' ? (
              <span className='meeting-status'>REGRETTED</span>
            ) : (
              <span className='meeting-status'>{meeting.status}</span>
            )}
          </div>

          <div className='meeting-info-tab'>
            <span>ID:</span>
            {meeting.id}
          </div>

          {meeting.status === 'REQUESTED' && (
            <div className='meeting-requested-date meeting-info-tab'>
              <span className='meeting-date-range'>Requested dates: </span>
              <Moment format='DD MMMM, YYYY'>
                {meeting.request_start_date}
              </Moment>{' '}
              -{' '}
              <Moment format='DD MMMM, YYYY'>{meeting.request_end_date}</Moment>
            </div>
          )}
          {meeting.status === 'ACCEPTED' && (
            <div className='meeting-requested-date meeting-info-tab'>
              <span className='meeting-date-range'>Requested dates: </span>
              <Moment format='DD MMMM, YYYY'>
                {meeting.request_start_date}
              </Moment>{' '}
              -{' '}
              <Moment format='DD MMMM, YYYY'>{meeting.request_end_date}</Moment>
            </div>
          )}
          {meeting.status === 'CANCELLED' && (
            <div>
              {meeting?.meeting_datetime ? (
                <div className='meeting-requested-date meeting-info-tab'>
                  <span className='meeting-date-range'>Meeting Date: </span>
                  <Moment
                    format='DD MMMM YYYY, h:mm A'
                    className='schedule-time'
                  >
                    {meeting?.meeting_datetime}
                  </Moment>
                </div>
              ) : (
                <div className='meeting-requested-date meeting-info-tab'>
                  <span className='meeting-date-range'>Requested dates: </span>
                  <Moment format='DD MMMM, YYYY'>
                    {meeting.request_start_date}
                  </Moment>{' '}
                  -{' '}
                  <Moment format='DD MMMM, YYYY'>
                    {meeting.request_end_date}
                  </Moment>
                </div>
              )}
            </div>
          )}

          {meeting.status === 'REJECTED' && (
            <div>
              {meeting?.meeting_datetime ? (
                <div className='meeting-requested-date meeting-info-tab'>
                  <span className='meeting-date-range'>Meeting Date: </span>
                  <Moment
                    format='DD MMMM YYYY, h:mm A'
                    className='schedule-time'
                  >
                    {meeting?.meeting_datetime}
                  </Moment>
                </div>
              ) : (
                <div className='meeting-requested-date meeting-info-tab'>
                  <span className='meeting-date-range'>Requested dates: </span>
                  <Moment format='DD MMMM, YYYY'>
                    {meeting.request_start_date}
                  </Moment>{' '}
                  -{' '}
                  <Moment format='DD MMMM, YYYY'>
                    {meeting.request_end_date}
                  </Moment>
                </div>
              )}
            </div>
          )}
          <div className='meeting-purpose meeting-info-tab'>
            <span>Agenda:</span>
            <div className='more-text'>
              <p
                className={classNames({
                  less: !more,
                })}
              >
                {meeting.request_message}
              </p>
              {meeting.request_message.length > 100 && (
                <div className='readmore'>
                  {!more ? (
                    <div className='p-moreless' onClick={() => toggle()}>
                      More <img className='more-less' src={down} alt='' />
                    </div>
                  ) : (
                    <div className='p-moreless' onClick={() => toggle()}>
                      Less <img className='more-less' src={up} alt='' />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='meeting-info-tab'>
            <span>
              Initiated by{' '}
              {meeting.initiator_details?.id === user.id && 'you with'}
              {meeting.initiator_details?.id !== user.id &&
                user.vendor_admin &&
                `${meeting.initiator_details.full_name} with`}
            </span>
          </div>
        </div>
        <div className='employee-info'>
          <div>
            {meeting.initiator_details.id !== user.id ? (
              <>
                {user.vendor_admin === true ? (
                  <>
                    {meeting.meeting_type !== '121 MEETING' &&
                      meeting.status !== 'REJECTED' &&
                      meeting.status !== 'CANCELLED' &&
                      meeting.status !== 'REQUESTED' &&
                      meeting.status !== 'ACCEPTED' &&
                      meeting.participants?.filter(
                        (element) =>
                          element.user.id !== user.id &&
                          element.attendance === 'Attending'
                      )?.length > 0 && (
                        <div className='attending'>
                          {meeting?.status === 'COMPLETED'
                            ? 'Attended'
                            : 'Attending'}
                        </div>
                      )}
                    {meeting.participants?.filter(
                      (element) =>
                        element.user.id !== user.id &&
                        element.attendance === 'Attending'
                    )?.length > 0 &&
                      meeting.participants
                        ?.filter(
                          (element) =>
                            element.user.id !== user.id &&
                            element.attendance === 'Attending'
                        )
                        ?.map((item) => (
                          <div className='d-flex user-card' key={item.user?.id}>
                            <Image
                              src={
                                item?.user?.photo
                                  ? item?.user?.photo
                                  : UserHolder
                              }
                              alt='profile-pic'
                              className='profile-image'
                              roundedCircle
                            />
                            <div className='employee-text'>
                              <h6
                                onClick={() =>{
                                  if (item?.user?.linked_in_url) {
                                    if (item?.user.linked_in_url.startsWith('http'))
                                    {
                                      window.open(`${item?.user?.linked_in_url}`, '_blank')
                                    }
                                    else {
                                      window.open(`//${item?.user?.linked_in_url}`, '_blank')
                                    }
                                }
                              }
                            }
                                className='member-name'
                              >
                                {item?.user?.full_name}
                              </h6>
                              <div className='description'>
                                <div>{item?.user?.title}, </div>{' '}
                                <div>{item?.user?.company_name}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                    {meeting.meeting_type !== '121 MEETING' &&
                      meeting.status !== 'REJECTED' &&
                      meeting.status !== 'CANCELLED' &&
                      meeting.status !== 'REQUESTED' &&
                      meeting.status !== 'ACCEPTED' &&
                      meeting.participants?.filter(
                        (element) =>
                          element.user.id !== user.id &&
                          (element.attendance === 'Not Attending' ||
                            element.attendance === 'Did Not Attend')
                      )?.length > 0 && (
                        <>
                          <div className='notattending'>
                            {meeting?.status === 'COMPLETED'
                              ? 'Not attended'
                              : 'Not attending'}
                          </div>
                        </>
                      )}
                    {meeting.participants?.filter(
                      (element) =>
                        element.user.id !== user.id &&
                        (element.attendance === 'Not Attending' ||
                          element.attendance === 'Did Not Attend')
                    )?.length > 0 &&
                      meeting.participants
                        ?.filter(
                          (element) =>
                            element.user.id !== user.id &&
                            (element.attendance === 'Not Attending' ||
                              element.attendance === 'Did Not Attend')
                        )
                        ?.map((item) => (
                          <div
                            className=' user-card align-items-start justify-content-between'
                            key={item.user?.id}
                          >
                            <div className='d-flex info-card'>
                              <Image
                                src={
                                  item?.user?.photo
                                    ? item?.user?.photo
                                    : UserHolder
                                }
                                alt='profile-pic'
                                className='profile-image'
                                roundedCircle
                              />
                              <div className='employee-text'>
                                <div>
                                  <h6
                                    onClick={() =>{
                                      if (item?.user?.linked_in_url) {
                                        if (item?.user.linked_in_url.startsWith('http'))
                                        {
                                          window.open(`${item?.user?.linked_in_url}`, '_blank')
                                        }
                                        else {
                                          window.open(`//${item?.user?.linked_in_url}`, '_blank')
                                        }
                                    }
                                  }
                                }
                                    className='member-name'
                                  >
                                    {item?.user?.full_name}
                                  </h6>
                                  <div className='description'>
                                    <div>{item?.user?.title}, </div>{' '}
                                    <div>{item?.user?.company_name}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item?.not_attending_reason &&
                              meeting.status !== 'REQUESTED' &&
                              meeting.status !== 'ACCEPTED' && (
                                <div className='danger w-100 rejected'>
                                  {item?.accepted_date && (
                                    <div className='notattendingreason'>
                                      Changed after accepting initially
                                    </div>
                                  )}

                                  <div className='d-flex align-items-center'>
                                    <Image src={info} alt='copt' />
                                    <div className='reason notattendingreason'>
                                      {item?.not_attending_reason}
                                    </div>
                                  </div>
                                </div>
                              )}
                            {item?.attendance === 'Did Not Attend' && (
                              <div className='danger w-100 rejected'>
                                <div className='d-flex align-items-center'>
                                  <Image src={info} alt='copt' />
                                  <div className='reason notattendingreason'>
                                    Did not attend
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    {meeting.meeting_type !== '121 MEETING' &&
                      meeting.status === 'SCHEDULED' &&
                      meeting.participants?.filter(
                        (element) =>
                          element.user.id !== user.id &&
                          element.attendance === 'Not Responded'
                      )?.length > 0 && <div>Not responded yet</div>}
                    {meeting.participants
                      ?.filter(
                        (element) =>
                          element.user.id !== user.id &&
                          element.attendance === 'Not Responded'
                      )
                      ?.map((item) => (
                        <div
                          className='d-flex user-card align-items-start'
                          key={item.user?.id}
                        >
                          <Image
                            src={
                              item?.user?.photo ? item?.user?.photo : UserHolder
                            }
                            alt='profile-pic'
                            className='profile-image'
                            roundedCircle
                          />
                          <div className='employee-text'>
                            <h6
                              onClick={() =>{
                                if (item?.user?.linked_in_url) {
                                  if (item?.user.linked_in_url.startsWith('http'))
                                  {
                                    window.open(`${item?.user?.linked_in_url}`, '_blank')
                                  }
                                  else {
                                    window.open(`//${item?.user?.linked_in_url}`, '_blank')
                                  }
                              }
                            }
                          }
                              className='member-name'
                            >
                              {item?.user?.full_name}
                            </h6>
                            <div className='description'>
                              <div>{item?.user?.title}, </div>{' '}
                              <div>{item?.user?.company_name}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <div className='d-flex user-card'>
                    <Image
                      src={
                        meeting.initiator_details?.photo
                          ? meeting.initiator_details?.photo
                          : UserHolder
                      }
                      alt='profile-pic'
                      className='profile-image'
                      roundedCircle
                    />
                    <div className='employee-text'>
                      <h6
                        onClick={() =>{
                          if (meeting.initiator_details?.linked_in_url) {
                            if (meeting.initiator_details.linked_in_url.startsWith('http'))
                            {
                              window.open(`${meeting.initiator_details?.linked_in_url}`, '_blank')
                            }
                            else {
                              window.open(`//${meeting.initiator_details?.linked_in_url}`, '_blank')
                            }
                        }
                      }
                    }
                        className='member-name'
                      >
                        {meeting.initiator_details?.full_name}
                      </h6>
                      <div className='description'>
                        <div>{meeting.initiator_details?.title}, </div>{' '}
                        <div>{meeting?.initiator_details?.company_name}</div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {meeting.meeting_type !== '121 MEETING' &&
                  meeting.status !== 'REJECTED' &&
                  meeting.status !== 'CANCELLED' &&
                  meeting.status !== 'REQUESTED' &&
                  meeting.status !== 'ACCEPTED' &&
                  meeting.participants?.filter(
                    (element) =>
                      element.user.id !== user.id &&
                      element.attendance === 'Attending'
                  )?.length > 0 && (
                    <div className='attending'>
                      {meeting?.status === 'COMPLETED'
                        ? 'Attended'
                        : 'Attending'}
                    </div>
                  )}
                {meeting.participants?.filter(
                  (element) =>
                    element.user.id !== user.id &&
                    element.attendance === 'Attending'
                )?.length > 0 &&
                  meeting.participants
                    ?.filter(
                      (element) =>
                        element.user.id !== user.id &&
                        element.attendance === 'Attending'
                    )
                    ?.map((item) => (
                      <div className='d-flex user-card' key={item.user?.id}>
                        <Image
                          src={
                            item?.user?.photo ? item?.user?.photo : UserHolder
                          }
                          alt='profile-pic'
                          className='profile-image'
                          roundedCircle
                        />
                        <div className='employee-text'>
                          <h6
                            onClick={() =>{
                              if (item?.user?.linked_in_url) {
                                if (item?.user.linked_in_url.startsWith('http'))
                                {
                                  window.open(`${item?.user?.linked_in_url}`, '_blank')
                                }
                                else {
                                  window.open(`//${item?.user?.linked_in_url}`, '_blank')
                                }
                            }
                          }
                        }
                            className='member-name'
                          >
                            {item?.user?.full_name}
                          </h6>
                          <div className='description'>
                            <div>{item?.user?.title}, </div>{' '}
                            <div>{item?.user?.company_name}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                {meeting.meeting_type !== '121 MEETING' &&
                  meeting.status !== 'REJECTED' &&
                  meeting.status !== 'CANCELLED' &&
                  meeting.status !== 'REQUESTED' &&
                  meeting.status !== 'ACCEPTED' &&
                  meeting.participants?.filter(
                    (element) =>
                      element.user.id !== user.id &&
                      (element.attendance === 'Not Attending' ||
                        element.attendance === 'Did Not Attend')
                  )?.length > 0 && (
                    <>
                      <div className='notattending'>
                        {meeting?.status === 'COMPLETED'
                          ? 'Not attended'
                          : 'Not attending'}
                      </div>
                    </>
                  )}
                {meeting.participants?.filter(
                  (element) =>
                    element.user.id !== user.id &&
                    (element.attendance === 'Not Attending' ||
                      element.attendance === 'Did Not Attend')
                )?.length > 0 &&
                  meeting.participants
                    ?.filter(
                      (element) =>
                        element.user.id !== user.id &&
                        (element.attendance === 'Not Attending' ||
                          element.attendance === 'Did Not Attend')
                    )
                    ?.map((item) => (
                      <div
                        className=' user-card align-items-start justify-content-between'
                        key={item.user?.id}
                      >
                        <div className='d-flex info-card'>
                          <Image
                            src={
                              item?.user?.photo ? item?.user?.photo : UserHolder
                            }
                            alt='profile-pic'
                            className='profile-image'
                            roundedCircle
                          />
                          <div className='employee-text'>
                            <div>
                              <h6
                                onClick={() =>{
                                  if (item?.user?.linked_in_url) {
                                    if (item?.user.linked_in_url.startsWith('http'))
                                    {
                                      window.open(`${item?.user?.linked_in_url}`, '_blank')
                                    }
                                    else {
                                      window.open(`//${item?.user?.linked_in_url}`, '_blank')
                                    }
                                }
                              }
                            }
                                className='member-name'
                              >
                                {item?.user?.full_name}
                              </h6>
                              <div className='description'>
                                <div>{item?.user?.title}, </div>{' '}
                                <div>{item?.user?.company_name}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {item?.not_attending_reason &&
                          meeting.status !== 'REQUESTED' &&
                          meeting.status !== 'ACCEPTED' && (
                            <div className='danger w-100 rejected'>
                              {item?.accepted_date && (
                                <div className='notattendingreason'>
                                  Changed after accepting initially
                                </div>
                              )}

                              <div className='d-flex align-items-center'>
                                <Image src={info} alt='copt' />
                                <div className='reason notattendingreason'>
                                  {item?.not_attending_reason}
                                </div>
                              </div>
                            </div>
                          )}
                        {item?.attendance === 'Did Not Attend' && (
                          <div className='danger w-100 rejected'>
                            <div className='d-flex align-items-center'>
                              <Image src={info} alt='copt' />
                              <div className='reason notattendingreason'>
                                Did not attend
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                {meeting.meeting_type !== '121 MEETING' &&
                  meeting.status === 'SCHEDULED' &&
                  meeting.participants?.filter(
                    (element) =>
                      element.user.id !== user.id &&
                      element.attendance === 'Not Responded'
                  )?.length > 0 && <div>Not responded yet</div>}
                {meeting.participants
                  ?.filter(
                    (element) =>
                      element.user.id !== user.id &&
                      element.attendance === 'Not Responded'
                  )
                  ?.map((item) => (
                    <div
                      className='d-flex user-card align-items-start'
                      key={item.user?.id}
                    >
                      <Image
                        src={item?.user?.photo ? item?.user?.photo : UserHolder}
                        alt='profile-pic'
                        className='profile-image'
                        roundedCircle
                      />
                      <div className='employee-text'>
                        <h6
                          onClick={() =>{
                            if (item?.user?.linked_in_url) {
                              if (item?.user.linked_in_url.startsWith('http'))
                              {
                                window.open(`${item?.user?.linked_in_url}`, '_blank')
                              }
                              else {
                                window.open(`//${item?.user?.linked_in_url}`, '_blank')
                              }
                          }
                        }
                      }
                          className='member-name'
                        >
                          {item?.user?.full_name}
                        </h6>
                        <div className='description'>
                          <div>{item?.user?.title}, </div>{' '}
                          <div>{item?.user?.company_name}</div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
      {meeting?.initiator_details.user_type === 'Organizer' ? (
        <OrgCancelModal
          show={showReject}
          setShow={setShowReject}
          uuid={meeting.uid}
        />
      ) : (
        <RejectModal
          show={showReject}
          setShow={setShowReject}
          uuid={meeting.uid}
        />
      )}
      <div className='actions  '>
        {user.vendor_admin === true &&
          meeting.initiator_details.id !== user.id && (
            <>
              <div className='attending-div'>
                <div className='points'>
                  <img src={points} alt='' />
                  <div>
                    {meeting.initiator_points > 0 ? (
                      <div>
                        Meeting points deducted{' '}
                        <span className='meeting-points'>
                          {' '}
                          {meeting.initiator_points}{' '}
                        </span>{' '}
                      </div>
                    ) : (
                      <div>No reward points</div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        {meeting.initiator_details.id === user.id ? (
          <>
            {(meeting?.status === 'REQUESTED' ||
              meeting?.status === 'ACCEPTED') && (
              <div className='attending-div'>
                <div className='points'>
                  <img src={points} alt='' />

                  <div>
                    {meeting.initiator_points > 0 ? (
                      <div>
                        {' '}
                        <span className='meeting-points'>
                          {' '}
                          {meeting.initiator_points}{' '}
                        </span>{' '}points will be deducted on successful completion of this meeting.
                      </div>
                    ) : (
                      <div>No reward points</div>
                    )}
                  </div>
                </div>
                <Button
                  onClick={() => {
                    location.push(`/cancel-meeting/${meeting.uid}`);
                  }}
                  className='btn-default cancelButton'
                >
                  Cancel request
                </Button>
              </div>
            )}
            {meeting?.status === 'SCHEDULED' && (
              <div className='scheduled w-100 '>
                <div className='points'>
                  <img src={points} alt='' />

                  <div className='attending-div'>
                    {meeting.initiator_points > 0 ? (
                      <div>
                        {' '}
                        <span className='meeting-points'>
                          {meeting.initiator_points}
                        </span>{' '}points will be deducted on successful completion of this meeting.
                      </div>
                    ) : (
                      <div>No reward points</div>
                    )}
                  </div>
                </div>
                {meeting?.meeting_datetime && (
                  <div
                    className='meeting-requested-date meeting-info-tab'
                    id='schedule-date'
                  >
                    <span className='meeting-date mr-1'>Meeting date </span>
                    <Moment format='DD MMMM YYYY,  h:mm A'>
                      {meeting?.meeting_datetime}
                    </Moment>{' '}
                    -{' '}
                    <Moment format='h:mm A'>
                      {meeting?.meeting_datetime_end}
                    </Moment>
                  </div>
                )}
                <div className='join'>
                  <div>
                    <a
                      href={
                        meeting?.participants.find(
                          (item) => item?.user?.id === user?.id
                        )
                          ? meeting?.participants
                              ?.filter((item) => item?.user?.id === user?.id)
                              .map((item) =>
                                item.individual_link
                                  ? item.individual_link
                                  : meeting?.meeting_link
                              )
                          : meeting?.meeting_link
                      }
                      className='btn btn-primary btn-default'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Join meeting
                    </a>
                    <button
                      className='btn-secondary'
                      onClick={() => {
                        navigator.clipboard.writeText(
                          meeting?.participants.find(
                            (item) => item?.user?.id === user?.id
                          )
                            ? meeting?.participants
                                ?.filter((item) => item?.user?.id === user?.id)
                                .map((item) =>
                                  item.individual_link
                                    ? item.individual_link
                                    : meeting?.meeting_link
                                )
                            : meeting?.meeting_link
                        );
                      }}
                    >
                      <Image src={copylink} alt='copt' className='px-1' />
                      Copy link
                    </button>
                  </div>
                  <div className='reschedule-cancel'>
                    Send request{' '}
                    <button
                      className='btn-secondary'
                      onClick={() => {
                        location.push(`/reschedule-meeting/${meeting.uid}`);
                      }}
                    >
                      reschedule
                    </button>
                    or
                    <button
                      className='btn-secondary'
                      onClick={() => {
                        location.push(`/cancel-meeting/${meeting.uid}`);
                      }}
                    >
                      cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {meeting?.status === 'COMPLETED' && (
              <div>
                <div className='points'>
                  <img src={points} alt='' />

                  <div className='attending-div'>
                    {meeting.initiator_points > 0 ? (
                      <div>
                        Meeting points deducted{' '}
                        <span className='meeting-points'>
                          {meeting.initiator_points}
                        </span>
                      </div>
                    ) : (
                      <div>No reward points</div>
                    )}
                  </div>
                </div>
                <div className='interest w-100 actions d-flex justify-content-between completed'>
                  {meeting?.meeting_datetime && (
                    <>
                      <div>
                        <h6 className='meeting-date'>Attended on</h6>
                        <Moment format='DD MMMM YYYY, h:mm A'>
                          {meeting?.meeting_datetime}
                        </Moment>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {(meeting?.status === 'REJECTED' ||
              meeting?.status === 'CANCELLED') && (
              <div className='danger w-100  d-flex align-items-start cancelled'>
                <div>
                  <Image src={info} alt='copt' />
                </div>

                <div className='reason'>
                  <span className='reason-title'>
                    {' '}
                    {meeting?.status === 'REJECTED'
                      ? 'Regret'
                      : 'Cancelled'}{' '}
                    reason:{' '}
                  </span>
                  {meeting?.status === 'REJECTED'
                    ? meeting?.reject_reason
                    : meeting?.cancel_reason}{' '}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {meeting?.status === 'REQUESTED' &&
              meeting?.participants?.find((item) => item.user.id === user.id)
                ?.attendance === 'Not Responded' && (
                <div className='attending-div'>
                  <div className='points'>
                    <img src={points} alt='' />

                    <div>
                      {meeting.acceptor_points > 0 ? (
                        <div>
                          Meeting points{' '}
                          <span className='meeting-points'>
                            {meeting.acceptor_points}
                          </span>
                        </div>
                      ) : (
                        <div>No reward points</div>
                      )}
                    </div>
                  </div>
                  <div className='cancelButton d-flex'>
                    <Button
                      onClick={() => {
                        onChangeMeetingListStatus(meeting.uid, null, 'accept');
                        setShowAccept(true);
                      }}
                      className='btn-default'
                    >
                      Accept
                    </Button>
                    <Button
                      onClick={() => {
                        setShowReject(true);
                      }}
                      className='btn-secondary'
                    >
                      Regret
                    </Button>
                  </div>
                </div>
              )}
            {meeting?.initiator_details.user_type === 'Organizer' ? (
              <OrgModal
                showAccept={showAccept}
                setShowAccept={setShowAccept}
                meeting={meeting}
              />
            ) : (
              <AcceptModal
                showAccept={showAccept}
                setShowAccept={setShowAccept}
                meeting={meeting}
              />
            )}

            {(meeting?.status === 'REQUESTED' ||
              meeting?.status === 'ACCEPTED') &&
              meeting?.participants?.find((item) => item.user.id === user.id)
                ?.attendance === 'Attending' && (
                <div className='scheduled w-100'>
                  <div className='points d-block'>
                    <div className='d-flex'>
                      <img src={points} alt='' />

                      <div className='attending-div d-flex justify-content-between'>
                        {meeting.acceptor_points > 0 ? (
                          <div>
                            Meeting points{' '}
                            <span className='meeting-points'>
                              {meeting.acceptor_points}
                            </span>
                          </div>
                        ) : (
                          <div>No reward points</div>
                        )}
                        {meeting?.initiator_details.user_type === 'DM' ? (
                          <div className='partner-check'>Peer Meeting</div>
                        ) : (
                          <div className='partner-check'>Partner Meeting </div>
                        )}
                      </div>
                    </div>
                    {meeting?.meeting_datetime && (
                      <div className='d-flex'>
                        <h6 className='meeting-date mr-1'>Meeting Date</h6>
                        <Moment
                          format='DD MMMM YYYY, h:mm A'
                          className='schedule-time'
                        >
                          {meeting?.meeting_datetime}
                        </Moment>
                      </div>
                    )}
                    <div className='d-flex'>
                      <img src={tick} alt='' className='tick' />
                      <p className='accepted-btn'>Accepted</p>
                      <Button
                        onClick={() => {
                          if (meeting.meeting_type === '121 MEETING') {
                            location.push(`/cancel-meeting/${meeting.uid}`);
                          } else {
                            setShowReject(true);
                          }
                        }}
                        className='btn-default'
                        id='req-brn'
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className='meeting-info-text'>
                      121 connects team will share meeting link shortly
                    </div>
                  </div>
                </div>
              )}
            {(meeting?.status === 'REQUESTED' ||
              meeting?.status === 'ACCEPTED') &&
              meeting?.participants?.find((item) => item.user.id === user.id)
                ?.not_attending_reason && (
                <div>
                  <div className='points'>
                    <img src={points} alt='' />

                    <div className='attending-div d-flex justify-content-between'>
                      {meeting.acceptor_points > 0 ? (
                        <div>
                          Meeting points{' '}
                          <span className='meeting-points'>
                            {meeting.acceptor_points}
                          </span>
                        </div>
                      ) : (
                        <div>No reward points</div>
                      )}
                      {meeting?.initiator_details.user_type === 'DM' ? (
                        <div className='partner-check'>Peer Meeting</div>
                      ) : (
                        <div className='partner-check'>Partner Meeting </div>
                      )}
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      onChangeMeetingListStatus(meeting.uid, null, 'accept');
                      setShowAccept(true);
                    }}
                    className='btn-default'
                  >
                    Attending
                  </Button>
                </div>
              )}
            {meeting?.status === 'COMPLETED' && (
              <div>
                {meeting?.participants?.find((item) => item.user.id === user.id)
                  ?.attendance === 'Attending' ? (
                  <div>
                    <div className='points'>
                      <img src={points} alt='' />

                      <div className='attending-div d-flex justify-content-between '>
                        {meeting.acceptor_points > 0 ? (
                          <div>
                            Meeting points awarded{' '}
                            <span className='meeting-points'>
                              {meeting.acceptor_points}
                            </span>
                          </div>
                        ) : (
                          <div>No reward points</div>
                        )}
                        {meeting?.initiator_details.user_type === 'DM' ? (
                          <div className='partner-check  px-2'>
                            Peer Meeting
                          </div>
                        ) : (
                          <div className='partner-check  px-2'>
                            Partner Meeting{' '}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='interest w-100 actions d-flex justify-content-between completed'>
                      {meeting?.meeting_datetime && (
                        <>
                          <div>
                            <h6 className='meeting-date'>Attended on</h6>
                            <Moment format='DD MMMM YYYY, h:mm A'>
                              {meeting?.meeting_datetime}
                            </Moment>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {user.vendor_admin ? (
                      <>
                        {meeting?.participants?.find(
                          (item) => item.user.id === user.id
                        )?.attendance === 'Did Not Attend' && (
                          <div className='danger w-100  d-flex align-items-start cancelled'>
                            <div>
                              <Image src={info} alt='copt' />
                            </div>
                            <div className='reason'>Did not attend</div>
                          </div>
                        )}
                        {meeting?.participants?.find(
                          (item) => item.user.id === user.id
                        )?.attendance !== 'Did Not Attend' && (
                          <div className=' w-100  d-flex align-items-start cancelled'>
                            <div></div>
                            <div className='reason'></div>
                          </div>
                        )}
                        {meeting?.participants?.find(
                          (item) => item.user.id !== user.id
                        )?.attendance === 'Did Not Attend' && (
                          <div className='danger w-100  d-flex align-items-start cancelled'>
                            <div>
                              <Image src={info} alt='copt' />
                            </div>
                            <div className='reason'>Did not attend</div>
                          </div>
                        )}

                        {meeting?.participants?.find(
                          (item) => item.user.id !== user.id
                        )?.attendance !== 'Did Not Attend' && (
                          <div className=' w-100  d-flex align-items-start cancelled'>
                            <div></div>
                            <div className='reason'></div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {meeting?.participants?.find(
                          (item) => item.user.id === user.id
                        )?.attendance === 'Did Not Attend' && (
                          <div className='danger w-100  d-flex align-items-start cancelled'>
                            <div>
                              <Image src={info} alt='copt' />
                            </div>
                            <div className='reason'>Did not attend</div>
                          </div>
                        )}

                        {meeting?.participants?.find(
                          (item) => item.user.id === user.id
                        )?.attendance !== 'Did Not Attend' && (
                          <div className='danger w-100  d-flex align-items-start cancelled'>
                            <div>
                              <Image src={info} alt='copt' />
                            </div>
                            <div className='reason'>Did not attend</div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            {(meeting?.status === 'REJECTED' ||
              meeting?.status === 'CANCELLED') && (
              <div className='danger w-100  d-flex align-items-start cancelled'>
                <div>
                  <Image src={info} alt='copt' />
                </div>

                <div className='reason'>
                  <span className='reason-title'>
                    {' '}
                    {meeting?.status === 'REJECTED'
                      ? 'Regret'
                      : 'Cancelled'}{' '}
                    reason:{' '}
                  </span>
                  {meeting?.status === 'REJECTED'
                    ? meeting?.reject_reason
                    : meeting?.cancel_reason}{' '}
                </div>
              </div>
            )}

            {meeting?.status === 'SCHEDULED' && (
              <div className='scheduled w-100 '>
                <div>
                  {meeting.meeting_type === '121 MEETING' ? (
                    <div>
                      <div className='d-flex'>
                        <img src={points} alt='' />
                        {meeting.acceptor_points > 0 ? (
                          <div>
                            Meeting points{' '}
                            <span className='meeting-points'>
                              {meeting.acceptor_points}
                            </span>
                          </div>
                        ) : (
                          <div>No reward points</div>
                        )}
                      </div>
                      {meeting?.meeting_datetime && (
                        <div
                          className='meeting-requested-date meeting-info-tab'
                          id='schedule-date'
                        >
                          <span className='meeting-date mr-1'>
                            Meeting date{' '}
                          </span>
                          <Moment format='DD MMMM YYYY,  h:mm A'>
                            {meeting?.meeting_datetime}
                          </Moment>{' '}
                          -{' '}
                          <Moment format='h:mm A'>
                            {meeting?.meeting_datetime_end}
                          </Moment>
                        </div>
                      )}
                      {user.vendor_admin &&
                      meeting?.participants.find(
                        (item) => item?.user?.id !== user?.id
                      ) ? (
                        <></>
                      ) : (
                        <>
                          <div className='join'>
                            <div>
                              <a
                                href={
                                  meeting?.participants.find(
                                    (item) => item?.user?.id === user?.id
                                  )
                                    ? meeting?.participants
                                        ?.filter(
                                          (item) => item?.user?.id === user?.id
                                        )
                                        .map((item) =>
                                          item.individual_link
                                            ? item.individual_link
                                            : meeting?.meeting_link
                                        )
                                    : meeting?.meeting_link
                                }
                                className='btn btn-primary btn-default'
                                target='_blank'
                                rel='noreferrer'
                              >
                                Join meeting
                              </a>
                              <button
                                className='btn-secondary'
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    meeting?.participants.find(
                                      (item) => item?.user?.id === user?.id
                                    )
                                      ? meeting?.participants
                                          ?.filter(
                                            (item) =>
                                              item?.user?.id === user?.id
                                          )
                                          .map((item) =>
                                            item.individual_link
                                              ? item.individual_link
                                              : meeting?.meeting_link
                                          )
                                      : meeting?.meeting_link
                                  );
                                }}
                              >
                                <Image
                                  src={copylink}
                                  alt='copt'
                                  className='px-1'
                                />
                                Copy link
                              </button>
                            </div>
                          </div>
                          <div className='reschedule-cancel'>
                            Send request{' '}
                            <button
                              className='btn-secondary'
                              onClick={() => {
                                location.push(
                                  `/reschedule-meeting/${meeting.uid}`
                                );
                              }}
                            >
                              reschedule
                            </button>
                            or
                            <button
                              className='btn-secondary'
                              onClick={() => {
                                location.push(`/cancel-meeting/${meeting.uid}`);
                              }}
                            >
                              cancel
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ) : meeting?.participants?.find(
                      (item) => item.user.id === user.id
                    )?.attendance === 'Attending' ? (
                    <div>
                      <div className='d-flex'>
                        <img src={points} alt='' />
                        {meeting.acceptor_points > 0 ? (
                          <div>
                            Meeting points{' '}
                            <span className='meeting-points'>
                              {meeting.acceptor_points}
                            </span>
                          </div>
                        ) : (
                          <div>No reward points</div>
                        )}
                      </div>
                      {meeting?.meeting_datetime && (
                        <div
                          className='meeting-requested-date meeting-info-tab'
                          id='schedule-date'
                        >
                          <span className='meeting-date mr-1'>
                            Meeting date{' '}
                          </span>
                          <Moment format='DD MMMM YYYY,  h:mm A'>
                            {meeting?.meeting_datetime}
                          </Moment>{' '}
                          -{' '}
                          <Moment format='h:mm A'>
                            {meeting?.meeting_datetime_end}
                          </Moment>
                        </div>
                      )}
                      <div className='join'>
                        <div>
                          <a
                            href={
                              meeting?.participants.find(
                                (item) => item?.user?.id === user?.id
                              )
                                ? meeting?.participants
                                    ?.filter(
                                      (item) => item?.user?.id === user?.id
                                    )
                                    .map((item) =>
                                      item.individual_link
                                        ? item.individual_link
                                        : meeting?.meeting_link
                                    )
                                : meeting?.meeting_link
                            }
                            className='btn btn-primary btn-default'
                            target='_blank'
                            rel='noreferrer'
                          >
                            Join meeting
                          </a>
                          <button
                            className='btn-secondary'
                            onClick={() => {
                              navigator.clipboard.writeText(
                                meeting?.participants.find(
                                  (item) => item?.user?.id === user?.id
                                )
                                  ? meeting?.participants
                                      ?.filter(
                                        (item) => item?.user?.id === user?.id
                                      )
                                      .map((item) =>
                                        item.individual_link
                                          ? item.individual_link
                                          : meeting?.meeting_link
                                      )
                                  : meeting?.meeting_link
                              );
                            }}
                          >
                            <Image src={copylink} alt='copt' className='px-1' />
                            Copy link
                          </button>
                        </div>
                      </div>
                      <div className='d-flex attend'>
                        <div className='btn-secondary at-status d-flex'>
                          <div className='yes'>
                            <Image src={check} />
                          </div>
                          Attending
                        </div>

                        <button
                          onClick={() => {
                            setShowReject(true);
                          }}
                          className='btn-secondary text-danger'
                        >
                          Cancel?
                        </button>
                      </div>
                    </div>
                  ) : meeting?.participants?.find(
                      (item) => item.user.id === user.id
                    )?.attendance === 'Not Responded' ? (
                    <div>
                      <div className='d-flex'>
                        <img src={points} alt='' />
                        {meeting.acceptor_points > 0 ? (
                          <div>
                            Meeting points{' '}
                            <span className='meeting-points'>
                              {meeting.acceptor_points}
                            </span>
                          </div>
                        ) : (
                          <div>No reward points</div>
                        )}
                      </div>
                      {meeting?.meeting_datetime && (
                        <div
                          className='meeting-requested-date meeting-info-tab'
                          id='schedule-date'
                        >
                          <span className='meeting-date mr-1'>
                            Meeting date{' '}
                          </span>
                          <Moment format='DD MMMM YYYY,  h:mm A'>
                            {meeting?.meeting_datetime}
                          </Moment>{' '}
                          -{' '}
                          <Moment format='h:mm A'>
                            {meeting?.meeting_datetime_end}
                          </Moment>
                        </div>
                      )}

                      <div>
                        <button
                          onClick={() => {
                            onChangeMeetingListStatus(
                              meeting.uid,
                              null,
                              'accept'
                            );
                            setShowAccept(true);
                          }}
                          className='btn-secondary'
                        >
                          Attend?
                        </button>

                        <button
                          onClick={() => {
                            setShowReject(true);
                          }}
                          className='btn-secondary text-danger'
                        >
                          Not attending?
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className='d-flex'>
                        <img src={points} alt='' />
                        {meeting.acceptor_points > 0 ? (
                          <div>
                            Meeting points{' '}
                            <span className='meeting-points'>
                              {meeting.acceptor_points}
                            </span>
                          </div>
                        ) : (
                          <div>No reward points</div>
                        )}
                      </div>
                      {meeting?.meeting_datetime && (
                        <div
                          className='meeting-requested-date meeting-info-tab'
                          id='schedule-date'
                        >
                          <span className='meeting-date mr-1'>
                            Meeting date{' '}
                          </span>
                          <Moment format='DD MMMM YYYY,  h:mm A'>
                            {meeting?.meeting_datetime}
                          </Moment>{' '}
                          -{' '}
                          <Moment format='h:mm A'>
                            {meeting?.meeting_datetime_end}
                          </Moment>
                        </div>
                      )}
                      <>
                        {user.vendor_admin ? (
                          <></>
                        ) : (
                          <>
                            <div className='d-flex attend'>
                              <div className='text-danger d-flex'>
                                <div className='not'>
                                  <Image src={close} />
                                </div>
                                Not attending
                              </div>
                              <button
                                onClick={() => {
                                  onChangeMeetingListStatus(
                                    meeting.uid,
                                    null,
                                    'accept'
                                  );
                                  setShowAccept(true);
                                }}
                                className='btn-secondary'
                              >
                                Attending?
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </UserCardBox>
  );
});

export default MeetingCard;
