import { makeAutoObservable, runInAction } from "mobx";
import {
  getAccount,
  editAddress,
  addAddress,
  deleteProfilePic,
  getCarousel,
  requestEdit,
  acceptTerms,
  
 
} from "../../api/account";
import { getRoles } from "../../api/search";
import history from "../../history";
import {getFooterLinks} from '../../api/footer';
import {pointsRefresh} from '../../api/Points'

type User = {
  id: string,
  first_name: string,
  last_name: string,
  email: string,
  mobile: string,
  mobile_verified: boolean,
  is_active: boolean,
  first_login: boolean,
  description: string,
  photo: string,
};

class AccountStore {
  isAccountLoading = true;

  user: User = {};

  carousel = [];

  isCarouselLoading = true;

  roleList = [];

  searchRoles = [];

  roleObjects = [];

  footer = [];

  refreshPoints={}
  
  constructor() {
    makeAutoObservable(this);
  }

  setCarouselLoading = (value) => {
    this.isCarouselLoading = value;
  };

  setCarousel = (items) => {
    this.carousel = items;
  };

  onFetchCarousel = async () => {
    const res = await getCarousel();
    if (res.status === 200) {
      this.setCarousel(res.data);
      this.setCarouselLoading(false);
    }
  };

  setRoleList = (data) => {
    this.roleList = data;
  };

  setUser = (user) => {
    this.user = user;
    this.setRoleList(user.role?.map((item) => item.id));
    localStorage.setItem("user", JSON.stringify(user));
  };

  setSearchRoles = (data) => {
    this.searchRoles = data;
  };

  setFooterLinks = (data) =>{
    this.footer = data;
  }

  setRoles = (data) => {
    this.roleObjects = data;
    // let object = data.reduce((r, a) => {
    //   r[a.rolel1] = [...(r[a.rolel1] || []), a];
    //   return r;
    // }, {});

    // this.roles = Object.entries(object).map((e) => {
    //   return {
    //     rolel1: e[0],
    //     children: e[1],
    //   };
    // });
    this.setSearchRoles(data);
  };

  listFooter = async () => {
    const res = await getFooterLinks();
    this.setFooterLinks(res.data)
  }

  setRefreshPoints = (data) =>{
    this.refreshPoints = data;
  }


listRefreshPoints = async () => {
  const res = await pointsRefresh();
  this.setRefreshPoints(res.data)
}

  listRoles = async () => {
    const res = await getRoles();
    this.setRoles(res.data);
  };

  setAccountLoading = (value) => {
    this.isAccountLoading = value;
  };

  onacceptTerms = async (cb) => {
    const res = await acceptTerms();

    if (res.status === 200) {
      cb();
    }
  };

 

  getUser = async () => {
    try {
      this.setAccountLoading(true);
      const res = await getAccount();
      this.setUser(res.data);
      if (res.data.first_login) {
        history.push("/set-password");
      } else if (!res.data.consent) {
        history.push("/terms");
      }
      this.setAccountLoading(false);
    } catch (error) {
      this.setAccountLoading(false);
    }
  };

  onEditAddress = async (data, id) => {
    const city = data.city?.name;
    const state = data.state?.name;
    const country = data.country;

    if (data.city) {
      data.city = data.city.id;
    }
    if (data.state) {
      data.state = data.state.id;
    }
    if (data.country === "INDIA") {
      data.country = 0;
    } else {
      data.country = 1;
    }
    const res = await editAddress(data, id);

    if (res.status === 200) {
      runInAction(() => {
        const updatedUser = this.user;
        const index = updatedUser.address_set.findIndex(
          (item) => item.id === id
        );
        updatedUser.address_set[index].street = res.data.street;
        updatedUser.address_set[index].pincode = res.data.pincode;
        if (data.city) {
          updatedUser.address_set[index].city = city;
        }
        if (data.state) {
          updatedUser.address_set[index].state = state;
        }
        updatedUser.address_set[index].country = country;
        this.setUser(updatedUser);
      });
    }
  };

  onAddAddress = async (data) => {
    const city = data.city?.name;
    const state = data.state?.name;
    const country = data.country;

    if (data.city) {
      data.city = data.city.id;
    }
    if (data.state) {
      data.state = data.state.id;
    }
    if (data.country === "INDIA") {
      data.country = 0;
    } else {
      data.country = 1;
    }
    data.user = this.user.id;

    const res = await addAddress(data);

    if (res.status === 201) {
      runInAction(() => {
        const updatedUser = this.user;
        let newAddress = {};
        newAddress = data;
        newAddress.city = city;
        newAddress.state = state;
        newAddress.country = country;
        updatedUser.address_set.push(newAddress);
        this.setUser(updatedUser);
      });
    }
  };

  onDeleteProfilePic = async () => {
    const res = await deleteProfilePic(this.user.id);
    if (res.status === 200) {
      runInAction(() => {
        const editUser = this.user;
        editUser.photo = res.data.photo;
        this.setUser(editUser);
      });
    }
  };

  onRequestEdit = async (data) => {
    await requestEdit(data);
  };
}

export default AccountStore;
