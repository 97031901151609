import React from "react";
import { observer } from "mobx-react-lite";
import { Image, Button } from "react-bootstrap";
import { SwitchModal } from "./styles";
import { useStore } from "../../store";
import close from "../../assets/icons/close.svg";
import switchIcon from "../../assets/images/Switch.svg";

const SwitchCart = observer(({ show, setShow, product }) => {
  const { meetingStore } = useStore();
  const { setSelectedProduct } = meetingStore;
  return (
    <SwitchModal show={show}>
      <div className="d-flex justify-content-between align-items-start">
        <Image src={switchIcon} />

        <button onClick={() => setShow(false)} className="close">
          <Image src={close} />
        </button>
      </div>
      <div>
        <h2>Do you want to switch to {product?.name} format</h2>
        <div className="text-danger">
          Performing this operation will clear your cart.
        </div>
        <div className="button-group">
          <Button
            className="btn-default"
            onClick={() => {
              setSelectedProduct(product);
              setShow(false);
            }}
          >
            Yes, Switch
          </Button>
          <button className="secondary" onClick={() => setShow(false)}>
            Cancel
          </button>
        </div>
      </div>
    </SwitchModal>
  );
});

export default SwitchCart;
