import axios from "./axios";

const sendMeetingRequest = (data) => axios.post(`/meeting/`, data);

const getMeetings = (q,initiator) => axios.get(`/meeting/`, { params: {q, initiator } });

const getMeetingDetails = (uuid) => axios.get(`/meeting/${uuid}`);

const changeMeetingStatus = (uuid, data, action) =>
  axios.patch(`/meeting/${uuid}?action=${action}`, data).then(
    (res) => res,
    (err) => err.response
  );

const getCart = () => axios.get("/meeting/cart/");

const addtoCart = (data) => axios.post("meeting/cart/", data);

const getProducts = (filter) => axios.get(`/meeting/product/${filter ? "?for=filter" : ""}`);

const getPointsHistory = (start_date, end_date) =>
  axios.get("/meeting/points/", {
    params: { start_date, end_date },
  });

const getRewards = () => axios.get("/meeting/redemptions/");

const requestRedemption = (data) => axios.post("/meeting/redeem/", data);

const addPoints = (points) => axios.post("/meeting/points/add/", { points });

const sendDispute = (data) => axios.post("/meeting/dispute/", data);

export {
  sendMeetingRequest,
  getMeetings,
  getMeetingDetails,
  changeMeetingStatus,
  getCart,
  addtoCart,
  getProducts,
  getPointsHistory,
  addPoints,
  getRewards,
  requestRedemption,
  sendDispute,
};
