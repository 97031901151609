import React, { useState } from "react";
import { Form, Card, Image } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import down from "../../../assets/icons/dropdown-arrow.svg";
import up from "../../../assets/icons/dropup-arrow.svg";

const ProfileCard = observer(({ item, desktop }) => {
  const [open, setOpen] = useState(false);

  const { searchStore } = useStore();
  const {
    profileL1List,
    profileL2List,
    onProfileListChanged,
    listSearchResults,
  } = searchStore;

  const onChange = (e, item, parent) => {
    if (!item.subprofiles?.length) {
      onProfileListChanged(item.name, e.target.checked, parent);
    } else {
      item.subprofiles.forEach((element) => {
        onProfileListChanged(element.name, e.target.checked);
      });
    }
    if (desktop) {
      listSearchResults();
    }
  };

  return (
    <Card>
      <Card.Header
        className="d-flex justify-content-between"
        onClick={() => {
          if (item.subprofiles?.length) {
            setOpen(!open);
          }
        }}
      >
        <Form.Check
          label={item.name}
          value={item.name}
          checked={
            item.subprofiles.length
              ? item.subprofiles.every((child) =>
                  profileL2List.includes(child.name)
                )
              : profileL1List.includes(item.name)
          }
          onChange={(e) => onChange(e, item, true)}
          onClick={(e) => e.stopPropagation()}
        />
        {item.subprofiles?.length ? (
          <button className="drop">
            <Image src={open ? up : down} />
          </button>
        ) : (
          <></>
        )}
      </Card.Header>
      {open && (
        <Card.Body>
          {item.subprofiles?.map((child) => {
            return (
              <Form.Check
                label={child.name}
                value={child.name}
                key={child.name}
                checked={profileL2List.includes(child.name)}
                onChange={(e) => onChange(e, child)}
              />
            );
          })}
        </Card.Body>
      )}
    </Card>
  );
});

export default ProfileCard;
