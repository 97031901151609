import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import LocalStorageService from "./utils/LocalStorageService";

const isAuthenticated = () => {
  const user = LocalStorageService.getUser();
  return user?.id;
};

const isNewLogin = () => {
  const user = LocalStorageService.getUser();
  return user?.first_login;
};

const isDM = () => {
  const user = LocalStorageService.getUser();
  return user?.user_type === 0;
};

const isVendor = () => {
  const user = LocalStorageService.getUser();
  return user?.user_type === 1;
};

export const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  return isAuthenticated() ? (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  ) : (
    <Redirect to={{ pathname: `/login` , search: `?next=${rest.path}` }} />
  );
};

export const DMRoute = ({ component: Component, ...rest }: Props) => {
  return isDM() ? (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  ) : (
    <Redirect to={{ pathname: "/" }} />
  );
};

export const VendorRoute = ({ component: Component, ...rest }: Props) => {
  return isVendor() ? (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  ) : (
    <Redirect to={{ pathname: "/" }} />
  );
};

export const LoginRoute = ({ component: Component, ...rest }: Props) => {
  return isAuthenticated() ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  );
};

export const FirstLoginRoute = ({ component: Component, ...rest }: Props) => {
  return isNewLogin() ? (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  ) : (
    <Redirect to={{ pathname: "/" }} />
  );
};
