import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { Image, Dropdown } from 'react-bootstrap';
import history from '../../history';
import BottomBar from '../../components/BottomBar';
import SwitchCart from '../../components/SwitchCart';
import Navigation from '../../components/TopBar';
import Footer from '../../components/Footer';
import MobileTopBar from '../../components/MobileTopBar';
import PeerWarningModal from '../../components/PeerWarningModal';
import LimitModal from '../../components/LimitModal';
import { useStore } from '../../store';
import { CompanyDiv } from './elements/styles';
import UserHolder from '../../assets/images/profile-holder.svg';
import waves from '../../assets/images/waveC.svg';
import back from '../../assets/icons/backpage.svg';
import connect from '../../assets/icons/addnew.svg';
import added from '../../assets/icons/added.svg';
import down from '../../assets/icons/dropdown-arrow.svg';
import wave from '../../assets/images/wave1.svg';
import ChatAppModal from '../../components/ChatModal';

const CompanyDetails = observer(() => {
  const { id } = useParams();
  const location = useHistory();
  const [chat, setChat] = useState();
  const [showSwitch, setShowSwitch] = useState(false);
  const [switchProduct, setSwitchProduct] = useState();
  const [show, setShow] = useState();
  const [showLimit, setShowLimit] = useState();

  const colors = ['#EBB298', '#9AC5D4', '#8BBFB5'];

  const { companyStore, meetingStore, accountStore } = useStore();
  const { company, onFetchCompanyDetails } = companyStore;
  const { user } = accountStore;

  const { cart, onAddToCart, products, selectedProduct, listProducts } =
    meetingStore;

  useEffect(() => {
    listProducts();
  }, [listProducts]);

  useEffect(() => {
    onFetchCompanyDetails(id);
  }, [onFetchCompanyDetails, id]);

  return (
    <>
      <Helmet title={`${company?.name} | 121connects`}>
        <meta
          id='meta-description'
          name='description'
          content={`${company?.about?.split('.')[0]}`}
        />
      </Helmet>
      <MobileTopBar />
      <Navigation />

      <SwitchCart
        setShow={setShowSwitch}
        show={showSwitch}
        product={switchProduct}
      />
      <PeerWarningModal show={show} setShow={setShow} />
      <LimitModal show={showLimit} setShow={setShowLimit} />
      <ChatAppModal show={chat} setShow={setChat} />
      <CompanyDiv>
        <button
          className='back-button d-lg-none'
          onClick={() => history.go(-1)}
        >
          <Image src={back} />
          Back
        </button>
        <div className='glass'>
          <div className='d-none d-lg-block'>
            <button className='back-button ' onClick={() => history.go(-1)}>
              <Image src={back} />
              Back
            </button>
          </div>
          <Image src={waves} alt='logo' className='d-lg-none waves' />
          <div className='glass-design d-none  d-lg-block'>
            <Image
              src={wave}
              alt='bg-design'
              className='wave-design d-none d-lg-block'
            />
          </div>

          <div className='profile-data d-lg-flex'>
            <div className='col-lg-8'>
              <div className='company-info'>
                {company.logo ? (
                  <Image
                    src={company.logo}
                    alt={company?.name}
                    className='company-logo'
                  />
                ) : (
                  <div
                    className='company-image'
                    style={{
                      backgroundColor: colors[Math.floor(Math.random() * 3)],
                    }}
                  >
                    <p>
                      {company.name?.split(/\W/).length > 1
                        ? (
                          company.name?.split(/\W/)[0].substring(0, 1) +
                          company.name?.split(/\W/)[1].substring(0, 1)
                        ).toUpperCase()
                        : company.name?.substring(0, 1).toUpperCase()}
                    </p>
                  </div>
                )}

                <h1 className='comp-header'>{company.name}</h1>
                <div className='d-flex'>
                  <div className='d-flex'>
                    {company.turnover && (
                      <>
                        <div className='d-flex justify-content-between turn-class'>
                          <h4 className='turn-name'>{process.env.REACT_APP_COUNTRY === 'India' ? 'Turnover (Cr.)' : 'Turnover (Million.)'}</h4>
                        </div>
                        <div className={`d-flex justify-content-between ${!company.headcount_range_display ? "turnover-range" : "turnover"}`}>
                          <h5 className='turn-num'>{company.turnover}</h5>
                        </div>
                      </>
                    )}
                    {
                      company.turnover && company.headcount_range_display && (
                        <div className='left-section'></div>
                      )}
                    {company.headcount_range_display && (
                      <>
                        <div className='d-flex justify-content-between'>
                          <h4 className='turn-name'>Headcount Range </h4>
                        </div>
                        <div className='d-flex justify-content-between turnover-range'>
                          <h5 className='turn-num'>
                            {company.headcount_range_display}
                          </h5>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className='d-flex tags'>
                  <div className='d-none d-lg-flex'>
                    {[
                      ...new Set(
                        company?.industry?.map((item) => item.industryl1)
                      ),
                    ].map((industry) => (
                      <div className='interest '>{industry}</div>
                    ))}
                  </div>

                  <div className='classifications d-flex'>
                    {company?.tags?.map((item) => (
                      <p className='class' key={item.name}>
                        {item.display_name}
                      </p>
                    ))}
                  </div>
                </div>
                <div className='d-flex  d-lg-none'>
                  {[
                    ...new Set(
                      company?.industry?.map((item) => item.industryl1)
                    ),
                  ].map((industry) => (
                    <div className='interest '>{industry}</div>
                  ))}
                </div>
              </div>

              <div className='members d-lg-none'>
                <div>
                  <h2>About {company.name}</h2>

                  <div className='bio-details'>
                    <p>{company?.about} </p>
                    {company?.about_website && (
                      <a
                        href={company?.about_website}
                        target='_blank'
                        rel='noreferrer'
                      >
                        More about {company.name}
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className='members'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h2>Members</h2>

                  <div className='meeting-type d-flex align-items-center'>
                    <>
                      Choose meeting type{' '}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='basic'
                          id='more'
                          className='a_selectSidebar d-flex align-items-center'
                        >
                          {selectedProduct?.name}
                          {products.length > 1 ? <Image src={down} /> : ''}
                        </Dropdown.Toggle>
                        {products.length > 1 ? (
                          <Dropdown.Menu align='right'>
                            {products.map((item) => (
                              <Dropdown.Item
                                as='button'
                                key={item.id}
                                onClick={() => {
                                  setSwitchProduct(item);
                                  setShowSwitch(true);
                                }}
                                active={item.id === selectedProduct.id}
                              >
                                {item.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        ) : (
                          ''
                        )}
                      </Dropdown>
                    </>
                  </div>
                </div>

                <div className='member-list'>
                  <div className='w-100'>
                    {company.members
                      ?.filter((member) => member.id !== user.id)

                      .map((member) => (
                        <div
                          className='member-card d-flex justify-content-between'
                          key={member.id}
                        >
                          <div
                            className='d-flex member-info-details'
                            onClick={() =>{
                              if (member?.linked_in_url) {
                                if (member.linked_in_url.startsWith('http'))
                                {
                                  window.open(`${member?.linked_in_url}`, '_blank')
                                }
                                else {
                                  window.open(`//${member?.linked_in_url}`, '_blank')
                                }
                            }
                          }
                        }
                          >
                            {member.photo ? (
                              <Image
                                src={member.photo ? member.photo : UserHolder}
                                className='member-image'
                                roundedCircle
                              />
                            ) : (
                              <div
                                className='member-image username d-flex justify-content-center align-items-center'
                                style={{
                                  background: `#${Math.floor(
                                    Math.random() * 16777215
                                  ).toString(16)}50`,
                                }}
                              >
                                <div>
                                  {member?.last_name
                                    ? `${member?.first_name.split('')[0]}${member?.last_name.split('')[0]
                                    }`
                                    : `${member?.first_name.split('')[0]} ${member?.first_name.split('')[1]
                                    }`}
                                </div>
                              </div>
                            )}
                            <div className='member-info'>
                              <h4>
                                {member.first_name} {member.last_name}
                              </h4>
                              <p className='designation'>{member.title}</p>
                            </div>
                          </div>
                          <div className='chatmeet-btn'>
                            {cart?.members_details?.find(
                              (item) => item.id === member.id
                            ) ? (
                              <button className='connect btn-default added'>
                                <Image src={added} /> Added
                              </button>
                            ) : (
                              <button
                                className={`connect btn-default ${(selectedProduct?.engagement ===
                                  '121 MEETING' ||
                                  selectedProduct?.engagement ===
                                  'PEER MEETING') &&
                                  cart?.members?.length >= 1
                                  ? 'disabled'
                                  : ''
                                  }`}
                                onClick={() => {
                                  if (
                                    user.user_type === 0 &&
                                    !user?.engagements?.find(
                                      (eng) =>
                                        eng.name === selectedProduct?.engagement
                                    )
                                  ) {
                                    setShow(true);
                                  } else if (
                                    (selectedProduct?.engagement ===
                                      '121 MEETING' ||
                                      selectedProduct?.engagement ===
                                      'PEER MEETING') &&
                                    cart?.members?.length >= 1
                                  ) {
                                    setShowLimit(true);
                                  } else {
                                    onAddToCart(member, true, false);
                                    if (
                                      selectedProduct?.engagement ===
                                      '121 MEETING' ||
                                      selectedProduct?.engagement ===
                                      'PEER MEETING'
                                    ) {
                                      location.push('/cart');
                                    }
                                  }
                                }}
                                disabled={
                                  // user.user_type === 0 &&
                                  !member.engagements?.find(
                                    (eng) =>
                                      eng.name === selectedProduct?.engagement
                                  ) &&
                                  !(
                                    (selectedProduct?.engagement ===
                                      '121 MEETING' ||
                                      selectedProduct?.engagement ===
                                      'PEER MEETING') &&
                                    cart?.members?.length >= 1
                                  )
                                }
                              >
                                <Image src={connect} /> Meet
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-4 address-div d-none d-lg-block'>
              <div className='members' id='member1'>
                <div>
                  <h2>About {company.name}</h2>

                  <div className='bio-details'>
                    <p>{company?.about}</p>
                    {company?.about_website && (
                      <a
                        href={company?.about_website}
                        target='_blank'
                        rel='noreferrer'
                      >
                        More about {company.name}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CompanyDiv>
      <Footer />
      <BottomBar />
    </>
  );
});

export default CompanyDetails;
