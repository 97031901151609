import { useState } from 'react';
import { DateRange } from 'react-date-range';
import { Image, Dropdown } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';
import { useStore } from '../../../store';
import { StyledModal } from '../elements/styles';
import left from '../../../assets/icons/left.svg';

const FilterModal = ({ setDate }) => {
  const [showDate, setshowDate] = useState();
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const { pointsStore } = useStore();
  const { listPointsHistory } = pointsStore;

  const onDateSelect = (start, end) => {
    setDate(
      `${moment(start).format('MMM DD, YYYY')} - ${moment(end).format(
        'MMM DD, YYYY'
      )}`
    );
    listPointsHistory(start, end);
  };

  return (
    <StyledModal>
      {showDate ? (
        <>
          <div className='header date-range'>
            <div className='d-flex justify-content-between'>
              <Image
                src={left}
                onClick={() => setshowDate(false)}
                className='back'
              />
              <Dropdown.Item
                className='filter'
                onClick={() =>
                  onDateSelect(
                    moment(state[0].startDate).format('YYYY-MM-DD'),
                    moment(state[0].endDate).format('YYYY-MM-DD')
                  )
                }
              >
                Filter
              </Dropdown.Item>
            </div>

            <div className='range-head'>Select date range</div>
          </div>

          <DateRange
            onChange={(item) => {
              setState([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction='horizontal'
          />
        </>
      ) : (
        <>
          <div className='header'>Choose date range</div>
          <div className='content'>
            <div className='d-flex justify-content-between date-range-select'>
              This week
              <Dropdown.Item
                onClick={() =>
                  onDateSelect(
                    moment().subtract(7, 'd').format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD')
                  )
                }
              >
                <Moment format='DD MMMM, YYYY'>
                  {moment().subtract(7, 'd')}
                </Moment>{' '}
                - <Moment format='DD MMMM, YYYY'>{new Date()}</Moment>
              </Dropdown.Item>
            </div>
            <div className='d-flex justify-content-between date-range-select'>
              Last week
              <Dropdown.Item
                onClick={() =>
                  onDateSelect(
                    moment().subtract(14, 'd').format('DD MMMM, YYYY'),
                    moment().subtract(7, 'd').format('DD MMMM, YYYY')
                  )
                }
              >
                <Moment format='DD MMMM, YYYY'>
                  {moment().subtract(14, 'd')}
                </Moment>{' '}
                -{' '}
                <Moment format='DD MMMM, YYYY'>
                  {moment().subtract(7, 'd')}
                </Moment>
              </Dropdown.Item>
            </div>
            <div className='d-flex justify-content-between date-range-select'>
              This month
              <Dropdown.Item
                onClick={() =>
                  onDateSelect(
                    moment().subtract(1, 'months').format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD')
                  )
                }
              >
                <Moment format='DD MMMM, YYYY'>
                  {moment().subtract(1, 'months')}
                </Moment>{' '}
                - <Moment format='DD MMMM, YYYY'>{new Date()}</Moment>
              </Dropdown.Item>
            </div>
            <div className='d-flex justify-content-between date-range-select'>
              Last month
              <Dropdown.Item
                onClick={() =>
                  onDateSelect(
                    moment().subtract(2, 'months').format('YYYY-MM-DD'),
                    moment().subtract(1, 'months').format('YYYY-MM-DD')
                  )
                }
              >
                <Moment format='DD MMMM, YYYY'>
                  {moment().subtract(2, 'months')}
                </Moment>{' '}
                -{' '}
                <Moment format='DD MMMM, YYYY'>
                  {moment().subtract(1, 'months')}
                </Moment>
              </Dropdown.Item>
            </div>

            <button onClick={() => setshowDate(true)} className='link'>
              Custom Range
            </button>
          </div>
        </>
      )}
    </StyledModal>
  );
};

export default FilterModal;
