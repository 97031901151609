import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Image } from "react-bootstrap";
import classNames from "classnames";
import { SwitchDiv, FilterModal } from "../elements/styles";
import IndustryFilter from "./IndustryFilter";
import { useStore } from "../../../store";
import RoleFilter from "./RoleFilter";
import CityFilter from "./CityFilter";
import InterestFilter from "./InterestFilter";
import ProfileFilter from "./ProfileFilter";
// import ClassificationFilter from "./ClassificationFilter";
// import tranges from "../../../assets/data/range.json";
// import hranges from "../../../assets/data/hrange.json";
import regionList from "../../../assets/data/regions.json";
import close from "../../../assets/icons/close.svg";
import filter from "../../../assets/icons/Filter.svg";

const SearchFilter = observer(({ setShow }) => {
  const [tab, setTab] = useState("interest");

  const manuList = [
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Services", value: "Services" },
    { label: "Both", value: "Both" },
  ];

  // const internetList = [
  //   { label: "BRICK-MORTAR", value: "BRICK-MORTAR" },
  //   { label: "INTERNET", value: "INTERNET" },
  //   { label: "BOTH", value: "BOTH" },
  // ];

  const { searchStore } = useStore();
  const {
    rangeChecked,
    selectedRegionList,
    onRegionListChange,
    onTrangeListChanged,
    listSearchResults,
    selectedHRangeList,
    onHrangeListChanged,
    selectedManuList,
    onManuListChanged,
    searchResults,
    hranges,
    tranges,
    clearAllFilters,
  } = searchStore;

  const handleRangeChange = (e) => {
    onTrangeListChanged(e.target);
  };

  const handleRegionChange = (e) => {
    onRegionListChange(e.target);
  };

  return (
    <FilterModal>
      <div className="d-flex  justify-content-between header">
        <div className="filter-icon">
          <Image src={filter} />
          Filter
        </div>
        <Button
          onClick={() => {
            setShow(false);
            clearAllFilters();
          }}
          className="close"
        >
          <Image src={close} />
        </Button>
      </div>
      <div className="d-flex filter-div">
          <SwitchDiv>
          <Button
                onClick={() => setTab("interest")}
                variant="light"
                className={classNames({
                  active: tab === "interest",
                })}
              >
                Interest
      </Button>
           <Button
            onClick={() => setTab("role")}
            variant="light"
            className={classNames({
              active: tab === "role",
            })}
          >
            Responsibility
          </Button>
          <Button
            onClick={() => setTab("industry")}
            variant="light"
            className={classNames({
              active: tab === "industry",
            })}
          >
            Industry
          </Button>
          <Button
            onClick={() => setTab("range")}
            variant="light"
            className={classNames({
              active: tab === "range",
            })}
          >
            Turnover range
      </Button>
          <Button
                onClick={() => setTab("hrange")}
                variant="light"
                className={classNames({
                  active: tab === "hrange",
                })}
              >
                Headcount range
              </Button>
              <Button
                onClick={() => setTab("city")}
                variant="light"
                className={classNames({
                  active: tab === "city",
                })}
              >
                City-Regions
              </Button>

          
        </SwitchDiv>
        {tab === "industry" && (
          <div className="tab-content">
            <IndustryFilter />
          </div>
        )}
        {tab === "range" && (
          <div className="tab-content">
            {tranges?.map((item) => (
              <Form.Check
                label={item.display_name}
                value={item.name}
                key={item.label}
                checked={rangeChecked.includes(item.name)}
                onChange={handleRangeChange}
              />
            ))}
          </div>
        )}
        {tab === "role" && (
          <div className="tab-content">
            <RoleFilter />
          </div>
        )}
        {tab === "region" && (
          <div className="tab-content">
            {regionList?.map((item) => (
              <Form.Check
                label={item.label}
                value={item.value}
                key={item.label}
                checked={selectedRegionList.includes(item.value)}
                onChange={handleRegionChange}
              />
            ))}
          </div>
        )}
        {tab === "city" && (
          <div className="tab-content">
            <CityFilter />
          </div>
        )}
        {tab === "interest" && (
          <div className="tab-content">
            <InterestFilter />
          </div>
        )}
        {tab === "profile" && (
          <div className="tab-content">
            <ProfileFilter />
          </div>
        )}
        {tab === "hrange" && (
          <div className="tab-content">
            {hranges?.map((item) => (
              <Form.Check
                label={item.name}
                value={item.name}
                key={item.id}
                checked={selectedHRangeList.includes(item.name)}
                onChange={(e) => onHrangeListChanged(e.target)}
              />
            ))}
          </div>
        )}
        {tab === "manufacturing" && (
          <div className="tab-content">
            {manuList?.map((item) => (
              <Form.Check
                label={item.label}
                value={item.value}
                key={item.value}
                type={"radio"}
                checked={selectedManuList.includes(item.value)}
                onChange={(e) => onManuListChanged(e.target)}
              />
            ))}
          </div>
        )}
        {/* {tab === "internet" && (
          <div className="tab-content">
            {internetList?.map((item) => (
              <Form.Check
                label={item.label}
                value={item.value}
                key={item.value}
                type={"radio"}
                checked={selectedInterList.includes(item.value)}
                onChange={(e) => onInterListChanged(e.target, item.label)}
              />
            ))}
          </div>
        )} */}
        {/* {tab === "classification" && (
          <div className="tab-content">
            <ClassificationFilter />
          </div>
        )} */}
      </div>
      <div className="d-flex justify-content-between bottom-bar">
        <div className="result-count">{searchResults.length} results found</div>
        <Button
          variant="light"
          className="btn-default a_applyButton"
          onClick={() => {
            listSearchResults();
            setShow(false);
          }}
        >
          Apply
        </Button>
      </div>
    </FilterModal>
  );
});

export default SearchFilter;
