import styled from "styled-components";
export const SuccessDiv = styled.div`
margin: 0 auto 0 auto;
padding: 76px 18px 45px 17px;
max-width: 600px;
position: relative;

.tr {
  position: absolute;
  top: -3px;
  right: 0;
  z-index: 1;
}

.change-logo {
  position: absolute;
  top: 12px;
  right: 20px;
  z-index: 1;
}

.welcome {
  color: #797979;
  font-size: 14px;
}

.danger {
  border: 1px solid #ff647f !important;
}

.glass {
 @media only screen and (min-width:900px){
  padding: 0;
  margin: 0;
  border-radius: 5px;
  margin-top: -93px;}

 
  @media only screen and (max-width:600px){
    margin: auto;
    position: relative;
    top: 72px;
  }
}

.help-text {
  color: #8b949e;
  font-size: 12px;
  max-width: 350px;
  margin-top: 30px;
  margin-bottom: 12px;
}

.logo {
  margin: 40px 0px 30px 40px;
}
.newlogo1{
  margin-left: 90px;

  @media only screen and (max-width:600px){
    margin-left: 33px;
  }
}

.form-hold {
  background: white;
  padding: 9em 2em 3em 2em;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @media only screen and (min-width: 920px) {
    padding-left: 5.5em;
  }
  margin-top: -105px;
  @media only screen and (max-width:600px){
    margin-bottom: 70px;
   
  }
}

.form-hold h2 {
  color: #32e0e0;
  background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
      font-sixe: 24px;
  
}

.form-hold form {
  width: 100%;
}

.a_loginResponseText {
  color: #ff647f;
  font-weight: 600;
}

.form-label {
  color: #797979;
  margin: 1em 0 0 0;
  font-size:14px;
  @media only screen and (max-width:600px){
      font-size:12px;
  }
}

.password-control {
  margin: 0 0 7px 0;
}

.form-control {
  margin: 4px 0;
  background: #ebf4f9;
  padding: 0.5em 1em;
  border: 1px solid #cdeaf0;
  border-radius: 5px;
  height: 45px;
  font-size: 1em;
  color: #403c3c;
  @media only screen and (min-width: 500px) {
    width: 350px;
  }
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #48bcd177;
}

.loginForm {
  .toggleButton {
    border: none;
    background: transparent;
    color: #7a7a7a;
    border-right: none;
    font-size: 20px !important;
    margin: 5px 0px 10px -35px;
  }
  #togglePassword {
    width: 18px;
    height: 18px;
  }
}

.form-check-input {
  width: 20px;
  height: 20px;
  background-color: #cdeaf0;
  border: 0.5px solid #cdeaf0;
}
.form-check-input:focus {
  box-shadow: none;
  border: 1px solid #48bcd177 !important;
}

.form-check-label {
  color: #797979;
  margin: 0 auto 0 9px;
}

.a_activateButton {
  margin-top: 27px;
}

.links {
  background: transparent;
  border: 0;
  padding: 0;
  color: #fff;
  margin-right: 40px;
  margin-top: 10px;

  @media only screen and (max-width: 920px) {
    margin-right: 20px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1025px) {
  min-width: 600px;
}

.status-verification-text {
  margin-bottom: 30px;
  margin-top: 8px;

  font-size: 12px;

  .failed {
    color: #ff647f;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .success {
    color: #27d3db;
  }

  p {
      
    margin-left: 9px;
    margin-bottom: 5px;
  }
}
.terms{
  border:none;

  border: none;
  background: transparent;
  color: #fff;

  margin-top: 12px;
  font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;

@media only screen and (max-width:600px){
    font-size: 11px;
  }
}
.privacy{
border:none;
  position: absolute;
  border: none;
  background: transparent;
  color: #fff;
  margin-left: 149px;
  margin-top: 12px;
  font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
    font-size: 11px;
    margin-left:130px;
  }
}
.cookie{
border:none;
  position: absolute;
  border: none;
  background: transparent;
  color: #fff;
  margin-left: 257px;
  margin-top: 12px;
  font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
    font-size: 11px;
    margin-left: 231px
  }
}
.face-logo{
  position: absolute;
  left: 79.72%;
  right: 33.93%;
  top: 90.4%;
  bottom: 20.88%;
  @media only screen and (max-width:600px){
    position: absolute;
    left: 7.72%;
    right: 33.93%;
    top: 92.3%;
    bottom: 20.88%;
  }
}
.linked-logo{
  position: absolute;
left: 85.6%;
right: 32.05%;
top: 90.4%;
bottom: 20.88%;
@media only screen and (max-width:600px){
position: absolute;
left: 15.6%;
right: 32.05%;
top: 92.3%;
bottom: 20.88%;
}

}
.twitter-logo{
  position: absolute;
left: 91.1%;
right: 30.18%;
top: 90.4%;
bottom: 20.88%;
@media only screen and (max-width:600px){
position: absolute;
  left: 23.1%;
  right: 30.18%;
  top: 92.3%;
  bottom: 20.88%;
}

}
.icon-div{
 
  @media only screen and (max-width:600px){
    margin-top: 22px;
   
}}
.enddesign{
    position: fixed;
    left: 0;
    bottom: 0;}
.wave-design{
  @media only screen and (max-width:600px){

    position: absolute;
    top: -1px;
    right: 1px;
  }
  

  @media only screen and (min-width:900px){
    margin-left: -181px;
    
    margin-top: -78px;
  }
  
}




`