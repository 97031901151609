import styled from 'styled-components';
import { Form } from 'react-bootstrap';

export const MeetingsDiv = styled.div`
  min-height: 300px;
  padding: 11px;
  border-radius: 0.5rem;
  position: relative;
  padding-bottom: 80px;

  h2 {
    font-size: 24px;
    color: #fff;
    margin-top: 23px;
  }

  .header-div {
    position: relative;
    padding-left: -1px;
    @media only screen and (max-width: 992px) {
      padding: 0 17px;
      .sm-wave {
        position: absolute;
        right: 0px;
        top: -50px;
        z-index: -1;
      }

      .search-button {
        padding-top: 15px;
        height: fit-content;
        img {
          width: 14px;
        }
      }
    }
  }

  .glass {
    margin: 0;
    max-width: 1280px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 992px) {
      h2 {
        margin-top: 38px;
        font-size: 36px;
      }
    }
    @media only screen and (max-width: 992px) {
      background: rgba(235, 255, 254, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.7);
      margin-top: 92px;
      padding-top: 30px;
    }
  }
  .glass-design {
    z-index: -1;
    margin-top: 0px;
    position: relative;
    margin-bottom: -218px;
    max-width: 1280px;
    height: 218px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    border-radius: 10px;
  }

  .holder {
    max-width: 914px;
    margin-left: auto;
    margin-right: auto;
  }

  .filter {
    padding: 0px 18px 11px 19px;
    margin-bottom: 27px;
    text-transform: capitalize;
    .statuses {
      flex-wrap: wrap;
      @media only screen and (max-width: 992px) {
        border-bottom: 0.5px solid #e1e1e1;
      }
    }
    @media only screen and (min-width: 992px) {
      margin-left: 55px;
    }
    .form-check {
      padding-left: 12px;
    }

    .form-check-input {
      background-color: transparent;
      border: 0.5px solid #cdeaf0 !important;
      margin-left: 0px;
      margin-right: 5px;
      @media only screen and (max-width: 992px) {
        width: 13px;
        height: 13px;
      }
    }

    .form-check-label {
      color: #fff;
      @media only screen and (max-width: 992px) {
        font-size: 10px;
      }
    }
  }

  .contents {
    @media only screen and (max-width: 992px) {
      border-radius: 5px;
    }
  }
  .wave-design {
    position: absolute;
    top: 73px;
    right: 0;
  }

  .nav-contents {
    border-radius: 5px 5px 0px 0px;
    margin-top: -65px;

    .nav-header {
      position: relative;

      margin: 0 18px;
      padding: 20px 0 0 0;
      .active {
        color: #00aeae;
      }
    }
    .nav-link {
      color: #66666680;
      font-weight: 700;
    }
    @media only screen and (max-width: 992px) {
      background: transparent;
      margin-top: -40px;
      .nav-link {
        font-size: 10px;
        color: #fff;
        font-weight: 500;
      }

      .nav-header {
        .active {
          color: #00ffc2;
          border-bottom: solid 2px #00ffc2;
        }
      }
    }
  }
  .filter-img {
    margin-right: 5px;
  }
  .filter-div {
    @media only screen and (max-width: 600px) {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: #ffffff;
      
    }
    @media only screen and (min-width: 992px){
      margin-bottom: 58px;
    }
   
    color: #fff;
    margin-bottom: 20px;
    button {
      box-shadow: none;
    }

    .a_selectSidebar {
      padding: 0;
      color: #00ffc2;

      :after {
        display: none;
      }

      @media only screen and (max-width: 600px) {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;

        color: #00ffc2;
      }
    }

    .dropdown {
      margin-left: 18px;
    }

    .dropdown-menu {
      padding: 0;
      border-radius: 5px;
      .dropdown-item {
        border: 0;
        background: #fff;
        border-radius: 5px;
        padding: 11px 15px;
        color: #333333;
        text-align: left;
        font-size: 12px;
      }
      .active {
        background: #bbffef;
      }
    }

    @media only screen and (max-width: 992px) {
      margin-top: 50px;
      font-size: 12px;
      
      padding: 0 19px;
      .a_selectSidebar {
        font-size: 12px;
      }
    }
  }
`;

export const UserCardBox = styled.div`
  background: #ffffff;
  height: fit-content;
  margin-bottom: 20px;
  width: 914px;
  border-radius: 5px;
  padding: 0 17px;

  @media only screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
  }

  .text-danger {
    color: #f6817d;
    font-size: 12px;
    font-weight: 700;
    margin-right: 10px;
  }

  .yes {
    border-radius: 50%;
    background: #00aeae;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
  }
  .not {
    border-radius: 50%;
    background: #f6817d;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
  }

  .at-status {
    margin-right: 10px;
  }

  .attend {
    margin-top: 5px;
  }

  .meeting-type {
    background: #9143c1;
    color: #fff;
    padding: 2px 4px;
    font-size: 10px;
    font-weight: 700;
  }

  .meeting-status {
    color: #888888;
    font-size: 10px;
    background: #e2e0ff;
    padding: 2px 10px;
    margin-left: 7px;
  }

  .info-card{
    margin-bottom: 6px;
  }

  .meeting-info-tab {
    margin: 5px 0;
    margin-top: 16px;
    span {
      font-weight: 700;
      color: #888888;
    }
  }

  

  .meeting-info {
    color: #333333;
    font-size: 12px;

    @media only screen and (min-width: 992px) {
      font-size: 14px;
    }    
  }

  #schedule-date{
white-space: break-spaces;
  }

  .meeting-info-text {
    font-weight: 500;
    font-size: 11px;
    color: #666666;
  }

  .box-content {
    margin-right: 25px;
  }

  .user-card {
    align-items: flex-start;
    padding: 10px 0;

    .network-partner {
      font-size: 8px;
      color: #5ba0df;
      background: #ebf4f9;
      padding: 2px 4px;
    }

    @media only screen and (min-width: 992px) {
      padding: 15px 0;
    }
  }

  .profile-image {
    width: 33px;
    height: 33px;
    border: 2px solid #eaedf3;
    @media only screen and (min-width: 992px) {
      width: 47px;
      height: 47px;
    }
  }

  .employee-text {
    font-size: 13px;
    padding-left: 10px;
    cursor:pointer;
    @media only screen and (min-width: 992px) {
      font-size: 14px;
    }
  }

  .more-text{
    white-space: break-spaces;
  }

  .less {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
    white-space: break-spaces;
  }
  
  .more{
    display:none;
    margin-top: 7px;
    font-weight: 700;
    color: #888888;
    font-size: 12px;
    
  }

  .readmore{
    width: fit-content;
  }


  .readmore:hover{
  .more{
    display: block;
  }}

  .bio-description {
    cursor: pointer;
    font-size: 10px;
    width: 30px;
    height: 30px;
    background: rgba(0, 174, 174, 0.1);
    padding: 10px 14px 12px 14px;
    border-radius: 50%;
    margin-top: 17px;
  }

  .more-less{
    width: 13px;
    cursor: pointer;
  }
  .p-moreless{
    color: #00aeae;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
  }

  .member-name {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 1px;
    color: #333333;
    @media only screen and (min-width: 992px) {
      font-size: 18px;
    }
  }

  .description {
    color: #666666;
  }

  .attending {
    color: #00aeae;
    font-size: 12px;
    font-weight: 700;
    @media only screen and (min-width: 992px) {
      font-size: 14px;
    }
  }

  .notattending {
    color: #f46b65;
    font-size: 12px;
    font-weight: 700;
    @media only screen and (min-width: 992px) {
      font-size: 14px;
    }
  }

  .notattendingreason {
    color: #f68a85;
    font-size: 12px;
    @media only screen and (max-width: 992px) {
      font-size: 10px;
    }
  }
  .button-group {
    margin-top: 10px;
  }

  .cancelButton {
    margin-top: 16px;
  }

  button,
  .btn-default,
  btn-secondary {
    font-size: 12px;
    font-weight: 600;
  }
  .scheduled {
    background: #fbf5e6;
    padding: 17px 10px 17px 10px;
  }

  .filter-img {
    margin-right: 4px;
  }

  .actions {
    padding: 17px 10px 17px 10px;
    @media only screen and (min-width: 992px) {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding-right: 10px;
      min-width: 338px;
    }
    .points {
      background: #fbf5e6;
      align-items: center;
      margin: auto;
      display: flex;
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      padding: 6px 0 4px 11px;
      color: #666666;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      @media only screen and (max-width: 992px) {
        padding: 5px 0 8px 13px;
      }
    }
    .scheduled {
      .points {
        padding: 0;
        border-radius: 5px;
      }
    }
    .meeting-points {
      font-weight: 700;
    }
    .p-point {
      font-family: Lato;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      /* identical to box height, or 17px */

      color: #666666;
    }

    .reward {
    }

    .meeting-date {
      font-size: 11px;
      font-weight: 500;
      color: #666666;
      margin-bottom: 0;
    }
    time {
      font-size: 11px;
      font-weight: 700;
    }
    .schedule-time {
      margin-left: 9px;
    }
    .join {
      margin-top: 6px;
      @media only screen and (min-width: 992px) {
        min-width: 300px;
      }
    }
  }

  .attending-div {
    margin-left: auto;
    width: 100%;
    display: block;
    button {
      @media only screena and (max-width: 600px) {
        margin-left: auto;
      }

      display: block;
    }
  }

  .danger {
    color: #ff647f;
    font-size: 14px;
    max-width: 338px;
    padding: 11px 0 12px 11px;

    .reason {
      white-space: break-spaces;
      margin-left: 10px;
      margin-top: 1.5px;
      overflow-wrap: anywhere;
    }

    .reason-title {
      font-weight: 700;
      width: 130px;
    }
  }

  .rejected {
    padding: 11px 18px;

    max-width: 222px;
    @media only screen and (max-width: 992px) {
      img {
        width: 11px;
      }
      margin: 4px 0 0 40px;
    }
  }

  .btn-secondary,
  .btn-secondary:hover,
  .btn-secondary:focus {
    background: transparent;
    color: #00aeae;
    border: 0;
    box-shadow: none;
    font-size: 12px;
    font-weight: 600;
  }

  .reschedule-cancel {
    color: #6f6f6f;
    font-size: 12px;
    padding-top: 5px;
  }

  .btn-default {
    padding: 10px 30px;
    width: fit-content !important;
  }

  .participant-buttons {
    font-weight: 700;
    margin-top: 17px;

    .form-check {
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .btn-default {
      margin-left: 10px;
    }
  }

  .interest {
    color: #666666;
    text-transform: capitalize;
    margin-bottom: 13px;
    border-radius: 5px;
  }

  .requested {
    background: #e3e3f0;
    border-radius: 5px;
  }
  #req-brn {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    background: transparent;
    border: none;

    color: #f6817d;
  }
  .accepted-btn {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 13px;
    color: #27d3db;
  }
  .tick {
    margin-right: 8px;
  }

  .partner-check {
    font-weight: 500;
    font-size: 10px;
    color: #5ba0df;
  }

  .rejected,
  .cancelled {
    background: #ffeeee;
    border-radius: 5px;
  }

  .completed {
    background: #faf2e0;
    border-radius: 5px;
  }
`;

export const SearchBar = styled(Form)`
  display: flex;
  padding: 20px 0px 21px 0px;
  .form-control {
    background: #fafafa;
    height: 33px;
    max-width: 401px;
    color: #111 !important;
    font-size: 14px;
  }
  .small-form {
    max-width: 600px;
    border-radius: 5px;
    height: 30px;
    margin: 20px 0 30px 0;
    font-size: 12px;
  }
  .form-control::placeholder {
    color: #d0d0d0;
    font-weight: 500;
  }
  .form-control:focus {
    box-shadow: none;
  }

  .search-button {
    height: 32px;
    border: none;
    background: transparent;
    color: #7a7a7a;
    border-right: none;
    font-size: 20px !important;
    margin: 0px 0px 5px -40px;
  }
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .serch{
    margin-bottom: 4px;
  }
`;
