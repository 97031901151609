import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Top } from './styles';
import { useStore } from '../../store';
import connect from '../../assets/icons/carticon.svg';
import logo from '../../assets/images/newmoblogo.svg';
import check from '../../assets/icons/check.svg';
import ScrollTopBar from './ScrollTopBar';

const MobileTopBar = observer(() => {
  const location = useHistory();
  const { meetingStore } = useStore();
  const { cart, recentUser, selectedProduct } = meetingStore;
  const [active, setActive] = useState(false);

  const { searchStore } = useStore();

  const { listProducts } = meetingStore;

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.scrollY > 150) {
        setActive(true);
      } else {
        setActive(false);
      }
    });
  });

  const {
    listSearchResults,
    listCities,
    listSearchinRoles,
    listHRanges,
    listTRanges,
    listRoles,
    listProfiles,
    listIndustries,
  } = searchStore;

  const History = useHistory();

  

  useEffect(() => {
    listProducts();
  }, [listProducts]);

  useEffect(() => {
    listSearchResults();
  }, [listSearchResults]);

  useEffect(() => {
    listCities(true);
  }, [listCities]);

  useEffect(() => {
    listSearchinRoles();
  }, [listSearchinRoles]);

  useEffect(() => {
    listIndustries();
  }, [listIndustries]);

  useEffect(() => {
    listHRanges();
  }, [listHRanges]);

  useEffect(() => {
    listTRanges();
  }, [listTRanges]);

  useEffect(() => {
    listRoles();
  }, [listRoles]);

  useEffect(() => {
    listProfiles();
  }, [listProfiles]);

  return (
    <Top className='d-flex d-lg-none align-items-center justify-content-between mob-topbar'>
      {active && location.location.pathname === '/search' ? (
        <ScrollTopBar />
      ) : (
        <>
          <Image
            onClick={() => History.push('/')}
            src={logo}
            alt='logo'
            className='logo d-block d-lg-none'
          />
          <div className='cart-div'>
            <button className='cart' onClick={() => location.push('/cart')}>
              {cart?.members_details?.length > 0 && (
                <div className='cart-count'>{cart.members_details.length}</div>
              )}
              <Image src={connect} />
            </button>
          </div>
        </>
      )}
      {recentUser && (
        <div className='alert d-flex align-items-center'>
          {/* <i className="alert-arrow" /> */}
          <div className='success-circle'>
            <Image src={check} id='checkCircle' />
          </div>
          Added
          <span>
            {recentUser?.full_name
              ? recentUser.full_name
              : `${recentUser.first_name} ${recentUser.last_name}`}
          </span>{' '}
          to your next {selectedProduct?.name} meeting
        </div>
      )}
    </Top>
  );
});

export default MobileTopBar;
