import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ConnectDiv = styled(Modal)`
  .modal-content {
    padding: 30px 36px 50px 35px;
    p {
      color: #c0c0c0;
      font-size: 14px;
      margin-bottom: 8px;
    }

    .name {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 18px;
    }

    .position {
      font-size: 14px;
    }

    .form-label {
      color: #666666;
      font-size: 12px;
      font-weight: 600;
    }

    .designation {
      font-style: italic;
      font-size: 13px;
      color: #8c97ac;
      font-weight: 400;
    }

    .form-control {
      background: #edfee7;
      box-shadow: none;
      border: 0;
    }
    .notif {
      background: #edfee7;
      margin-top: 18px;
      padding: 14px 5px 16px 20px;
      font-size: 13px;
      align-items: flex-start;

      .notif-text {
        margin-left: 9px;
      }

      p {
        font-size: 11px;
        color: #666666;
      }
    }

    .a_sendButton {
      margin-top: 15px;
    }

    .success-circle {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #27d3db;
      #checkCircle {
        height: 10px;
        width: 18px;
        margin-left: 7px;
        margin-top: 10px;
      }
    }
    h2 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .profile-pic {
    width: 80px;
    height: 80px;
    border: 2px solid #eaedf3;
  }

  .close {
    cursor: pointer;
    width: 15px;
  }

  .divider {
    flex-grow: 1;
    border-bottom: 1px dashed #b6c0d2;
  }

  .btn-default {
    padding: 8px 30px;
    width: fit-content;
  }

  .link {
    background: 0;
    border: 0;
    color: #00aeae;
    padding: 8px 30px;
    margin-top: 15px;
  }
`;
