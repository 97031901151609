import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const CartDiv = styled.div`
  position: relative;
  padding: 11px;
  padding-bottom: 58px;

  .glass {
    background: rgba(235, 255, 254, 0.2);
    margin: 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding-bottom: 10px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 992px) {
      padding: 38px 43px 10px 67px;
    }
  }

  .logo {
    margin: 40px 0px 30px 24px;
  }

  h2 {
    font-size: 36px;
    color: #fff;
    @media only screen and (max-width: 992px) {
      font-size: 24px;
      max-width: 290px;
      margin-left: 24px;
    }
  }

  .date-selector {
    max-width: 348px;
  }

  .rdrDateDisplayWrapper {
    background: #00aeae;
  }
  .rdrDateDisplayItemActive {
    border-color: #00aeae;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber {
    span {
      color: #000000de;
    }
  }

  .rdrInRange {
    background: #00aeae1f;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    background: #00aeae;
  }

  .meeting-form {
    background: #fff;
    padding: 20px 49px 50px 22px;
    border-radius: 5px;
    margin-top: 22px;
    min-height: 300px;
    position: relative;

    .danger {
      border: 1px solid #ff647f !important;
    }

    .text-danger {
      font-size: 12px;
    }

    .back-design {
      position: absolute;
      bottom: -200px;
      left: -170px;
      z-index: -1;
    }

    .sm-wave {
      position: absolute;
      top: -100px;
      right: -10px;
      z-index: -1;
    }

    .wave-design {
      position: absolute;
      right: -350px;
      top: -175px;
      z-index: -1;
    }

    @media only screen and (min-width: 992px) {
      padding: 55px 62px 47px 55px;
      min-height: 500px;
    }
  }

  .form-group {
    padding-top: 20px;
    color: #333333;
    .form-label {
      font-size: 14px;
      font-weight: 700;
      margin-left: 25px;
    }

    .form-control {
      background: #ebf4f9;
      font-size: 12px;
      font-weight: 600;
      color: #403c3c;
      box-shadow: none;
      border: 0.5px solid #cdeaf0;
      padding: 13px 16px 13px 20px;
    }

    @media only screen and (min-width: 992px) {
      padding-top: 30px;
      margin-left:22px;
      width:582px;
     
      .form-control {
        margin-left:22px;
        background:white;
        font-size: 14px;
      }
    }
  }
  .newreq{
   
    height: 342px;
    left: 329px;
   
    background: #FFF7DA;
    border-radius: 5px;
    @media only screen and (max-width: 600px){
      width:425px;
      height: 342px;
    left: 329px;
    /* width: 673px; */
    background: #FFF7DA;
    border-radius: 5px;
    }
}
  }
  .points-message {
    margin-top: 40px;
    color: #666666;
    font-size: 10px;
    @media only screen and (min-width: 992px) {
      margin-top: 25px;
      font-size: 14px;

      font-family: Lato;
      font-style: italic;
      font-weight: normal;
      font-size: 13px;
      line-height: 150%;
      /* or 19px */
      
      
      color: #8C97AC;
    }
  }

  .btn-default {
    max-width: 259px;
    height: 45px;
    padding: 0;
    margin: 15px auto 0 auto;
    font-size: 14px;
  }

  .meeting-info {
    font-size: 10px;
    color: #666666;
    padding: 11px 12px;
    background: #fff7da;

    .info-icon {
      margin-right: 6px;
    }

    .review-button {
      border: 0;
      background: transparent;
      color: #00aeae;
      font-size: 10px;
      margin-left: auto;
      font-weight: 600;
    }
    @media only screen and (min-width: 992px) {
      font-size: 14px;
      padding: 15px 20px;
      .info-icon {
        width: 18px;
        margin-right: 10px;
      }

      .review-button {
        font-size: 16px;
      }
    }
  }
`;

export const SuccessDiv = styled(Modal)`
  .modal-content {
    padding: 30px 36px 50px 35px;

    @media only screen and (min-width: 992px) {
    }

    h2 {
      font-size: 24px;
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h4 {
      max-width: 480px;
      margin-bottom: 30px;
      font-size: 13px;
      font-style: italic;
      color: #8c97ac;
    }

    .secondary {
      border: 0;
      background: #fff;
      color: #00aeae;
      font-weight: 700;
      font-size: 12px;
      padding: 14px 40px;
    }

    .a_sendButton {
      font-size: 14px;
      width: fit-content;
      padding: 14px 30px;
    }

    .success-circle {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #27d3db;
      #checkCircle {
        height: 10px;
        width: 18px;
        margin-left: 7px;
        margin-top: 10px;
      }
    }
  }
  .button-group {
    display: flex;
    /* justify-content: center; */
    /* display: flex; */
    flex-direction: 138px;
    flex-direction: column;
    align-items: center;
  }
  .close {
    cursor: pointer;
  }
  .modal-dialog {
    width: 354px;
  }
`;
