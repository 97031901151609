import React from 'react';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { ConnectDiv } from '../../../components/ConnectModal/styles';
// import check from '../../../assets/icons/check.svg';
// import { useHistory } from 'react-router-dom';
import close from '../../../assets/icons/close.svg';

import { addPoints } from '../../../api/meeting';


const SuccessModal = observer(({ show, setShow }) => {
 
  // const location = useHistory();
  return (
    <ConnectDiv show={show}>
      <div className='d-flex justify-content-end'>
        <Image
          src={close}
          onClick={() => setShow(false)}
          alt='close'
          className='close'
        />
      </div>

      <div>
        {/* <div className='success-circle'>
          <Image src={check} id='checkCircle' />
        </div> */}
        <h2 className='my-2'>Add points Request</h2>
        <p className='designation'>
        Once you place the request, 121connects team will schedule a meeting with you to provide details and assist.
        </p>
        {/* <div className='d-flex justify-content-center'>
          <Button
            variant='light'
            onClick={() => {
              location.push('/my-points');
            }}
            className='btn-default a_sendButton'
          >
            Back to points
          </Button>
        </div> */}
        <button className='btn-default a_sendButton' onClick={()=> {addPoints(); setShow(false);}}>
         Confirm
            </button>
            <button className='link'  onClick={() => setShow(false)} >
              Cancel
            </button>
      </div>
    </ConnectDiv>
  );
});

export default SuccessModal;
