import React, { useState } from "react";
import { Form, Card, Image } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import down from "../../../assets/icons/dropdown-arrow.svg";
import up from "../../../assets/icons/dropup-arrow.svg";

const RoleCard = observer(({ item, desktop }) => {
  const [open, setOpen] = useState(true);

  const { searchStore } = useStore();
  const { roleL2List, onRoleListChanged, listSearchResults, roleL1List } =
    searchStore;

  const onChange = (e, item, parent) => {
    if (parent && !item.rolel2?.length) {
      onRoleListChanged(item.rolel1, e.target.checked, parent);
    } else if (!parent) {
      onRoleListChanged(item.rolel2, e.target.checked);
    } else {
      item.rolel2.forEach((element) => {
        onRoleListChanged(element.rolel2, e.target.checked);
      });
    }
    if (desktop) {
      listSearchResults();
    }
  };

  return (
    <Card>
      <Card.Header
        className="d-flex justify-content-between"
        onClick={() => {
          if (item.rolel2?.length) {
            setOpen(!open);
          }
        }}
      >
        <Form.Check
          label={item.rolel1}
          value={item.rolel1}
          checked={
            item.rolel2.length
              ? item.rolel2.every((child) => roleL2List.includes(child.rolel2))
              : roleL1List.includes(item.rolel1)
          }
          onChange={(e) => onChange(e, item, true)}
          onClick={(e) => e.stopPropagation()}
        />
        {item.rolel2?.length ? (
          <button className="drop">
            <Image src={open ? up : down} />
          </button>
        ) : (
          <></>
        )}
      </Card.Header>
      {open && (
        <Card.Body>
          {item.rolel2?.map((child) => {
            return (
              <Form.Check
                label={child.rolel2}
                value={child.rolel2}
                key={child.rolel2}
                checked={roleL2List.includes(child.rolel2)}
                onChange={(e) => onChange(e, child)}
              />
            );
          })}
        </Card.Body>
      )}
    </Card>
  );
});

export default RoleCard;
