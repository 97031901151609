import { makeAutoObservable } from "mobx";
import { getMemberDetails } from "../../api/member";

class MemberStore {
  member = {};

  constructor() {
    makeAutoObservable(this);
  }

  setMemberDetails = (member) => {
    this.member = member;
  };

  onFetchMemberDetails = async (id) => {
    const res = await getMemberDetails(id);
    if (res.status === 200) {
      this.setMemberDetails(res.data);
    }
  };
}

export default MemberStore;
