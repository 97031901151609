import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Image, Form } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import Moment from 'react-moment';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import Footer from '../../components/Footer';
import { useStore } from '../../store';
import { CartDiv } from './elements/styles';
import { StyledModal } from '../MeetingDetails/elements/styles';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import bgDesign from '../../assets/images/back-design.svg';
import smWave from '../../assets/images/smwave.svg';
import wave from '../../assets/images/wave1.svg';
import calendarIcon from '../../assets/icons/Calendar.svg';
import close from '../../assets/icons/close.svg';
import MobileTopBar from '../../components/MobileTopBar';

const CancelReschedule = observer(() => {
  const location = useHistory();
  const { uid } = useParams();

  const [rescheduleError, setRescheduleError] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [cancelError, setCancelError] = useState(false);
  // const [cancel, setCancel] = useState(false);

  const { meetingStore } = useStore();
  const { listMeetings, meetings, onChangeMeetingStatus } = meetingStore;

  useEffect(() => {
    listMeetings();
  }, [listMeetings]);

  const { register, handleSubmit } = useForm();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [showDate, setshowDate] = useState(false);

  const onSubmit = (data) => {
    if (data.reschedule_message) {
      data.request_start_date = moment(state[0].startDate).format('YYYY-MM-DD');
      data.request_end_date = moment(state[0].endDate).format('YYYY-MM-DD');
      onChangeMeetingStatus(uid, data, 'reschedule');
      setSuccess(true);
    } else {
      setRescheduleError(true);
    }
    // } else {
    //   if (data.cancel_message) {
    //     onChangeMeetingStatus(uid, data, "cancel");
    //     setCancel(true);
    //   } else {
    //     setCancelError(true);
    //   }
    // }
  };

  const refDate = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refDate &&
        refDate.current &&
        !refDate.current.contains(event.target)
      ) {
        setshowDate(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
  }, [refDate, setshowDate]);

  return (
    <>
      <Navigation />
      <MobileTopBar />
      <StyledModal show={success}>
        <button
          className='a_closeButton'
          onClick={() => {
            location.push('/my-meetings');
          }}
        >
          <Image src={close} alt='close' />
        </button>

        <>
          <h3>Reschedule request received.</h3>
          <div>

          121connects has received your request and will see if this is possible, else you will be billed as per policy. Our team will contact you about this shortly.
          </div>
         
        </>
      </StyledModal>
      {/* <StyledModal show={cancel}>
        <button
          className='a_closeButton'
          onClick={() => {
            location.push('/meetings');
          }}
        >
          <Image src={close} alt='close' />
        </button>

        <>
          <h3>Meeting cancel request sent.</h3>
          <Button
            className='btn-default'
            onClick={() => {
              location.push('/meetings');
            }}
          >
            Back to meetings
          </Button>
        </>
      </StyledModal> */}
      <CartDiv>
        <div className='glass'>
          <div className='glass-design d-none d-lg-block'>
            <Image
              src={wave}
              alt='bg-design'
              className='wave-design d-none d-lg-block'
            />
          </div>
          {meetings
            .filter((item) => item.uid === uid)
            .map((meeting) => (
              <div className='col-lg-8 ' key={meeting.id}>
                <h2>Reschedule {meeting?.product_engagement_name} meeting</h2>

                <Form
                  className='meeting-form'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Form.Group>
                    <Form.Label>
                      Add a reason for rescheduling this meeting
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      className={rescheduleError && 'danger'}
                      id='reschedule'
                      {...register('reschedule_message')}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Select preferred meeting date</Form.Label>
                    <div
                      className='date-selector form-control d-flex justify-content-between'
                      ref={refDate}
                    >
                      <div>
                        <Moment format='DD MMMM, YYYY'>
                          {state[0].startDate}
                        </Moment>{' '}
                        -{' '}
                        <Moment format='DD MMMM, YYYY'>
                          {state[0].endDate}
                        </Moment>
                      </div>

                      <Image
                        src={calendarIcon}
                        onClick={() => setshowDate(!showDate)}
                      />
                      {showDate && (
                        <DateRange
                          onChange={(item) => {
                            setState([item.selection]);
                          }}
                          moveRangeOnFirstSelection={false}
                          months={1}
                          ranges={state}
                          direction='horizontal'
                        />
                      )}
                    </div>
                  </Form.Group>
                  {/* <div className='points-message'>
                    Once you place a request, 121 Connects team will call{' '}
                    {meeting.participants?.length === 1
                      ? `${meeting.participants[0]?.user?.full_name} `
                      : `${meeting.participants[0]?.user?.full_name} and ${
                          meeting.participants?.length - 1
                        } others `}
                    to confirm a meeting based on their availability.
                  </div> */}

                  {/* <Form.Group>
                        <Form.Label>
                          Add a reason for cancelling this meeting
                      </Form.Label>
                     <Form.Control
                          as='textarea'
                          rows={5}
                          className={cancelError && 'danger'}
                          {...register('cancel_message')}
                          id='cancel'
                        />
                      </Form.Group>
                      <div className='points-message'>
                        Once you place a request, 121 Connects team will call{' '}
                        {meeting.participants?.length === 1
                          ? `${meeting.participants[0]?.user?.full_name} `
                          : `${meeting.participants[0]?.user?.full_name} and ${
                              meeting.participants?.length - 1
                            } others `}
                        to confirm cancellation.
                      </div> */}

                  <button className='btn-default' type='submit'>
                    Send request
                  </button>
                  <Image
                    src={bgDesign}
                    alt='bg-design'
                    className='back-design d-none d-lg-block'
                  />
                  <Image src={smWave} className='d-lg-none sm-wave' />
                </Form>
              </div>
            ))}
        </div>
      </CartDiv>
      <Footer />
      <BottomBar />
    </>
  );
});

export default CancelReschedule;
