import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
// import Moment from 'react-moment';
import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import Footer from '../../components/Footer';
import RewardCard from './components/RewardCard';
import MobileTopBar from '../../components/MobileTopBar';
import { useStore } from '../../store';
import { CartDiv } from './elements/styles';
import bgDesign from '../../assets/images/back-design.svg';
import smWave from '../../assets/images/smwave.svg';
import wave from '../../assets/images/wave1.svg';
// import back from '../../assets/icons/backpage.svg';
import points from '../../assets/images/pointstar.svg';
// import pointshistoryicon from '../../assets/images/pointshistoryicon.svg';
// import { useState } from 'react';

const Rewards = observer(() => {
  const location = useHistory();

  const { pointsStore, accountStore } = useStore();
  const { rewards, listRewards,listPointsHistory } = pointsStore;
  const { user,listRefreshPoints, refreshPoints } = accountStore;

  var country = '';

  const modifiedAddresses = user?.address_set.map(address => {
    // var country = '';
    if (address.address_type === 0) {
      country = address.country
      
    }
    return address;
  });
  
  
  const filteredRedeem = rewards.filter(reward => reward.country === country
  )

  console.log(modifiedAddresses);
 
useEffect(() => {
    listPointsHistory();
  }, [listPointsHistory]);

  useEffect(() => {
    listRewards();
  }, [listRewards]);
  useEffect(() => {
      // listRefreshPoints();
      setTimeout(
        () => listRefreshPoints(), 
        2000
      );
    }, [listRefreshPoints]);

  // const [avlpoints, setavlpoints] = useState(refreshPoints?.points);
  // const [redpoints, setredpoints] = useState(refreshPoints?.redeemable_points);

  return (
    <>
      <Navigation />
      <CartDiv>
        <MobileTopBar />

        <div className='glass'>
          <div className='glass-design d-none  d-lg-block'>
            <Image
              src={wave}
              alt='bg-design'
              className='wave-design d-none d-lg-block'
            />
          </div>

          <div className='holder'>
            {/* <button
              className='back-button '
              onClick={() => location.push('/my-points')}
            >
              <Image src={back} />
              Points history
            </button> */}

            <div className='meeting-form  d-lg-flex justify-content-between'>
              <h2 className='page-title'>Redeem points</h2>

              <div className='points-div d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                  <Image src={points} className='points-icon' />
                  <div className='points-info'>
                    Available points 
                    <h5>
                      {user.user_type === 0
                        ? refreshPoints?.points
                        : user.company?.points_balance}
                    </h5>
                  </div>
                  {user.user_type === 0 &&
                  <div className='points-info'>
                    Redeemable points 
                    <h5>
                      {
                        refreshPoints?.redeemable_points
                      }
                    </h5>
                  </div>}
                </div>
                <button
                  className='btn-default'
                  onClick={() => location.push('/my-points')}
                >
                  {/* <Image src={pointshistoryicon} className='coins' /> */}
                  Points
                </button>
              </div>

              <Image src={smWave} className='d-lg-none sm-wave' />
            </div>

            <div className='content'>
              <div className='rewards-info'>
                Select any voucher at a time and enter denomination of your
                choice to redeem
              </div>

              <div className='w-100 cards '>
                {filteredRedeem.map((item) => (
                  <RewardCard item={item} key={item.id} />
                  // <div key={item.id} className="card">
                  //   <Image src={item.image} />
                  //   <div className="form d-flex">
                  //     <Form.Control />
                  //     <button className="btn-default">Redeem</button>
                  //   </div>
                  // </div>
                ))}
                <Image
                  src={bgDesign}
                  alt='bg-design'
                  className='back-design d-none d-lg-block'
                />
              </div>
            </div>
          </div>
        </div>
      </CartDiv>
      <Footer />
      <BottomBar />
    </>
  );
});

export default Rewards;
