import styled from 'styled-components';

export const CartDiv = styled.div`
  position: relative;
  padding: 11px;
  padding-bottom: 58px;

  .glass {
    margin: 0;
    border-radius: 5px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 992px) {
      padding: 24px 0px 10px 0px;
    }
    @media only screen and (max-width: 992px) {
      border: 1px solid rgba(255, 255, 255, 0.7);
      background: rgba(235, 255, 254, 0.2);
      margin-top: 100px;
    }
  }

  .glass-design {
    z-index: -1;
    margin-top: 4px;
    position: relative;
    margin-bottom: -188px;
    max-width: 1280px;
    height: 188px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    border-radius: 10px;

    .wave-design {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: -1;
    }
  }

  .holder {
    margin-top: 28px;
    @media only screen and (min-width: 992px) {
      max-width: 914px;
      margin: 36px auto 0 auto;
    }
  }

  .back-button {
    background: transparent;
    color: #00ffc2;
    border: 0;
    font-weight: 500;
    margin: 0px 28px 17px 15px;

    img {
      margin-right: 9.5px;
    }
    @media only screen and (min-width: 992px) {
      margin: 0;
    }
  }

  .coins {
    margin-right: 10px;
    margin-bottom: 2px;
  }
  .btn-default {
    padding: 6px 11px;
    width: fit-content;
    font-size: 14px;
    height: fit-content;
    margin-left: 17px;
  }

  .points-info {
    padding:5px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;

    h5 {
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 0;
    }

    .time {
      font-weight: 500;
    }
  }
  .points-icon {
    width: 43px;
    height: 43px;
  }

  .points-div {
  }

  .page-title {
    color: #fff;
    margin-bottom: 0;

    @media only screen and (max-width: 992px) {
      margin-left: 6px;
      margin-bottom: 17px;
    }
  }

  .meeting-form {
    position: relative;
    @media only screen and (max-width: 992px) {
      padding-left: 18px;
    }

    .sm-wave {
      position: absolute;
      top: -110px;
      right: -10px;
      z-index: -1;
    }
  }

  .content {
    background: #fff;
    border-radius: 5px;
    margin-top: 10px;
    padding: 27px 12px;

    .rewards-info {
      color: #8c97ac;
      font-style: italic;
      font-size: 13px;
    }

    @media only screen and (min-width: 992px) {
      padding: 30px 40px;

      .rewards-info {
        padding-left: 20px;
      }
    }
  }

  .cards {
    min-height: 300px;
    background: #fff;
    border-radius: 5px;
    position: relative;
    padding-top: 16px;

    img {
      width: 312px;
      height: 145px;
      border-radius: 5px 5px 0 0;
    }

    .card {
      width: 312px;
      height: 200px;
      border-radius: 5px;
      border: 0;
      margin-bottom: 20px;
      position: relative;

      .text-danger {
        font-size: 10px;
        padding-left: 20px;
        position: absolute;
        bottom: -12px;
      }
      .form {
        padding: 10px 14px;
        background: #111;
        border-radius: 0 0 5px 5px;
      }

      .form-control {
        border-radius: 5px 0 0 5px;
        font-size: 12px;
      }

      .btn-default {
        margin: 0;
        padding: 7px 13px;
        height: auto;
        border-radius: 0 5px 5px 0;
        font-size: 12px;
      }
    }
    .back-design {
      position: absolute;
      left: -170px;
      bottom: -205px;
      z-index: -1;
    }

    @media only screen and (min-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      padding-top: 10px;

      .card {
        margin: 20px 20px;
      }
    }
  }

  .contents {
    margin: 20px 103px 30px 67px;
    @media only screen and (max-width: 992px) {
      margin: 20px 16px;
    }
  }
`;
