import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { Col, Form, Button, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useStore } from "../../../store";
import show from "../../../assets/icons/Show.svg";
import showtoggle from "../../../assets/icons/toggleshow.svg";
import info from "../../../assets/icons/info-circle.svg";
import check from "../../../assets/icons/pass-success.svg";
import { SuccessDiv } from "../../SetPassword/components/styles";

const ChangePassword = observer(() => {
  const [passwordLengthError, setPasswordLengthError] = useState(true);
  const [uppercaseError, setUpperCaseError] = useState(true);
  const [numericError, setNumericError] = useState(true);
  const [characterError, setCharacterError] = useState(true);

  const checks = [
    {
      flag: passwordLengthError,
      message: "Use 8 or more characters",
    },
    {
      flag: uppercaseError,
      message: "Use atleast 1 UPPERCASE",
    },
    {
      flag: numericError,
      message: "Use atleast 1 numeric",
    },
    {
      flag: characterError,
      message: "Use atleast 1 special character ( @,$,!,%,*,?,&,# )",
    },
  ];

  const [showPassword, setShowPassword] = useState(true);
  const [showConfPassword, setShowConfPassword] = useState(true);

  const regemail = window.location.search.slice(7);

  const { authStore } = useStore();
  const { resetPassword, passwordResetMessage } = authStore;

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfVisibility = () => {
    setShowConfPassword(!showConfPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data) => {
    if (checks.every((item) => item.flag === false)) {
      resetPassword(regemail, data.password);
    }
  };

  const passwordCheck = (data) => {
    if (data.length < 8) {
      setPasswordLengthError(true);
    } else {
      setPasswordLengthError(false);
    }
    if (/(?=.*[A-Z])/.test(data)) {
      setUpperCaseError(false);
    } else {
      setUpperCaseError(true);
    }
    if (/(?=.*\d)/.test(data)) {
      setNumericError(false);
    } else {
      setNumericError(true);
    }
    if (/(?=.*[@$!%*?&#])/.test(data)) {
      setCharacterError(false);
    } else {
      setCharacterError(true);
    }
  };

  return (
    <SuccessDiv className="set-password-page">
    <>
      <h2 className=" login-title">Enter new password</h2>

      <Form className="loginForm" onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} sm={12} controlId="password">
            <Form.Label>Enter new password</Form.Label>
            <div className="d-flex password-control">
              <Form.Control
                size="sm"
                type={showPassword ? "password" : "text"}
                name="password"
                className={classNames({
                  formerror: errors.password || passwordResetMessage,
                })}
                {...register("password", {
                  required: "Password is required.",
                })}
                onChange={(e) => passwordCheck(e.target.value)}
              />
              <button
                onClick={() => toggleVisibility()}
                className="toggleButton"
                type="button"
              >
                <Image
                  src={showPassword ? show : showtoggle}
                  id="togglePassword"
                />
              </button>
            </div>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} sm={12} controlId="confirmpassword">
            <Form.Label>Confirm Password</Form.Label>
            <div className="d-flex password-control">
              <Form.Control
                size="sm"
                type={showConfPassword ? "password" : "text"}
                name="confirmpassword"
                {...register("confirmpassword", {
                  required: true,
                  validate: (value) => value === watch("password"),
                })}
                className={classNames({
                  formerror: errors.confirmpassword || passwordResetMessage,
                })}
              />
              <button
                onClick={() => toggleConfVisibility()}
                className="toggleButton"
                type="button"
              >
                <Image
                  src={showConfPassword ? show : showtoggle}
                  id="togglePassword"
                />
              </button>
            </div>
            <div
              id="login-Status-Text"
              className="small mb-0 a_loginResponseText"
            >
              <div>{passwordResetMessage}</div>
              <div>{errors.password?.message}</div>
              {errors.confirmpassword &&
                errors.confirmpassword.type === "required" &&
                "Confirm password is required."}
              {errors.confirmpassword &&
                errors.confirmpassword.type === "validate" &&
                "Please enter the same password as above."}
            </div>
          </Form.Group>
        </Form.Row>
        <div
          id="verify-status-text"
          className="password-verification-text text-center"
        >
          {checks.map((item) => (
            <div
              className={`d-flex ${item.flag ? "failed" : "success"}`}
              key={item.message}
            >
              <div>
                <Image src={item.flag ? info : check} />
              </div>

              <p>{item.message}</p>
            </div>
          ))}
        </div>

        <Button
          variant="light"
          className="btn-default"
          type="submit"
          id="continue_password"
        >
          Continue
        </Button>
      </Form>
    </>
    </SuccessDiv>
  );
});

export default ChangePassword;
