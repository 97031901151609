import React from "react";
import { observer } from "mobx-react-lite";

import {  Button, Image } from "react-bootstrap";
import { useStore } from "../../store";
import background from '../../assets/images/Background.svg';
import './Unsubscribe.css'
import close from "../../assets/icons/close.svg";
import { useHistory } from "react-router";


const Unsubscribe = observer(() => {
  const { authStore } = useStore();
  const { onOptOut } = authStore;
  const history = useHistory();

  

  

  return (
      <>
    <style>
    {`body { 
    background: 0; 
    background-image:  url(${background}); 
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh; }`}
  </style>
    <div className='root'> 

      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h2> Are You Sure?</h2>
        </div>

        <button onClick={() => history.push('/')} className="close-button">
          <Image src={close} alt="close" className="close" />
        </button>
      </div>

      

        <div className='content' style={{width:' 368px'}}>
          Opting out means you will no longer be a member on 121connects and that your login credentials will be terminated. Any points balance in your account can no longer be redeemed.
          </div>

        <Button className="btn-default" type="submit" onClick={() =>{ 
          onOptOut(); 
          history.push('/');
          }}>
          Confirm
        </Button>
     
      </div>
      </>
  );
});

export default Unsubscribe;
