import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ConnectDiv } from './styles';
import close from '../../assets/icons/close.svg';

const LimitModal = observer(({ show, setShow }) => {
  const location = useHistory();
  return (
    <ConnectDiv show={show} onClick={(e) => e.stopPropagation()}>
      <div className='d-flex align-items-start justify-content-between'>
        <div>
          <h3>
            You need to fix or clear the current meeting in your cart before
            selecting additional meetings.
          </h3>
        </div>
        <Image
          src={close}
          onClick={(e) => {
            setShow(false);
            e.stopPropagation();
          }}
          alt='close'
          className='close'
        />
      </div>
      <Button
        variant='light'
        onClick={(e) => {
          location.push('/cart');
          e.stopPropagation();
        }}
        className='btn-default a_sendButton'
      >
        Visit cart
      </Button>
    </ConnectDiv>
  );
});

export default LimitModal;
