import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Form, Col, Button, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ProjectDiv, ButtonDiv } from "./elements/styles";
import { useStore } from "../../store";
import BottomBar from "../../components/BottomBar";
import Navigation from "../../components/TopBar";
import logo from "../../assets/images/Logo-Black@2x.svg";
import back from "../../assets/icons/backpage.svg";

const AddProject = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useHistory();

  const { projectStore } = useStore();
  const { addProject } = projectStore;

  const onSubmit = (data) => {
    if (!data.go_live_date) {
      data.go_live_date = null;
    }
    data.display_owner = !data.display_owner;
    addProject(data);
  };
  return (
    <>
      <Navigation />
      <ProjectDiv>
        <div className="glass">
          <Image src={logo} alt="logo" className="logo d-block d-lg-none" />

          <div className="col-lg-8">
            <button
              className="back-button "
              onClick={() => location.push("/projects")}
            >
              <Image src={back} />
              My projects
            </button>
          </div>

          <Form
            className="addProjectForm col-lg-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="project-div ">
              <h2 className="my-3">Add project</h2>

              <Form.Group as={Col} sm={12} controlId="name">
                <Form.Label>Project name</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  {...register("name", { required: "This field is required." })}
                />
                <div className="text-danger">
                  {errors.name && errors.name.message}
                </div>
              </Form.Group>
              <Form.Group as={Col} sm={12} controlId="description">
                <Form.Label>Project description</Form.Label>
                <Form.Control
                  size="sm"
                  rows="9"
                  as="textarea"
                  name="description"
                  {...register("description")}
                />
              </Form.Group>
            </div>
            <div className="project-div ">
              <Form.Group as={Col} sm={12} controlId="key_technologies">
                <Form.Label>
                  Key technologies <i>(Optional)</i>
                </Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="key_technologies"
                  {...register("key_technologies")}
                />
              </Form.Group>
              <Form.Group as={Col} sm={12} controlId="key_vendors">
                <Form.Label>
                  Key vendors <i>(Optional)</i>
                </Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="key_vendors"
                  {...register("key_vendors")}
                />
              </Form.Group>
              <Form.Group as={Col} sm={12} controlId="go_live_date">
                <Form.Label>
                  Go live date <i>(Optional)</i>
                </Form.Label>
                <Form.Control
                  size="sm"
                  type="date"
                  name="go_live_date"
                  {...register("go_live_date")}
                />
              </Form.Group>
              <Form.Group as={Col} sm={12} controlId="display_owner">
                <Form.Check
                  type="checkbox"
                  id="display_owner"
                  label="Hide owner and team details on the project pages"
                  {...register("display_owner")}
                />
              </Form.Group>
            </div>
            <div className=" right-div">
              <div className="project-div">
                <Form.Row>
                  <Form.Group sm={12} controlId="project-status">
                    <h2>Project status</h2>
                    <div className="project-status">
                      <Form.Check
                        type="radio"
                        name="project-status"
                        id="ongoing"
                        label="Ongoing"
                        value={1}
                        {...register("status")}
                      />
                      <Form.Check
                        type="radio"
                        name="project-status"
                        id="planning"
                        label="Planned"
                        defaultChecked
                        value={2}
                        {...register("status")}
                      />
                      <Form.Check
                        type="radio"
                        name="project-status"
                        id="completed"
                        label="Completed"
                        value={0}
                        {...register("status")}
                      />
                    </div>
                  </Form.Group>
                </Form.Row>
              </div>
              <div className="project-div d-none d-lg-flex">
                <Button
                  variant="dark"
                  className="a_publishButton btn-default col"
                  type="submit"
                  id="publish_confirm"
                >
                  Publish project
                </Button>
                <Button
                  variant="light"
                  className=" a_cancelButton  col"
                  type="submit"
                  id="cancel"
                  onClick={() => location.push("/projects")}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </ProjectDiv>
      <ButtonDiv className="d-flex d-lg-none">
        <Button
          variant="dark"
          className="a_publishButton btn-default col"
          type="submit"
          id="publish_confirm"
        >
          Publish project
        </Button>
        <Button
          variant="light"
          className=" a_cancelButton  col"
          type="submit"
          id="cancel"
          onClick={() => location.push("/projects")}
        >
          Cancel
        </Button>
      </ButtonDiv>

      <BottomBar />
    </>
  );
});

export default AddProject;
