import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { Form, Button, Image } from "react-bootstrap";
import { useStore } from "../../../store";
import { ProfileFormDiv } from "../elements/styles";
import close from "../../../assets/icons/close.svg";


const RequestModal = observer(({ show, setEdit, user }) => {
  const [error, setError] = useState();
  
  const { accountStore } = useStore();
const [success, setSuccess] = useState(false);
  const { onRequestEdit } = accountStore;

  const { handleSubmit, register,reset } = useForm();

  const onSubmit = (data) => {
    let req = {};
    if (data.email) {
      req.field = "primary email";
      req.value = data.email;
      onRequestEdit(req);
    }
    if (data.phone) {
      req.field = "primary mobile";
      req.value = data.phone;
      onRequestEdit(req);
    }
    if (data.email || data.phone) {
      
      setError();
      setSuccess(true)
    } else {
      setError("Please enter the field you want to change.");
    }
  };

  return (
    <ProfileFormDiv show={show}>
      
       {!success ? (
         <>
      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h4>Edit your profile</h4>
          <h2>Send edit request</h2>
          <p className='warning text-danger'> Your registered email ID cannot be changed.</p>
        </div>

        <button onClick={() => {setEdit(false); reset()}} className="close-button">
          <Image src={close} alt="close" className="close" />
        </button>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} className="request-change">
        {/* <Form.Group>
          <Form.Label>Edit field</Form.Label>
          <Form.Control as="select" name="field" {...register("field")}>
            {["primary email", "primary mobile"].map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </Form.Control>
        </Form.Group> */}
        <Form.Group>
          <h5>Current email</h5>
          <div className="current">{user.email}</div>
          {/* <Form.Label>Change to</Form.Label>
          <Form.Control name="email" {...register("email")} /> */}
         
        </Form.Group>
        <Form.Group>
          <h5>Current mobile number</h5>
          <div className="current">{user.mobile}</div>

          <Form.Label>Change to</Form.Label>
          <Form.Control name="phone" {...register("phone")} />
        </Form.Group>
        <div className="text-danger">{error}</div>

        <Button className="btn-default" type="submit" >
          Send request
        </Button>
      </Form>
      </>):(
         <div>
         <div className="d-flex justify-content-end">
          
           <Image src={close} onClick={() => {setEdit(false); reset(); setSuccess(false);}} />
         </div>
 
         <h2 className="my-2"> Profile Edit Request Received </h2>
         
       
         
       </div>
      )}
    </ProfileFormDiv>
  );
});

export default RequestModal;
