import { makeAutoObservable } from 'mobx';
import {
  getPointsHistory,
 
  getRewards,
  requestRedemption,
  sendDispute,
} from '../../api/meeting';

class PointsStore {
  
  pointsHistory = [];

  rewards = [];

  constructor() {
    makeAutoObservable(this);
  }

  setPointsHistory = (data) => {
    this.pointsHistory = data;
  };

  listPointsHistory = async (start, end) => {
    const res = await getPointsHistory(start, end);
    if (res.status === 200) {
      this.setPointsHistory(res.data);
      
    }
  };

  setRewards = (data) => {
    this.rewards = data;
  };

  listRewards = async () => {
    const res = await getRewards();
    if (res.status === 200) {
      this.setRewards(res.data);
    }
  };

  // // onAddPoints = async () => {
  //   const res = await addPoints();

  //   // if (res.status === 200) {
  //   //   cb();
  //   // }
  // };

  onRequestRedemption = async (data, cb) => {
    const res = await requestRedemption(data);
    if (res.status === 200) {
      cb();
    }
  };

  onsendDispute = async (data, cb) => {
    const res = await sendDispute(data);
    if (res.status === 200) {
      cb();
    }
  };
}

export default PointsStore;
