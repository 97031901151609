import React from 'react';
import { CarouselCard } from './styles';
import UserHolder from '../../assets/images/profile-holder.svg';

const CarouselTile = ({ member, level }) => {
  const className = 'item level' + level;
  return (
    <div className={className}>
      <CarouselCard>
        <img
          src={member?.photo ? member.photo : UserHolder}
          alt={member?.first_name}
        />
        <div className='user-info'>
          <h2 style={{ color: '#FFFFFF' }} className='text-center'>
            {member?.first_name} {member?.last_name}
          </h2>
          <h4 style={{ color: '#DDDCDC' }} className='text-center' id='h4'>
            {member?.title && `${member.title}  |  `} {member?.company?.name}
          </h4>
        </div>
      </CarouselCard>
    </div>
  );
};

export default CarouselTile;
