import axios from "axios";
import LocalStorageService from "../utils/LocalStorageService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

instance.interceptors.request.use(
  (config) => {
    const access_token = LocalStorageService.getAccessToken();
    if (access_token) {
      const item = { ...config };
      item.headers.Authorization = `Bearer ${access_token}`;
      return item; // replace original with new instance
    }
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const refresh = LocalStorageService.getRefreshToken();
    if (refresh) {
      const originalRequest = error?.config;
      if (
        error?.response?.status === 401 &&
        originalRequest?.url !== "/token/refresh/"
      ) {
        const res = await instance.post("/token/refresh/", {
          refresh: refresh,
        });
        if (res.status === 200) {
          const { access } = res?.data || {};
          if (access) {
            LocalStorageService.setToken({ access, refresh });
          }
          axios.defaults.headers.common.Authorization = access;
          return instance(originalRequest);
        }
      }
      if (originalRequest?.url === "/token/refresh/") {
        LocalStorageService.clearToken();
      }
    }
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);

export default instance;
