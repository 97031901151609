import React from "react";

import { observer } from "mobx-react-lite";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { LoginDiv } from "../Login/elements/styles";
import close from "../../assets/icons/close.svg";
import background from "../../assets/images/Background.svg";
import logo from '../../assets/images/LogoBlack.png'
// import flash from "../../assets/images/Key.svg";
import pattern from "../../assets/images/bp.svg";
import { useHistory } from "react-router-dom";


const Cookie = observer(() => {
const location = useHistory()

  return (
    <LoginDiv className="login-page">
      <Helmet>
        <style>
          {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh; }`}
        </style>
      </Helmet>
      {/* <Image src={flash} alt="flash" className="tr" /> */}
      <div className='terms-root'>
       

        <Image src={logo} alt="logo" className="terms-logo" />
        <div className=" form-hold terms">
          <div className="d-flex justify-content-between align-items-start pb-3">
            <div>
            <Image src={close} alt="close" className='back-closebtn' onClick={() => location.go(-1)} />
              <h2 className="login-title">Cookie Policy</h2>
             
            </div>
          </div>

          <div className="terms-content" >
            <div className="scroll-content">
           
              <p>
              Thank you for visiting 121connects.com (by 121 Connects Pvt. Ltd.)  
              </p>
              <p>
                {" "}
                This Website collects certain information via cookies and other online technologies. 
We care about your privacy and the information you share with us and want you to 
understand how we are using and protecting the information we collect about you. 
121connects is respectful of data privacy and adopts best practice in compliance 
with applicable privacy law and regulations. 
              </p>
              <p>
                {" "}
                This Cookie Policy, as well as the Privacy Policy describes 121connects’ policies 
and practices regarding its use of information collected on this Website and sets 
forth your privacy rights. 
              </p>{" "}
              <p>
                {" "}
                By using this Website you consent to this Cookie Policy, and agree that 121connects 
as explained in the Privacy Policy can place cookies as we describe below, and you 
agree to the collection, sharing, and use of your Personal Data for personalization of 
advertising or other services. 
              </p>
              <p>
                {" "}
                <h5>WHY DO WE USE COOKIES?</h5> 
              </p>
              <p>
                {" "}
                <strong>Cookies improve your experience.</strong> We use cookies to help you access this 
Website faster and more efficiently. Cookies allow us to distinguish you from other 
users, so that we can recognize you each time you visit. Cookies also allow us to 
store user preferences and provide you with a positive experience when you browse 
this Website, including enabling you to personalize Website settings. Our marketing 
partners also collect web viewing data to ensure that you are seeing the most 
relevant advertising. 
              </p>
              <p>
                {" "}
                <h5>WHAT ARE COOKIES?</h5> 
              </p>
              <p>
                {" "}
                Cookies are small text files that can be stored on a User’s computer, tablet or mobile 
device (“Computer”) when you visit a website.  A cookie will typically contain the 
 
 
name of the originating domain, the lifetime of the cookie, and a randomly generated 
unique number or other value. 
Cookies used by 121connects identify you as a unique user by means of a tracking 
ID. The cookie itself is anonymous and does not contain any Personal Data, except 
when such information has been supplied by you, such as when you provide your 
name and email address when completing a registration form. 
              </p>
              <p>
                {" "}
                <h5>COOKIE POLICY CHANGES AND UPDATES </h5> 
              </p>
              <p>
                {" "}
                We reserve the right to amend this Cookie Policy from time to time as necessary. We 
will post a notice on this Website if there are material changes to the Cookie Policy, 
but you should also check this Website periodically to review the current policy. 
              </p>
              <p>
                {" "}
                <h5>
              HOW CAN WE HELP? GETTING IN TOUCH </h5> 
              </p>
              <p>
                {" "}
                If you have any questions, concerns or complaints about 121connects’ Personal 
Data practices around cookies or this Cookie Policy, we encourage you to get in 
touch with us at <a href='https://privacy@121connects.com'>privacy@121connects.com </a>
              </p>
              Last Updated: [15 August 2021 version CP-1.0] 
            </div>
          </div>

         
        </div>
      </div>
      <Image className="bp" src={pattern} />
    </LoginDiv>
  );
});

export default Cookie;
