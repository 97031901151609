import styled from 'styled-components';

export const CartDiv = styled.div`
  position: relative;
  padding: 11px;
  padding-bottom: 58px;

  .glass {
    background: rgba(235, 255, 254, 0.2);
    margin: 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    max-width: 1280px;
    margin: 90px auto 0 auto;
    padding: 50px 0 0px 0;
    @media only screen and (min-width: 992px) {
      padding: 0px;
      border: 0;
      background: 0;
      margin: 0 auto;
    }
  }

  .glass-design {
    z-index: -1;
    margin-top: 0px;
    position: relative;
    margin-bottom: -160px;
    max-width: 1280px;
    height: 218px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    border-radius: 10px;

    .wave-design {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: -1;
    }
  }

  .col-lg-8 {
    margin: 0 auto;
  }

  h2 {
    font-size: 36px;
    color: #fff;
    @media only screen and (max-width: 992px) {
      font-size: 24px;
      max-width: 290px;
      margin-left: 24px;
    }
  }

  .meeting-form {
    background: #fff;
    padding: 20px 49px 20px 22px;
    border-radius: 5px;
    margin-top: 22px;
    position: relative;

    .back-design {
      position: absolute;
      bottom: -200px;
      left: -170px;
      z-index: -1;
    }

    .sm-wave {
      position: absolute;
      top: -100px;
      right: -10px;
      z-index: -1;
    }

    .wave-design {
      position: absolute;
      right: -350px;
      top: -175px;
      z-index: -1;
    }

    @media only screen and (min-width: 992px) {
      padding: 30px 62px 47px 55px;
      min-height: 500px;
    }
  }

  .disabled-check {
    color: #c0c0c0;
  }

  .form-group {
    padding-top: 20px;
    color: #333333;
    .form-label,
    .form-check-label {
      font-size: 14px;
      font-weight: 700;
    }

    .form-control {
      background: #ebf4f9;
      font-size: 12px;
      font-weight: 600;
      color: #403c3c;
      box-shadow: none;
      border: 0.5px solid #cdeaf0;
      padding: 13px 16px 13px 20px;
    }

    .danger {
      border: 0.5px solid red;
    }

    @media only screen and (min-width: 992px) {
      padding-top: 30px;
      .form-control {
        font-size: 14px;
      }
    }
  }

  .points-message {
    margin-top: 40px;
    color: #666666;
    font-size: 10px;
    @media only screen and (min-width: 992px) {
      margin-top: 50px;
      font-size: 14px;
    }
  }

  .btn-default {
    max-width: 259px;
    height: 45px;
    padding: 0;
    margin: 15px auto 0 auto;
    font-size: 14px;
  }

  .meeting-info {
    font-size: 10px;
    color: #666666;
    padding: 11px 12px;
    background: #fff7da;

    .info-icon {
      margin-right: 6px;
    }

    .review-button {
      border: 0;
      background: transparent;
      color: #00aeae;
      font-size: 10px;
      margin-left: auto;
      font-weight: 600;
    }
    @media only screen and (min-width: 992px) {
      font-size: 14px;
      padding: 15px 20px;
      .info-icon {
        width: 18px;
        margin-right: 10px;
      }

      .review-button {
        font-size: 16px;
      }
    }
  }
`;
