import axios from './axios';

const getSearchResults = (offset, key, data) =>
  axios
    .get(`/search/?limit=30&offset=${offset}&key=${encodeURIComponent(key)}`, {
      params: data,
    })
    .then(
      (res) => res,
      (err) => err.response
    );

const getCities = (filter) =>
  axios.get(`/search/cities/${filter ? '?for=filter' : ''}`);

const getStates = () => axios.get('/search/states/');

const getInterests = () => axios.get('/search/interests/');

const getEngagements = () => axios.get('/search/engagements/');

const getRoles = (filter) =>
  axios.get(`/search/role/${filter ? '?for=filter' : ''}`);

const getSearchinRoles = () => axios.get('/search/role/?for=searchin');

const getTags = () => axios.get('/search/tags/');

const getHRanges = () => axios.get('/search/headcount/');

const getTRanges = () => axios.get('/search/turnover/');

const getProfiles = () => axios.get('/search/profile/');

const getIndustries = () => axios.get('/search/industry/');

const getPreviousSearch = () => axios.get('/search/history/');

export {
  getSearchResults,
  getCities,
  getStates,
  getInterests,
  getEngagements,
  getRoles,
  getTags,
  getHRanges,
  getTRanges,
  getProfiles,
  getIndustries,
  getPreviousSearch,
  getSearchinRoles,
};
