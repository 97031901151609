import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Image, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import Footer from '../../components/Footer';
import { useStore } from '../../store';
import { CartDiv } from './elements/styles';
import { StyledModal } from '../MeetingDetails/elements/styles';
import bgDesign from '../../assets/images/back-design.svg';
import smWave from '../../assets/images/smwave.svg';
import wave from '../../assets/images/wave1.svg';
import close from '../../assets/icons/close.svg';
import MobileTopBar from '../../components/MobileTopBar';

const CancelMeeting = observer(() => {
  const location = useHistory();
  const { uid } = useParams();

  const [cancelError, setCancelError] = useState(false);
  const [cancel, setCancel] = useState(false);

  const { meetingStore, accountStore } = useStore();
  const { listMeetings, meetings, onChangeMeetingStatus } = meetingStore;
  const {user} = accountStore

  useEffect(() => {
    listMeetings();
  }, [listMeetings]);

  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (data.cancel_message) {
      onChangeMeetingStatus(uid, data, 'cancel');
      setCancel(true);
    } else {
      setCancelError(true);
    }
  };

  return (
    <>
      <Navigation />
      <MobileTopBar />

      <StyledModal show={cancel}>
        <button
          className='a_closeButton'
          onClick={() => {
            location.push('/my-meetings');
          }}
        >
          <Image src={close} alt='close' />
        </button>
{user.user_type !== 0 ?
        <>
          <h3>Meeting cancellation request.</h3>
          <div style={{width:' 353px'}}>
          121connects has received your request and will see if this is possible, else you will be billed as per policy. Our team will contact you about this shortly. 
          </div>
          <Button
            className='btn-default'
            onClick={() => {
              location.push('/my-meetings');
            }}
          >
            Back to meetings
          </Button>
        </>: <>
        <h3>Meeting cancellation request.</h3>
        {meetings
            .filter((item) => item.uid === uid)
            .map((meeting) => (
        <diV>
        {meeting?.product_name} Cancelled
        </diV>))}
        <Button
            className='btn-default'
            onClick={() => {
              location.push('/my-meetings');
            }}
          >
            Back to meetings
          </Button>
         </>}
      </StyledModal>
      <CartDiv>
        <div className='glass'>
          <div className='glass-design d-none d-lg-block'>
            <Image
              src={wave}
              alt='bg-design'
              className='wave-design d-none d-lg-block'
            />
          </div>
          {meetings
            .filter((item) => item.uid === uid)
            .map((meeting) => (
              <div className='col-lg-8 ' key={meeting.id}>
                <h2>Cancel {meeting?.product_engagement_name} </h2>

                <Form
                  className='meeting-form'
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Form.Group>
                    <Form.Label>
                      Add a reason for cancelling this meeting
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={5}
                      className={cancelError && 'danger'}
                      {...register('cancel_message')}
                      id='cancel'
                    />
                  </Form.Group>
                  {/* <div className='points-message'>
                    Once you place a request, 121 Connects team will call{' '}
                    {meeting.participants?.length === 1
                      ? `${meeting.participants[0]?.user?.full_name} `
                      : `${meeting.participants[0]?.user?.full_name} and ${
                          meeting.participants?.length - 1
                        } others `}
                    to confirm cancellation.
                  </div> */}

                  <button className='btn-default' type='submit'>
                    Send request
                  </button>
                  <Image
                    src={bgDesign}
                    alt='bg-design'
                    className='back-design d-none d-lg-block'
                  />
                  <Image src={smWave} className='d-lg-none sm-wave' />
                </Form>
                
              </div>
              
            ))}
        </div>
      </CartDiv>
      <Footer />
      <BottomBar />
    </>
  );
});

export default CancelMeeting;
