import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { Form, Button, Image } from "react-bootstrap";
import { useStore } from "../../../store";
import { ProfileFormDiv } from "../elements/styles";
import close from "../../../assets/icons/close.svg";
import Gallery from "../../../assets/icons/Gallery.svg";
import Delete from "../../../assets/icons/Delete-pic.svg";
// import Camera from "../../../assets/icons/Camera.svg";

const UploadImage = observer(({ user, show, setEdit }) => {
  const [file, setFile] = useState();
  const { authStore, accountStore } = useStore();

  const { onEditProfilePic } = authStore;
  const { onDeleteProfilePic } = accountStore;

  const { handleSubmit } = useForm();

  const hiddenFileInput = useRef(null);
  //   const hiddenFileCapture = useRef(null);

  const handleDpEditClick = () => {
    hiddenFileInput.current.click();
  };

  //   const handleDpCaptureClick = () => {
  //     hiddenFileCapture.current.click();
  //   };

  const onSubmit = () => {
    onEditProfilePic(file);
    setFile(null);
    setEdit(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <ProfileFormDiv show={show}>
      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h4>Edit your profile</h4>
          <h2>Edit profile picture</h2>
        </div>

        <button
          onClick={() => {
            setFile(null);
            setEdit(false);
          }}
          className="close-button"
        >
          <Image src={close} alt="close" className="close" />
        </button>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <button
          onClick={handleDpCaptureClick}
          className="edit-pic"
          type="button"
        >
          <Image src={Camera} />
        </button>
        <Form.Control
          type="file"
          ref={hiddenFileCapture}
          onChange={handleFileChange}
          accept="image/*"
          capture="user"
        /> */}
        <button onClick={handleDpEditClick} className="edit-pic" type="button">
          <Image src={Gallery} />
        </button>
        <Form.Control
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileChange}
        />
        <button
          className="edit-pic upload-pic"
          type="button"
          disabled={!user.photo}
          onClick={() => {
            onDeleteProfilePic();
            setEdit(false);
          }}
        >
          <Image src={Delete} className={user.photo && `active`} />
        </button>
        <p>{file?.name}</p>
        <p className="tip">Maximum upload file size: 2 MB</p>

        <Button className="btn-default" type="submit">
          Save
        </Button>
      </Form>
    </ProfileFormDiv>
  );
});

export default UploadImage;
