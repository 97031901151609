import React, { useState, useEffect } from 'react';
import './components/Style.css';
import { observer } from 'mobx-react-lite';
import { Col, Form, Button, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { useStore } from '../../store';
import { SuccessDiv } from './components/styles';
import background from '../../assets/images/Backgroundnew.svg.svg';

import show from '../../assets/icons/Show.svg';
import showtoggle from '../../assets/icons/toggleshow.svg';
import LogoBlack from '../../assets/images/LogoBlack.png';
import linkedin from '../../assets/icons/Icon-Linkedin.svg';
import info from '../../assets/icons/info-circle.svg';
import check from '../../assets/icons/pass-success.svg';
import wave from '../../assets/images/waveDesign.svg';

const SetPassword = observer(() => {
  const [passwordLengthError, setPasswordLengthError] = useState(true);
  const [uppercaseError, setUpperCaseError] = useState(true);
  const [numericError, setNumericError] = useState(true);
  const [characterError, setCharacterError] = useState(true);

  const checks = [
    {
      flag: passwordLengthError,
      message: 'Use 8 or more characters',
    },
    {
      flag: uppercaseError,
      message: 'Use atleast 1 UPPERCASE',
    },
    {
      flag: numericError,
      message: 'Use atleast 1 numeric',
    },
    {
      flag: characterError,
      message: 'Use atleast 1 special character ( @,$,!,%,*,?,&,# )',
    },
  ];

  const { authStore, accountStore } = useStore();
  const { setPassword, setPasswordError } = authStore;
  const { footer, listFooter } = accountStore;

  const [showPassword, setShowPassword] = useState(true);
  const [showConfPassword, setShowConfPassword] = useState(true);

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfVisibility = () => {
    setShowConfPassword(!showConfPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data) => {
    if (checks.every((item) => item.flag === false)) {
      setPassword(data.password, data.confirmpassword);
    }
  };

  const passwordCheck = (data) => {
    if (data.length < 8) {
      setPasswordLengthError(true);
    } else {
      setPasswordLengthError(false);
    }
    if (/(?=.*[A-Z])/.test(data)) {
      setUpperCaseError(false);
    } else {
      setUpperCaseError(true);
    }
    if (/(?=.*\d)/.test(data)) {
      setNumericError(false);
    } else {
      setNumericError(true);
    }
    if (/(?=.*[@$!%*?&#])/.test(data)) {
      setCharacterError(false);
    } else {
      setCharacterError(true);
    }
  };

  useEffect(() => {
    listFooter();
  }, [listFooter]);

  return (
    <SuccessDiv className='set-password-page'>
      <Helmet>
        <style>
          {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh; }`}
        </style>
      </Helmet>
      <img src={wave} alt='' className='wave-design' />
      <div className='glass'>
        <img src={LogoBlack} alt='' className='newlogo1' />

        <div className='form-hold'>
          <h2>Enter new password</h2>
          <Form className='loginForm' onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group as={Col} sm={12} controlId='password'>
                <Form.Label>Enter Password</Form.Label>
                <div className='d-flex password-control'>
                  <Form.Control
                    size='sm'
                    type={showPassword ? 'password' : 'text'}
                    name='password'
                    className={classNames({
                      danger: errors.password,
                    })}
                    {...register('password', {
                      required: 'Password is required.',
                    })}
                    onChange={(e) => passwordCheck(e.target.value)}
                  />
                  <button
                    onClick={() => toggleVisibility()}
                    className='toggleButton'
                    type='button'
                  >
                    <Image
                      src={showPassword ? show : showtoggle}
                      id='togglePassword'
                    />
                  </button>
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm={12} controlId='confirmpassword'>
                <Form.Label>Confirm Password</Form.Label>
                <div className='d-flex password-control'>
                  <Form.Control
                    size='sm'
                    className={classNames({
                      danger: errors.confirmpassword || setPasswordError,
                    })}
                    type={showConfPassword ? 'password' : 'text'}
                    name='confirmpassword'
                    {...register('confirmpassword', {
                      required: true,
                      validate: (value) => value === watch('password'),
                    })}
                  />
                  <button
                    onClick={() => toggleConfVisibility()}
                    className='toggleButton'
                    type='button'
                  >
                    <Image
                      src={showConfPassword ? show : showtoggle}
                      id='togglePassword'
                    />
                  </button>
                </div>

                <div
                  id='login-Status-Text'
                  className='small mb-0 a_loginResponseText'
                >
                  {setPasswordError}
                  <div>
                    {errors.confirmpassword &&
                      errors.confirmpassword.type === 'required' &&
                      'Confirm password is required.'}

                    {errors.confirmpassword &&
                      errors.confirmpassword.type === 'validate' &&
                      'Please enter the same password as above.'}
                  </div>
                  <div>{errors.password?.message}</div>
                </div>
              </Form.Group>
            </Form.Row>
            <div
              id='verify-status-text'
              className='status-verification-text text-center'
            >
              {checks.map((item) => (
                <div
                  className={`d-flex ${item.flag ? 'failed' : 'success'}`}
                  key={item.message}
                >
                  <div className='verify-circle'>
                    <Image src={item.flag ? info : check} id='checkCircle' />
                  </div>

                  <p>{item.message}</p>
                </div>
              ))}
            </div>
            <Button
              variant='light'
              className='w-100 btn-default '
              type='submit'
              id='login_confirm'
            >
              Continue
            </Button>
          </Form>
        </div>
      </div>
      <div className='d-flex'>
        {footer.map((item) => (
          <div className='button-group d-flex'>
            <a href={item.link} target='_blank' rel='noreferrer'>
              <button className='terms' id='terms'>
                {item.text}
              </button>
            </a>
          </div>
        ))}
      </div>

      <div className='icon-div'>
        <a
          href={process.env.REACT_APP_API_LINKEDIN_LINK}
          target='_blank'
          rel='noreferrer'
        >
          <img src={linkedin} className='linked-logo' alt='icon' />{' '}
        </a>
      </div>
    </SuccessDiv>
  );
});

export default SetPassword;
