import React from 'react'
import { useState } from 'react';
import { CartDiv } from '../elements/styles';
import classNames from 'classnames';
import up from "../../../assets/icons/dropup-arrow.svg";
import down from "../../../assets/icons/dropdown-arrow.svg";



export default function Agenda({item}) {
    const [more, setMore] = useState(false);

    function toggle(){
        setMore(!more)
      }

    return (
        <div>
            <CartDiv>
                {item?.meeting?.request_message && (
                <div>
             <div className='points-head designation'>
                            Agenda : 
                            </div>
                            <div className='designation'>
                            <p
                    className={classNames({
                      less: !more,
                    })}
                  >
                           {item?.meeting?.request_message} 
                           </p>
                          </div>
                          {item?.meeting?.request_message.length > 100 &&  (
                <div className='readmore'>
                {!more ? (
                  <div className='p-moreless' onClick={() => toggle()}>
                   More  <img className='more-less'  src={down} alt="" />
                    </div>
                ):(
                  <div className='p-moreless' onClick={() => toggle()}>
                   Less  <img className='more-less'  src={up} alt="" />
                  </div>
                )}
                
                  
                </div>
                  )}
                           </div>)}
                         
                           {item?.note && (
                          <div className='designation' >
                            Note : {''}
                            <p
                    className={classNames({
                      less: !more,
                    })}
                  >
                            {item?.note} 
                            </p>
                            {item?.note.length > 50  &&  (
                <div className='readmore'>
                {!more ? (
                  <div className='p-moreless' onClick={() => toggle()}>
                   More  <img className='more-less'  src={down} alt="" />
                    </div>
                ):(
                  <div className='p-moreless' onClick={() => toggle()}>
                   Less  <img className='more-less'  src={up} alt="" />
                  </div>
                )}
                
                  
                </div>
                  )}
                          </div>)}
                          </CartDiv>
        </div>
    )
}
