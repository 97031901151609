import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import background from "../../assets/images/Background.svg";
import logo from '../../assets/images/LogoBlack.png';
import { useStore } from "../../store";
import { LoginDiv } from "../Login/elements/styles";
import { StyledModal } from "../MeetingDetails/elements/styles";
// import flash from "../../assets/images/Key.svg";
import close from "../../assets/icons/close.svg";
import pattern from "../../assets/images/bp.svg";


const Terms = observer(() => {
  const [bottom, setBottom] = useState(false);
  const [reject, setReject] = useState(false);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 50;
    setBottom(bottom);
  };
  const location = useHistory();
  const { accountStore, authStore } = useStore();
  const { onacceptTerms, user } = accountStore;
  const { onLogOut } = authStore;

  // const onSubmit = () => {
  //   onacceptTerms(() => {
  //     location.push("/set-password/success");
  //   });
  // };

  const logOut = () => {
    onLogOut();
    location.push("/");
  };

  return (
    <LoginDiv className="login-page">
      <Helmet>
        <style>
          {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh; }`}
        </style>
      </Helmet>
      {/* <Image src={flash} alt="flash" className="tr" /> */}
      <div className='terms-root' >

        <StyledModal show={reject}>
          <button className="a_closeButton" onClick={() => setReject(false)}>
            <Image src={close} alt="close" />
          </button>

          <h3>Are You Sure?</h3>
          <div className="reject-terms">
            Accepting Terms &amp; Conditions are mandatory for members using this application. Choosing reject, will mean you will be immediately logged out. You can login again later to accept and continue
          </div>

          <Button className="btn-default" onClick={() => logOut()}>
            Confirm
          </Button>
        </StyledModal>

        <Image src={logo} alt="logo" className="terms-logo" />

        <div className=" form-hold terms" id='termsCond'>
          <div className='terms-backbtn'>
            {user?.consent && (
              <Image src={close} alt="close" className='back-closebtn' onClick={() => location.go(-1)} />)}
          </div>
          <div className="d-flex justify-content-between align-items-start pb-3">

            <div>

              <h2 className="login-title">Terms and Conditions</h2>

              {user.id && (
                <>
                  {!user.consent && (
                    <div className="condition text-danger">
                      You have to accept the terms and conditions to continue
                      and complete the activation.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <p className='scroll-msg text-danger'>Please scroll down inside the box and read the complete terms of use to accept.</p>
          <div className="terms-content" onScroll={handleScroll}>
            <div className="scroll-content">

              <p>
                Welcome to 121connects!  <br />
                Please read these non-negotiable terms of use carefully before using the
                website. By continuing to use the website, you (the “user”) agree to be
                bound by these terms of use.
              </p>
              {process.env.REACT_APP_COUNTRY === 'India' ? <p>
                {" "}
                <h5> CONTENT AND COPYRIGHT: </h5>
                This Website is the property of 121 Connects Pvt. Ltd. (121connects) and
                is protected by international copyright law and conventions. The User does
                however reserve all rights related to personal information contributed
                directly in sections such as Profile and can edit or delete such information
                in keeping with the User rights to personal data defined in the Privacy
                Policy. 121connects grants to User the right to access and use the
                Website, so long as the User does not alter, copy, disseminate, redistribute
                or republish any content or feature of this Website. User acknowledges that
                access to and use of this Website is subject to these TERMS OF USE and
                any expanded access or use must be approved in writing by 121connects.
              </p> :
                <p>
                  {" "}
                  <h5> CONTENT AND COPYRIGHT: </h5>
                  This Website is the property of 121connects Australia and New Zealand Pty Ltd (121connects) and
                  is protected by international copyright law and conventions. The User does
                  however reserve all rights related to personal information contributed
                  directly in sections such as Profile and can edit or delete such information
                  in keeping with the User rights to personal data defined in the Privacy
                  Policy. 121connects grants to User the right to access and use the
                  Website, so long as the User does not alter, copy, disseminate, redistribute
                  or republish any content or feature of this Website. User acknowledges that
                  access to and use of this Website is subject to these TERMS OF USE and
                  any expanded access or use must be approved in writing by 121connects.
                </p>}
              <p>
                {" "}
                <h5>ACCURACY OF INFORMATION:​ </h5>
                While the information contained on this Website has been obtained from
                sources believed to be reliable, 121connects disclaims all warranties as to
                the accuracy, completeness or adequacy of such information. User
                assumes sole responsibility for the use it makes of this Website to achieve
                his/her intended results.
              </p>{" "}
              <p>
                {" "}
                <h5>THIRD-PARTY LINKS:​</h5>
                This Website may contain links to other third-party websites, which are
                provided as additional resources for the convenience of Users.
                121connects does not endorse, sponsor or accept any responsibility for
                these third-party websites. User agrees to direct any concerns relating to
                these third-party websites to the relevant website administrator.
              </p>
              <p>
                {" "}

                <h5>CHANGES OR UPDATES TO THE WEBSITE:​</h5>
                121connects reserves the right to change, update or discontinue any
                aspect of this Website at any time without notice. Your continued use of the


                Website after any such change constitutes your agreement to these
                TERMS OF USE, as modified.
              </p>
              <p>{" "}
                <h5>REPRESENTATION:​</h5>
                User assumes sole responsibility for the use of this Website to achieve his/her
                intended results in a personal capacity. Vendor representatives using the website
                assume responsibility for being authorised by their company to engage on its behalf.
              </p>

              <p> {" "}
                <h5>
                  REWARDS PROGRAM
                </h5>
                User might be offered reward points for certain engagements on the platform.
                These reward points can be redeemed through third-party merchant arrangements by 121connects
                subject to the terms and conditions of the individual merchant or partner.
                121connects is in no way liable for any service failure by the concerned third party and all liabilities
                cease on delivery of the merchant gift voucher. Rewards for activities are at the sole discretion of 121connects
                and can be changed or withdrawn at any time. User will be intimated explicitly when an activity or engagement has
                associated rewards points and any specific terms related to its accrual. When not explicitly mentioned, activities and
                engagements on 121connects are deemed to carry no reward points. 121connects has the right to reverse any rewards points
                if erroneously credited and use its sole discretion in awarding or withholding reward points in case of any disputes.
              </p>

              <p>{" "}
                <h5>
                  DISCLAIMER OF WARRANTIES:​</h5>
                121connects does not make any warranties, express or implied, including,
                without limitation, those of merchantability and fitness for a particular
                purpose, with respect to this Website. Although 121connects takes
                reasonable steps to screen this Website for infection by viruses, worms,
                Trojan horses or other code manifesting contaminating or destructive
                properties, 121connects cannot guarantee that it will be free of infection or
                that it will be available at any particular time or location.
              </p>
              <p>{" "}
                <h5>
                  LIMITATION OF LIABILITY:​</h5>
                In no event will 121connects be liable for: (a) damages of any kind,
                including without limitation, direct, incidental or consequential damages
                (including, but not limited to, damages for lost profits Limitation of Liability:
                business interruption and loss of programs or information) arising out of the


                use of or inability to use this Website, or any information provided on this
                Website, or (b) any claim attributable to errors, omissions or other
                inaccuracies in the information provided on this Website.
              </p>
              <p>{" "}
                <h5>
                  TERMINATION:​</h5>
                In accessing and using this Website, User agrees to comply with all
                applicable laws and agrees not to take any action that would compromise
                the security or viability of this Website. 121connects may terminate User's
                access to this Website at any time for any reason. The terms hereunder
                regarding Disclaimer of Warranty, Accuracy of Information, Indemnification,
                and Third Party Rights shall survive termination.
              </p>
              <p>{" "}
                <h5>
                  INDEMNIFICATION:</h5>
                User agrees to indemnify, defend and hold harmless 121connects, its affiliates,
                partners, and their respective officers, directors, employees and agents from and
                against all losses, expenses, damages and costs, including reasonable attorneys' fees,
                arising out of User's use/ misuse of this Website.
              </p>
              <p>{" "}
                <h5>
                  THIRD-PARTY RIGHTS:​</h5>
                The terms hereunder regarding Disclaimer of Warranty, Limitation of
                Liability and Indemnification are for the benefit of 121connects, and its
                partners, employees and agents, each of whom shall have the right to
                assert and enforce those terms against a User.
              </p>
              <p>{" "}
                <h5>
                  GENERAL PROVISIONS
                </h5>
                Any provision in any written communication received by 121connects in connection
                with this Website which is inconsistent with, or adds to, these TERMS OF USE is void.
                If any term hereunder is determined by a court of competent jurisdiction to be invalid,
                all remaining terms will remain in full force and effect.
              </p>

              {process.env.REACT_APP_COUNTRY === 'India' ? <p>
                {" "}
                <h5>
                  GOVERNING LAW:​</h5>
                These TERMS OF USE and the resolution of any dispute arising hereunder shall all be governed
                by and construed in accordance with the laws of India. You agree, as we do, to submit to the
                exclusive jurisdiction of the courts at Bengaluru.
              </p> :
              <p>
              {" "}
              <h5>
                GOVERNING LAW:​</h5>
              These TERMS OF USE and the resolution of any dispute arising hereunder shall all be governed
              by and construed in accordance with the laws of Australia. You agree, as we do, to submit to the
              exclusive jurisdiction of the courts at New South Wales.
            </p>}

              <p>{" "}
                <h5>
                  PRIVACY STATEMENT:​</h5>
                User's right to privacy is of paramount importance to 121connects. When
                you register to use our Website, 121connects will ask you to provide your
                name, organization, email address, and other related information. 121connects
                will use this information to provide you with a more personalised online
                experience and, where applicable, to process orders, as well as to keep
                track of what topics are of interest to our Users. 121connects will always
                use this information in accordance with the Privacy Policy and Cookie
                Policy. </p>

              <h5>
                RESTRICTION ON USE:​</h5>
              User will not (i) violate the security of the Site nor attempt to gain
              unauthorised access to the Site, data, materials, information, computer
              systems or networks connected to any server associated with the Site,
              through hacking, password timing or any other means; (ii) take nor attempt
              any action that, in the sole discretion of 121connects, imposes or may
              impose an unreasonable or disproportionately large burden on the Site or
              its infrastructure; (iii) use nor attempt to use any scraper, robot, bot, spider,
              data mining, computer code, or any other automated device, program, tool,
              algorithm, process or methodology to access, index, acquire, copy, or
              monitor any portion of the Site, any data or content found on or accessed
              through the Site without the prior written consent of 121connects; (iv) forge
              headers or otherwise manipulate identifiers in order to disguise the origin of
              any other content.
              { process.env.REACT_APP_COUNTRY === 'India' ? <p>{" "}Last Updated: [1 October 2021 version TC-1.0]
              </p> :
              <p>{" "}Last Updated: [9 June 2023 version TC-1.0]
              </p> }
            </div>
          </div>

          {user.id && (
            <>
              {!user.consent && (
                <div className="d-flex">
                  <Button
                    variant="light"
                    className="a_loginButton btn-default"
                    onClick={() => {
                      onacceptTerms(() => {
                        location.push("/set-password/success");
                      });
                    }}
                    id="login_confirm"
                    disabled={!bottom}
                  >
                    Accept
                  </Button>{" "}
                  <Button

                    className="a_loginButton"
                    onClick={() => setReject(true)}
                    id="reject_terms"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Image className="bp" src={pattern} />
    </LoginDiv>
  );
});

export default Terms;
