import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { Image, Button } from "react-bootstrap";
import { SidebarDiv } from "./sidebar";
import { useStore } from "../../store";

import OptOutModal from "../OptOutModal";
import UserHolder from "../../assets/images/profile-holder.svg";
// import points from "../../assets/images/sidepoints.svg";
import close from "../../assets/icons/sidebar-close.svg";
// import logout from "../../assets/icons/Logout.svg";
// import call from "../../assets/icons/Call.svg";

const Sidebar = ({ show, setShow }) => {

  const [showOut, setOptOut] = useState(false);

  const { accountStore, authStore } = useStore();
  const { user, footer, listFooter } = accountStore;
  const { onLogOut } = authStore;

  const logOut = () => {
    onLogOut();
    location.push("/");
  };

  
  useEffect(() => {
    listFooter();
  }, [listFooter]);  

  const location = useHistory();
  return show ? (
    <SidebarDiv className="d-flex flex-column  d-lg-none">
      <button
        onClick={() => setShow(false)}
        id="close"
        className="d-flex flex-column align-items-center sidebutton"
      >
        <Image src={close} />
        Close
      </button>
      <div className="account-info d-flex flex-column justify-content-between">
        <div>
          <Image
            src={user?.photo ? user?.photo : UserHolder}
            roundedCircle
            className="profile-pic"
          />
          <h3>
            {user.first_name} {user.last_name} 
          </h3>
          <p className="designation">{user.title} </p>
          <p className="designation">{user?.company?.name}</p>
          <p className="designation">{user.email}</p>

          <Button
            className="btn-default"
            id="edit-profile"
            onClick={() => location.push("/profile")}
          >
            My Profile
          </Button>
          {/* <div className="btn-actions">
            <button className="sidebutton">
              <Image src={call} />
              Contact Us
            </button>
            <div className="points d-flex align-items-center">
              <Image src={points} /> Available points{" "}
              <h5>
                {user.user_type === 0
                  ? user.points_balance
                  : user.company?.points_balance}
              </h5>
            </div>
          </div> */}
        </div>

        <div>
          {/* <div className="btn-actions">
            <div>
              <button onClick={() => logOut()} className="sidebutton">
                <Image src={logout} />
                Logout
              </button>
            </div>
          </div> */}
          <div className="side-footer">
            <button onClick={() => logOut()} className="sidebutton">
              Logout
            </button>
            <div>
            {footer.map((item)=> 
            <div className='button-group'>
              <a href={item.link}
              target='_blank'
              rel='noreferrer'>
              <button
                className='sidebutton'
                id='terms'
              
              >
               {item.text}
              </button></a>
             
            </div>)}
            </div>

           

            <div className="consent">
              {user.consent_date && (
                <>
                  Accepted on:{" "}
                  <Moment format="DD MMMM, YYYY">{user.consent_date}</Moment>
                </>
              )}{" "}
              <button className="sidebutton" onClick={() => setOptOut(true)}>
                Opt-out
              </button>
            </div>
            <div className="version">Latest version: v1</div>
          </div>
        </div>
      </div>

      <OptOutModal show={showOut} setEdit={setOptOut} />
      
    </SidebarDiv>
  ) : (
    <></>
  );
};

export default Sidebar;
