import React from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { Form, Button, Image } from "react-bootstrap";
import { useStore } from "../../store";
import { ProfileFormDiv } from "../../pages/Profile/elements/styles";
import close from "../../assets/icons/close.svg";

const OptOutModal = observer(({ show, setEdit }) => {
  const { authStore } = useStore();
  const { onOptOut } = authStore;

  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    onOptOut(data.reason);
    setEdit(false);
  };

  return (
    <ProfileFormDiv show={show}>
      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h2> Are You Sure?</h2>
        </div>

        <button onClick={() => setEdit(false)} className="close-button">
          <Image src={close} alt="close" className="close" />
        </button>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Form.Group>
          <Form.Label>Opt out reason</Form.Label>
          <Form.Control
            size="sm"
            as="textarea"
            name="reason"
            className="description"
            rows="7"
            {...register("reason")}
          />
        </Form.Group> */}

        <div style={{width:' 368px'}}>
          Opting out means you will no longer be a member on 121connects and that your login credentials will be terminated. Any points balance in your account can no longer be redeemed.
          </div>

        <Button className="btn-default" type="submit">
          Confirm
        </Button>
      </Form>
    </ProfileFormDiv>
  );
});

export default OptOutModal;
