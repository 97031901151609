import axios from "./axios";

/**
 * calls the user GET api to get the user details
 */
const getAccount = () => axios.get("/auth/user/");

const sendOTP = (mobile) =>
  axios.post("/users/verify-mobile/", { mobile }).then(
    (res) => res,
    (err) => err.response
  );

const verifyOTP = (otp, mobile) => {
  return axios.post("/users/verify-mobile/confirm/", { otp, mobile }).then(
    (res) => res,
    (err) => err
  );
};

const setPassword = (new_password1, new_password2) =>
  axios.post("/auth/password/change/", { new_password1, new_password2 }).then(
    (res) => res,
    (err) => err.response
  );

const editProfile = (data, id) => {
  return axios.put(`/users/profile/${id}/`, data).then(
    (res) => res,
    (err) => err
  );
};

const editProfilePic = (data, id) => {
  return axios.put(`/users/photo/${id}/`, data).then(
    (res) => res,
    (err) => err
  );
};

const deleteProfilePic = (id) => {
  return axios.put(`/users/photo/${id}/`, { photo: null }).then(
    (res) => res,
    (err) => err
  );
};

const editAddress = (data, id) => {
  return axios.put(`/users/address/${id}/`, data).then(
    (res) => res,
    (err) => err
  );
};

const addAddress = (data) => {
  return axios.post("/users/address/", data).then(
    (res) => res,
    (err) => err
  );
};

const getCarousel = () => {
  return axios.get("/users/carousel/").then(
    (res) => res,
    (err) => err
  );
};

const requestEdit = (data) => {
  return axios.post("/users/request-edit/", data).then(
    (res) => res,
    (err) => err
  );
};

const optOut = (reason) => {
  return axios.post("/users/opt-out/", { reason }).then(
    (res) => res,
    (err) => err
  );
};

const acceptTerms = () => {
  return axios.post("/users/consent/").then(
    (res) => res,
    (err) => err
  );
};

export {
  getAccount,
  setPassword,
  sendOTP,
  verifyOTP,
  editProfile,
  editAddress,
  addAddress,
  deleteProfilePic,
  getCarousel,
  editProfilePic,
  requestEdit,
  optOut,
  acceptTerms,
};
