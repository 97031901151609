import React from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { Form, Button, Image } from "react-bootstrap";
import { useStore } from "../../../store";
import { ProfileFormDiv } from "../elements/styles";
import close from "../../../assets/icons/close.svg";

const AddressForm = observer(
  ({ show, address, setEdit, cities, states, address_type }) => {
    const { accountStore } = useStore();

    const { onEditAddress, onAddAddress } = accountStore;

    const { handleSubmit, register, reset } = useForm();

    const country = process.env.REACT_APP_COUNTRY

    const onSubmit = (data) => {
      if (data.city) {
        data.city = cities.find((item) => item.name === data.city);
      }
      if (data.state) {
        data.state = states.find((item) => item.name === data.state);
      }
      if (address) {
        onEditAddress(data, address.id);
      } else {
        data.address_type = address_type;
        onAddAddress(data);
      }
      setEdit(false);
    };

    return (
      <ProfileFormDiv show={show}>
        <div className="d-flex justify-content-between align-items-start mb-4">
          <div>
            <h4>Edit your profile</h4>
            <h2>
              {address_type === 0 ? "Office Address" : "Personal Address"}
            </h2>
          </div>

          <button onClick={() => {setEdit(false); reset()}} className="close-button">
            <Image src={close} alt="close" className="close" />
          </button>
         
        </div>
       <p className='form-label'> This information is not displayed to others. </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Street <span className='asteric'>*</span> </Form.Label>

            <Form.Control
              name="street"
              defaultValue={address?.street}
              required={true}
              {...register("street")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{country === 'Australia' ? ' Postal code' : 'Pincode'} <span className='asteric'>*</span></Form.Label>

            <Form.Control
              name="pincode"
              type="text"
              required={true}
              defaultValue={address?.pincode}
              {...register("pincode")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>City <span className='asteric'>*</span></Form.Label>
            <Form.Control
              as="select"
              {...register("city")}
              defaultValue={address?.city}
            >
              {cities?.map((item) => (
                <option value={item.name} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
         
          <Button className="btn-default" type="submit">
            Save
          </Button>
        </Form>
      </ProfileFormDiv>
    );
  }
);

export default AddressForm;
