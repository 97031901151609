import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const TermsModal = styled(Modal)`
  .modal-content {
    padding: 30px 36px 50px 30px;

    p {
      color: #666666;
    }

    button {
      background: #fff;
      border: 0;
    }

    h2 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;
