import { makeAutoObservable } from "mobx";
import {
  getProjects,
  addProject as addProjectApi,
  getProjectDetails as getProjectDetailsApi,
  getSearchProjectDetails,
  deleteProject,
  getCompanyProjects,
  editProject,
} from "../../api/project";
import AccountStore from "../account/accountStore";
import history from "../../history";

class ProjectStore {
  accountStore: AccountStore;

  userProjects = [];

  project = [];

  companyProjects = [];

  constructor(accountStore: AccountStore) {
    this.accountStore = accountStore;
    makeAutoObservable(this);
  }

  setUserProjects = (projects) => {
    this.userProjects = projects;
  };

  setCompanyProjects = (projects) => {
    this.companyProjects = projects;
  };

  setProjectDetails = (project) => {
    this.project = project;
  };

  getUserProjects = async () => {
    const res = await getProjects(this.accountStore.user.id);
    if (res.status === 200) {
      this.setUserProjects(res.data);
    }
  };

  listCompanyProjects = async () => {
    const res = await getCompanyProjects(this.accountStore.user.id);
    if (res.status === 200) {
      this.setCompanyProjects(res.data);
    }
  };

  addProject = async (data) => {
    const res = await addProjectApi(this.accountStore.user.id, data);
    if (res.status === 201) {
      history.push("/projects");
    }
  };

  onEditProject = async (id, data) => {
    const res = await editProject(this.accountStore.user.id, id, data);
    if (res.status === 200) {
      history.push(`/project/${id}`);
    }
  };

  onDeleteProject = async (id) => {
    const res = await deleteProject(this.accountStore.user.id, id);
    if (res.status === 204) {
      history.push("/projects");
    }
  };

  getProjectDetails = async (id) => {
    const res = await getProjectDetailsApi(this.accountStore.user.id, id);
    if (res.status === 200) {
      this.setProjectDetails(res.data);
    } else {
      const res = await getSearchProjectDetails(id);
      if (res.status === 200) {
        this.setProjectDetails(res.data);
      }
    }
  };
}

export default ProjectStore;
