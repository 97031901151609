import React from "react";

import { observer } from "mobx-react-lite";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { LoginDiv } from "../Login/elements/styles";

import background from "../../assets/images/Background.svg";
import logo from '../../assets/images/LogoBlack.png'
// import flash from "../../assets/images/Key.svg";
import pattern from "../../assets/images/bp.svg";
import close from "../../assets/icons/close.svg";
import { useHistory } from "react-router-dom";


const Privacy = observer(() => {
  const location = useHistory()
  
 
  
  

 

  return (
    <LoginDiv className="login-page">
      <Helmet>
        <style>
          {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh; }`}
        </style>
      </Helmet>
      {/* <Image src={flash} alt="flash" className="tr" /> */}
      <div className='terms-root'>
       

        <Image src={logo} alt="logo" className="terms-logo" />
        <div className=" form-hold terms">
          <div className="d-flex justify-content-between align-items-start pb-3">
            <div>
            <Image src={close} alt="close" className='back-closebtn' onClick={() => location.go(-1)} />
              <h2 className="login-title">Privacy Policy</h2>
             
            </div>
          </div>

          <div className="terms-content" >
            <div className="scroll-content">
              <p>Last updated: July 15, 2021</p>
              <p>
              121 Connects Private Ltd. (“121connects”) is committed to protecting your privacy 
and the information you share with us. Please read this Privacy Policy (the “Policy") 
carefully as it sets out important information relating to how we use and protect any 
information we collect about you.  
              </p>
              <p>
                {" "}
               <h5> INTRODUCTION  </h5>
               This Policy sets out how we collect and use personal information, and your choices 
and rights regarding our use of your personal information. 
This Policy describes our practices when using your information when you:  
1.express an interest in or have signed up for our events or products including 
newsletters, apps, extensions, webinars, and e-books, or activate your 
121connects user account and/or membership; 
2.attend a 121connects event; or 
3.visit our websites (including our public and/or member-based websites) or 
social media sites. 
This Policy also applies to information we collect from you via our surveys or online 
forms as outlined in more detail below. You may be shown an additional 
confidentiality notice before participating in a survey or filling such a form. Please 
note that in cases where the terms of any such surveys or forms conflict with any 
terms in this Policy, the terms of that notice will take precedence over the terms in 
this Policy. 
Our websites and online services are for individuals who are at least 18 years old. 
Our online services are not designed to be used by individuals under the age of 18. 
This Policy will apply whether you have provided the information directly to us or we 
have obtained it from a different source, such as a third party.     
              </p>
              <p>
                {" "}
               <h5>
WHAT INFORMATION WE COLLECT </h5> 
               121connects collects data about you that can include typical business card details 
such as: first name, last name, job title, employer/company name, work address, 
 
 
work email, and work phone number. In some cases, we may ask you to provide 
additional professional information such as the size of the company you work for, 
and industry type. 
To enhance and/or update the information that you have provided to us, we may 
combine it with professional information or personal data that we collect from third 
party sources. The personal data that we refer to in this document is the personal 
and professional information you provide, as well as information that we obtain from 
third party sources, both of which we typically combine as one user record. 
This site also collects and stores certain information automatically using cookies and 
similar technologies, including IP addresses, the region or general location of a 
computer or device accessing the internet, browser type, operating system, page 
view history, and other usage information. <br/> 
See our Cookie Policy for further details.
              </p>
              <p>
                {" "}
                <h5> HOW WE COLLECT IT ​</h5>

               
We may collect your personal data when you register to receive any of the products, 
content or services offered by 121connects or its third-party sponsors (“sponsors”) 
such as publications, subscriptions, contests, newsletters, memberships, premium 
content, webcasts, video, white papers, online seminars, conferences and events. 
What happens if you don’t provide personal data? 
In order for us to provide the products, content or services you request, we need 
your personal data. Therefore, if you do not provide such personal data, we cannot 
deliver the products, content or services. </p>
              <p>
                {" "}
                
<h5>HOW DO WE SAFEGUARD YOUR PERSONAL DATA? ​</h5>

Security of your personal data 
121connects maintains safeguards which include technical and organizational 
 
 
security measures to protect your personal data from loss, misuse, unauthorized 
use, access, inadvertent disclosure, alteration, and destruction, and we require the 
third parties we contract with to support 121connects’  business operations to 
employ reasonable security measures as well (see “When and how we share your 
information with others” below). 
We update and test security on an ongoing basis and restrict access to your 
personal data to only those who need to know in order to provide 121connects’ 
products, content or services to you. 
Storage of your personal data 
We may store your personal data using 121connects’ own secure on-site servers or 
other internally hosted technology.  Your personal data may also be stored by third 
parties, via cloud services or other technology, to whom 121connects has contracted 
with, to support 121connects’ business operations (see “When and how we share 
your information with others” below). 
These third parties do not use or have access to your personal data other than for 
cloud storage and retrieval, and 121connects requires such parties to employ at 
least the same level of security that we use to protect your personal data (see 
“Security of your personal data” above).  
              </p>
              <p>{" "}
             <h5> WHAT WE DO WITH THE PERSONAL DATA WE COLLECT</h5> 

             
We use the personal data that we collect to communicate with you, deliver what you 
request, improve our service, and to present other information and offers that may 
be of interest. 
To communicate with you 
We may use your personal data to send you important notices, such as 
communications about your registration, transactions, and changes to 121connects’ 
 
 
terms, conditions, policies, and/or other internal purposes. 
To deliver what you request 
We may use your personal data to provide the products, content or services you 
request. We may also use your personal information to administer and fulfil rewards, 
sweepstakes, contests, or similar 121connects promotions. 
To improve our service 
We may use your personal data for auditing, data analysis, and research to improve 
121connects’ products, content and services. 
To present offers that may be of interest to you 
We may contact you to offer you additional 121connects or third-party products, 
content or services that may be of interest to you.      </p>

<p>{" "}
<h5>
THE LEGAL BASIS WE HAVE FOR PROCESSING YOUR PERSONAL 
DATA ​</h5>

121connects uses the personal data that you provide to supply you with high-quality 
products, content and services as you request, to send important notices, and for 
internal purposes such as auditing, data analysis, and research to provide you with 
the information you need to make the most informed technology purchasing 
decisions. 
We may also use your personal data to offer you products, content or services that 
may be of interest to you, based upon your interests or the preferences you shared 
with us, and may share your personal data with third party sponsors of content, 
events, and other services or offers. 
The legal basis for processing your personal data is your consent. In certain 
circumstances we may otherwise process your personal data if 121connects has a 
 
 
legitimate interest in doing so and 121connects is not infringing any of your rights 
and freedoms. 
When 121connects processes your personal data for 121connects’ legitimate 
interests, 121connects will consider and balance any potential impact on you and 
your rights under data protection and any other relevant law. Our legitimate business 
interests do not override your interests. 121connects will not use your personal data 
in circumstances where your rights and freedoms override our legitimate interests, 
unless we have your consent or are otherwise required or permitted to by law. 
</p>
<p>{" "}
<h5>
WHEN AND HOW WE SHARE YOUR PERSONAL DATA WITH OTHERS ​</h5>

121connects may share your personal data to deliver content and services from our 
sites, affiliated companies and third parties services that might interest you, including 
sponsored content and events, for business operations, and to comply with valid 
legal processes. 
For content and services that may interest you or that you request 
Your personal data may be shared to provide you with information about products, 
content and services that might interest you and for internal analytical and business 
development purposes. We may also share your personal data with third parties to 
deliver third party sponsored content or other services that you request (see “third 
party sponsored content and events” below). 
For business operations 
We may also share your personal data with third parties we have contracted with to 
support 121connects’ business operations including delivery, fulfilment, payment 
processing, email deployment, suppression list management and data processing. 
For third party sponsored content and events 
121connects partners with third party sponsors to make available a large library of 
 
 
content to our users, such as white papers, professional events (live or online), as 
well as other services or offers. In return for access to such offers, we may ask you 
to provide us with personal data as part of the registration. 
We may use your personal data to send you the offer you request. In addition, your 
personal data may be shared with the sponsor(s) of the offer to communicate with 
you regarding the offer, as well as additional products, content or services that may 
be of interest to you. Please note that once your personal data is shared with the 
sponsor, the sponsor’s privacy policy, including information on how to opt-out in the 
future, will apply to your personal data. 
As required by law 
Your personal data may be shared when authorized by law or necessary to comply 
with a valid legal process. 
In the event of new ownership 
If part or all the ownership of 121connects or any of its products or services are sold 
or transferred, your personal data will be transferred to the new owner.
  </p>
  <p>{" "}
  <h5>
INTERNATIONAL DATA TRANSFERS </h5>

If 121connects shares your personal data with any other third party as described in 
this privacy policy and your personal data will be transferred to a State which is not a 
Member State of either the European Union or the EEA, or deemed adequate by the 
European Commission, 121connects (as a data controller/data exporter) will only 
conduct such transfer (to a data processor/data importer) if there are suitable 
safeguards in place, such as binding corporate rules, standard contractual clauses, 
approved Codes of Conduct, or approved certification mechanism.  For more 
information, please contact <a href='https://privacy@121connects.com'>privacy@121connects.com </a>  </p>
<p>{" "}
<h5>
HOW LONG WE KEEP YOUR PERSONAL DATA ​</h5>

 
 
We retain your personal data for the duration of your business relationship with us, 
and we hold your information only as long as necessary for each purpose we use it, 
unless we have obtained your consent to use for another or similar purpose.  </p>
<p>{" "}
<h5>
YOUR RIGHTS AND PREFERENCES ​</h5>

If you inform us that you want your personal data erased, or that you no longer wish 
for us to communicate with you for marketing purposes, or to otherwise restrict 
121connects’ processing of your personal data, we may retain some basic 
information in order to avoid sending you unwanted materials in the future, and to 
keep a record of your request and our response. Please send an email to: 
<a href='https://privacy@121connects.com'>privacy@121connects.com </a>
 </p>
 
 <p>{" "}
 <h5>
CHANGES TO THIS POLICY ​</h5>


From time to time, we may change and/or update this Policy. If this Policy changes 
in any way, we will post an updated version on this website. We recommend you 
regularly review this website to ensure that you are always aware of our information 
practices and any changes to such. Any changes to this Policy will go into effect on 
posting to this page. 
  </p>
 

    <p>{" "}Last Updated: [15 August 2021 version PP-1.0]
</p>
            </div>
          </div>

         
        </div>
      </div>
      <Image className="bp" src={pattern} />
    </LoginDiv>
  );
});

export default Privacy;
