import React from "react";
import { observer } from "mobx-react-lite";
// import { Form, Image } from "react-bootstrap";
// import Fuse from "fuse.js";
import { useStore } from "../../../store";
// import { SearchBar } from "../elements/styles";
import ProfileCard from "./ProfileCard";
// import search from "../../../assets/icons/search.svg";

const ProfileFilter = observer(({ desktop }) => {
  const { searchStore } = useStore();
  const { profiles } = searchStore;
  // const [data, setData] = useState(profiles);

  // const searchData = (pattern) => {
  //   if (!pattern) {
  //     setData(profiles);
  //     return;
  //   }
  //   const fuse = new Fuse(data, {
  //     keys: ["name", "subprofiles.name"],
  //   });

  //   const result = fuse.search(pattern);
  //   const matches = [];
  //   if (!result.length) {
  //     setData([]);
  //   } else {
  //     result.forEach(({ item }) => {
  //       matches.push(item);
  //     });
  //     setData(matches);
  //   }
  // };

  return (
    <div>
      {/* <SearchBar onChange={(e) => searchData(e.target.value)}>
        <Form.Control
          type="search"
          id="search-tablet-industry"
          data-testid="search-input-tablet"
          className="search-filter"
          placeholder="Type to search"
          aria-label="Search"
          aria-describedby="basic-addon1"
        />

        <button className="search-button" type="submit">
          <Image src={search} />
        </button>
      </SearchBar> */}
      {profiles.map((item) => {
        if(item.name ==='OTHERS'){
          return <></>
        } else{
        return <ProfileCard item={item} key={item.name} desktop={desktop} />;
      }})}
    </div>
  );
});

export default ProfileFilter;
