import styled from 'styled-components';

export const CartDiv = styled.div`
  position: relative;
  padding: 11px;
  padding-bottom: 58px;

  .glass {
    background: rgba(235, 255, 254, 0.2);
    margin: 100px auto 0 auto;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding-bottom: 10px;
    max-width: 1280px;
    min-height: 85vh;
    @media only screen and (min-width: 992px) {
      padding: 28px 0px 10px 0px;
      min-height: 0;
      border: 0;
      background: 0;
      margin: 0 auto;
    }
  }

  .glass-header {
    max-width: 1280px;
    height: 188px;
    background: rgba(235, 255, 254, 0.2);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    position: relative;
    margin-bottom: -188px;
    z-index: -1;

    .wave-design {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: -1;
    }
  }

  .holder {
    position: relative;
    @media only screen and (min-width: 992px) {
      max-width: 914px;
      margin: 38px auto 0 auto;
    }
  }

  .back-button {
    background: transparent;
    color: #00ffc2;
    border: 0;
    font-weight: 500;
    position: absolute;
    top: -60px;
    left: 24px;

    img {
      margin-right: 9.5px;
    }
    @media only screen and (min-width: 992px) {
      top: -73px;
    }
  }

  .page-title {
    color: #fff;
    margin: 26px 0 18px 26px;
    @media only screen and (min-width: 992px) {
      margin: 0px 0 18px 24px;
    }
  }

  .meeting-form {
    background: #fff;
    padding: 36px 29px 100px 29px;
    border-radius: 5px;

    position: relative;

    @media only screen and (min-width: 992px) {
      padding: 51px 57px;
    }

    .sm-wave {
      position: absolute;
      top: -110px;
      right: -10px;
      z-index: -1;
    }

    .back-design {
      position: absolute;
      left: -180px;
      bottom: -200px;
      z-index: -1;
    }
  }

  .btn-default {
    padding: 12px 30px;
    width: fit-content;
    font-size: 14px;
    margin-top: 16px;
    height: fit-content;
  }

  .form-control {
    color: #403c3c;
    background: #ebf4f9;
    padding: 0.5em 1em;
    font-size: 16px;
  }

  .form-control:focus {
    box-shadow: none;
    border: 1px solid #48bcd177;
  }

  .form-label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #666666;
  }

  .form-group {
    .date-selector {
      max-width: 267px;
      margin-bottom: 40px;
      position: relative;
    }

    .rdrCalendarWrapper {
      position: absolute;
      z-index: 1;
      top: 40px;
      left: 0px;
      border: 0.5px solid #ced4da;
      border-radius: 5px;
      width: 100%;
    }

    .rdrDateDisplayWrapper {
      display: none;
    }
    .rdrDateDisplayItemActive {
      border-color: #00aeae;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber {
      span {
        color: #000000de;
      }
    }

    .rdrInRange {
      background: #00aeae1f;
    }
    .rdrStartEdge,
    .rdrEndEdge {
      background: #00aeae;
    }
  }

  .designation {
    color: #8c97ac;
    margin-top: 25px;
    font-size: 13px;
    font-style: italic;
  }
`;
