import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Image } from "react-bootstrap";
import { useStore } from "../../../store";
import { SelectedDiv, ChipText } from "../elements/styles";
import close from "../../../assets/icons/remove.svg";
import up from "../../../assets/icons/dropup-arrow.svg";

const Selected = observer(() => {
  const [filterStyle, setFilterStyle] = useState(true);
  const { searchStore } = useStore();
  const {
    rangeChecked,
    selectedRegionList,
    onRegionListChange,
    onTrangeListChanged,
    onIndustryListChanged,
    industryL2List,
    onRoleListChanged,
    onHrangeListChanged,
    selectedHRangeList,
    selectedManuList,
    onInterListChanged,
    selectedInterObject,
    onManuListChanged,
    selectedCitiesList,
    onCityListChanged,
    clearAllFilters,
    listSearchResults,
    roleL2List,
    profileL1List,
    profileL2List,
    onProfileListChanged,
    industryL1List,
    selectedInterestList,
    onInterestListChanged,
    roleL1List,
  } = searchStore;

  const handleRegionChange = (item) => {
    let target = {};
    target.value = item;
    onRegionListChange(target);
    listSearchResults();
  };

  const handleRangeChange = (item) => {
    let target = {};
    target.value = item;
    onTrangeListChanged(target);
    listSearchResults();
  };

  const handleIndustryChange = (item, parent) => {
    onIndustryListChanged(item, false, parent);
    listSearchResults();
  };

  const handleRoleChange = (item, parent) => {
    onRoleListChanged(item, false, parent);
    listSearchResults();
  };

  return (
    <SelectedDiv className="d-flex">

      <div
        className={`d-flex flex-wrap ${
          filterStyle === true ? "filter-active" : "filter-objects"
        } col-9 col-lg-10`}
      >
         <div className='filter-count d-none d-lg-block'>
                  {selectedRegionList?.length +
                    industryL2List?.length +
                    selectedHRangeList?.length +
                    selectedManuList?.length +
                    selectedInterObject?.length +
                    selectedCitiesList?.length +
                    roleL2List?.length +
                    profileL1List?.length +
                    profileL2List?.length +
                    industryL1List?.length +
                    selectedInterestList?.length +
                    rangeChecked?.length +
                    roleL1List?.length}{' '}
                  filters applied
                </div>

                <div className=' tag-div'>

         {selectedInterestList?.length > 0 && (
           <p className='tags'> Interest :</p>
         )}
        <div className='tags-content'>
        {selectedInterestList?.map((item) => (
          <ChipText key={item}>
            {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => {
                  let target = {};
                  target.value = item;
                  onInterestListChanged(target);
                  listSearchResults();
                }}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
        </div>
        </div>

        <div className=' tag-div'>
        {roleL1List?.length > 0  && (
          <p className='tags'> Responsibility :</p>
        )}
      
      <div className='tags-content'>
        {roleL1List?.map((item) => (
          <ChipText key={item}>
            {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => handleRoleChange(item, true)}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
        </div>
       </div>

       <div className=' tag-div'>
       {roleL2List?.length > 0  && (
          <p className='tags'> Responsibility : </p>
        )}

        <div className='tags-content'>
        {roleL2List?.map((item) => (
          <ChipText key={item}>
            {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => handleRoleChange(item)}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
        </div>
 </div>

                <div className=' tag-div'>
                {industryL1List?.length > 0 && (
                  <p className='tags'>Industry :</p>
                )}

<div className='tags-content'>
         
        {industryL1List?.map((item) => (
         
         
          <ChipText key={item}>
            {item === "OTHERS" ? "INDUSTRY - OTHERS" : item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => handleIndustryChange(item, true)}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
          
        ))}
        </div>
        </div>
        {industryL2List?.map((item) => (
          <ChipText key={item}>
            {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => handleIndustryChange(item)}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
         
         <div className=' tag-div'>
         {rangeChecked?.length > 0 && (
           <p className='tags'> Turnover range :</p>
         )}

<div className='tags-content'>
        {rangeChecked?.map((item) => (
          <ChipText key={item}>
            {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => handleRangeChange(item)}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
        </div>
        </div>

        
        {selectedRegionList?.map((item) => (
          <ChipText key={item}>
            {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => handleRegionChange(item)}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}

<div className=' tag-div'>
        {selectedHRangeList?.length > 0 && (
          <p className='tags'> Headcount range :</p>
        )}

<div className='tags-content'>

        {selectedHRangeList?.map((item) => (
          <ChipText key={item}>
             {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => {
                  let target = {};
                  target.value = item;
                  onHrangeListChanged(target);
                  listSearchResults();
                }}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
        </div>
        </div>

<div className=' tag-div'>
        {selectedCitiesList?.length > 0 && (
          <p className='tags'> City-Region : </p>
        )}

<div className='tags-content'>
        {selectedCitiesList?.map((item) => (
          <ChipText key={item}>
            {item === "OTHERS" ? "CITY - OTHERS" : item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => {
                  let target = {};
                  target.value = item;
                  onCityListChanged(target);
                  listSearchResults();
                }}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
        </div>
       </div>
         
       

       

        {selectedManuList?.map((item) => (
          <ChipText key={item}>
            {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => {
                  let target = {};
                  target.value = item;
                  onManuListChanged(target);
                  listSearchResults();
                }}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}

        {selectedInterObject?.map((item) => (
          <ChipText key={item.value}>
            {item.label}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => {
                  onInterListChanged(item);
                  listSearchResults();
                }}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
        {profileL1List?.map((item) => (
          <ChipText key={item}>
            {item === "OTHERS" ? "PROFILE - OTHERS" : item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => {
                  onProfileListChanged(item, false, true);
                  listSearchResults();
                }}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}
        {profileL2List?.map((item) => (
          <ChipText key={item}>
            {item}
            <span>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={() => {
                  onProfileListChanged(item, false);
                  listSearchResults();
                }}
              >
                <img alt="close" src={close} />
              </button>
            </span>
          </ChipText>
        ))}

{(selectedRegionList?.length > 0 ||
        industryL2List?.length > 0 ||
        selectedHRangeList?.length > 0 ||
        selectedManuList?.length > 0 ||
        selectedInterObject?.length > 0 ||
        selectedCitiesList?.length > 0 ||
        roleL2List?.length > 0 ||
        profileL1List?.length > 0 ||
        profileL2List?.length > 0 ||
        industryL1List?.length > 0 ||
        selectedInterestList?.length > 0 ||
        rangeChecked?.length > 0 ||
        roleL1List?.length > 0) && (

        <>
        {filterStyle && (
          <ChipText
            onClick={() => setFilterStyle(false)}
            style={{ cursor: "pointer" }}
          >
            <Image src={up} />
          </ChipText>
        )}
        </>
        )}
      </div>
      {(selectedRegionList?.length > 0 ||
        industryL2List?.length > 0 ||
        selectedHRangeList?.length > 0 ||
        selectedManuList?.length > 0 ||
        selectedInterObject?.length > 0 ||
        selectedCitiesList?.length > 0 ||
        roleL2List?.length > 0 ||
        profileL1List?.length > 0 ||
        profileL2List?.length > 0 ||
        industryL1List?.length > 0 ||
        selectedInterestList?.length > 0 ||
        rangeChecked?.length > 0 ||
        roleL1List?.length > 0) && (
        <>
          {!filterStyle && (
            <ChipText
              style={{ cursor: "pointer" }}
              onClick={() => setFilterStyle(true)}
              id='expand'
            >
              ...
            </ChipText>
          )}

          <Button
            variant="link"
            onClick={() => {
              clearAllFilters();
              listSearchResults();
            }}
          >
            Clear all
          </Button>
        </>
      )}
    </SelectedDiv>
  );
});

export default Selected;
