import styled from 'styled-components';

export const CompanyDiv = styled.div`
  position: relative;
  padding: 11px;
  padding-bottom: 58px;

  .glass {
    @media only screen and (min-width: 992px) {
      margin-top: -74px;
    }
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    padding-bottom: 10px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 992px) {
      position: relative;
      margin-top: -8px;
      border: 1px solid rgba(255, 255, 255, 0.7);
      background: rgba(235, 255, 254, 0.2);
    }
  }
  .profile-data {
    margin-top: 42px;
  }

  .glass-design {
    z-index: -1;
    margin-top: -8px;
    position: relative;
    margin-bottom: -180px;
    max-width: 1280px;
    height: 181px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    border-radius: 10px;
  }

  .wave-design {
    position: absolute;
    right: 1px;
  }

  .bio-description {
    cursor: pointer;
    font-size: 10px;
    width: 30px;
    height: 30px;
    background: rgba(0, 174, 174, 0.1);
    padding: 10px 14px 12px 14px;
    border-radius: 50%;
  }
  .turnover {
    margin-top: 29px;
    margin-left: -85px;
    margin-right: 35px;
  }
  .turnover-range {
    margin-top: 29px;
    margin-left: -111px;
  }
  .turn-num {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-left: 1px;
    color: #1d293f;
  }

  .turn-name {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #8c97ac;
  }

  .left-section {
    @media only screen and (min-width: 992px) {
      margin-top: 25px;
      width: 30px;
      height: 0px;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      margin-top: 25px;
      border: 0.5px solid #c0c0c0;
    }
  }

  .added {
    background: #00ffc266;
  }

  .disabled {
    background: #e1fbf5;
  }

  .back-button {
    background: transparent;
    color: #00ffc2;
    border: 0;
    font-weight: 500;
    margin: 0px 28px 17px 15px;

    img {
      margin-right: 9.5px;
    }
    @media only screen and (min-width: 992px) {
      margin: 11px 0px 15px 80px;
      margin-top: 1px;
      margin-left: 1px;
    }
  }

  h2 {
    color: #32e0e0;
    background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 18px;
  }

  .company-info {
    background: #fff;
    padding: 32px 28px 46px 15px;
    border-radius: 5px;

    .company-image {
      display: flex;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 2px solid #f4f4f4;
      p {
        margin: auto;
        font-size: 15px;
      }
    }

    .company-logo {
      max-height: 95px;
    }

    h3 {
      margin-top: 3px;
      line-height: 27px;
    }

    .tags {
      margin-top: 6px;
    }
    .tags > .interest ~ .interest {
      margin-left: 6px;
    }

    .classifications {
      color: #39a6c9;
      font-weight: 600;
      font-size: 12px;
      margin-top: 13px;
      flex-wrap: wrap;
      p {
        height: fit-content;
      }
      @media only screen and (min-width: 992px) {
        margin-top: 8px;
      }
    }

    .classifications > p ~ p {
      padding-left: 10px;
      margin-left: 10px;
      border-left: 0.5px solid #c0c0c0;
    }

    @media only screen and (min-width: 992px) {
      margin: 0px 0px 12px 18px;
      padding: 40px 0px 40px 50px;

      h3 {
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 140%;

        color: #333333;
        margin-top: 6px;
      }

      .tags {
        margin-top: 20px;
        color: #666666;
      }
      .company-image {
        width: 95px;
        height: 95px;
        p {
          font-size: 35px;
        }
      }

      .classifications {
        font-size: 16px;
      }
    }
  }
  .back-button {
    margin-top: 77px;
  }

  .members {
    background: #fff;
    padding: 44px 28px 52px 23px;
    border-radius: 5px;
    margin-top: 11px;
    position: relative;

    .meeting-type {
      font-size: 14px;
      color: #666666;
      margin-top: -6px;

      .dropdown-toggle {
        :after {
          display: none;
        }
      }
      button,
      button:focus {
        padding: 0;
        background: transparent;
        border: 0;
        color: #00aeae;
        font-size: 14px;
        box-shadow: none;
      }
      .dropdown {
        padding-left: 11px;
      }
      .active {
        background: #bbffef;
      }

      .dropdown-menu {
        padding: 0;
        border: 0.5px solid #cdeaf0;
        box-shadow: 3px 4px 20px 0px #0000001a;

        .dropdown-item {
          padding: 17px 0px 11px 15px;
          color: #333333;
        }
      }
      @media only screen and (max-width: 992px) {
        font-size: 10px;
        button,
        button:focus {
          font-size: 10px;
        }
      }
    }

    .back-design {
      position: absolute;
      bottom: -220px;
      left: -150px;
      z-index: -1;
    }

    .member-card {
      padding: 20px 0px 20px 0px;
    }

    @media only screen and (min-width: 992px) {
      .member-list {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        .member-card {
          padding-right: 20px;
        }

        .member-card:first-child {
          padding-top: 0;
        }

        .member-card:last-child {
          padding-bottom: 0;
        }

        .left-members {
          border-left: 1px solid #7e7e7e33;
          .member-card {
            padding-left: 20px;
            padding-right: 0;
          }
        }
      }
    }

    .member-card {
      @media only screen and (max-width: 992px) {
        border-bottom: 0.5px solid #b9b9b9;
      }
      .member-info {
        margin-left: 9px;

        p {
          font-size: 14px;
          color: #666666;
          margin-bottom: 0;
        }
      }
      .connect {
        margin-top: 8px;
        padding: 7px 10px;
        font-size: 10px;
        font-weight: 700;
        width: 74px;
        margin-right: 10px;
        @supports (-webkit-touch-callout: none) {
          display: flex;
        }
      }

      .member-info-details {
        cursor: pointer;
        width: 70%;
        h4 {
          margin-bottom: 0;
        }
      }
    }

    .member-image {
      display: flex;
      width: 47px;
      height: 47px;
      border: 2px solid #eaedf3;
      p {
        margin: auto;
        font-size: 15px;
      }
    }
    @media only screen and (min-width: 992px) {
      margin: 0px 0px 12px 18px;
      padding: 40px 50px 40px 50px;
    }
  }
  .username {
    border-radius: 50%;
    text-transform: uppercase;
    font-weight: 600;
  }

  .comp-header {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 140%;
    /* or 39px */

    color: #333333;
  }

  .company-details {
    background: #fff;
    padding: 44px 28px 52px 23px;
    border-radius: 5px;
    margin-top: 11px;

    .ranges {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      column-gap: 30px;
    }

    .address {
      color: #8c97ac;
      font-size: 12px;
      h5 {
        color: #111;
        text-transform: capitalize;
      }
    }

    .range-item {
      border-right: 0.5px solid #c0c0c0;
      color: #8c97ac;
      font-size: 12px;
      h5 {
        color: #111;
      }
    }

    .ranges > .range-item ~ .range-item {
      border: 0;
    }

    @media only screen and (min-width: 992px) {
      margin: 0px 0px 12px 80px;
      padding: 40px 0px 40px 50px;
    }
  }
  .company-url {
    @media only screen and (min-width: 992px) {
      margin-left: 886px;
      margin-top: -566px;
      margin-bottom: 461px;
    }
    @media only screen and (max-width: 992px) {
      background: #fff;
      padding: 44px 28px 52px 23px;
      border-radius: 5px;
      margin-top: 11px;
      position: relative;
    }
  }

  #member1 {
    @media only screen and (min-width: 992px) {
      margin-top: 183px;
      margin-left: 6px;
      background: none;
    }
  }
  .waves {
    position: absolute;
    right: -3px;
    top: -66px;
  }

  .turn-class {
    margin-right: 4px;
    width: 146px;
  }

  .less {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  .c-link {
    white-space: pre;
  }
  .class {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #39a6c9;
    @media only screen and (max-width: 992px) {
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #39a6c9;
    }
  }
  .interest {
    margin-right: 5px;
    @media only screen and (min-width: 992px) {
      margin-right: 19px;
    }
  }
  .chatmeet-btn {
    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
  .chat-img {
    width: 15px;
    margin-right: 5px;
  }
`;
