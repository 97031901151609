import styled from "styled-components";

export const FooterDiv = styled.div`
  padding: 0px 50px 50px 50px;
  font-size: 14px;
  color: #8c97ac;

  .footer {
    border-top: 0.5px solid #8c97ac50;
    /* border-bottom: 0.5px solid #8c97ac50; */
    padding-top: 46px;
    padding-bottom: -32px;
    margin-bottom: -91px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;

    
  }

  .button-group {
    padding-right: 10px;
  }

  #privacy {
    border-right: 0.5px solid #8c97ac50;
  }
  #terms{
    border-right: 0.5px solid #8c97ac50;
  }
  #contact{
    border-right: 0.5px solid #8c97ac50;
  }
  .link {
    background: transparent;
    border: 0;
    color: #8c97ac;
    padding-right: 10px;
    padding-left: 10px;
  }
  .icons{
    margin-left:29px;
    cursor:pointer;
  }
  .twitter-logo{
    margin-left:9px;
    cursor:pointer;
  }
  .linked-logo{
    margin-left:11px;
    cursor:pointer;
  }
  .footer-logo{
    margin-top: -27px;
  }
  .footer-div{
    display: flex;
    /* justify-content: flex-end; */
    /* flex-wrap: wrap; */
    margin-left: 300px;
  }
`;
