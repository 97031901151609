import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Button, Image } from "react-bootstrap";
// import { Typeahead } from "react-bootstrap-typeahead";
import { useStore } from "../../../store";
import { ProfileFormDiv } from "../elements/styles";
import close from "../../../assets/icons/close.svg";

const InterestForm = observer(({ user, show, setEdit, interests }) => {
  const [multiSelections, setMultiSelections] = useState(user.interests);
  const { authStore } = useStore();

  const { onEditProfile } = authStore;

  const onSubmit = () => {
    let data = {};
    data.interests = multiSelections;
    onEditProfile(data);
    setEdit(false);
    
  };

  const onChange = (e, item) => {
    let interestfinal = [...multiSelections];
    const index = interestfinal.findIndex(
      (interest) => interest.id === item.id
    );
    if (e.target.checked && index < 0) {
      interestfinal.push(item);
    }
    if (!e.target.checked && index >= 0) {  
      interestfinal.splice(index, 1);
    }
    setMultiSelections(interestfinal);
  };

  return (
    <ProfileFormDiv show={show}>
      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h4>Edit your profile</h4>
          <h2>Areas of interest</h2>
        </div>

        <button onClick={() => {setEdit(false); setMultiSelections(user.interests)}} className="close-button">
          <Image src={close} alt="close" className="close" />
        </button>
      </div>

      <Form className="typeahead-form">
        <Form.Group>
          {interests.map((item) => {
            return (
              <Form.Check
                key={item.name}
                label={item.name}
                value={item.name}
                checked={multiSelections?.find(
                  (element) => element.id === item.id
                )}
                onChange={(e) => onChange(e, item)}
              />
            );
          })}

          {/* <Typeahead
            options={interests}
            id="interest-typehead"
            labelKey="name"
            placeholder="Choose your interests"
            onChange={setMultiSelections}
            multiple
            selected={multiSelections}
          /> */}
        </Form.Group>

        <Button
          className="btn-default"
          type="button"
          onClick={() => onSubmit()}
        >
          Save
        </Button>
      </Form>
    </ProfileFormDiv>
  );
});

export default InterestForm;
