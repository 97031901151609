import React from "react";
import { Image } from "react-bootstrap";
import { PrivacyModal } from "./styles";
import close from "../../assets/icons/close.svg";

const PrivacyPolicy = ({ show, setShow }) => {
  return (
    <PrivacyModal show={show}>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h2>Privacy Policy</h2>
        </div>
        <button onClick={() => setShow(false)}>
          <Image src={close} />
        </button>
      </div>
      <h5>Welcome</h5>
      <p>
        Stripe provides economic infrastructure for the internet. Businesses of
        all sizes use our software and services to accept payments and manage
        their businesses online. Stripe cares about the security and privacy of
        the personal data that is entrusted to us.
      </p>
      <p>
        {" "}
        This policy describes the Personal Data that we collect, how we use and
        share it, your rights and choices, and how you can contact us about our
        privacy practices.
      </p>
      <p>
        {" "}
        Stripe (“Stripe”, “we”, “our” or “us”) provides its services globally
        and the Stripe entity responsible for the collection and use of personal
        data under this Privacy Policy differs depending on your country. We may
        collect and use personal data when we do business with you or when you
        do business with those that use our services. Some of our services may
        be accessed directly by you, including through our websites that
        reference this policy (e.g. stripe.com) (collectively “Sites”).
      </p>{" "}
      <p>
        {" "}
        Many of our services are provided to others in connection with their own
        business and activities, and you may engage with Stripe services as part
        of another’s service, such as when you make a payment to a merchant and
        we provide the payment processing services to that merchant through
        Stripe Checkout (collectively, we refer to Sites and direct and indirect
        services as “Services”).
      </p>
      <p>
        {" "}
        This policy applies to Stripe’s own Services. Websites, products and
        services of third-parties and some affiliates of Stripe are subject to
        their own separate privacy policies.
      </p>
    </PrivacyModal>
  );
};

export default PrivacyPolicy;
