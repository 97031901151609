import styled from "styled-components";

export const SuccesspageDiv = styled.div`
  margin: 0 auto 0 auto;
  padding: 80px 18px 45px 17px;
  max-width: 600px;
  position: relative;

  .tr {
    position: absolute;
    top: -5px;
    right: 0;
  }

  .verify-logo {
    position: absolute;
    top: 6px;
    right: 17px;
    z-index: 1;
  }

  .logo {
    margin: 40px 0px 30px 40px;
  }
  .newlogo1{
    margin-left: 73px;
    /* margin-top: -37px; */
    margin-bottom: -80px;


  }

  .glass {
   
    padding: 0;
    margin: 0;
    border-radius: 5px;
  
    margin-top: -98px;
    margin-left: -19px;
    @media only screen and (max-width:600px){
      margin-top: -63px;
      margin-left:2px;
    }
    @media only screen and (min-width:900px){
      margin-top: -124px;
    } 
  }
  .wave-design{
    margin-left: 106px;
    margin-top: -71px;
    @media only screen and (max-width:600px){
      width: 335px;
      height: 237px;
     margin-left:19px;
     margin-top: -79px;
    }
   

    @media only screen and (min-width:900px){
      margin-left: -181px;
        width: 335px;
     height: 237px;
     margin-top: -89px;
    }
  }

  .content-card {
    
    min-height: 400px;
    background: white;
    padding: 10.3em 2em 2em 2em;
    margin: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    
    margin-top: -59px;
    @media only screen and (min-width: 920px) {
      padding-left: 5em;
    }
    @media only screen and (max-width: 600px){
      min-height: 484px;
    background: white;
    padding: 10.3em 2em 2em 2em;
    margin: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -59px;
    }

    small {
      font-size: 10px;
    }

    .success-info {
      margin-bottom: 36px;
      i {
        color: #27d3db;
        margin-right: 5px;
      }
    }

    h2 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    form {
      width: 100%;
    }
  }
  .terms1{
    border:none;
   
    border: none;
    background: transparent;
    color: #fff;
  
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
    font-size: 11px;
  }
}
.privacy{
  border:none;
    position: absolute;
    border: none;
    background: transparent;
    color: #fff;
    margin-left: 149px;
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
    font-size: 11px;
    margin-left: 130px;
  }
}
.cookie{
  border:none;
    position: absolute;
    border: none;
    background: transparent;
    color: #fff;
    margin-left: 257px;
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
    font-size: 11px;
    margin-left: 231px;
  }
}
  .form-control {
    margin: 0.5em 0 0.3em 0;
    background: #ebf4f9;
    padding: 0.5em 1em;
    border: 1px solid #cdeaf0;
    border-radius: 5px;
    height: 45px;
    font-size: 1em;
    color: #403c3c;
    @media only screen and (min-width: 500px) {
      width: 350px;
    }
  }

  .form-control:focus {
    box-shadow: none;
    border: 1px solid #48bcd177;
  }

  .moberror {
    border: 1px solid #ff647f !important;
  }

  .error-info {
    color: #ff647f;
    font-size: 14px;
  }

  .code-data {
    color: #8c97ac;
    margin-bottom: 12px;
    width: 219px;
    font-size: 16px;
    margin-left: 2px;
    .alert {
      width: fit-content;
      .sr-only {
        display: none;
      }
      .close {
        border: 0;
        background: transparent;
      }
      span {
        color: #111;
      }
    }
    @media screen and (min-width: 576px) {
      width: 284px;
    }
  }

  .arrow {
    color: #1ec6c6;
  }

  span {
    color: #fff;
  }

  .code-entry {
    font-size: 14px;
  }

  .code-resend {
    background-color: #fff;
    color: #00aeae;
    font-weight: 600;
    border: none;
    font-size: 14px;
    padding: 5px 2px 8px 0px;
  }

  .otp-input {
    background: #ebf4f9;
    width: 52px !important;
    height: 52px;
    border: 0;
    border: 0.5px solid #cdeaf0;
    border-radius: 50%;
    font-size: 33px;
    padding-bottom: 7px;
    color: #111;
  }

  .otp-content {
    width: 245px;
    justify-content: space-between;
  }

  .otp-focus {
    outline: none;
  }

  i,
  .spinner {
    width: 16px;
    height: 16px;
  }

  .status-verification-text {
    margin-top: 7px;
    margin-bottom: 16px;

    height: 20px;
    color: #8c97ab;

    .danger {
      color: #ff647f;

      p {
        height: 16px;
        align-items: center;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
    .success {
      color: #27d3db;
      align-items: baseline;
    }

    p {
      margin-left: 9px;
    }
  }

  .a_continue {
    font-weight: 600;
    line-height: 27px;
  }

  .a_backButton {
    margin-bottom: 5px;
    cursor: pointer;
  }

  .verify-circle {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #27d3db;
    #checkCircle {
      height: 11px;
      width: 11px;
      margin-left: 1px;
      margin-bottom: 4px;
    }
  }

  .success-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #27d3db;
    #checkCircle {
      height: 10px;
      width: 12px;
      margin-left: 4px;
      margin-bottom: 5px;
    }
  }
  .terms{
    position: absolute;
    width: 568px;
    height: 24px;
    left: 43px;
    top: 578px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 150%;
    color: #FFFFFF;
    white-space: break-spaces;
  }
  .face-logo{
    position: absolute;
    left: 79.72%;
    right: 33.93%;
    top: 88.3%;
    bottom: 20.88%;
    @media only screen and (max-width:600px){
      position: absolute;
      left: 7.72%;
      right: 33.93%;
      top: 91.6%;
      bottom: 20.88%;
    }
  }
  .linked-logo{
    position: absolute;
left: 85.6%;
right: 32.05%;
top: 88.3%;
bottom: 20.88%;
@media only screen and (max-width:600px){
  position: absolute;
  left: 15.6%;
  right: 32.05%;
  top: 91.6%;
  bottom: 20.88%;
  }}
  .twitter-logo{
    position: absolute;
left: 91.1%;
right: 30.18%;
top: 88.3%;
bottom: 20.88%;
@media only screen and (max-width:600px){
  position: absolute;
    left: 23.1%;
    right: 30.18%;
    top: 91.6%;
    bottom: 20.88%;
}

  }
  .icon-div{
   
    @media only screen and (max-width:600px){
        margin-top: 26px;
  }}
  .enddesign{
    position: fixed;
    left: 0;
    bottom: 0;}

  
 
`;