import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ForgotPasswordDiv } from './elements/styles';
import SuccessPage from './components/SuccessPage';
import VerifyMobile from './components/VerifyMobile';
import ChangePassword from './components/ChangePassword';
import background from '../../assets/images/Backgroundnew.svg.svg';

import LogoBlack from '../../assets/images/LogoBlack.png';
import linkedin from '../../assets/icons/Icon-Linkedin.svg';
import wave from '../../assets/images/waveDesign.svg';
import { useStore } from '../../store';

const ForgotPassword = observer(() => {
  const { path } = useRouteMatch();

  const { accountStore } = useStore();

  const { footer, listFooter } = accountStore;

  useEffect(() => {
    listFooter();
  }, [listFooter]);

  return (
    <ForgotPasswordDiv className='min-vh-100 login-page'>
      <Helmet>
        <style>
          {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: left center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh; }`}
        </style>
      </Helmet>
      <img src={wave} alt='' className='wave-design' />
      <div className='glass'>
        <img src={LogoBlack} alt='' className='newlogo' />

        <div className='content-card'>
          <Switch>
            <Route exact path={`${path}/`} render={() => <VerifyMobile />} />
            <Route
              exact
              path={`${path}/change`}
              render={() => <ChangePassword />}
            />
            <Route
              exact
              path={`${path}/success`}
              render={() => <SuccessPage />}
            />
          </Switch>
        </div>
      </div>

      <div className='d-flex'>
        <div className='terms-con'>
          {footer.map((item) => (
            <div className='button-group d-flex'>
              <a href={item.link} target='_blank' rel='noreferrer'>
                <button className='terms' id='terms'>
                  {item.text}
                </button>
              </a>
            </div>
          ))}
        </div>
        <div className='logos d-none  d-lg-block'>
          <a
            href={process.env.REACT_APP_API_LINKEDIN_LINK}
            target='_blank'
            rel='noreferrer'
          >
            <img src={linkedin} className='linked-logo' alt='icon' />{' '}
          </a>
        </div>
      </div>

      <div className='icon-div d-lg-none'>
        <a
          href={process.env.REACT_APP_API_LINKEDIN_LINK}
          target='_blank'
          rel='noreferrer'
        >
          <img src={linkedin} className='linked-logo' alt='icon' />{' '}
        </a>
      </div>
    </ForgotPasswordDiv>
  );
});

export default ForgotPassword;
