import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "react-bootstrap";
// import DateTimePicker from "react-datetime-picker";
import { StyledModal } from "../elements/styles";
import { useStore } from "../../../store";

// import close from "../../../assets/icons/close.svg";

const AcceptModal = observer(({ showAccept, setShowAccept, meeting }) => {
  /*const [acceptDate, setAcceptDate] = useState(new Date());
  const [errorMessage, setError] = useState();*/

  const { meetingStore } = useStore();
 
  const { setMeetings, results } = meetingStore;
  

  
  


  /*
  const onSubmit = () => {
    let data = {};
    if (acceptDate) {
      data.meeting_datetime = acceptDate.toISOString();
      data.status = "ACCEPTED";
      if (list) {
        onChangeMeetingListStatus(uuid, data, "accept");
      } else {
        onChangeMeetingStatus(uuid, data, "accept");
      }
      setError("");
      setShowAccept(false);
    } else {
      setError("This field is required.");
    }
  };*/

  return (
    <StyledModal show={showAccept}>
      {/* <button className="a_closeButton" onClick={() => setShowAccept(false)}>
        <Image src={close} alt="close" />
      </button> */}
      {/* <h3>Choose date &amp; time to continue meeting procedures</h3>
      <div className="pt-1 pb-3">
        <DateTimePicker
          onChange={setAcceptDate}
          value={acceptDate}
          calendarIcon={null}
          clearIcon={null}
        />
        <div className="text-danger">{errorMessage}</div>
        <Button
          type="submit"
          onClick={() => onSubmit()}
          className="btn-default"
        >
          Accept
        </Button>
      </div> */}

{meeting?.initiator_details.user_type === 'Vendor' && meeting?.meeting_type !== '121 MEETING' ? (
    
     
        <>
         <h3>{meeting?.product_engagement_name} accepted</h3>

<div>
Thank you for accepting the meeting. You will be credited with {meeting?.acceptor_points} points after you attend the event.

  </div> 
<Button
  onClick={() => {
    setShowAccept(false);
    setMeetings(results);
  }}
  className="btn-default"
>
  Close
</Button>
        </>
      ):( <> 
        <h3>Meeting accepted</h3>
 
       <div>
       Thank you for accepting the meeting.  121connects team will reach out to you to fix a convenient date and time for the same.
 
         </div> 
       <Button
         onClick={() => {
           setShowAccept(false);
           setMeetings(results);
         }}
         className="btn-default"
       >
         Close
       </Button>
       </>)}
    </StyledModal>
  );
});

export default AcceptModal;
