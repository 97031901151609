import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Image, Button, Form } from 'react-bootstrap';
import Moment from 'react-moment';
import classNames from 'classnames';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import { useForm } from 'react-hook-form';
import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import Footer from '../../components/Footer';
import MobileTopBar from '../../components/MobileTopBar';
import SuccessModal from '../MeetingRequest/components/SuccessModal';
import AddSuccessModal from '../AddPoints/components/SuccessModal';
import { useStore } from '../../store';
import { CartDiv } from './elements/styles';
import bgDesign from '../../assets/images/back-design.svg';
import remove from '../../assets/icons/removecart.svg';
import smWave from '../../assets/images/smwave.svg';
import wave from '../../assets/images/wave1.svg';
import UserHolder from '../../assets/images/profile-holder.svg';
import calendarIcon from '../../assets/icons/Calendar.svg';
import points from '../../assets/icons/points.svg';

const MeetingCart = observer(() => {
  const location = useHistory();

  const [showDate, setshowDate] = useState(false);
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState();
  const [state, setState] = useState([
    {
      startDate: moment().add(1, 'weeks').toDate(),
      endDate: moment().add(2, 'weeks').toDate(),

      key: 'selection',
    },
  ]);
  const [btnDisable, setBtnDisable] = useState(false);
  const { meetingStore, accountStore } = useStore();
  const { cart, onAddToCart, onSendMeetingRequest } = meetingStore;
  const { user } = accountStore;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.request_start_date = moment(state[0].startDate).format('YYYY-MM-DD');
    data.request_end_date = moment(state[0].endDate).format('YYYY-MM-DD');
    setBtnDisable(true);
    onSendMeetingRequest(data, () => {
      setSuccess(true);
    });
  };

  const refDate = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refDate &&
        refDate.current &&
        !refDate.current.contains(event.target)
      ) {
        setshowDate(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
  }, [refDate, setshowDate]);

  return (
    <>
      <MobileTopBar />
      <Navigation />

      <SuccessModal
        show={success}
        meeting={cart?.product_details?.engagement}
      />
      <AddSuccessModal show={show} setShow={setShow} />

      <CartDiv>
        <div className='glass'>
          <div className='glass-design d-none d-lg-block'></div>

          {cart?.product ? (
            <div className='col-lg-8 content '>
              <h2>New {cart?.product_details?.name} </h2>

              <div className='member-list'>
                {cart?.members_details?.map((member) => (
                  <div
                    className='d-flex justify-content-between member-card'
                    key={member.id}
                  >
                    <div
                      className='d-flex'
                      onClick={() =>
                        location.push(
                          `/${member?.first_name
                            ?.toLowerCase()
                            .replace(' ', '-')}-${member?.last_name
                            ?.toLowerCase()
                            .replace(' ', '-')}_${member?.id}_m`
                        )
                      }
                    >
                      <Image
                        src={member.photo ? member.photo : UserHolder}
                        alt='profile-pic'
                        className='profile-image'
                        roundedCircle
                      />
                      <div className='dm-info'>
                        <h6>{member.full_name}</h6>
                        <div className='designation'>
                          {member.title}, <div> {member.company_name}</div>
                        </div>
                      </div>
                    </div>
                    <button
                      className='d-flex align-items-center remove-button'
                      onClick={() => onAddToCart(member, false, false)}
                    >
                      <Image src={remove} className='remove-icon' />
                      Remove
                    </button>
                  </div>
                ))}

                {cart?.members_details?.length > 0 ? (
                  <div>
                    {(user.user_type === 0
                      ? user.points_balance
                      : user.company?.points_balance) >=
                    cart?.product_details?.initiator_points ? (
                      <div className=''>
                        {user?.user_type !== 0 ? (
                          <div className='points-info success d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={points}
                                className='points-image'
                                alt='points'
                              />

                              <div className='points-text'>
                                Points required for this meeting{' '}
                              </div>
                            </div>

                            <div className='points'>
                              <span className='points-num'>
                                {cart?.product_details?.initiator_points}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className='points-info success d-flex justify-content-between'>
                            <div className='dm-points'>
                              Peer meetings do not carry reward points.
                            </div>
                          </div>
                        )}

                        <Form
                          className='meeting-form'
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className='newreq'>
                            <Form.Group>
                              <Form.Label>
                                Add a purpose for this meeting
                              </Form.Label>
                              <Form.Control
                                as='textarea'
                                rows={5}
                                {...register('request_message', {
                                  required: 'This field is required.',
                                })}
                                className={classNames({
                                  danger: errors.request_message,
                                })}
                              />
                              <div className='text-danger'>
                                {errors.request_message &&
                                  errors.request_message.message}
                              </div>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>
                                Select preferred meeting date range.
                              </Form.Label>
                              <div
                                className='date-selector form-control d-flex justify-content-between'
                                onClick={() => setshowDate(true)}
                                ref={refDate}
                              >
                                <div className='date'>
                                  <Moment format='DD MMMM, YYYY'>
                                    {state[0].startDate}
                                  </Moment>{' '}
                                  -{' '}
                                  <Moment format='DD MMMM, YYYY'>
                                    {state[0].endDate}
                                  </Moment>
                                </div>

                                <Image src={calendarIcon} />
                                {showDate && (
                                  <DateRange
                                    onChange={(item) => {
                                      setState([item.selection]);
                                    }}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    ranges={state}
                                    direction='horizontal'
                                    minDate={moment().add(1, 'weeks').toDate()}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </div>
                          {/* <div className='points-message'>
                            This represents your wishlist for attendees. You may
                            add as many as you want or seek 121connects help to
                            identify the best set. You can edit this list later.
                          </div> */}
                          <button
                            className='btn-default w-100'
                            type='submit'
                            disabled={btnDisable}
                          >
                            Send meeting request
                          </button>
                        </Form>
                      </div>
                    ) : (
                      <div className='points-info insufficient d-lg-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-baseline'>
                          <img
                            src={points}
                            className='points-image'
                            alt='points'
                          />

                          <div className='points-text'>
                            <div className='points d-flex justify-content-between'>
                              Points required for this meeting
                              <span>
                                {' '}
                                {cart?.product_details?.initiator_points}
                              </span>
                            </div>

                            <div className='insufficient-info'>
                              Please add{' '}
                              {cart?.product_details.initiator_points -
                                (user.user_type === 0
                                  ? user.points_balance
                                  : user.company?.points_balance)}{' '}
                              more points to place meeting request
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-center mx-auto'>
                          <Button
                            className='btn-default'
                            onClick={() => setShow(true)}
                          >
                            Request to add points
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='col-lg-8 '>
                    <div className='meeting-form'>
                      <h2 className='text-dark text-center mt-5'>
                        Your cart is empty
                      </h2>{' '}
                      <Image src={smWave} className='d-lg-none sm-wave' />
                      <Image
                        src={wave}
                        alt='bg-design'
                        className='wave-design d-none d-lg-block'
                      />
                    </div>
                  </div>
                )}

                <Image
                  src={bgDesign}
                  alt='bg-design'
                  className='back-design d-none d-lg-block'
                />
                <Image src={smWave} className='d-lg-none sm-wave' />
                <Image
                  src={wave}
                  alt='bg-design'
                  className='wave-design d-none d-lg-block'
                />
              </div>
            </div>
          ) : (
            <div className='col-lg-8 content '>
              <div className='member-list'>
                <h2 className='text-dark text-center mt-5'>
                  Your cart is empty
                </h2>{' '}
                <Image
                  src={bgDesign}
                  alt='bg-design'
                  className='back-design d-none d-lg-block'
                />
                <Image
                  src={smWave}
                  className='d-lg-none sm-wave'
                  alt='bg-design'
                />
                <Image
                  src={wave}
                  alt='bg-design'
                  className='wave-design d-none d-lg-block'
                />
              </div>
            </div>
          )}
        </div>
      </CartDiv>
      <Footer />
      <BottomBar />
    </>
  );
});

export default MeetingCart;
