import React from "react";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { Form, Button, Image } from "react-bootstrap";
import { useStore } from "../../../store";
import { ProfileFormDiv } from "../elements/styles";
import close from "../../../assets/icons/close.svg";

const ProfileForm = observer(({ user, show, setEdit }) => {
  const { authStore } = useStore();

  const { onEditProfile } = authStore;

  const { handleSubmit, register, reset } = useForm();

  const onSubmit = (data) => {
    onEditProfile(data);
    setEdit(false);
  };

  return (
    <ProfileFormDiv show={show}>
      <div className="d-flex justify-content-end">
        <button onClick={() => {setEdit(false); reset()}} className="close-button">
          <Image src={close} alt="close" className="close" />
        </button>
      </div>
      <h4>Edit your profile</h4>
      <h2>Primary details</h2>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Form.Group>
          <Form.Label>Salutation</Form.Label>
          <Form.Control
            as="select"
            name="salutation"
            {...register("salutation")}
            defaultValue={user?.salutation}
          >
            {["MR", "MRS", "MS", "DR", "PROF", "CAPT"].map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </Form.Control>
        </Form.Group> */}
        <Form.Group>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            name="first_name"
            defaultValue={user?.first_name}
            {...register("first_name")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            name="last_name"
            defaultValue={user?.last_name}
            {...register("last_name")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            defaultValue={user?.title}
            {...register("title")}
          />
        </Form.Group>

        <Button className="btn-default" type="submit">
          Save
        </Button>
      </Form>
    </ProfileFormDiv>
  );
});

export default ProfileForm;
