import styled from 'styled-components';

export const CardBox = styled.div`
  background: #ffffff;
  height: fit-content;
  border-bottom: 1px solid #7e7e7e33;

  .profile-image {
    width: 47px;
    height: 47px;
    border: 2px solid #eaedf3;
  }
  .username {
    border-radius: 50%;
    text-transform: uppercase;
    font-weight: 600;
  }
  .connect {
    margin-top: 8px;
    padding: 7px 10px;
    font-size: 10px;
    font-weight: 700;
    width: 74px;
    margin-right: 10px;
    @supports (-webkit-touch-callout: none) {
      display: flex;
    }
  }

  .connect:disabled {
    pointer-events: none;
  }

  .added {
    background: #00ffc266;
  }

  h6 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 3px;
    color: #333333;
  }

  .dm-info {
    margin-left: 20px;
    cursor: pointer;
  }

  .designation {
    color: #666666;
    font-size: 12px;
  }

  .company {
    color: #00aeae;
  }

  .disabled {
    background: #e1fbf5;
  }

  @media only screen and (min-width: 992px) {
    .designation {
      font-size: 14px;
    }

    h6 {
      font-size: 18px;
    }
  }
  .chatmeet-btn {
    @media only screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
  .chat-img {
    width: 15px;
    margin-right: 5px;
  }
`;
