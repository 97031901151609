import styled from "styled-components";

export const VerificationText = styled.h6`
  line-height: 21px;
  margin: 23px 0 -15px 0;
  color: #8c97ac;
  font-size: 16px;
  max-width: 350px;
`;

export const ForgotPasswordDiv = styled.div`
  margin: 0 auto 0 auto;
  padding: 76px 18px 45px 17px;
  max-width: 600px;
  border-radius: 0.5rem;
  position: relative;

  .tr {
    position: absolute;
    top: 21px;
    right: 25px;
  }

  .help-text {
    color: #8b949e;
    font-size: 12px;
    max-width: 350px;
    margin-top: 25px;
    margin-bottom: 12px;
  }

  .glass {
  
    padding: 0;
    margin: 0;
    border-radius: 5px;
  
    margin-top: -98px;
    margin-left: -19px;
    @media only screen and (max-width:600px){
      margin: auto;
      /* top: 43px; */
      position: relative;
    }
  }

  .wave-design{
    margin-left: 106px;
    margin-top: -71px;

    @media only screen and (min-width:900px){
      margin-left: -181px;
    }
    @media only screen and (max-width:600px){
      position: absolute;
    width: 335px;
    height: 237px;
    top: 30px;
    right: -16px;
    }
  }

  .logo {
    margin: 40px 0px 30px 40px;
  }
  .newlogo{
    margin-top: 30px;
    margin-left: 80px;
    @media only screen and (max-width:600px){
    margin-bottom: -219px;
    /* top: -24.32%; */
    margin-left: 35px;
    
  }}

  span {
    color: #fff;
  }

  .content-card {
    padding: 173px 39px 86px 39px;
    background: #fff;
    min-height: 400px;
    @media only screen and (min-width: 992px) {
      min-height: 450px;
      padding-left: 80px;
      margin-top: -124px;
    }
  }

  .login-title {
    margin-bottom: 0px;
    color: #32e0e0;
    background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size:28px;
    @media only screen and (max-width:600px){
      font-size:24px;
    }
  }

  .toggle {
    margin-left: 353px;
    margin-top: -37px;
    position: absolute;
  }

  #checkCircle {
    font-size: 29px;
    color: #a1a1a1;
    width: 100%;
  }

  .verify-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #27d3db;
    img {
      height: 10px;
      margin-left: 2px;
      margin-bottom: 3px;
    }
  }

  .success-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #27d3db;
    margin-right: 5px;
    #checkCircle {
      height: 10px;
      width: 12px;
      margin-left: 4px;
      margin-top: 5px;
    }
  }

  i,
  .spinner {
    width: 16px;
    height: 16px;
  }

  .password-verification-text {
    margin-bottom: 30px;
    margin-top: 8px;

    font-size: 12px;

    .failed {
      color: #ff647f;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .success {
      color: #27d3db;
    }

    p {
      margin-left: 9px;
      margin-bottom: 5px;
    }
  }

  .status-verification-text {
    margin-top: 15px;
    height: 20px;
    color: #8c97ab;

    .danger {
      color: #ff647f;

      p {
        height: 16px;
        align-items: center;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
    .success {
      color: #27d3db;
      align-items: baseline;
    }

    p {
      margin-left: 9px;
    }
  }

  .success-title {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
  }

  .success-message {
    font-weight: 300;
    line-height: 21px;
    margin: 10px 0 60px 0;
  }

  .code-data {
    font-size: 13px;
    margin-top: 24px;
    color: #8c97ac;

    .alert {
      width: fit-content;
      .sr-only {
        display: none;
      }
      .close {
        border: 0;
        background: transparent;
      }
      span {
        color: #111;
      }
    }
  }
  .a_codeResend {
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: 4px;
    color: #00aeae;
    font-weight: 600;
    font-size: 14px;
  }

  .status-verification-text {
    font-size: 14px;
    font-weight: 300;
    margin-top: 15px;
    height: 20px;
  }

  a {
    color: #e7e7e7;
  }

  .otp-input {
    background: #ebf4f9;
    width: 52px !important;
    height: 52px;
    border: 0;
    border: 0.5px solid #cdeaf0;
    border-radius: 50%;
    font-size: 33px;
    padding-bottom: 7px;
    color: #111;
  }

  .otp-content {
    width: 245px;
    justify-content: space-between;
  }

  .otp-focus {
    outline: none;
  }

  .a_changeNumber {
    margin-left: 300px;
    margin-top: -40px;
    position: absolute;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
  }

  .a_sendCode {
    font-size: 18px;
    font-weight: 600;
    margin-top: 25px;
  }

  .btn-default {
    max-width: 245px;
    height: 45px;
    padding: 0;
  }

  .form-label {
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 35px;
    color: #797979;
    font-weight: 600;
    @media only screen and (max-width:600px){
      font-size:12px;
    }
  }

  .form-control {
    color: #403c3c;
    background: #ebf4f9;
    padding: 0.5em 1em;
    font-size: 16px;
    height: 50px;
    font-weight: 300;
    @media only screen and (min-width: 500px) {
      width: 350px;
    }
  }

  .form-control:focus {
    box-shadow: none;
    border: 1px solid #48bcd177;
  }

  .formerror {
    border: 1px solid #ff647f !important;
  }

  #continue {
    margin-top: 17px;
  }
  .a_loginResponseText {
    color: #ff647f;
    font-weight: 600;
    margin-top: 20px;
  }

  .a_backButton {
    margin-bottom: 5px;
    cursor: pointer;
  }

  @media screen and (max-width: 576px) {
    .toggle {
      margin-left: 250px;
      margin-top: -32px;
    }
    .a_changeNumber {
      margin-left: 200px;
      margin-top: -35px;
      font-size: 14px;
    }

    .form-control {
      height: 48px;
      font-size: 14px;
    }

    .a_confirm {
      margin-top: 42px;
    }
  }

  .loginForm {
    .toggleButton {
      border: none;
      background: transparent;
      color: #7a7a7a;
      border-right: none;
      font-size: 20px !important;
      margin: 5px 0px 10px -35px;
    }
    #togglePassword {
      width: 18px;
      height: 18px;
      @supports (-webkit-touch-callout: none) {
        margin-left: -18px;
      }
    }
  }

  .terms{
    border:none;
    
    border: none;
    background: transparent;
    color: #fff;
  
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
  font-size: 11px;
}
}
.privacy{
  border:none;
    position: absolute;
    border: none;
    background: transparent;
    color: #fff;
    margin-left: 149px;
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
  font-size: 11px;
  margin-left: 130px;
}
}
.cookie{
  border:none;
    position: absolute;
    border: none;
    background: transparent;
    color: #fff;
    margin-left: 257px;
    margin-top: 12px;
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 16px;
@media only screen and (max-width:600px){
  font-size: 11px;
  margin-left: 231px;
}
}
  .face-logo{
    margin-right: 10px;
    left: 79.72%;
    right: 33.93%;
    top: 91.7%;
    bottom: 20.88%;
    @media only screen and (max-width:600px){
      position: absolute;
      left: 7.72%;
      right: 33.93%;
      top: 81.6%;
      bottom: 20.88%;
    }
  }
  .linked-logo{
    margin-right: 10px;
left: 85.6%;
right: 32.05%;
top: 91.7%;
bottom: 20.88%;
@media only screen and (max-width:600px){
  position: absolute;
  left: 15.6%;
  right: 32.05%;
  top: 81.6%;
  bottom: 20.88%;
  }

  }
  .twitter-logo{
 
left: 91.1%;
right: 30.18%;
top: 91.7%;
bottom: 20.88%;
@media only screen and (max-width:600px){
  position: absolute;
    left: 23.1%;
    right: 30.18%;
    top: 81.6%;
    bottom: 20.88%;
}

  }
  .enddesign{
    position: fixed;
    left: 0;
    bottom: 0;
  }}
  .icon-div{
    
    @media only screen and (max-width:600px){
      margin-top: 117px;
  }}
  .terms-con{
    margin-right: 36px;
    display:flex;
  }
  .logos{
    margin-top: 12px;
  }
`;
