import styled from "styled-components";

export const ProjectDiv = styled.div`
  padding: 11px 10px;
  min-height: 300px;
  position: relative;
  margin-bottom: 90px;

  .glass {
    background: rgba(235, 255, 254, 0.2);
    margin: 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding-bottom: 10px;
    @media only screen and (min-width: 992px) {
      padding: 15px 67px 0px 67px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .logo {
    margin: 40px 0px 30px 24px;
  }

  .back-button {
    background: transparent;
    color: #00ffc2;
    border: 0;
    font-weight: 500;
    margin: 0px 0px 20px 24px;
    img {
      margin-right: 9.5px;
    }
    @media only screen and (min-width: 992px) {
      margin-left: 0px;
    }
  }

  h2 {
    color: #32e0e0;
    background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .project-div {
    background: #fff;
    padding: 33px 18px 52px 22px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .addProjectForm {
    font-size: 12px;

    .form-label {
      margin-top: 10px;
      font-weight: 600;
      color: #666666;

      i {
        color: #b1acac;
      }
    }

    .form-check {
      margin-top: 5px;
    }

    .form-check-input {
      height: 20px;
      width: 20px;
    }

    .form-check-label {
      margin: 5px 0px 0px 13px;
      color: #797979;
      font-weight: 600;
    }

    .form-control {
      background: #ebf4f9;
      padding: 16px 16px;
      border: 1px solid #cdeaf0;
      border-radius: 5px;
      font-size: 1em;
      color: #333333;
    }

    .form-group {
      margin: 0em 0 2em 0;
    }

    .project-status {
      display: flex;
      .form-check {
        margin: 10px 10px 10px 10px;
      }
    }

    @media only screen and (min-width: 992px) {
      .right-div {
        button,
        button:hover {
          padding: 10px 30px;
          font-size: 14px;
          max-width: fit-content;
        }
      }
    }
  }

  .btn-light {
    background: #fff;
    color: #1ec6c6;
    font-weight: 700;
  }
`;

export const ButtonDiv = styled.div`
  padding: 23px 42px 18px 34px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  button,
  button:hover {
    padding: 10px 30px;
    font-size: 12px;
  }

  .btn-light {
    background: #fff;
    color: #1ec6c6;
    font-weight: 700;
  }

  @media only screen and (max-width: 992px) {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    bottom: 70px;
  }
`;
