import { makeAutoObservable } from "mobx";
import { getCompanyDetails } from "../../api/company";

class CompanyStore {
  company = {};

  constructor() {
    makeAutoObservable(this);
  }

  setCompanyDetails = (company) => {
    this.company = company;
  };

  onFetchCompanyDetails = async (id) => {
    const res = await getCompanyDetails(id);
    if (res.status === 200) {
      this.setCompanyDetails(res.data);
    }
  };
}

export default CompanyStore;
