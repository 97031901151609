import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Form, Button, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import { useStore } from "../../store";
import { LoginDiv } from "./elements/styles";

import show from "../../assets/icons/Show.svg";
import background from "../../assets/images/Backgroundnew.svg.svg";
import showtoggle from "../../assets/icons/toggleshow.svg";
import LogoBlack from '../../assets/images/LogoBlack.png'
import linkedin from '../../assets/icons/Icon-Linkedin.svg'
import wave from '../../assets/images/waveDesign.svg'

const Activate = observer(() => {
  
  const { authStore, accountStore } = useStore();
  const { loginResponseText, onLogin, setLoginResponseText } = authStore;
  const regemail = window.location.search.slice(7);
 const { footer, listFooter} = accountStore;

 useEffect(() => {
  listFooter();
}, [listFooter]); 


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(true);


  const onSubmit = (data) => {
    onLogin(data);
    setTimeout(function () {
      setLoginResponseText(null);
    }, 3000);
  };

  const toggleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <LoginDiv className="activate-page">
      <Helmet>
        <style>
          {`body { 
            background: 0; 
            background-image:  url(${background}); 
            background-position: left center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 102vh; }`}
        </style>

      </Helmet>
      <img src={wave} alt='' className='wave-design'/>
      <div className="glass">
      <img src={LogoBlack} alt="" className='newlogo1' />

        <div className="form-hold">

          
          <h2>Activate account</h2>
          <Form className="loginForm" onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
              <Form.Group controlId="emailAddress">
                <Form.Label>Official email</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="email"
                  defaultValue={regemail}
                  className={classNames({
                    danger: errors.email,
                  })}
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group controlId="password">
                <Form.Label>Temporary password</Form.Label>
                <div className="d-flex password-control">
                  <Form.Control
                    size="sm"
                    type={showPassword ? "password" : "text"}
                    className={classNames({
                      danger: errors.password || loginResponseText,
                    })}
                    name="password"
                    autoComplete="on"
                    {...register("password", {
                      required: "This field is required.",
                      minLength: {
                        value: 8,
                        message: "Password should have at least 8 characters.",
                      },
                    })}
                  />

                  <button
                    onClick={() => toggleVisibility()}
                    className="toggleButton"
                    type="button"
                  >
                    <Image
                      src={showPassword ? show : showtoggle}
                      id="togglePassword"
                    />
                  </button>
                </div>
              </Form.Group>
            </Form.Row>
            <div
              id="login-Status-Text"
              className="small mb-0 a_loginResponseText "
            >
              {errors.password && errors.password.message}
              {loginResponseText}

              {errors.email && errors.email.message}
            </div>

            <Button
              variant="light"
              className="btn-default a_activateButton mt-5"
              type="submit"
              id="login_confirm"
            >
              Activate
            </Button>
          </Form>
        </div>
      </div>
      <div className='d-flex'>
      {footer.map((item)=> 
            <div className='button-group d-flex'>
              <a href={item.link}
              target='_blank'
              rel='noreferrer'>
              <button
                className='terms'
                id='terms'
              
              >
               {item.text}
              </button></a>
             
            </div>)}</div>
      
      <div className='icon-div'> 
         <a href={process.env.REACT_APP_API_LINKEDIN_LINK} target="_blank" rel="noreferrer">
            <img  src={linkedin} className='linked-logo'  alt='icon'/> </a>
            </div>
    
    </LoginDiv>
  );
});

export default Activate;
