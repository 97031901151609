import axios from "./axios";

const getProjects = (id) => axios.get(`/users/${id}/project/`);

const getCompanyProjects = (id) => axios.get(`/users/${id}/project/?q=company`);

const addProject = (id, data) => axios.post(`/users/${id}/project/`, data);

const editProject = (id, pid, data) =>
  axios.patch(`/users/${id}/project/${pid}/`, data);

const getProjectDetails = (id, pid) =>
  axios.get(`/users/${id}/project/${pid}/`).then(
    (res) => res,
    (err) => err.response
  );

const getSearchProjectDetails = (id) =>
  axios.get(`/users/project/${id}/`).then(
    (res) => res,
    (err) => err.response
  );

const deleteProject = (id, pid) => axios.delete(`/users/${id}/project/${pid}/`);

export {
  getProjects,
  addProject,
  getProjectDetails,
  deleteProject,
  getCompanyProjects,
  editProject,
  getSearchProjectDetails,
};
