import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useStore } from '../../../store';
import { ConnectDiv } from '../../../components/ConnectModal/styles';
import check from '../../../assets/icons/check.svg';
// import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

const RewardCard = observer(({ item}) => {
  const [points, setPoints] = useState();
  const [error, setError] = useState();
  const [show, setShow] = useState();
  const [btnDisable, setBtnDisable] = useState(false);

  const { pointsStore, accountStore } = useStore();
  const { onRequestRedemption } = pointsStore;
  const { user, setUser,refreshPoints,setRefreshPoints } = accountStore;
  // const { allowRedemptiom, listAllowRedemption } = meetingStore;

  // useEffect(() => {
  //   listAllowRedemption();
  // }, [listAllowRedemption]);
//   useEffect(() => {
//       listRefreshPoints();
//       setTimeout(
//   () => listRefreshPoints(), 
//   2000
// );
//   }, [listRefreshPoints]);
  
  return (
    <div key={item.id} className='card'>
      <ConnectDiv show={show}>
        {/* {allowRedemptiom?.allow === true ? (
          <div>
            <div className='success-circle'>
              <Image src={check} id='checkCircle' />
            </div>
            <h2 className='my-2'>Confirm points redemption.</h2>
            <h5>{item.name}</h5>
            <p className='form-label designation'>
              Once you place the request, we will not be able to revert the
              points and {points} points will be deducted from your account. We
              will share further details by email.
            </p>
            <div className='d-flex justify-content-center'>
              <button
                disabled={btnDisable}
                onClick={() => {
                  setBtnDisable(true);
                  onRequestRedemption(
                    {
                      product: item.id,
                      points: Number(points),
                    },
                    () => {
                      setBtnDisable(false);
                      setPoints(0);
                      setShow(false);
                      setredpoints(0)
                      let updatedUser = user;
                      updatedUser.points_balance = user.points_balance - points;
                      
                      setUser(updatedUser);
                    }
                  );
                }}
                className='btn-default a_sendButton'
              >
                Confirm
              </button>
              <button
                className='link'
                onClick={() => {
                  setShow(false);
                  setPoints('');
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : ( */}
          <div>
            {refreshPoints?.redeemable_points > 0 ? (
              <>
                {points >
                refreshPoints?.redeemable_points ? (
                  <>
                    <h2 className='my-2'>Redemption Conditions.</h2>
                    <p className='form-label designation'>
                      Only {refreshPoints?.redeemable_points}{' '}
                      Points can be redeemed. Points with a locked icon can only
                      be redeemed after completion of the terms mentioned
                      against those points.
                    </p>

                    <div className='d-flex justify-content-center'>
                      <button
                        className='btn-default a_sendButton'
                        onClick={() => {
                          setShow(false);
                          setPoints(0);
                        }}
                      >
                        Okay
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div className='success-circle'>
                        <Image src={check} id='checkCircle' />
                      </div>
                      <h2 className='my-2'>Confirm points redemption.</h2>
                      <h5>{item.name}</h5>
                      <p className='form-label designation'>
                        Once you place the request, we will not be able to
                        revert the points and {points} points will be deducted
                        from your account. We will share further details by
                        email.
                      </p>
                      <div className='d-flex justify-content-center'>
                        <button
                          disabled={btnDisable}
                          onClick={() => {
                            setBtnDisable(true);
                            onRequestRedemption(
                              {
                                product: item.id,
                                points: Number(points),
                              },
                              () => {
                                setBtnDisable(false);
                                setPoints(0);
                                setShow(false);

                                let updatedUser = user;
                                updatedUser.points_balance =
                                  user.points_balance - points;
                                setUser(updatedUser);
                                setRefreshPoints({"redeemable_points":refreshPoints?.redeemable_points-points,"points":refreshPoints?.points-points});
                                // setavailablelpoints();
                              
                              }
                            );
                          }}
                          className='btn-default a_sendButton'
                        >
                          Confirm 
                        </button>
                        <button
                          className='link'
                          onClick={() => {
                            setShow(false);
                            setPoints(0);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (

              <>
              {refreshPoints?.redeemable_points === 0 && refreshPoints?.points > 0 && <>

               <h2 className='my-2'>Redemption Conditions.</h2>
                {/* <h5>{item.name}</h5> */}
                <p className='form-label designation'>
                  Bonus points can only be redeemed after your first meeting
                  related points have been added.
                </p>
                <div>
                  <button
                    className='btn-default a_sendButton'
                    onClick={() => {
                      setShow(false);
                      setPoints(0);
                    }}
                  >
                    Close
                  </button>
                </div>
              
              </> }

                    {refreshPoints?.redeemable_points === 0 && refreshPoints?.points ===0 && <>

               <h2 className='my-2'>Redemption Conditions.</h2>
                {/* <h5>{item.name}</h5> */}
                <p className='form-label designation'>
                  You don't have sufficient Redeemable points 
                </p>
                <div>
                  <button
                    className='btn-default a_sendButton'
                    onClick={() => {
                      setShow(false);
                      setPoints(0);
                    }}
                  >
                    Close
                  </button>
                </div>
              
              </> }

            
              </>       
            )}
          </div>
        
      </ConnectDiv>
      <a href={item.description} target='_blank' rel='noreferrer'>
        <Image src={item.image} />
      </a>

      <div className='form d-flex'>
        <select
          value={points}
          aria-label='Points select'
          className='form-control'
          onChange={(e) => setPoints(e.target.value)}
        >
          <option value=''>Points to redeem</option>
          {item?.voucher_denominations?.map((points) => (
            <option value={points}>{points}</option>
          ))}
        </select>
        <button
          className='btn-default'
          onClick={() => {
            if (points) {
              setError();
              setShow(true);
            } else {
              setError('Select the points to be redeemed.');
            }
          }}
          // disabled={points > redeempoints}
        >
          Redeem
        </button>
      </div>
      <div className='text-danger'>{error}</div>
    </div>
  );
});

export default RewardCard;
