import styled from 'styled-components';
import { Form } from 'react-bootstrap';
// import check from "../../../assets/icons/form-check.svg";
import check2 from '../../../assets/icons/blue-check.svg';

export const SearchDiv1 = styled.div`
  border-radius: 0.5rem;

  .chips {
    padding: 0px 20px;
    padding-top: 13px;
  }

  .back-design {
    position: absolute;
    bottom: -230px;
    left: -150px;
    z-index: -1;
  }

  .wave-design {
    position: absolute;
    top: -100px;
    left: -320px;
    z-index: -1;
  }

  .result-count {
    color: #32e0e0;
    background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 700;

    @media only screen and (min-width: 992px) {
      font-size: 18px;
    }
  }
  .search-line {
    border-bottom: 1px solid #7e7e7e33;
    padding-bottom: 14px;
    width: 100%;
  }

  .sort-div {
    color: #c0c0c0;
    font-size: 10px;
    img {
      margin-right: 4px;
    }
    button {
      background: 0;
      border: 0;
      color: #666666;
    }
    .active {
      color: #00aeae;
    }
    .transform {
      img {
        transform: scaleY(-1);
      }
    }

    margin-left: auto;

    @media only screen and (min-width: 992px) {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  .meeting-type {
    margin-left: auto;
    .dropdown-toggle {
      :after {
        display: none;
      }
    }
    .down {
      margin-left: 2px;
    }
    .dropdown {
      padding-left: 3px;
    }
    .dropdown-menu {
      padding: 0;
      border: 0.5px solid #cdeaf0;
      box-shadow: 3px 4px 20px 0px #0000001a;

      .dropdown-item {
        padding: 17px 0px 11px 15px;
        color: #333333;
      }
      .active {
        background: #bbffef;
      }
    }

    @media only screen and (min-width: 992px) {
      font-size: 14px;
      color: #666666;
      button,
      .dropdown-toggle {
        padding: 0;
        background: transparent;
        border: 0;
        color: #00aeae;
        font-size: 14px;
        box-shadow: none;
      }

      .dropdown-toggle:focus {
        box-shadow: none;
      }
    }
  }

  .content {
    padding-bottom: 50px;

    .filter-button:focus {
      box-shadow: none;
    }

    .filter-count {
      padding-left: 3px;
    }

    .cards {
      border-radius: 5px;
      background: #fff;
      padding: 19px 17px 63px 17px;
      position: relative;
      margin-top: 15px;

      .dm-cards {
        padding-top: 30px;
      }

      @media only screen and (min-width: 992px) {
        padding: 27px 96px 100px 55px;

        .dm-cards {
          padding-top: 40px;
        }
      }

      .empty-results {
        height: 70vh;

        .col-8 {
          margin: auto;
          margin-top: 140px;
        }

        h3 {
          font-size: 24px;
          color: #666666;
          margin-bottom: 47px;
        }

        .skeletons {
          margin-left: 15px;
          width: 90%;
          .react-loading-skeleton {
            max-width: 300px;
          }
        }
      }
    }

    .search-within {
      font-size: 11px;
      color: #666666;
      padding-bottom: 26px;

      .form-check {
        margin-left: 4px;
        margin-right: 5px;
        padding: 0;
        min-height: 0;
        margin-bottom: 0;
        display: flex;

        .form-check-input {
          background: transparent;
          border: 0.5px solid #00aeae;
          width: 12px;
          height: 12px;
          box-shadow: none;
          margin-left: 0px;
          margin-top: 2px;
        }

        .form-check-input:checked[type='checkbox'] {
          background-image: url(${check2});
          background-repeat: no-repeat;
          background-position: center;
        }

        .form-check-label {
          color: #333333;
          margin-left: 4px;
        }
      }
      @media screen and (min-width: 992px) {
        padding-bottom: 18px;
      }
    }

    .filter-bar {
      margin-top: 20px;
      font-size: 10px;
      color: #fff;
      margin-left: 18px;
      margin-right: 18px;

      @media screen and (min-width: 992px) {
        border: 0;
        margin-top: 240px;
        margin-bottom: -190px;
        padding-left: 30px;
      }

      @media screen and (min-width: 850px) {
        font-size: 12px;
      }

      button {
        padding: 0;
        background: transparent;
        border: 0;
        color: #00ffc2;
        font-size: 10px;

        @media screen and (min-width: 850px) {
          font-size: 12px;
        }
      }
      button:focus {
        box-shadow: none;
      }
    }
  }

  .level-2 {
    margin-left: 20px;
  }

  .content-holder {
    @media screen and (min-width: 992px) {
      display: flex;
      justify-content: space-around;
      padding-top: 13px;

      .left-content-holder {
        padding-top: 77px;
        min-width: 700px;
      }
    }
  }

  .desk-filter {
    padding-left: 24px;
  }

  .filter-header {
    font-size: 18px;
    color: #333333;
    font-weight: 600;
  }
  .more {
    color: #00aeae;
    padding: 18px 0px 33px 0px;
    border-bottom: 1px solid #7e7e7e33;
  }

  .search-initiatorr {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    padding-top: 10px;
    /* or 14px */

    color: #666666;

    .dropdown {
      margin-left: 13px;
    }

    .droparrow {
      margin: 0 0 2px 5px;
      border-left: 1px solid #00aeae;
      border-top: 1px solid #00aeae;
      padding: 3.5px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .arrow {
      position: absolute;
      top: -5px;
      left: 180px;
      border: solid #fff;
      background: #fff;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .dropdown-toggle {
      font-size: 12px;
      box-shadow: none;
      padding: 0;
      margin-top: -4px;
      margin-left: -7px;

      color: #00aeae;
      :after {
        display: none;
      }
    }
    .seeAll {
      color: #00aeae;
      cursor: pointer;
      font-size: 12px;
    }

    .dropdown-item {
      color: #00aeae;
      font-size: 10px;
    }
    .dropdown-menu {
      padding: 10px;
      width: fit-content;
      background: #fff;

      .nav-link {
        color: #111;
      }

      @media screen and (min-width: 992px) {
        left: -150px !important;
        padding: 40px 16px 24px 43px;

        .dropdown-item {
          font-size: 12px;
        }
      }
    }
  }
`;

export const SearchBar1 = styled(Form)`
  position: relative;

  display: flex;
  justify-content: center;
  .form-control {
    margin-top: 6px;
    height: 36px;
    width: 261px;
    background: #ebf4f9;
    border: 1px solid #00aeae;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: none;

    @media screen and (min-width: 992px) {
      font-size: 14px;
    }
  }
  .form-control::placeholder {
    color: #d0d0d0;
    font-weight: 500;
  }

  .search-button {
    height: 32px;
    border: none;
    background: transparent;
    margin: 6px 0px 10px -35px;
  }
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .suggested {
    position: absolute;
    background: #fff;
    border: 0.5px solid #cdeaf0;
    padding: 20px 0px 30px 30px;
    width: 100%;
    z-index: 10000;
    border-radius: 5px;
    color: #777777;
    top: 53px;
    left: 0px;

    .suggest-card {
      margin-bottom: 18px;
      cursor: pointer;
    }

    .suggest-name {
      font-size: 13px;
    }

    .suggest-info {
      font-size: 10px;
    }

    .search-head {
      font-size: 12px;
      font-weight: 600;
    }

    .search-chip {
      padding-top: 12px;
      font-size: 10px;
      font-weight: 600;
      color: #666666;
      cursor: pointer;

      .key {
        margin-left: 4px;
      }
    }

    @media screen and (min-width: 992px) {
      width: 415px;
    }
  }

  @media screen and (max-width: 992px) {
    max-width: fit-content;
  }
`;

export const SwitchDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 110px;
  background: #f8f8fa;
  padding-bottom: 850px;

  button {
    background: #f8f8fa;
    padding: 26px 27px 24px 23px;
    color: #111;
    border-radius: 0;
    border: none !important;
    box-shadow: none !important;
    font-weight: 400;
    font-size: 12px;
  }

  .more {
    color: #00aeae;
  }

  .active {
    background: #fff;
    font-weight: 600;

    :after {
      content: '>';
    }
  }
`;

export const FilterModal = styled.div`
  position: relative;
  .card-header {
    background: #ebf4f9;
    border: 0;
    cursor: pointer;
    @media screen and (min-width: 992px) {
      background: #cbebff;
    }
  }
  .card {
    border: 0;
    margin-top: 3px;
    background: 0;

    .card-body {
      padding-left: 2rem;
    }
  }
  .tab-content {
    padding: 23px 18px 0px 18px;
    width: -webkit-fill-available;

    @media screen and (min-width: 992px) {
      padding: 18px 0px 33px 0px;
      border-bottom: 1px solid #7e7e7e33;
    }
  }
  .drop {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
  }

  .search-button {
    img {
      width: 10px;
      height: 10px;
      @media screen and (min-width: 992px) {
        width: 15px;
        height: 15px;
      }
    }
  }

  .search-filter {
    font-size: 10px;
    width: -webkit-fill-available;
    margin-bottom: 20px;
    height: 19px;
    border: 0;
    margin-top: 23px;
    box-shadow: 0px 0px 0px 0px;
    border-bottom: 0.5px solid #b9b9b9;
    background: transparent;
    border-radius: 0;
    @media screen and (min-width: 992px) {
      font-size: 14px;
      padding-bottom: 8px;
    }
  }

  .form-check {
    margin-left: 10px;

    .form-check-input[type='checkbox'] {
      width: 16px;
      height: 16px;
      background: white;
      border-radius: 3px;
    }

    .form-check-input:checked[type='checkbox'] {
      border: 0.5px solid #00aeae;
      background-image: url(${check2});
      background-repeat: no-repeat;
      background-position: center;
    }

    label {
      padding-top: 2px;
      padding-left: 4px;
      font-size: 11px;
    }
  }

  .close {
    padding: 0;
    background: #fff;
    border: 0;
  }

  .header {
    border-bottom: 0.5px solid #b9b9b9;
    padding: 17px 10px 16px 20px;

    .filter-icon {
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 18px;

      img {
        margin-right: 5px;
      }
    }

    @media screen and (min-width: 992px) {
      border-bottom: 1px solid #7e7e7e33;
      padding: 30px 0px 17px 0px;
    }
  }

  .filter-div {
    height: 598px;
    overflow: auto;
  }

  .bottom-bar {
    padding: 12px 17px 13px 19px;
    border-top: 2px groove #11111120;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
  }

  .result-count {
    font-size: 14px;
    color: #00aeae;
  }

  .a_applyButton {
    width: 106px;
    padding: 9px 34px 8px 35px;
    font-size: 14px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 250px;
  }
`;

export const SelectedDiv = styled.div`
  padding: 8px 18px 0 19px;
  .filter-objects {
    overflow: hidden;
    height: 20px;
  }
  .filter-active {
    @media overflow: auto;

    @media screen and (min-width: 992px) {
      max-height: 160px;
    }
  }

  .btn-link {
    color: #fff;
    padding: 0;
    padding-bottom: 2px;
    font-size: 13px;
    height: fit-content;
    white-space: pre;

    @media screen and (max-width: 992px) {
      font-size: 10px;
    }
  }
  .btn-link:focus {
    box-shadow: none;
  }
`;

export const ChipText = styled.p`
  color: #333333;
  margin-right: 4px;
  padding: 2px 10px;
  font-size: 10px;
  border-radius: 3px;
  background-color: #f4f4f4;
  margin-bottom: 4px;
  white-space: pre;
  height: 20px;
`;
