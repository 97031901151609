import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Image, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import history from '../../history';
import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import Footer from '../../components/Footer';
import SwitchCart from '../../components/SwitchCart';
import MobileTopBar from '../../components/MobileTopBar';
import PeerWarningModal from '../../components/PeerWarningModal';
import LimitModal from '../../components/LimitModal';
import { useStore } from '../../store';
import { ProfileDiv } from './elements/styles';
import UserHolder from '../../assets/images/profile-holder.svg';
import bg from '../../assets/images/bg-2.svg';
import back from '../../assets/icons/backpage.svg';
import connect from '../../assets/icons/addnew.svg';
import added from '../../assets/icons/added.svg';
import smWave from '../../assets/images/g10.svg';
import wave from '../../assets/images/wave1.svg';
import downSm from '../../assets/icons/meetingdown.svg';
import down from '../../assets/icons/dropdown-arrow.svg';
import up from '../../assets/icons/dropup-arrow.svg';
import ChatAppModal from '../../components/ChatModal';
import chatimg from '../../assets/icons/chat.svg';

const MemberDetails = observer(() => {
  const { id } = useParams();
  const location = useHistory();
  const [chat, setChat] = useState();
  const [more, setMore] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);
  const [switchProduct, setSwitchProduct] = useState();
  const [show, setShow] = useState();
  const [showLimit, setShowLimit] = useState();

  const { memberStore, accountStore, meetingStore } = useStore();
  const { member, onFetchMemberDetails } = memberStore;
  const { selectedProduct, products, listProducts, cart, onAddToCart } =
    meetingStore;
  const { user } = accountStore;

  useEffect(() => {
    listProducts();
  }, [listProducts]);

  useEffect(() => {
    onFetchMemberDetails(id);
    setMore(false);
  }, [onFetchMemberDetails, id]);

  function toggle() {
    setMore(!more);
  }

  const engagementId = parseInt(process.env.REACT_APP_CHAT_ID);
  const enable = parseInt(process.env.REACT_APP_ENABLE);
  const colors = ['#0cc8f2', '#e85aed', '#05d1fa'];

  return (
    <>
      <Helmet
        title={`${member?.first_name} ${member?.last_name} | 121connects`}
      >
        <meta
          id='meta-description'
          name='description'
          content={`${member?.first_name} ${member?.last_name} is ${
            member?.title
          } at ${member?.company?.name}| ${member?.description?.split('.')[0]}`}
        />
      </Helmet>

      <Navigation />

      <PeerWarningModal show={show} setShow={setShow} />
      <LimitModal show={showLimit} setShow={setShowLimit} />
      <ChatAppModal show={chat} setShow={setChat} />
      <ProfileDiv>
        <div className='glass'>
          <MobileTopBar />
          <div className='glass-design d-none  d-lg-block'>
            <Image
              src={wave}
              alt='bg-design'
              className='wave-design d-none d-lg-block'
            />
          </div>
          <div className='profile-data d-lg-flex'>
            <div className='col-lg-8'>
              <div className='profile-info '>
                <button className='back-button' onClick={() => history.go(-1)}>
                  <Image src={back} />
                  Back
                </button>
                <div className='profile-pic'>
                  {member?.photo ? (
                    <>
                      <Image
                        src={member?.photo ? member.photo : UserHolder}
                        alt={`${member?.first_name} ${member?.last_name}`}
                        className='profile-image'
                        roundedCircle
                      />
                      <Image src={smWave} className='d-lg-none sm-wave' />
                    </>
                  ) : (
                    <div
                      className='profile-image userpic d-flex justify-content-center align-items-center'
                      style={{
                        background: `${
                          colors[Math.floor(Math.random() * colors.length)]
                        }50`,
                      }}
                    >
                      <div>
                        {member?.last_name !== undefined
                          ? `${member?.first_name?.split('')[0]}${
                              member?.last_name?.split('')[0]
                            }`
                          : member?.first_name !== undefined 
                          ? `${member?.first_name?.split('')[0]} ${
                              member?.first_name?.split('')[1]
                            }` : ""}
                      </div>
                    </div>
                  )}
                </div>

                <div className='info-tab'>
                  <h1 className='page-title'>
                    {member?.first_name} {member?.last_name}
                  </h1>
                  <h4>
                    {member?.title}
                    <div
                      onClick={() =>
                        location.push(
                          `/${member?.company?.name
                            ?.toLowerCase()
                            .replace(/\s/g, '-')}_${member?.company?.id}_c`
                        )
                      }
                      className='company-name'
                    >
                      {member?.company?.name}
                    </div>
                  </h4>
                </div>
              </div>
              <div>
                {member?.user_type === 0 && (
                  <div
                    className='profile-bio d-flex d-none d-lg-block '
                    id='meeting-div'
                  >
                    {user?.id !== member?.id && (
                      <div>
                        <div className='meeting-type d-flex align-items-center'>
                          <>
                            Choose Meeting Type{' '}
                            <Dropdown>
                              <Dropdown.Toggle
                                variant='basic'
                                id='more'
                                className='a_selectSidebar d-flex align-items-center'
                              >
                                {selectedProduct?.name}
                                <Image src={downSm} className='down-toggle' />
                              </Dropdown.Toggle>
                              {products.length > 1 ? (
                                <Dropdown.Menu align='right'>
                                  {products.map((item) => (
                                    <Dropdown.Item
                                      as='button'
                                      key={item.id}
                                      onClick={() => {
                                        setSwitchProduct(item);
                                        setShowSwitch(true);
                                      }}
                                      active={item.id === selectedProduct.id}
                                    >
                                      {item.name}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              ) : (
                                ''
                              )}
                            </Dropdown>
                          </>
                        </div>

                        {cart?.members_details?.find(
                          (item) => item.id === member?.id
                        ) ? (
                          <button className='a_buttonConnect btn-default added'>
                            <Image src={added} /> Added
                          </button>
                        ) : (
                          <button
                            className={`a_buttonConnect btn-default ${
                              (selectedProduct?.engagement === '121 MEETING' ||
                                selectedProduct?.engagement ===
                                  'PEER MEETING') &&
                              cart?.members?.length >= 1
                                ? 'disabled'
                                : ''
                            }`}
                            onClick={() => {
                              if (
                                user.user_type === 0 &&
                                !user?.engagements?.find(
                                  (eng) =>
                                    eng.name === selectedProduct?.engagement
                                )
                              ) {
                                setShow(true);
                              } else if (
                                (selectedProduct?.engagement ===
                                  '121 MEETING' ||
                                  selectedProduct?.engagement ===
                                    'PEER MEETING') &&
                                cart?.members?.length >= 1
                              ) {
                                setShowLimit(true);
                              } else {
                                onAddToCart(member, true, false);
                                if (
                                  selectedProduct?.engagement ===
                                    '121 MEETING' ||
                                  selectedProduct?.engagement === 'PEER MEETING'
                                ) {
                                  location.push('/cart');
                                }
                              }
                            }}
                            disabled={
                              // user.user_type === 0 &&
                              !member.engagements?.find(
                                (eng) =>
                                  eng.name === selectedProduct?.engagement
                              ) &&
                              !(
                                (selectedProduct?.engagement ===
                                  '121 MEETING' ||
                                  selectedProduct?.engagement ===
                                    'PEER MEETING') &&
                                cart?.members?.length >= 1
                              )
                            }
                          >
                            <Image src={connect} /> Meet
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <SwitchCart
                setShow={setShowSwitch}
                show={showSwitch}
                product={switchProduct}
              />

              <div>
                {user?.id !== member?.id && (
                  <div className='meeting-div d-flex d-lg-none align-items-center justify-content-between'>
                    <div className='meeting-type align-items-center d-flex'>
                      <>
                        Choose meeting type{' '}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='basic'
                            id='more'
                            className='a_selectSidebar d-flex align-items-center'
                          >
                            {selectedProduct?.name}
                            <Image src={down} />
                          </Dropdown.Toggle>
                          {products.length > 1 ? (
                            <Dropdown.Menu align='right'>
                              {products.map((item) => (
                                <Dropdown.Item
                                  as='button'
                                  key={item.id}
                                  onClick={() => {
                                    setSwitchProduct(item);
                                    setShowSwitch(true);
                                  }}
                                  active={item.id === selectedProduct.id}
                                >
                                  {item.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          ) : (
                            ''
                          )}
                        </Dropdown>
                      </>
                    </div>

                    {cart?.members_details?.find(
                      (item) => item.id === member?.id
                    ) ? (
                      <button className='a_buttonConnect btn-default added'>
                        <Image src={added} /> Added
                      </button>
                    ) : (
                      <button
                        className={`a_buttonConnect btn-default ${
                          (selectedProduct?.engagement === '121 MEETING' ||
                            selectedProduct?.engagement === 'PEER MEETING') &&
                          cart?.members?.length >= 1
                            ? 'disabled'
                            : ''
                        }`}
                        onClick={() => {
                          if (
                            user.user_type === 0 &&
                            !user?.engagements?.find(
                              (eng) => eng.name === selectedProduct?.engagement
                            )
                          ) {
                            setShow(true);
                          } else if (
                            (selectedProduct?.engagement === '121 MEETING' ||
                              selectedProduct?.engagement === 'PEER MEETING') &&
                            cart?.members?.length >= 1
                          ) {
                            setShowLimit(true);
                          } else {
                            onAddToCart(member, true, false);
                            if (
                              selectedProduct?.engagement === '121 MEETING' ||
                              selectedProduct?.engagement === 'PEER MEETING'
                            ) {
                              location.push('/cart');
                            }
                          }
                        }}
                        disabled={
                          // user.user_type === 0 &&
                          !member.engagements?.find(
                            (eng) => eng.name === selectedProduct?.engagement
                          ) &&
                          !(
                            (selectedProduct?.engagement === '121 MEETING' ||
                              selectedProduct?.engagement === 'PEER MEETING') &&
                            cart?.members?.length >= 1
                          )
                        }
                      >
                        <Image src={connect} /> Meet
                      </button>
                    )}
                    {user.user_type === 0 && enable === 1 && (
                      <button
                        className={`a_buttonConnect btn-default `}
                        onClick={() => {
                          setChat(true);
                        }}
                        disabled={
                          !member?.engagements?.find(
                            (eng) => eng.id === engagementId
                          )
                        }
                      >
                        <Image src={chatimg} /> Chat
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className='profile-bio'>
                <div className='d-flex'>
                  <h2>
                    {' '}
                    About {member?.first_name} {member?.last_name}{' '}
                  </h2>
                </div>

                <div className='bio-details'>
                  <p
                    className={classNames({
                      less: !more,
                    })}
                  >
                    {member?.description}
                  </p>

                  {member?.description?.length > 200 && (
                    <div className='readmore'>
                      {!more ? (
                        <div className='p-moreless' onClick={() => toggle()}>
                          More <img className='more-less' src={down} alt='' />
                        </div>
                      ) : (
                        <div className='p-moreless' onClick={() => toggle()}>
                          Less <img className='more-less' src={up} alt='' />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <Image
                  src={bg}
                  alt='bg-design'
                  className='bg-design d-none d-lg-block'
                />
              </div>
              {member?.user_type === 0 && (
                <div className='profile-bio'>
                  <div className='d-flex justify-content-between'>
                    <h2>
                      {' '}
                      {member?.first_name} {member?.last_name}'s Experience
                    </h2>
                  </div>

                  <div className='bio-details'>
                    <p>{member?.experience}</p>
                  </div>
                </div>
              )}
            </div>
            <div className='col-lg-4 address-div'>
              {member?.user_type === 0 ? (
                <>
                  <div className='profile-bio' id='res-section'>
                    <div className='d-flex justify-content-between'>
                      <h2 className='side-text'>
                        {member?.first_name} {member?.last_name}'s
                        Responsibilities
                      </h2>
                    </div>

                    <div className='bio-details'>
                      <div className='d-flex tags flex-wrap role-line'>
                        {member?.role?.map((item) => (
                          <div className='interest' key={item.id}>
                            {item.rolel2}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className=' profile-bio' id='inter-section'>
                    <h2 className='side-text'>
                      {member?.first_name} {member?.last_name}'s Interests
                    </h2>

                    <div className='bio-details'>
                      <div className='d-flex tags flex-wrap role-line'>
                        {member?.interests?.map((item) => (
                          <div className='interest' key={item.id}>
                            {item.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>{' '}
                </>
              ) : (
                <div className='member-company profile-bio' id='res-section'>
                  <h2 className='member-website'>
                    About {member?.company?.name}
                  </h2>
                  <p>{member?.company?.about}</p>
                  {member?.company?.about_website && (
                    <a
                      href={member?.company?.about_website}
                      target='_blank'
                      rel='noreferrer'
                      className='company-url'
                    >
                      {' '}
                      More About {member?.company?.name}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </ProfileDiv>
      <Footer />

      <BottomBar />
    </>
  );
});

export default MemberDetails;
