import React from "react";
import { observer } from "mobx-react-lite";
import { Form } from "react-bootstrap";
// import Fuse from "fuse.js";
import { useStore } from "../../../store";
// import { SearchBar } from "../elements/styles";
// import search from "../../../assets/icons/search.svg";

const InterestFilter = observer(({ desktop }) => {
  const { searchStore } = useStore();
  const {
    interests,
    selectedInterestList,
    onInterestListChanged,
    listSearchResults,
  } = searchStore;
  // const [data, setData] = useState(interests);

  // const searchData = (pattern) => {
  //   if (!pattern) {
  //     setData(interests);
  //     return;
  //   }
  //   const fuse = new Fuse(data, {
  //     keys: ["name"],
  //   });

  //   const result = fuse.search(pattern);
  //   const matches = [];
  //   if (!result.length) {
  //     setData([]);
  //   } else {
  //     result.forEach(({ item }) => {
  //       matches.push(item);
  //     });
  //     setData(matches);
  //   }
  // };

  return (
    <div>
      {/* <SearchBar onChange={(e) => searchData(e.target.value)}>
        <Form.Control
          type="search"
          id="search-tablet-city"
          data-testid="search-input-tablet"
          className="search-filter"
          placeholder="Type to search"
          aria-label="Search"
          aria-describedby="basic-addon1"
        />

        <button className="search-button" type="submit">
          <Image src={search} />
        </button>
      </SearchBar> */}
      {interests.map((item) => {
        return (
          <Form.Check
            value={item.name}
            key={item.name}
            label={item.name}
            checked={selectedInterestList.includes(item.name)}
            onChange={(e) => {
              onInterestListChanged(e.target);
              if (desktop) {
                listSearchResults();
              }
            }}
          />
        );
      })}
    </div>
  );
});

export default InterestFilter;
