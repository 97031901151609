import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Spinner } from 'react-bootstrap';
import './App.css';
import Home from './pages';
import Profile from './pages/Profile';
import Login from './pages/Login';
import SetPassword from './pages/SetPassword';
import UpdateSuccess from './pages/SetPassword/components/SuccessPage.js';
import VerifyMobile from './pages/VerifyMobile';
import {
  LoginRoute,
  PrivateRoute,
  FirstLoginRoute,
  DMRoute,
} from './PrivateRoute';
import history from './history';
import ForgotPassword from './pages/ForgotPassword';
import { useStore } from './store';
import Activate from './pages/Activate';
// import Projects from "./pages/Projects";
import AddProject from './pages/AddProject';
// import ProjectDetail from "./pages/ProjectDetails";
// import EditProject from "./pages/EditProject";
import Search from './pages/Search';
import CompanyDetails from './pages/CompanyDetails';
import MemberDetails from './pages/MemberDetails';
import Meetings from './pages/Meetings';
// import MeetingDetails from "./pages/MeetingDetails";
import Terms from './pages/Terms';
import MeetingCart from './pages/MeetingCart';
import CancelReschedule from './pages/CancelRescheduleMeeting';
import PointsHistory from './pages/PointsHistory';
// import AddPoints from './pages/AddPoints';
import Rewards from './pages/Rewards';
import Dispute from './pages/Dispute';
import Privacy from './pages/Privacy';
import Cookie from './pages/Cookie';
import CancelMeeting from './pages/CancelMeeting';
import Unsubscribe from './pages/unsubscribe/Unsubscribe';

const App = () => {
  const { accountStore } = useStore();

  const { getUser, isAccountLoading } = accountStore;

  useEffect(() => {
    getUser();
  }, [getUser]);

  history.listen((_) => {
    window.scrollTo(0, 0);
  });

  return (
    <Router history={history}>
      {isAccountLoading ? (
        <div
          data-testid='app-loader'
          className='d-flex vh-100 justify-content-center align-items-center'
        >
          <Spinner animation='grow' size='lg' />
        </div>
      ) : (
        <Switch>
          <LoginRoute exact path='/login' component={Login} />
          <LoginRoute path='/activate' component={Activate} />
          <LoginRoute path='/reset-password' component={ForgotPassword} />
          <FirstLoginRoute
            exact
            path='/verify-mobile'
            component={VerifyMobile}
          />
          <Route exact path='/verify-phone-number' component={VerifyMobile} />
          <FirstLoginRoute exact path='/set-password' component={SetPassword} />
          <PrivateRoute exact path='/unsubscribe' component={Unsubscribe} />
          <PrivateRoute
            exact
            path='/set-password/success'
            component={UpdateSuccess}
          />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/cookie' component={Cookie} />

          <PrivateRoute exact path='/' component={Home} />
          <PrivateRoute exact path='/search' component={Search} />
          <PrivateRoute exact path='/profile' component={Profile} />
          <DMRoute exact path='/projects/new' component={AddProject} />
          {/* <DMRoute path="/projects" component={Projects} /> */}
          {/* <PrivateRoute exact path="/project/:id" component={ProjectDetail} /> */}
          <PrivateRoute exact path='/:slug\_:id\_m' component={MemberDetails} />
          {/* <DMRoute exact path="/project/:id/edit" component={EditProject} /> */}
          <PrivateRoute
            exact
            path='/:slug\_:id\_c'
            component={CompanyDetails}
          />
          <PrivateRoute
            exact
            path='/reschedule-meeting/:uid'
            component={CancelReschedule}
          />
          <PrivateRoute
            exact
            path='/cancel-meeting/:uid'
            component={CancelMeeting}
          />
          <PrivateRoute path='/my-meetings' component={Meetings} />
          <PrivateRoute path='/cart' component={MeetingCart} />

          <PrivateRoute path='/my-points' component={PointsHistory} />
          {/* <VendorRoute path='/add-points' component={AddPoints} /> */}
          <DMRoute path='/rewards' component={Rewards} />
          <PrivateRoute path='/dispute' component={Dispute} />
        </Switch>
      )}
    </Router>
  );
};

export default observer(App);
