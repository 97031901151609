import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "react-bootstrap";
// import DateTimePicker from "react-datetime-picker";
import { StyledModal } from "../elements/styles";
import { useStore } from "../../../store";

// import close from "../../../assets/icons/close.svg";

const OrgModal= observer(({ showAccept, setShowAccept,meeting  }) => {
  /*const [acceptDate, setAcceptDate] = useState(new Date());
  const [errorMessage, setError] = useState();*/

  const { meetingStore } = useStore();
 
  const { setMeetings, results } = meetingStore;
  

  
  


  

  return (
    <StyledModal show={showAccept}>
     


   
      <>
     

      <div>
      <h3>{meeting?.product_engagement_name} accepted</h3>
      {meeting?.acceptor_points > 0 ?
      <div>
      Thank you for accepting the meeting. You will be credited with {meeting?.acceptor_points} points after you attend the event
      </div> :
      <div>
        Thank you for accepting the invite. We look forward to seeing you at the event.
      </div>}
      <Button
        onClick={() => {
          setShowAccept(false);
          setMeetings(results);
        }}
        className="btn-default"
      >
        Close
      </Button>
      </div>
      </>
    </StyledModal>
  );
});

export default OrgModal;
