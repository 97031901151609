import styled from 'styled-components';

export const CartDiv = styled.div`
  position: relative;
  padding: 11px;
  padding-bottom: 58px;

  .glass {
    margin: 0;
    border-radius: 5px;
    padding-bottom: 10px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 992px) {
      padding: 0px 43px 10px 43px;
    }
    @media only screen and (max-width: 992px) {
      margin-top: 76px;
      background: rgba(235, 255, 254, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.7);
      padding-bottom: 21px;
      margin-bottom: 30px;
    }
  }

  .glass-design {
    z-index: -1;
    position: relative;
    margin-bottom: -200px;
    max-width: 1280px;
    height: 220px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    border-radius: 5px;
  }

  .logo {
    margin: 40px 0px 30px 24px;
  }

  h2 {
    font-size: 28px;
    color: #fff;
    @media only screen and (max-width: 992px) {
      font-size: 18px;
      padding-left: 25px;
    }
  }

  .remove-icon {
    margin-right: 4px;
  }

  .member-list {
    background: #fff;
    padding: 25px 25px 268px 16px;
    border-radius: 5px;
    margin-top: 19px;
    min-height: 300px;
    position: relative;
    @media only screen and (min-width: 992px) {
      padding: 50px 90px 30px 55px;
      min-height: 500px;
    }

    .back-design {
      position: absolute;
      bottom: -200px;
      left: -170px;
      z-index: -1;
    }

    .sm-wave {
      position: absolute;
      top: -100px;
      right: -10px;
      z-index: -1;
    }

    .wave-design {
      position: absolute;
      right: -195px;
      top: -82px;
      z-index: -1;
    }
  }

  .content {
    margin: 0 auto;
  }

  .profile-image {
    width: 47px;
    height: 47px;
    border: 2px solid #eaedf3;
  }
  .remove-button {
    border: 0;
    background: #fff;
    color: #00aeae;
    font-size: 14px;
  }

  .dm-info {
    margin-left: 20px;
    color: #333333;
    cursor: pointer;

    h6 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }

    .designation {
      color: #666666;
      font-size: 14px;
    }
  }

  .points-div {
    @media only screen and (min-width: 992px) {
      flex: 0 0 auto;
      width: 35%;
    }
  }

  .insufficient {
    background: #ffebdd;

    .points-text {
      padding-left: 20px;
      @media only screen and (max-width: 992px) {
        padding-bottom: 18px;
      }
    }

    .btn-default {
      font-size: 12px;
    }
  }

  .insufficient-info {
    font-weight: 500;
    font-size: 12px;
    color: #666666;
    padding-top: 10px;
  }

  .member-card {
    padding: 17px 0px;
    border-bottom: 1px solid #c4c4c4;
  }

  .success {
    border-radius: 5px;
    background: #edfee7;
  }

  .points-info {
    margin: 17px 0 14px 0;
    padding: 14px 20px 13px 22px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;

    .points {
      font-weight: 700;
    }

    .points-message {
      padding-top: 25px;
      color: #666666;
    }

    @media only screen and (min-width: 992px) {
      font-size: 14px;
    }
  }

  .btn-default {
    width: fit-content;
    height: fit-content;
    padding: 9px 13px;
    font-size: 14px;
  }

  .points-message {
    margin: 40px 0 10px 0;
    color: #8c97ac;
    font-size: 13px;
    font-style: italic;
    max-width: 557px;
    padding-right: 10px;
    @media only screen and (min-width: 992px) {
      margin: 25px 0 5px 0;
    }
  }

  .col-lg-8 {
    margin-top: 29px;
  }

  .points-text {
    font-weight: bold;
    color: #333333;
    margin-left: 4px;
  }

  .points-num {
    @media only screen and (min-width: 992px) {
      margin-right: 225px;
    }
  }

  .dm-points {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    color: #403c3c;
    opacity: 0.4;
  }

  .form-group {
    padding-top: 20px;
    color: #333333;
    width: 85%;

    .form-label {
      font-size: 14px;
      font-weight: 700;
      margin-left: 25px;
    }

    .text-danger {
      font-size: 12px;
      padding: 5px 0 0 22px;
    }

    .date-selector {
      max-width: 290px;
      position: relative;
      cursor: pointer;
    }

    .form-control {
      background: white;
      font-size: 12px;
      font-weight: 600;
      color: #403c3c;
      box-shadow: none;
      border: 0.5px solid #cdeaf0;
      padding: 13px 16px 13px 20px;
      margin-left: 22px;

      @media only screen and (max-width: 992px) {
        width: 100%;
        margin-left: 22px;
      }
    }

    @media only screen and (min-width: 992px) {
      padding-top: 30px;
      margin-left: 22px;

      .form-control {
        margin-left: 22px;
        background: white;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  .rdrCalendarWrapper {
    background: white;
    z-index: 1;
    position: absolute;
    top: 45px;
    border: 0.5px solid #cdeaf0;
    left: 0px;
    border-radius: 5px;
    width: 100%;
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonth {
    z-index: 1;
    background-color: white;
  }

  .rdrDateDisplayItemActive {
    border-color: #00aeae;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber {
    span {
      color: #000000de;
    }
  }

  .date{
    font-size: 12px;
  }

  .rdrInRange {
    background: #00aeae1f;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background: #00aeae;
  }

  .newreq {
    height: 342px;
    left: 329px;
    margin-bottom: 19px;

    background: #fff7da;
    border-radius: 5px;
    @media only screen and (max-width: 992px) {
      height: 342px;
      left: 329px;
      background: #fff7da;
      border-radius: 5px;
    }
  }
`;
