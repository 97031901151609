import { createContext, useContext } from "react";
import AuthStore from "./auth/authStore";
import AccountStore from "./account/accountStore";
import ProjectStore from "./project/projectStore";
import SearchStore from "./search/searchStore";
import CompanyStore from "./company/companyStore";
import MemberStore from "./member/memberStore";
import MeetingStore from "./meeting/meetingStore";
import PointsStore from "./points/pointsStore";

const accountStore = new AccountStore();
const authStore = new AuthStore(accountStore);
const projectStore = new ProjectStore(accountStore);
const searchStore = new SearchStore();
const companyStore = new CompanyStore();
const memberStore = new MemberStore();
const meetingStore = new MeetingStore(accountStore);
const pointsStore = new PointsStore();

export const store = {
  authStore,
  accountStore,
  projectStore,
  searchStore,
  companyStore,
  memberStore,
  meetingStore,
  pointsStore,
};
export const StoreContext = createContext(store);
export const useStore = () => {
  return useContext(StoreContext);
};
