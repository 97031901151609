import styled from 'styled-components';

export const CartDiv = styled.div`
  position: relative;
  // padding: 11px;
  // padding-bottom: 58px;

  .stat {
    
    color: #fff;
    position: relative;

    
  }
.stat-tooltip {
    display: none;
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 2.2px 5.8px 0px #000000b0;
    background: white;
    color: black;
    top: -60.5px;
    left:25px;
    border: 2px solid #27d3db;
    padding: 5.5px 5.4px 0px 5.5px;
    font-size: 14.7px;
    width: 305.5px; 
    font-weight: 400;

    p {
      font-size: 13.8px;
      margin: 5.2px 0;
    }
  }
  .stat:hover .stat-tooltip {
    display: block;
  }
  .glass {
    margin: 0;
    border-radius: 5px;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 992px) {
      padding: 24px 0px 10px 0px;
    }
    @media only screen and (max-width: 992px) {
      border: 1px solid rgba(255, 255, 255, 0.7);
      background: rgba(235, 255, 254, 0.2);
      margin-top: 100px;
    }
  }



  .glass-design {
    z-index: -1;
    margin-top: 4px;
    position: relative;
    margin-bottom: -188px;
    max-width: 1280px;
    height: 188px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: linear-gradient(
      161.3deg,
      rgba(234, 255, 254, 0.2) -8.5%,
      rgba(201, 229, 241, 0.2) 151.17%
    );
    border-radius: 10px;
    position: relative;
    .wave-design {
      position: absolute;
      right: 0px;
      top: 20px;
      z-index: -1;
    }
  }

  .less {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
    white-space: break-spaces;
  }

  .bio-description {
    cursor: pointer;
    font-size: 10px;
    width: 30px;
    height: 30px;
    background: rgba(0, 174, 174, 0.1);
    padding: 10px 14px 12px 14px;
    border-radius: 50%;
    margin-top: 17px;
  }

  .readmore{
    margin-bottom: 12px;
  }

  .more-less{
    width: 13px;
    cursor: pointer;
  }
  .p-moreless{
    color: #00aeae;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
  }

  .holder {
    position: relative;
    @media only screen and (min-width: 992px) {
      max-width: 914px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 30px;
    }
  }

  .header-div {
    @media only screen and (max-width: 992px) {
      padding: 28px 18px 0 18px;
    }
  }

  .back-button {
    background: transparent;
    color: #00ffc2;
    border: 0;
    font-weight: 500;
    margin: 0px 28px 17px 15px;

    img {
      margin-right: 9.5px;
    }
    @media only screen and (min-width: 992px) {
      margin: 0;
    }
  }

  #transaction{
    color: #00aeae;
  }

  .mob-topbar {
    padding-left: 21px;
  }
  .coins {
    filter: brightness(0) saturate(100%) invert(31%) sepia(10%) saturate(0%)
      hue-rotate(251deg) brightness(90%) contrast(87%);
    margin-right: 10px;
  }
  .btn-default {
    padding: 6px 11px;
    font-size: 14px;
  }

  .dispute-div {
    white-space: break-spaces;
    font-size: 14px;
    padding: 26px 0px 25px 0px;

    @media only screen and (min-width: 992px) {
      padding: 25px 103px 30px 0px;
    }
  }

  .dispute-button {
    background: none;
    border: none;
    color: #27D3DB;
    font-size: 14px;
  }

  .points-info {
    font-weight: 600;
    font-size: 10px;
    line-height: 153.5%;
    color: #ffffff;
    padding-left: 10px;

    h5 {
      font-family: Lato;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 153.5%;
      /* identical to box height, or 25px */

      color: #ffffff;
    }
  }

  .page-title {
    color: #fff;
    margin-bottom: 0;
  }

  .header-div {
    .form-control {
      height: 33px;
    }
    .search-button {
      margin-top: 0;
    }
  }

  .meeting-form {
    .form-control {
      height: 33px;
    }
    .search-button {
      margin-top: 0;
    }
    .sm-wave {
      position: absolute;
      top: -80px;
      right: -10px;
      z-index: -1;
    }
  }

  .cards {
    min-height: 300px;
    background: #fff;
    border-radius: 5px;
    position: relative;

    .back-design {
      position: absolute;
      bottom: -200px;
      left: -170px;
      z-index: -1;
    }
  }

  .contents {
    margin: 0px 103px 30px 67px;
    @media only screen and (max-width: 992px) {
      margin: 10px 16px;
    }
  }

  .item-card {
    padding: 34px 0px;
    border-bottom: 1px solid #7e7e7e33;
    color: #666666;
    font-size: 12px;

    .points {
      font-weight: 700;
      font-size: 18px;
      color: #ff647f;
    }
    .added {
      color: #27d3db;
    }
    .redeem-message {
      background: #fbf5e6;
      padding: 6px 8px 7px 13px;
      width: fit-content;

      @media only screen and (min-width: 992px) {
        margin-left: 60px;
      }
    }

    .profile-image {
      width: 47px;
      height: 47px;
      border: 2px solid #eaedf3;
    }

    .dm-info {
      margin-left: 20px;
      color: #333333;

      h6 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        height: 20px;
        color: #333333;
      }

      #note{
        margin-bottom: 1px;
      }

      .designation {
        color: #666666;
        font-size: 14px;
        
        width: 200px;
        
        
        white-space: break-spaces;
        @media only screen and (min-width:992px){
          width: 450px;
          
        }
        
        
      }

      .time {
        color: #00aeae;
        width: auto;
      }

      @media only screen and (max-width: 992px) {
        h6 {
          font-size: 16px;
        }
        .designation {
          font-size: 12px;
        }
      }
    }

    @media only screen and (max-width: 992px) {
      font-size: 10px;
      padding: 25px 0px;
      .points {
        font-size: 14px;
      }
    }
  }
  .points-image {
    width: 43px;
    height: 42.39px;
  }

  .top-points {
    margin-right: 14px;
  }

  .points-div{
    margin-top: 44px;
  }

  .drop {
    margin-top: -10px;
    @media only screen and (min-width: 992px) {
      margin-top: 24px;
      
      margin-left: -694px;

    margin-top: -33px;
    
  }

  .filter-drop {
    margin-right: 13px;
    color: #fff;
    font-size: 10px;
    @media only screen and (min-width: 992px) {
      font-size: 14px;
      margin-bottom: 9px;
    }
  }

  #search-tablet {
    width: 385px;
  }

  .date-filter {
    margin-right: 23px;
  }

  .filter-button {
    padding: 0px;
    background: transparent;
    border: 0px;
    color: rgb(0, 255, 194);
    font-size: 10px;
    box-shadow: none;

    :after {
      display: none;
    }

    @media only screen and (min-width: 992px) {
      font-size: 14px;
    }
  }

  .dropdown-menu {
    min-width: 277px;
    padding: 0;
    border: 0.5px solid #cdeaf0;
    box-shadow: 3px 4px 20px 0px #0000001a;
    font-size: 14px;

    .dropdown-item {
      padding: 17px 0px 11px 15px;
      color: #333333;
    }
    .active {
      background: #bbffef;
    }
  }

  

  
`;

export const StyledModal = styled.div`
  .link {
    border: 0;
    background: 0;
    color: #00aeae;
    font-size: 12px;
    padding: 0;
  }

  .filter {
    border: 0;
    background: 0;
    color: #fff;
    font-size: 12px;
    width: fit-content;
  }

  .header {
    background: #00aeae;
    color: #fff;
    padding: 20px 30px;
    font-weight: 700;
    .dropdown-item {
      padding: 0;
      color: #fff;
    }

    .range-head {
      padding-top: 20px;
    }
  }

  .date-range {
    padding-bottom: 0px;
    font-weight: 400;
    font-size: 12px;
  }

  .date-range-select {
    font-size: 12px;
    color: #7a7a7a;
    margin-bottom: 10px;
    .dropdown-item {
      border: 0;
      padding: 0;
      background: 0;
      width: fit-content;
    }
  }

  .content {
    padding: 26px 30px;
    border-radius: 5px;
  }

  .rdrCalendarWrapper {
    max-width: 277px;
  }

  .rdrDateDisplayWrapper {
    background: #00aeae;
  }

  // .rdrDateDisplayItem {
  //   background: #00aeae;
  //   input {
  //     color: #fff;
  //   }
  // }

  .rdrDateDisplayItemActive {
    border-color: #00aeae;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber {
    span {
      color: #000000de;
    }
  }

  .rdrInRange {
    background: #00aeae1f;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    background: #00aeae;
  }
  .back {
    cursor: pointer;
  }

  
`;
