import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Image, Button } from "react-bootstrap";
import { useStore } from "../../../store";
// import Fuse from "fuse.js";
// import RoleCard from "./RoleCard";
// import { SearchBar } from "../../Search/elements/styles";
import { ProfileFormDiv } from "../elements/styles";
// import search from "../../../assets/icons/search.svg";
import close from "../../../assets/icons/close.svg";

const RoleForm = observer(({ show, setEdit }) => {
  const { accountStore, authStore , searchStore} = useStore();

  const { onEditProfile } = authStore;

  const {  roleList } = accountStore;
  const {searchinRoles} = searchStore;

  const [newroleList, setRoleList] = useState(roleList);

  const onSubmit = () => {
    let data = {};
    data.role = newroleList;
    onEditProfile(data);
    setEdit(false);
  };

  const onChange = (e) => {
    let rolefinal = [...newroleList];
    const index = rolefinal.findIndex(
      (rolel2) => rolel2 === Number(e.target.value)
    );
    if (e.target.checked && index < 0) {
      rolefinal.push(Number(e.target.value));
    }
    if (!e.target.checked && index >= 0) {
      rolefinal.splice(index, 1);
    }
    setRoleList(rolefinal);
  };

  // const searchData = (pattern) => {
  //   if (!pattern) {
  //     setSearchRoles(roles);
  //     return;
  //   }
  //   const fuse = new Fuse(searchRoles, {
  //     keys: ["rolel1", "children.rolel2"],
  //   });

  //   const result = fuse.search(pattern);
  //   const matches = [];
  //   if (!result.length) {
  //     setSearchRoles([]);
  //   } else {
  //     result.forEach(({ item }) => {
  //       matches.push(item);
  //     });
  //     setSearchRoles(matches);
  //   }
  // };

  return (
    <ProfileFormDiv show={show}>
      <div className="d-flex justify-content-end">
        <button onClick={() => {setEdit(false); setRoleList(roleList) }} className="close-button">
          <Image src={close} alt="close" className="close" />
        </button>
      </div>
      <h4>Edit your profile</h4>
      <h2>Responsibilities</h2>

      {/* <SearchBar
        onChange={(e) => searchData(e.target.value)}
        className="search-form"
      >
        <Form.Control
          type="search"
          id="search-tablet-role"
          data-testid="search-input-tablet"
          className="search-filter"
          placeholder="Type to search"
          aria-label="Search"
          aria-describedby="basic-addon1"
        />

        <button className="search-button" type="submit">
          <Image src={search} />
        </button>
      </SearchBar> */}

      {searchinRoles.map((item) => {
        return (
          <Form.Check
            key={item.rolel2}
            label={item.rolel2}
            value={item.id}
            checked={newroleList.includes(item.id)}
            onChange={(e) => onChange(e)}
          />
        );
      })}

      <Button className="btn-default" onClick={() => onSubmit()}>
        Save
      </Button>
    </ProfileFormDiv>
  );
});

export default RoleForm;
