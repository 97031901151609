import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ConnectDiv } from './styles';
import close from '../../assets/icons/close.svg';

const PeerWarningModal = observer(({ show, setShow }) => {
  const location = useHistory();
  return (
    <ConnectDiv show={show} onClick={(e) => e.stopPropagation()}>
      <div className='d-flex align-items-start justify-content-between'>
        <div>
          <h3>
            You will not be able to connect to other members for peer meeting
            unless you are open to receiving invites for the same.
          </h3>
        </div>
        <Image
          src={close}
          onClick={(e) => {
            setShow(false);
            e.stopPropagation();
          }}
          alt='close'
          className='close'
        />
      </div>
      <Button
        variant='light'
        onClick={(e) => {
          location.push('/profile');
          e.stopPropagation();
        }}
        className='btn-default a_sendButton'
      >
        Change in profile
      </Button>
    </ConnectDiv>
  );
});

export default PeerWarningModal;
