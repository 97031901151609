import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { CardBox } from './styles';
// import UserHolder from '../../assets/images/profile-holder.svg';
import { useStore } from '../../store';
import connect from '../../assets/icons/addnew.svg';
import added from '../../assets/icons/added.svg';
import PeerWarningModal from '../PeerWarningModal';
import LimitModal from '../LimitModal';
import ChatAppModal from '../ChatModal';

const DMCard = observer(({ dm }) => {
  const [show, setShow] = useState();
  const [showLimit, setShowLimit] = useState();
  const [chat, setChat] = useState();
  const location = useHistory();

  const { meetingStore, accountStore } = useStore();
  const { cart, onAddToCart, selectedProduct } = meetingStore;
  const { user } = accountStore;

  var name = dm?.full_name.split(' ');
  const first_name = name[0].split('');
  const last_name = name[1] ? name[1].split('') : '';

  return (
    <CardBox className='w-100 py-3  d-flex'>
      <div
        className='d-flex col'
        onClick={() =>{
          if (dm?.linked_in_url) {
            if (dm.linked_in_url.startsWith('http'))
            {
              window.open(`${dm?.linked_in_url}`, '_blank')
            }
            else {
              window.open(`//${dm?.linked_in_url}`, '_blank')
            }
        }
      }
    }
      >
        <PeerWarningModal show={show} setShow={setShow} />
        <LimitModal show={showLimit} setShow={setShowLimit} />
        <ChatAppModal show={chat} setShow={setChat} />
        {dm?.photo ? (
          <Image
            src={dm?.photo}
            alt='profile-pic'
            className='profile-image'
            roundedCircle
          />
        ) : (
          <div
            className='profile-image username d-flex justify-content-center align-items-center'
            style={{
              background: `#${Math.floor(Math.random() * 16777215).toString(
                16
              )}50`,
            }}
          >
            <div>
              {last_name
                ? `${first_name[0]}${last_name[0]}`
                : `${first_name[0]}${first_name[1]}`}
            </div>
          </div>
        )}
        <div className='dm-info'>
          <h6>{dm.full_name}</h6>
          <div className='designation'>
            {dm.title}
            <div
              className='company'
              onClick={(e) => {
                window.open(
                  `/${dm.company_name?.toLowerCase()?.replace(/\s/g, '-')}_${
                    dm.company_id
                  }_c`,
                  '_blank'
                );
                e.stopPropagation();
              }}
            >
              {dm.company_name}
            </div>
          </div>
        </div>
      </div>
      {user.id !== dm.id && (
        <div className='chatmeet-btn btn-lg'>
          {cart?.members_details?.find((item) => item.id === dm.id) ? (
            <button className='connect btn-default added'>
              <Image src={added} /> Added
            </button>
          ) : (
            <button
              className={`connect btn-default ${
                (selectedProduct?.engagement === '121 MEETING' ||
                  selectedProduct?.engagement === 'PEER MEETING') &&
                cart?.members?.length >= 1
                  ? 'disabled'
                  : ''
              }`}
              onClick={() => {
                if (
                  user.user_type === 0 &&
                  !user?.engagements?.find(
                    (eng) => eng.name === selectedProduct?.engagement
                  )
                ) {
                  setShow(true);
                } else if (
                  (selectedProduct?.engagement === '121 MEETING' ||
                    selectedProduct?.engagement === 'PEER MEETING') &&
                  cart?.members?.length >= 1
                ) {
                  setShowLimit(true);
                } else {
                  onAddToCart(dm, true, false);
                  if (
                    selectedProduct?.engagement === '121 MEETING' ||
                    selectedProduct?.engagement === 'PEER MEETING'
                  ) {
                    location.push('/cart');
                  }
                }
              }}
              disabled={
                !(
                  (selectedProduct?.engagement === '121 MEETING' ||
                    selectedProduct?.engagement === 'PEER MEETING') &&
                  cart?.members?.length >= 1
                ) &&
                !dm.engagements?.find(
                  (eng) => eng.name === selectedProduct?.engagement
                )
              }
            >
              <Image src={connect} /> Meet
            </button>
          )}
        </div>
      )}
    </CardBox>
  );
});

export default DMCard;
