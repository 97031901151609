import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const ConnectDiv = styled(Modal)`
  .modal-content {
    padding: 30px 36px 50px 35px;
    p {
      color: #c0c0c0;
      font-size: 14px;
      margin-bottom: 8px;
    }

    h3 {
      color: #666666;
    }

    h2 {
      color: #32e0e0;
      background: -webkit-linear-gradient(#32e0e0, #0ab4cc);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .close {
    cursor: pointer;
  }

  .btn-default {
    padding: 8px 30px;
    width: fit-content;
    margin-top: 20px;
  }
`;
