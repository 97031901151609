import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Form, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import BottomBar from '../../components/BottomBar';
import Navigation from '../../components/TopBar';
import Footer from '../../components/Footer';
import MobileTopBar from '../../components/MobileTopBar';
import SuccessModal from './components/SuccessModal';
import { useStore } from '../../store';
import { CartDiv } from './elements/styles';
import bgDesign from '../../assets/images/back-design.svg';
import smWave from '../../assets/images/smwave.svg';
import wave from '../../assets/images/wave1.svg';
import back from '../../assets/icons/backpage.svg';


const Dispute = observer(() => {
  const location = useHistory();

  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [transactionerror, setTransactionerror] = useState();
 

  const { pointsStore } = useStore();
  const { onsendDispute } = pointsStore;
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if(data.message){
      setError();
    }else{
      setError('Field required');
    }
    if (data.transaction_id ) {
      setTransactionerror()
      if(isNaN(data.transaction_id ) ){
        setTransactionerror('Transaction ID should be a number');
    }else {
      setTransactionerror();
    }
    } else {
      setTransactionerror('Field required');
    }
    
    if(data.message && data.transaction_id && !isNaN(data.transaction_id)){
      onsendDispute(data, () => setSuccess(true));
      
    }
  };

  // const refDate = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       refDate &&
  //       refDate.current &&
  //       !refDate.current.contains(event.target)
  //     ) {
  //       setshowDate(false);
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside);
  // }, [refDate, setshowDate]);

  return (
    <>
      <Navigation />
      <SuccessModal show={success} setShow={setSuccess} />
      <CartDiv>
        <div className='glass'>
          <MobileTopBar />
          <div className='glass-header d-none d-lg-block'>
            <Image
              src={wave}
              alt='bg-design'
              className='wave-design d-none d-lg-block'
            />
          </div>{' '}
          <div className='holder'>
            <button
              className='back-button '
              onClick={() => location.push('/my-points')}
            >
              <Image src={back} />
              Points History
            </button>
            <h2 className='page-title'>Request points change</h2>

            <div className='meeting-form'>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                  <Form.Label>Enter Transaction ID</Form.Label>
                  
                  <Form.Control
                    type='text'
                   
                    
                    {...register('transaction_id')}
                  />
                    <div className='text-danger'>{transactionerror}</div>

                    

                    
               
                </Form.Group>
                <Form.Group>
                  <Form.Label>Enter details</Form.Label>
                  <Form.Control
                    type='text'
                    as='textarea'
                    rows={6}
                    {...register('message')}
                  />
                </Form.Group>
                <div className='text-danger'>{error}</div>
                <div className='designation'>
                  Once you place a request, 121 Connects team will review the
                  details and reach out to you via email.
                </div>
                <button className='btn-default' type='submit'>
                  Send request
                </button>
              </Form>

              <Image src={smWave} className='d-lg-none sm-wave' />

              <Image
                src={bgDesign}
                alt='bg-design'
                className='back-design d-none d-lg-block'
              />
            </div>
          </div>
        </div>
      </CartDiv>
      <Footer />
      <BottomBar />
    </>
  );
});

export default Dispute;
