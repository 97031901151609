import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem, Nav, Image, Button } from 'react-bootstrap';
import Sidebar from '../Sidebar';
// import { useStore } from "../../store";
import { BottomDiv } from './bottombar';
import searchIcon from '../../assets/icons/Home-icon.svg';
import meetingIcon from '../../assets/icons/meeting.svg';
import moreIcon from '../../assets/icons/more.svg';
import coins from '../../assets/icons/coins.svg';

// import projectIcon from "../../assets/icons/projects.svg";

// const tabs = [
//   {
//     route: "/search",
//     label: "Search",
//     image: searchIcon,
//   },
//   {
//     route: "/projects",
//     label: "Projects",
//     image: projectIcon,
//   },
//   {
//     route: "/meetings",
//     label: "Meetings",
//     image: meetingIcon,
//   },
// ];

const BottomBar = () => {
  const [show, setShow] = useState();

  return (
    <>
      <Sidebar show={show} setShow={setShow} />
      <BottomDiv
        className='navbar fixed-bottom d-block d-lg-none'
        role='navigation'
      >
        <Nav className='w-100'>
          <div className=' d-flex flex-row justify-content-around w-100'>
            <NavItem>
              <NavLink
                to='/'
                exact
                className='nav-link'
                activeClassName='active'
              >
                <div className='row d-flex flex-column justify-content-center align-items-center'>
                  <Image src={searchIcon} />
                  <div className='tab-label'>Home</div>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to='/my-meetings'
                className='nav-link'
                activeClassName='active'
              >
                <div className='row d-flex flex-column justify-content-center align-items-center'>
                  <Image src={meetingIcon} />
                  <div className='tab-label'>Meetings</div>
                </div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to='/my-points'
                className='nav-link'
                activeClassName='active'
              >
                <div className='row d-flex flex-column justify-content-center align-items-center'>
                  <Image src={coins} />
                  <div className='tab-label'>Points</div>
                </div>
              </NavLink>
            </NavItem>

            <Button className='more-button' onClick={() => setShow(true)}>
              <div className='row d-flex flex-column justify-content-center align-items-center nav-link'>
                <Image src={moreIcon} alt='more' />
                <div className='tab-label'>More</div>
              </div>
            </Button>
          </div>
        </Nav>
      </BottomDiv>
    </>
  );
};

export default BottomBar;
