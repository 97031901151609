import React, { useState } from "react";
import { Form, Card, Image } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import down from "../../../assets/icons/dropdown-arrow.svg";
import up from "../../../assets/icons/dropup-arrow.svg";

const IndustryCard = observer(({ item, desktop }) => {
  const [open, setOpen] = useState(false);

  const { searchStore } = useStore();
  const {
    industryL2List,
    industryL1List,
    onIndustryListChanged,
    listSearchResults,
  } = searchStore;

  const onChange = (e, item, parent) => {
    if (parent && !item.industryl2?.length) {
      onIndustryListChanged(item.industryl1, e.target.checked, parent);
    } else if (!parent) {
      onIndustryListChanged(item.industryl2, e.target.checked);
    } else {
      item.industryl2.forEach((element) => {
        onIndustryListChanged(element.industryl2, e.target.checked);
      });
    }
    if (desktop) {
      listSearchResults();
    }
  };

  return (
    <Card>
      <Card.Header
        className="d-flex justify-content-between"
        onClick={() => {
          if (item.industryl2?.length) {
            setOpen(!open);
          }
        }}
      >
        <Form.Check
          label={item.industryl1}
          value={item.industryl1}
          checked={
            item.industryl2.length
              ? item.industryl2.every((child) =>
                  industryL2List.includes(child.industryl2)
                )
              : industryL1List.includes(item.industryl1)
          }
          onChange={(e) => onChange(e, item, true)}
          onClick={(e) => e.stopPropagation()}
        />
        {item.industryl2?.length ? (
          <button className="drop">
            <Image src={open ? up : down} />
          </button>
        ) : (
          <></>
        )}
      </Card.Header>
      {open && (
        <Card.Body>
          {item.industryl2?.map((child) => {
            return (
              <Form.Check
                label={child.industryl2}
                value={child.industryl2}
                key={child.industryl2}
                checked={industryL2List.includes(child.industryl2)}
                onChange={(e) => onChange(e, child)}
              />
            );
          })}
        </Card.Body>
      )}
    </Card>
  );
});

export default IndustryCard;
