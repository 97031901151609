import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { Form, Button, Alert, Image, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { VerificationText } from "../elements/styles";
import { useStore } from "../../../store";
import check from "../../../assets/icons/check.svg";
import info from "../../../assets/icons/info-circle.svg";
import back from "../../../assets/icons/back.svg";

const ForgotPassword = observer(() => {
  const location = useHistory();

  const [otp, setOtp] = useState(null);
  const [email, setUserEmail] = useState(null);
  const [show, setShow] = useState(false);
  const [resetOtpSent, setresetOtpSent] = useState(false);
  const [seconds, setSeconds] = useState();
  const { authStore } = useStore();
  const {
    sendresetOtp,
    resetOtpResponseText,
    verifyResetOtp,
    resetOtpVerified,
    reotpVerificationStatus,
    setReOtpVerificationStatus,
    setResetOtpVerified,
  } = authStore;

  useEffect(() => {
    if (seconds > 0 && resetOtpSent) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds();
    }
  }, [seconds, resetOtpSent]);

  const handleChange = (otp) => {
    setOtp(otp);
    if (otp?.length === 4) {
      verifyResetOtp(email, otp);
    }
  };

  const onSubmit = (data) => {
    setUserEmail(data.email);
    sendresetOtp(data.email, () => {
      setresetOtpSent(true);
    });
    setTimeout(() => setSeconds(60), 1000);
  };

  const resendResetOtp = () => {
    setShow(true);
    sendresetOtp(email, () => {
      setresetOtpSent(true);
    });
    setSeconds(60);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      {!resetOtpSent ? (
        <>
          <h2 className="login-title">Forgot password?</h2>

          <VerificationText>
          Please enter your registered email to receive a reset code

          </VerificationText>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Label>Email</Form.Label>

            <Form.Control
              size="sm"
              type="text"
              name="email"
              {...register("email", { required: true })}
              className={classNames({
                formerror: errors.email || resetOtpResponseText,
              })}
            />
            <div id="login-Status-Text" className="small a_loginResponseText">
              {errors.email
                ? errors.email.type === "required" && "This field is required."
                : ""}{" "}
              {resetOtpResponseText}
            </div>

            <Button
              className="text-center a_sendCode btn-default"
              type="submit"
              id="send_code"
            >
              Send reset code
            </Button>
          </Form>
        </>
      ) : (
        <>
          <Image
            src={back}
            onClick={() => setresetOtpSent(false)}
            className="a_backButton"
          />
          <h2 className="login-title">Enter verification code</h2>

          <VerificationText>
            We have sent the reset code to your mobile and email.
          </VerificationText>
          <div className="code-data">
            <Button
              onClick={() => resendResetOtp()}
              className="a_codeResend"
              disabled={seconds > 0}
            >
              Resend code
            </Button>{" "}
            {seconds && `after ${seconds} sec`}
            {show && (
              <Alert
                variant="success"
                onClose={() => setShow(false)}
                dismissible
              >
                OTP resend
              </Alert>
            )}
          </div>
          <OtpInput
            value={otp}
            isInputNum
            onChange={(e) => {
              handleChange(e);
            }}
            numInputs={4}
            separator={<span>-</span>}
            inputStyle={`otp-input ${
              reotpVerificationStatus === "Incorrect verification code" &&
              "formerror"
            }`}
            focusStyle="otp-focus"
            containerStyle="otp-content"
            isDisabled={resetOtpVerified}
          />
          <div
            id="verify-status-text"
            className="small mb-0 status-verification-text text-center"
          >
            {reotpVerificationStatus === "Verified" && (
              <div className="d-flex success">
                <div className="verify-circle">
                  <Image src={check} alt="success" />
                </div>

                <p>{reotpVerificationStatus}</p>
              </div>
            )}
            {reotpVerificationStatus === "Incorrect verification code" && (
              <div className="d-flex danger">
                <Image src={info} />
                <p className="d-flex">{reotpVerificationStatus}</p>
              </div>
            )}

            {reotpVerificationStatus === "Verifying" && (
              <div className="d-flex">
                <Spinner animation="border spinner" />
                <p>{reotpVerificationStatus}</p>
              </div>
            )}
          </div>
          <Button
            variant="light"
            className="w-100 btn-default"
            id="continue"
            disabled={!resetOtpVerified || otp?.length !== 4}
            onClick={() => {
              location.push(`/reset-password/change?email=${email}`);
              setReOtpVerificationStatus();
              setResetOtpVerified(false);
              setresetOtpSent(false);
            }}
          >
            Continue
          </Button>
        </>
      )}
    </>
  );
});

export default ForgotPassword;
