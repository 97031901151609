import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "react-bootstrap";
import { StyledModal } from "../elements/styles";
import { useHistory } from "react-router";


const EmailModal = observer(({ showAccept, setShowAccept }) => {
  

  const location = useHistory();

  return (
    <StyledModal show={showAccept}>
     


    
     
        <>
         {/* <h3>{meeting?.product_engagement_name} accepted</h3> */}

<div>
You have already responded to this meeting invite.

  </div> 
<Button
  onClick={() => {
   location.push('/my-meetings')
   setShowAccept(false)
   
  }}
  className="btn-default"
>
  Close
</Button>
        </>
     
    </StyledModal>
  );
});

export default  EmailModal;
